import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '../helper/ApiAction';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { 
    Typography, 
    Checkbox,
    Divider, 
    TextField, 
    MenuItem, 
    Button, 
    Paper, 
    IconButton, 
    Modal, 
    Avatar, 
    FormControl, 
    FormControlLabel, 
    FormHelperText, 
    Grid, 
    GridList, 
    GridListTile 
} from '@material-ui/core';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import ProductPointSection from './ProductPointSection';
import ProductOptionMultiPricing from './ProductOptionMultiPricing';

import Autocomplete from '@material-ui/lab/Autocomplete';

function useInput({ type, label, val, placeholder }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} placeholder={placeholder}/>
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

/* new part  **/

function useInputNew({ type, label, val, helpText }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error ? error : helpText} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} />
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

function useSelectAmountType({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useMultiSelect({ data, label, val , method}) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");

    const select =
        <Grid item xs={12}>
            <Autocomplete
                // disableCloseOnSelect
                // multiple
                disableCloseOnSelect = {!method}
                multiple = {method}
                id="select-wallet"
                size="small"
                options={data}
                getOptionLabel = {(option) => value == {id: "cash", wallet_name: "Cash"} ? "Cash" : option?.wallet_name || ""}
                value={ value }
                disabled = { value.id == {id: "cash", wallet_name: "Cash"} || value.id == "cash" || value.id == "Cash" ? true : false }
                onChange={(event, newValue) => {
                    setValue(newValue);
                    // if(value == {id: "cash", wallet_name: "Cash"}){
                    //     setValue("cash");
                    // }else{
                    //     setValue(newValue);
                    // }
                }}
                
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} />
                )}
            />
            {
                error
                    ?
                        _.map(error, err => {
                            return(
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {err}
                                </Typography>
                            )
                        })
                    : null
            }
        </Grid>
    return [value, select, setErrorValue, setValue];
}

/* new part  **/

export default function ProductEditOptions({data, companies, setLoading, addAlert, setData, pointList, pointData, setPointData, wallets}) 
{
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [deleteOptionIndex, setOpenDeleteOptionDialog] = useState("");
    const [deletePricingWarehouseIndex, setOpenDeletePricingWarehouseDialog] = useState("");
    const [customAttributeName, setCustomAttributeName] = useState(data.attribute_name || t('product.customAttribute'));
    const [selectedOptionIndex, setOptionIndex] = useState('');
    const [warehouses, setWarehouses] = useState([]);
    const [pricingWarehouse, setPricingWarehouse] = useState([]);
    const [warehouseErrors, setWarehouseErrors] = useState({});
    const [openImagesModal, setOpenImagesModal] = useState(false);
    const [optionImage, setOptionImage] = useState("");
    const [multiPricingData, setMultiPricingData] = useState([]);

    const [multiPriceSelected, setMultiPriceSelected] = useState([]); 

    const [deleteOptionMultiPriceIndex, setDeleteOptionMultiPriceIndex] = useState("");

    // const [price_mod, priceModInput, setPriceModError, setPriceModValue] = useInput({ type: "number", label: data.type == 'rank' ? t('product.priceStarter') : t('product.price'), val: '' });
    // const [handling_fee, handlingFeeInput, setHandlingFeeError, setHandlingFeeValue] = useInput({ type: "number", label: t('product.handlingFee'), val: '' });
    // const [packing_expense, packingExpenseInput, setPackingExpenseError, setPackingExpenseValue] = useInput({ type: "number", label: t('product.packingExpense'), val: '' });

    const [color, colorInput, setColorError, setColorValue] = useInput({ type: "text", label: t('product.color'), val: '', placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [size, sizeInput, setSizeError, setSizeValue] = useInput({ type: "text", label: t('product.size'), val: '', placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [attribute_value, attributeValueInput, setAttributeValueError, setAttributeValueValue] = useInput({ type: "text", label: data.attribute_name ? (i18n.language === 'cn' ? (data.attribute_name_cn ? data.attribute_name_cn : data.attribute_name_en) : data.attribute_name_en) : t('product.customAttribute') , val: '', placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sku, skuInput, setSkuError, setSkuValue] = useInput({ type: "text", label: "SKU", val: '' });
    const [ship_weight, weightInput, setWeightError, setWeightValue] = useInput({ type: "number", label: t('product.weightKg'), val: '' });
    const [package_length, lengthInput, setLengthError, setLengthValue] = useInput({ type: "number", label: t('product.lengthCm'), val: '' });
    const [package_width, widthInput, setWidthError, setWidthValue] = useInput({ type: "number", label: t('product.widthCm'), val: '' });
    const [package_height, heightInput, setHeightError, setHeightValue] = useInput({ type: "number", label: t('product.heightCm'), val: '' });
    const [price_mod, priceModInput, setPriceModError, setPriceModValue] = useInput({ type: "number", label: t('product.price'), val: '' });
    const [quantity, quantityInput, setQuantityError, setQuantityValue] = useInput({ type: "number", label: `${t('product.stock')}*`, val: '' });
    const [rpredeem, rpredeemInput, setRpRedeemError, setRpRedeemValue] = useInput({ type: "number", label: t('product.rpredeem'), val: '' });
    const [cost_price, costPriceInput, setCostPriceError, setCostPriceValue] = useInput({ type: "number", label: t('product.costPrice'), val: '' });
    const [retail_price, retailPriceInput, setRetailPriceError, setRetailPriceValue] = useInput({ type: "number", label: t('product.retailPrice'), val: '' });
    const [shipping_fee, shippingFeeInput, setShippingFeeError, setShippingFeeValue] = useInput({ type: "number", label: t('product.shippingFee'), val: '' });
    const [west_shipping_fee, westShippingFeeInput, setWestShippingFeeError, setWestShippingFeeValue] = useInput({ type: "number", label: t('product.westShippingFee'), val: '' });
    const [east_shipping_fee, eastShippingFeeInput, setEastShippingFeeError, setEastShippingFeeValue] = useInput({ type: "number", label: t('product.eastShippingFee'), val: '' });
    const [malaysiaMerchant, setMalaysiaMerchant] = useState(false); // if true, can set shipping for east and west msia
    const [price_mod_repurchase, priceModReInput, setPriceModReError, setPriceModReValue] = useInput({ type: "number", label: t('product.priceRepurchase'), val: '' });

    /* new part  **/

    // const [wallets, setWallets] = useState([]);

    const [defaultCashData, setDefaultCashData] = useState(false);
    const [selectedWalletID, setSelectedWalletID] = useState([]);

    const [optionMultiPriceDialog, setOptionMultiPriceDialog] = useState("");

    let setSelectionMultiple = false;
    const amountType = [{code: "fix", label: t('product.aType.fix')}, {code: "percent", label: t('product.aType.percent')}];

    const [wallet_ids, walletIdsInput, setWalletIdsError, setWalletIdsValue] = useMultiSelect({ data: wallets, label: t('product.walletIds'), val: [] , method: setSelectionMultiple });
    const [pricing_name, pricingNameInput, setPricingNameError, setPricingNameValue] = useInputNew({ type: "text", label: t('product.pricingName'), val: '' });
    const [amount_type, amountTypeInput, setAmountTypeError, setAmountTypeValue] = useSelectAmountType({ data: amountType, label: t('product.aType.title'), val: 'fix' });
    const [min_amount, minAmountInput, setMinAmountError, setMinAmountValue] = useInputNew({ type: "number", label: t('product.minAmount'), val: '' });
    const [max_rp_redeem, maxRpRedeemInput, setMaxRpRedeemInputError, setMaxRpRedeemInputValue] = useInputNew({ type: "number", label: t('product.redeemAmount'), val: '' });

    /* new part  **/
    
    const validate = { 
        color: setColorError, 
        size: setSizeError, 
        attribute_value: setAttributeValueError, 
        sku: setSkuError, 
        ship_weight: setWeightError, 
        package_length: setLengthError,
        package_width: setWidthError, 
        package_height: setHeightError, 
        price_mod: setPriceModError, 
        quantity: setQuantityError,
        rpredeem:setRpRedeemError,
        cost_price: setCostPriceError, 
        retail_price: setRetailPriceError, 
        shipping_fee: setShippingFeeError || setWestShippingFeeError, 
        shipping_fee2: setEastShippingFeeError, 
        price_mod_repurchase: setPriceModReError,
        max_rp_redeem: setMaxRpRedeemInputError ,
        min_amount: setMinAmountError ,
        amount: setAmountTypeError ,
        pricing_name: setPricingNameError ,
        wallet_id: setWalletIdsError
    };

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        })
    };

    const setModelData = ({method}) => {
        setOptionMultiPriceDialog(method);
        if ( selectedOptionIndex != "new" )
        {
            if(method !== "new")
            {
                let multiPricingSelected = _.find(multiPriceSelected, (data, key) => { return key == method; });
                let walletSelected = _.find(wallets, (data, key) => { return data.id == multiPricingSelected.wallet_settings[0].wallet_ids; });

                _.map(multiPricingSelected.wallet_settings[0].wallet_ids, (data, key) => {
                    if (data == "cash")
                    {
                        setWalletIdsValue({id: "cash", wallet_name: "Cash"});
                    }
                    else
                    {
                        setWalletIdsValue(walletSelected);
                    }
                })
                setSelectedWalletID(multiPricingSelected.id);
                setPricingNameValue(multiPricingSelected.wallet_settings[0].pricing_name);
                setAmountTypeValue(multiPricingSelected.wallet_settings[0].type);
                setMinAmountValue(multiPricingSelected.wallet_settings[0].min_amount);
                setMaxRpRedeemInputValue(multiPricingSelected.wallet_settings[0].max_rp_redeem);
            }
            else
            {
                setWalletIdsValue([]);
                setSelectedWalletID('');
                setPricingNameValue('');
                setAmountTypeValue('');
                setMinAmountValue('');
                setMaxRpRedeemInputValue("");
            }
        }
        else
        {
            if ( method != "new" )
            {
                let multiPricingSelected = _.find(multiPriceSelected, (data, key) => { return key == method; });
                let walletSelected = _.find(wallets, (data, key) => { return data.id == multiPricingSelected.wallet_settings[0].wallet_ids; });

                _.map(multiPricingSelected.wallet_settings[0].wallet_ids, (data, key) => {
                    if (data == "cash")
                    {
                        setWalletIdsValue({id: "cash", wallet_name: "Cash"});
                    }
                    else
                    {
                        setWalletIdsValue(walletSelected);
                    }
                })
                setSelectedWalletID(multiPricingSelected.id);
                setPricingNameValue(multiPricingSelected.wallet_settings[0].pricing_name);
                setAmountTypeValue(multiPricingSelected.wallet_settings[0].type);
                setMinAmountValue(multiPricingSelected.wallet_settings[0].min_amount);
                setMaxRpRedeemInputValue(multiPricingSelected.wallet_settings[0].max_rp_redeem);
            }
            else
            {
                setWalletIdsValue([]);
                setSelectedWalletID('');
                setPricingNameValue('');
                setAmountTypeValue('');
                setMinAmountValue('');
                setMaxRpRedeemInputValue("");
            }
        }
        
    }

    // useEffect(() => {
    //     getUrl('admin/wallets').then((response) => {
    //         setLoading(false);
    //         if (response.status === 1) {
    //             let w = response.data;
    //             // w.push({id: 'cash', wallet_name: t('product.cash')});
    //             setWallets(w);
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
    //     });
    // }, []);
    
    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(companies)){
            let companyIndex = companies.findIndex(item => item.id === data.merchant_company_id);
            if(companies[companyIndex])
                setWarehouses(companies[companyIndex].warehouses);
        }            
        return () => { isMountedRef.current = false };
    }, [companies, data.merchant_company_id]);
    
    useEffect(() => {
        if(selectedOptionIndex !== ""){
            if(selectedOptionIndex === "new"){
                setColorValue("");
                setSizeValue("");
                setAttributeValueValue("");
                setSkuValue("");
                setWeightValue("");
                setLengthValue("");
                setWidthValue("");
                setHeightValue("");
                setOptionImage("");
                setPointData([]);
                setMultiPricingData([]);
                setRpRedeemValue(0)
                setMultiPriceSelected([]);
                setDefaultCashData(false); 
                if(_.size(warehouses) === 0){
                    setPriceModValue("");
                    setQuantityValue("");
                    setCostPriceValue("");
                    setRetailPriceValue("");
                    setShippingFeeValue("");
                    setWestShippingFeeValue("");
                    setEastShippingFeeValue("");
                    // setHandlingFeeValue("");
                    // setPackingExpenseValue("");
                    setPriceModReValue("");
                }else{
                    setPricingWarehouse([{id: "", warehouse_id: "", price_mod: "", quantity: "", cost_price:"", retail_price: "", shipping_fee: "", shipping_fee2: "", price_mod_repurchase: "" }]);
                }
            }else{
                setColorValue(data.options[selectedOptionIndex].color || "");
                setSizeValue(data.options[selectedOptionIndex].size || "");
                setAttributeValueValue(data.options[selectedOptionIndex].attribute_value || "");
                setSkuValue(data.options[selectedOptionIndex].sku || "");
                setWeightValue(data.options[selectedOptionIndex].ship_weight || "");
                setLengthValue(data.options[selectedOptionIndex].package_length || "");
                setWidthValue(data.options[selectedOptionIndex].package_width || "");
                setHeightValue(data.options[selectedOptionIndex].package_height || "");
                setOptionImage(data.options[selectedOptionIndex].image || "");
                setRpRedeemValue(data.options[selectedOptionIndex].rp_redeem || 0)
                let tempPointData = [];
                _.map((data.options[selectedOptionIndex].points || []), (data, key) => {
                    let visible = false;
                    _.map((pointList), (point) => {
                        if(parseInt(point.id) === data.point_id){
                            visible = true;
                        }
                    });
                    if(visible){
                        tempPointData[key] = {id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: false}
                    }
                });
                let filteredArray = tempPointData.filter(item => item !== null);

                setPointData(filteredArray);
                setMultiPricingData(data.options[selectedOptionIndex].multi_pricing_id || []);
                
                const getOptionMultiPrice = new Set(data?.options[selectedOptionIndex]?.multi_pricing_id);

                const selectedMultiPricingLists = data?.multi_pricings.filter(item => getOptionMultiPrice.has(item.id.toString()));
                setMultiPriceSelected(selectedMultiPricingLists);

                setDefaultCashData(false); 
                _.map(selectedMultiPricingLists, (data, key) => {
                    if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                    {
                        setDefaultCashData(true); 
                    }
                })

                // walletCashChecking()
                if(_.size(warehouses) === 0){
                    // setPriceModValue((data.type == 'rank' ? data.options[selectedOptionIndex].pricing[0].price_mod_starter : data.options[selectedOptionIndex].pricing[0].price_mod) || "");
                    setPriceModValue(data.options[selectedOptionIndex].pricing[0].price_mod || "");
                    setQuantityValue(data.options[selectedOptionIndex].pricing[0].quantity || "");
                    setCostPriceValue(data.options[selectedOptionIndex].pricing[0].cost_price || "");
                    setRetailPriceValue(data.options[selectedOptionIndex].pricing[0].retail_price || "");
                    setShippingFeeValue(data.options[selectedOptionIndex].pricing[0].shipping_fee || "");
                    setWestShippingFeeValue(data.options[selectedOptionIndex].pricing[0].shipping_fee || "");
                    setEastShippingFeeValue(data.options[selectedOptionIndex].pricing[0].shipping_fee2 || "");
                    // setHandlingFeeValue(data.options[selectedOptionIndex].pricing[0].handling_fee || "");
                    // setPackingExpenseValue(data.options[selectedOptionIndex].pricing[0].packing_expense || "");
                    setPriceModReValue(data.options[selectedOptionIndex].pricing[0].price_mod_repurchase || "");
                }else{
                    setPricingWarehouse(data.options[selectedOptionIndex].pricing);
                }
            }
        }
    }, 
    [
        data.options, 
        warehouses, 
        selectedOptionIndex, 
        setColorValue, 
        setSizeValue, 
        setAttributeValueValue, 
        setSkuValue, 
        setWeightValue, 
        setLengthValue, 
        setWidthValue, 
        setHeightValue, 
        setPriceModValue, 
        setQuantityValue, 
        setPricingWarehouse,
        setCostPriceValue, 
        setRetailPriceValue, 
        setShippingFeeValue, 
        setWestShippingFeeValue, 
        setEastShippingFeeValue, 
        setOptionImage, 
        pointList, 
        setPointData, 
        setMultiPricingData 
    ]);

    // grab merchant company country for checking
    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/companies/${data.merchant_company_id}`).then(response => {
            if(response.status && isMountedRef.current) {
                if(response.data.country === 'MY') {
                    setMalaysiaMerchant(true);
                } else {
                    setMalaysiaMerchant(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [data.merchant_company_id]);

    const saveCustomAttributeName = () => {
        setLoading(true);

        putUrl(`admin/products/${data.id}/attribute_name`, {attribute_name: customAttributeName })
        .then((response) => {
            setLoading(false);
            let {status, message} = response;
            if(status === 1){
                addAlert('', message, 'success', '');
                let attributeName = [..._.split(customAttributeName, '|')];
                setData({ ...data, 'attribute_name': customAttributeName , [`attribute_name_en`]: attributeName[0], [`attribute_name_cn`]: attributeName[1] ? attributeName[1] : attributeName[0] });
                setOpenDialog(false);
            }else{
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };

    const saveOptions = () => {
        setLoading(true);
        resetAllFieldError();
        let apiData = {
            color,
            size,
            attribute_value,
            sku,
            ship_weight,
            package_length,
            package_width,
            package_height,
            image: optionImage,
            point_data: pointData,
            multi_pricing_id: multiPricingData,
            rpredeem,
            multi_price_datas: multiPriceSelected
        }

        if(_.size(warehouses) === 0){
            apiData.price_mod = price_mod;
            apiData.quantity = quantity;
            apiData.cost_price = cost_price;
            apiData.retail_price = retail_price;
            apiData.shipping_fee = malaysiaMerchant ? west_shipping_fee : shipping_fee;
            apiData.shipping_fee2 = east_shipping_fee;
            // apiData.handling_fee = handling_fee;
            // apiData.packing_expense = packing_expense;
            apiData.price_mod_repurchase = price_mod_repurchase;
        }else{
            apiData.warehouse_price = pricingWarehouse;
        }

        if(selectedOptionIndex !== "" && selectedOptionIndex !== "new"){
            putUrl(`admin/products/${data.id}/options/${data.options[selectedOptionIndex].id}`, apiData)
            .then((response) => {                
                let {status, message, error, data:newData} = response;
                if(isMountedRef.current) {
                    setLoading(false);
                    if(status === 1){
                        addAlert('', message, 'success', '');
                        setData(newData);
                        setOptionIndex("");
                    }else{
                        addAlert('', message, 'error', '');
                        let whErrors = {};
                        if(_.size(error) > 0){
                            _.map(error, (value, key) => {
                                if(validate[key])
                                    validate[key](value[0]);
                                else
                                    whErrors[key] = value[0];
                            })
                            if(whErrors){
                                setWarehouseErrors(whErrors);
                            }
                        }
                    }
                }
            }).catch((error) => {
                if(isMountedRef.current) {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                }
            });
        }else{

            let submit_data_checking = true;

            const uniqueWalletIds = new Set();

            _.map(multiPriceSelected, (data, key) => {
                if ( uniqueWalletIds.has(data.wallet_settings[0].wallet_ids[0]) )
                {
                    submit_data_checking = false;
                }else{
                    uniqueWalletIds.add(data.wallet_settings[0].wallet_ids[0]);
                }
            })

            if (submit_data_checking)
            {
                postUrl(`admin/products/${data.id}/options`, apiData)
                .then((response) => {
                    if(isMountedRef.current) {
                        setLoading(false);
                        let {status, message, error, data:newData} = response;
                        if(status === 1){
                            addAlert('', message, 'success', '');
                            setData(newData);
                            setOptionIndex("");
                        }else{
                            addAlert('', message, 'error', '');
                            let whErrors = {};
                            if(_.size(error) > 0){
                                _.map(error, (value, key) => {
                                    if(validate[key])
                                        validate[key](value[0]);
                                    else
                                        whErrors[key] = value[0];
                                })
                                if(whErrors){
                                    setWarehouseErrors(whErrors);
                                }
                            }
                        }
                    }
                }).catch((error) => {
                    if(isMountedRef.current) {
                        setLoading(false);
                        addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                    }
                });

            }
            else
            {
                addAlert('',  t('error.option_multi_pricing_duplicate') , 'error', '');

            }
            setLoading(false);
        }
    };

    const addPricingWarehouse = () => {
        let totalWarehouses = _.size(warehouses);
        if(totalWarehouses > 0 && _.size(pricingWarehouse) < totalWarehouses){
            let newPricingWarehouse = {id: "", warehouse_id: "", price_mod: "", quantity: "", cost_price:"", retail_price: "", shipping_fee: "", shipping_fee2: "", price_mod_repurchase: ""};
            setPricingWarehouse([...pricingWarehouse, newPricingWarehouse]);
        }else{
            addAlert('', "Create new warehouse to insert more options", 'warning', '');
        }
    };

    const deleteOption = () => {
        setLoading(true);
        let currentOptions = [...data.options];
        currentOptions.splice(deleteOptionIndex, 1);
        deleteUrl(`admin/products/${data.id}/options/${data.options[deleteOptionIndex].id}`)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                    setData({...data, 'options': [...currentOptions] });
                }else{
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        setOpenDeleteOptionDialog("");
    };

    const deletePricingWarehouse = () => {
        setLoading(true);
        let currentPricingWarehouse = [...pricingWarehouse];
        currentPricingWarehouse.splice(deletePricingWarehouseIndex, 1);
        if(pricingWarehouse[deletePricingWarehouseIndex].id !== ""){
            deleteUrl(`admin/products/${data.id}/options/${data.options[selectedOptionIndex].id}/warehouse-price/${pricingWarehouse[deletePricingWarehouseIndex].id}`)
            .then((response) => {
                setLoading(false);
                let {status, message} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                    setData({...data, 'options': {...data.options, [selectedOptionIndex]: {...data.options[selectedOptionIndex], 'pricing': [...currentPricingWarehouse] }} });
                    setPricingWarehouse([...currentPricingWarehouse]);
                }else{
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
        }else{
            setPricingWarehouse([...currentPricingWarehouse]);
            setLoading(false);
        }
        setOpenDeletePricingWarehouseDialog("");
    };

    const onChangePricingOption = (index, field, value) => {
        let currentPricingWarehouse = pricingWarehouse;
        currentPricingWarehouse[index][field] = value;
        setPricingWarehouse([...currentPricingWarehouse]);
    };

    const handleChange = ( newValue ) => {
        setLoading(true);
        if ( selectedOptionIndex != "new" )
        {
            setDefaultCashData(true);
            if (newValue == true)
            {
                setLoading(true);
                let newData = { wallet_ids: ["cash"], pricing_name: "Cash", type: "fix", min_amount: "100", max_amount: "100", max_rp_redeem: "0" };
    
                let wSettings = [];
                wSettings.push(newData)
                
                let apiData = {
                    optionID: data.options[selectedOptionIndex].id,
                    type: data.type,
                    page: "option",
                    wallet_settings: wSettings
                }
    
                postUrl(`admin/products/${data.id}/options/${data.options[selectedOptionIndex].id}/multi_pricing`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        setOptionMultiPriceDialog("");
                        setMultiPriceSelected(response.data);
                        // walletCashChecking()
                        setDefaultCashData(false); 
                        _.map(response.data, (data, key) => {
                            if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                            {
                                setDefaultCashData(true); 
                            }
                        })
                    } else {
                        if (_.size(error) > 0) {
                            // setInputErrors(error);
                            _.map(error, (value, key) => {
                                if (validate[key])
                                    validate[key](value[0]);
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
            }
        }
        else
        {
            setLoading(true);
            setDefaultCashData(true);

            let newData = { wallet_ids: ["cash"], pricing_name: "Cash", type: "fix", min_amount: "100", max_amount: "100", max_rp_redeem: "0" };

            let wSettings = [];

            wSettings.push(newData)

            let apiData = {
                type: data.type,
                page: "option",
                wallet_settings: wSettings
            }

            const newArray = [...multiPriceSelected, apiData]; 
            setMultiPriceSelected(newArray);
            setDefaultCashData(false); 
            _.map(newArray, (data, key) => {
                if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                {
                    setDefaultCashData(true); 
                }
            })
            setLoading(false);

        }
        setLoading(false);
    }


    const deleteOptionMultiPrice = () => {
        setLoading(true);
        if (  selectedOptionIndex != "new" )
        {
            deleteUrl(`admin/products/${data.id}/options/${data.options[selectedOptionIndex].id}/multi_pricing/${deleteOptionMultiPriceIndex}`)
            .then((response) => {
                setLoading(false);
                let { status, message } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    setMultiPriceSelected(response.data);
                    // walletCashChecking()
                    setDefaultCashData(false); 
                    _.map(response.data, (data, key) => {
                        if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                        {
                            setDefaultCashData(true); 
                        }
                    })
                } else {
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
        }
        else
        {
            let old_data = multiPriceSelected;

            let datas = []
            _.map(old_data, (data, key) => {
                if (key != deleteOptionMultiPriceIndex){
                    datas.push(data)
                }
            })

            setDefaultCashData(false); 
            _.map(datas, (data, key) => {
                if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                {
                    setDefaultCashData(true); 
                }
            })

            setMultiPriceSelected(datas);
            setLoading(false);
        }
        
        setDeleteOptionMultiPriceIndex("");
    };

    const changeMultipriceData = ({ method }) => {
        setLoading(true);
        if (  selectedOptionIndex != "new" )
        {
            if(method !== "new")
            {
                let set_max_rp_redeem = 0;
                if(max_rp_redeem < 0 || !max_rp_redeem){
                    set_max_rp_redeem = 0;
                }else{
                    set_max_rp_redeem = max_rp_redeem;
                }
                if(wallet_ids && pricing_name && amount_type && min_amount){
                    let newData = { wallet_ids: [wallet_ids.id], pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: min_amount, max_rp_redeem: set_max_rp_redeem };
                    let wSettings = [];
                    wSettings.push(newData)
                    let apiData = {
                        selectedWalletID: selectedWalletID,
                        optionID: data.options[selectedOptionIndex].id,
                        type: data.type,
                        page: "option",
                        wallet_settings: wSettings
                    }

                    putUrl(`admin/products/${data.id}/options/${data.options[selectedOptionIndex].id}/multi_pricing/${selectedWalletID}`, apiData)
                    .then((response) => {
                        setLoading(false);
                        let { status, message, error, data: newData } = response;
                        if (status === 1) {
                            addAlert('', message, 'success', '');
                            setOptionMultiPriceDialog("");
                            setMultiPriceSelected(response.data);
                            // walletCashChecking()
                            setDefaultCashData(false); 
                            _.map(response.data, (data, key) => {
                                if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                                {
                                    setDefaultCashData(true); 
                                }
                            })
                        } else {
        
                            if (_.size(error) > 0) {
                                _.map(error, (value, key) => {
                                    if (validate[key])
                                        validate[key](value[0]);
                                    if(key === "general")
                                        message+="\n"+value[0];
                                })
                            }
                            addAlert('', message, 'error', '');
                        }
                    }).catch((error) => {
                        setLoading(false);
                        addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                    });
                }else{
                    addAlert(t('error.fieldFieldError'));
                }
            }
            else
            {

                let set_max_rp_redeem = 0;

                if(max_rp_redeem < 0 || !max_rp_redeem){
                    set_max_rp_redeem = 0;
                }else{
                    set_max_rp_redeem = max_rp_redeem;
                }

                if(wallet_ids && pricing_name && amount_type && min_amount){
                    let newData = { wallet_ids: [wallet_ids.id], pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: min_amount, max_rp_redeem: set_max_rp_redeem };
                    let wSettings = [];
                    wSettings.push(newData)
                    let apiData = {
                        optionID: data.options[selectedOptionIndex].id,
                        type: data.type,
                        page: "option",
                        wallet_settings: wSettings
                    }
                    
                    postUrl(`admin/products/${data.id}/options/${data.options[selectedOptionIndex].id}/multi_pricing`, apiData)
                    .then((response) => {
                        setLoading(false);
                        let { status, message, error, data: newData } = response;
                        if (status === 1) {
                            addAlert('', message, 'success', '');
                            setOptionMultiPriceDialog("");
                            setMultiPriceSelected(response.data);
                            // walletCashChecking()
                            setDefaultCashData(false); 
                            _.map(response.data, (data, key) => {
                                if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                                {
                                    setDefaultCashData(true); 
                                }
                            })
                        } else {
                            if (_.size(error) > 0) {
                                // setInputErrors(error);
                                _.map(error, (value, key) => {
                                    if (validate[key])
                                        validate[key](value[0]);
                                    if(key === "general")
                                        message+="\n"+value[0];
                                })
                            }
                            addAlert('', message, 'error', '');
                        }
                    }).catch((error) => {
                        setLoading(false);
                        addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                    });
                }
                else
                {
                    addAlert(t('error.fieldFieldError'));
                }
            }
        }
        else
        {
            if(method !== "new")
            {
                let old_data = multiPriceSelected;

                let set_max_rp_redeem = 0;
                if(max_rp_redeem < 0 || !max_rp_redeem){
                    set_max_rp_redeem = 0;
                }else{
                    set_max_rp_redeem = max_rp_redeem;
                }
                
                if(wallet_ids && pricing_name && amount_type && min_amount){
                    let newData = { wallet_ids: [wallet_ids.id], pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: min_amount, max_rp_redeem: set_max_rp_redeem };
                    let wSettings = [];
                    wSettings.push(newData)
                    let apiData = {
                        type: data.type,
                        page: "option",
                        wallet_settings: wSettings
                    }

                    let datas = []
                    _.map(old_data, (data, key) => {
                        if (key != method){
                            datas.push(data)
                        }
                    })

                    const newArray = [...datas, apiData]; 
                    setMultiPriceSelected(newArray);
                    setOptionMultiPriceDialog("");
                    // walletCashChecking()
                    setDefaultCashData(false); 
                    _.map(newArray, (data, key) => {
                        if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                        {
                            setDefaultCashData(true); 
                        }
                    })
                }else{
                    addAlert(t('error.fieldFieldError'));
                }
            }
            else
            {
                
                let set_max_rp_redeem = 0;
                
                if(max_rp_redeem < 0 || !max_rp_redeem){
                    set_max_rp_redeem = 0;
                }
                else
                {
                    set_max_rp_redeem = max_rp_redeem;
                }

                if(wallet_ids && pricing_name && amount_type && min_amount) 
                {
                    let newData = { wallet_ids: [wallet_ids.id], pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: min_amount , max_rp_redeem: set_max_rp_redeem };
                    
                    let wSettings = [];
                    wSettings.push(newData)
                    let apiData = {
                        type: data.type,
                        page: "option",
                        wallet_settings: wSettings
                    }

                    const newArray = [...multiPriceSelected, apiData];
                    setMultiPriceSelected(newArray);
                    setOptionMultiPriceDialog("");
                    // walletCashChecking()
                    setDefaultCashData(false); 
                    _.map(newArray, (data, key) => {
                        if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                        {
                            setDefaultCashData(true); 
                        }
                    })
                }
                else
                {
                    addAlert(t('error.fieldFieldError'));
                }
            }
        }
        setLoading(false);
    }

    return(
        <Grid container spacing={3} style={theme.p20}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h5" style={theme.textline} component="h1">{t('product.productOptions')}</Typography>
                    <Button size="small" startIcon={<AddIcon />} onClick={() => setOptionIndex("new")}>{t('button.new')}</Button>
                </Grid>
                {/* <Divider /> */}
            </Grid>

            <Grid item xs={12} container spacing={3} className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('product.color')}</TableCell>
                                <TableCell>{t('product.size')}</TableCell>
                                <TableCell>{data.attribute_name ? (i18n.language === 'cn' ? (data.attribute_name_cn ? data.attribute_name_cn : data.attribute_name_en) : data.attribute_name_en) : t('product.customAttribute') }<EditOutlinedIcon fontSize="small" style={{ verticalAlign: "middle"}} onClick={() => setOpenDialog(true)}/></TableCell>
                                <TableCell>{t('product.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.size(data.options) > 0 ? _.map(data.options, (row, optionIndex) => {
                                return (
                                    <TableRow key={optionIndex}>
                                        <TableCell>{row.image ? <Avatar alt="product" src={row.image}/> : <Avatar>?</Avatar>}</TableCell>
                                        <TableCell>{i18n.language === 'cn' ? (row.color_cn ? row.color_cn : row.color_en) : row.color_en }</TableCell>
                                        <TableCell>{i18n.language === 'cn' ? (row.size_cn ? row.size_cn : row.size_en) : row.size_en }</TableCell>
                                        <TableCell>{i18n.language === 'cn' ? (row.attribute_value_cn ? row.attribute_value_cn : row.attribute_value_en) : row.attribute_value_en }</TableCell>
                                        <TableCell>
                                            <Grid container alignItems="center" className={classes.actionRoot}>
                                                <IconButton aria-label="edit" size="small" color="primary" onClick={() => setOptionIndex(optionIndex)}>
                                                    <EditIcon/>
                                                    </IconButton>
                                                    <Divider orientation="vertical" flexItem />
                                                    <IconButton aria-label="delete" size="small" color="secondary" onClick={() => setOpenDeleteOptionDialog(optionIndex)}>
                                                    <DeleteForeverIcon/>
                                                </IconButton>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : null }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth={true} maxWidth="sm" aria-labelledby="dialog-custom-attribute">
                <DialogTitle id="custom-attribute-dialog-title">{t('product.editCustomAttribute')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('product.inputMethod')}</DialogContentText>
                    <DialogContentText>{t('product.inputMethodExample')}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={t('product.customAttribute')}
                        type="email"
                        value={customAttributeName}
                        fullWidth
                        onChange={e => setCustomAttributeName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">{t('button.cancel')}</Button>
                    <Button onClick={saveCustomAttributeName} color="primary">{t('button.save')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteOptionIndex !== "" ? true : false}
                onClose={() => setOpenDeleteOptionDialog("")}
                aria-labelledby="delete-option-dialog-title"
                aria-describedby="delete-option-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('product.confirmationOnDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('product.askDeleteOption')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteOptionDialog("")} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={deleteOption} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                // open={deletePricingWarehouseIndex !== "" ? true : false}
                open={deletePricingWarehouseIndex !== "" ? true : false}
                onClose={() => setOpenDeletePricingWarehouseDialog("")}
                aria-labelledby="delete-pricing-warehouse-dialog-title"
                aria-describedby="delete-pricing-warehouse-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('product.confirmationOnDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('product.askDeleteWarehousePricingOption')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeletePricingWarehouseDialog("")} color="secondary">{t('button.nope')}</Button>
                    <Button onClick={deletePricingWarehouse} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>

            <Modal
                open={selectedOptionIndex !== '' ? true : false}
                onClose={() => {setOptionIndex(""); resetAllFieldError();} }
                aria-labelledby="product option"
                aria-describedby="product option modal"
            >
                <Grid container spacing={3} className={classes.modal}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>

                                {/* variant  */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{selectedOptionIndex === "new" ? t('product.addVariant') : t('product.editVariant')}</Typography>
                                    <Divider />
                                </Grid>
                                {colorInput}
                                {sizeInput}
                                {attributeValueInput}
                                {skuInput}

                                {/* package size */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{t('product.packagingSize')}</Typography>
                                    <Divider />
                                </Grid>
                                {weightInput}
                                {lengthInput}
                                {widthInput}
                                {heightInput}


                                {/* prices */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{t('product.prices')}</Typography>
                                    <Divider />
                                </Grid>
                                {
                                _.size(warehouses) > 0 && selectedOptionIndex !== '' ?
                                (
                                    <>
                                        {_.size(pricingWarehouse) > 0 ?
                                        _.times(_.size(pricingWarehouse), (index)=> {
                                            return(
                                                <Fragment key={index}>
                                                    <Grid item xs={12} container spacing={1} alignItems="center" justify="center">
                                                        <Grid item xs={12} sm={4} md={3} spacing={1} container justify="center">
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    select
                                                                    size="small"
                                                                    label={t('product.warehouse')}
                                                                    value={pricingWarehouse[index].warehouse_id}
                                                                    onChange={({ target }) => onChangePricingOption(index, 'warehouse_id', target.value)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={warehouseErrors[`warehouse_price.${index}.warehouse_id`] ? true : false}
                                                                >
                                                                    {warehouses.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                                {warehouseErrors[`warehouse_price.${index}.warehouse_id`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.warehouse_id`]}</FormHelperText>
                                                                : null}
                                                            </Grid>
                                                            <IconButton aria-label="delete" size="small" color="primary" onClick={() => setOpenDeletePricingWarehouseDialog(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={9} spacing={1} container>
                                                            <Grid item xs={6} sm={6}>
                                                                {/* <TextField fullWidth size="small" label={data.type == 'rank' ? t('product.priceStarter') : t('product.price')} variant="outlined" value={pricingWarehouse[index].price_mod} onChange={e => onChangePricingOption(index, 'price_mod', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.price_mod`] ? true : false} type="number"/> */}
                                                                <TextField fullWidth size="small" label={t('product.price')} variant="outlined" value={pricingWarehouse[index].price_mod} onChange={e => onChangePricingOption(index, 'price_mod', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.price_mod`] ? true : false} type="number"/>
                                                                {warehouseErrors[`warehouse_price.${index}.price_mod`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.price_mod`]}</FormHelperText>
                                                                : null}
                                                            </Grid>
                                                            {/* {
                                                                data.type == 'rank'
                                                                    ?
                                                                    <Grid item xs={6} sm={6}>
                                                                        <TextField fullWidth size="small" label={t('product.priceRepurchase')} variant="outlined" value={pricingWarehouse[index].price_mod_repurchase} onChange={e => onChangePricingOption(index, 'price_mod_repurchase', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.price_mod_repurchase`] ? true : false} type="number" />
                                                                        {warehouseErrors[`warehouse_price.${index}.price_mod_repurchase`] ?
                                                                            <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.price_mod_repurchase`]}</FormHelperText>
                                                                            : null}
                                                                    </Grid>
                                                                    : null
                                                            } */}
                                                            <Grid item xs={6} sm={6}>
                                                                <TextField fullWidth size="small" label={t('product.quantity')} variant="outlined" value={pricingWarehouse[index].quantity} onChange={e => onChangePricingOption(index, 'quantity', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.quantity`] ? true : false} type="number" />
                                                                {warehouseErrors[`warehouse_price.${index}.quantity`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.quantity`]}</FormHelperText>
                                                                : null}
                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <TextField fullWidth size="small" label={t('product.costPrice')} variant="outlined" value={pricingWarehouse[index].cost_price} onChange={e => onChangePricingOption(index, 'cost_price', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.cost_price`] ? true : false} type="number"/>
                                                                {warehouseErrors[`warehouse_price.${index}.cost_price`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.cost_price`]}</FormHelperText>
                                                                : null}
                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <TextField fullWidth size="small" label={t('product.retailPrice')} variant="outlined" value={pricingWarehouse[index].retail_price} onChange={e => onChangePricingOption(index, 'retail_price', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.retail_price`] ? true : false} type="number"/>
                                                                {warehouseErrors[`warehouse_price.${index}.retail_price`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.retail_price`]}</FormHelperText>
                                                                : null}
                                                            </Grid>
                                                            { data.shipping_channel === "self" ? 
                                                                <>
                                                                    {/* { malaysiaMerchant ?
                                                                        <>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <TextField fullWidth size="small" label={t('product.westShippingFee')} variant="outlined" value={pricingWarehouse[index].shipping_fee} onChange={e => onChangePricingOption(index, 'shipping_fee', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.shipping_fee`] ? true : false} type="number"/>
                                                                                {warehouseErrors[`warehouse_price.${index}.shipping_fee`] ?
                                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.shipping_fee`]}</FormHelperText>
                                                                                : null}
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <TextField fullWidth size="small" label={t('product.eastShippingFee')} variant="outlined" value={pricingWarehouse[index].shipping_fee2} onChange={e => onChangePricingOption(index, 'shipping_fee2', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.shipping_fee2`] ? true : false} type="number"/>
                                                                                {warehouseErrors[`warehouse_price.${index}.shipping_fee2`] ?
                                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.shipping_fee2`]}</FormHelperText>
                                                                                : null}
                                                                            </Grid>
                                                                        </>
                                                                    : <Grid item xs={6} sm={6}>
                                                                        <TextField fullWidth size="small" label={t('product.shippingFee')} variant="outlined" value={pricingWarehouse[index].shipping_fee} onChange={e => onChangePricingOption(index, 'shipping_fee', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.shipping_fee`] ? true : false} type="number"/>
                                                                        {warehouseErrors[`warehouse_price.${index}.shipping_fee`] ?
                                                                        <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.shipping_fee`]}</FormHelperText>
                                                                        : null}
                                                                    </Grid>} */}
                                                                </>
                                                            : null }
                                                            {/* <Grid item xs={6} sm={6}>
                                                                <TextField fullWidth size="small" label={t('product.handlingFee')} variant="outlined" value={pricingWarehouse[index].handling_fee} onChange={e => onChangePricingOption(index, 'handling_fee', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.handling_fee`] ? true : false} type="number"/>
                                                                {warehouseErrors[`warehouse_price.${index}.handling_fee`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.handling_fee`]}</FormHelperText>
                                                                : null}
                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <TextField fullWidth size="small" label={t('product.packingExpense')} variant="outlined" value={pricingWarehouse[index].packing_expense} onChange={e => onChangePricingOption(index, 'packing_expense', e.target.value)} error={warehouseErrors[`warehouse_price.${index}.packing_expense`] ? true : false} type="number"/>
                                                                {warehouseErrors[`warehouse_price.${index}.packing_expense`] ?
                                                                <FormHelperText style={{ color: "red" }}>{warehouseErrors[`warehouse_price.${index}.packing_expense`]}</FormHelperText>
                                                                : null}
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    {index !== _.size(pricingWarehouse)-1 ?
                                                    <Grid item xs={12}><Divider /></Grid>: null}
                                                </Fragment>
                                            );
                                        })
                                        : null}
                                        <Grid item xs={12}>
                                            <IconButton aria-label="add" size="small" color="primary" onClick={addPricingWarehouse}>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Grid>
                                    </>
                                )
                                :  
                                (
                                    <>
                                    {priceModInput}
                                    {/* {data.type == 'rank' ? priceModReInput : null} */}
                                    {quantityInput}
                                    {costPriceInput}
                                    {retailPriceInput}
                                    { data.shipping_channel === "self" ? 
                                        <>
                                            { malaysiaMerchant ? 
                                                <>
                                                    { westShippingFeeInput }
                                                    { eastShippingFeeInput }
                                                </>
                                            : shippingFeeInput }
                                        </>
                                    : null }
                                    {/* {handlingFeeInput} */}
                                    {/* {packingExpenseInput} */}
                                    {rpredeemInput}
                                    </>
                                )
                                }

                                {/* variant iamge */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{t('product.variantImage')}</Typography>
                                    <Divider />
                                </Grid>

                                {/* image */}
                                <Grid item xs={12}>
                                    {optionImage ? 
                                        <Avatar alt="product" variant="rounded" src={optionImage} className={classes.large} onClick={() => {setOpenImagesModal(true);}}/>
                                        : 
                                        <Avatar alt="product" variant="rounded" className={classes.large} onClick={() => {setOpenImagesModal(true);}}>?</Avatar>}
                                </Grid>

                                {/* {
                                    _.size(pointList) > 0
                                    ?
                                    <ProductPointSection pointList={pointList} pointData={pointData} setPointData={setPointData} />
                                    :
                                    null
                                } */}
                                {
                                    // _.size(data.multi_pricings) > 0
                                    // ?
                                    // <ProductOptionMultiPricing multiPricingList={data.multi_pricings} multiPricingData={multiPricingData} setMultiPricingData={setMultiPricingData} wallets={wallets} />
                                    // :
                                    // null
                                }

                                {/* multi pricing */}

                                {
                                    // selectedOptionIndex !== "new" && selectedOptionIndex !== "" ?
                                    <>
                                        <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                                            <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.multiPricings')}</Typography>
                                            <Button size="small" startIcon={<AddIcon />} onClick={() => setModelData({ method : "new" }) }>{t('button.new')}</Button>
                                        </Grid>

                                        <Grid item container sx={{p: 0}} direction="row" justify="flex-end" alignItems="flex-end" style={{padding: 0}}>
                                            <FormControl component="fieldset">
                                                <FormControlLabel
                                                    // value="start"
                                                    checked={ defaultCashData }
                                                    disabled = { defaultCashData ? true : false }
                                                    onChange={(e) => handleChange(e.target.checked)}
                                                    control={<Checkbox />}
                                                    label={t('product.cashWallet')}
                                                    // label={t('product.selfShipping')}
                                                    // label="Auto Create Cash Wallet"
                                                    labelPlacement="start"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <TableContainer>
                                                <Table
                                                    // className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    size={'medium'}
                                                    aria-label="table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {/* <TableCell>{t('product.wType.title')}</TableCell> */}
                                                            <TableCell>{t('product.walletSettings')}</TableCell>
                                                            <TableCell>{t('product.action')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    
                                                    <TableBody>
                                                        {_.size(multiPriceSelected) > 0 ? 
                                                        _.map(multiPriceSelected, (row, rowIndex) => {
                                                            return (
                                                                <TableRow key={rowIndex}>
                                                                    {/* <TableCell>{t('product.wType.' + row.type)}</TableCell> */}
                                                                    <TableCell>
                                                                        {
                                                                            _.size(row.wallet_settings) > 0
                                                                                ?
                                                                                _.map(row.wallet_settings, (setting, key) => {
                                                                                    return (
                                                                                        <Typography key={key}>
                                                                                            {setting.pricing_name + ' : ' + setting.min_amount + (setting.type === 'percent' ? ' % ' : '')}
                                                                                        </Typography>
                                                                                    );
                                                                                })
                                                                                :
                                                                                null
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell>

                                                                        <Grid container alignItems="center" className={classes.actionRoot}>
                                                                            
                                                                            <IconButton aria-label="edit" size="small" color="primary" onClick={() => setModelData({ method : rowIndex })}>
                                                                                <EditIcon />
                                                                            </IconButton>

                                                                            <Divider orientation="vertical" flexItem />
                                                                            
                                                                            <IconButton aria-label="delete" size="small" color="secondary" onClick={() => setDeleteOptionMultiPriceIndex( (selectedOptionIndex != 'new') ?  multiPriceSelected[rowIndex].id : rowIndex) }>
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>

                                                                        </Grid>

                                                                    </TableCell>

                                                                </TableRow>
                                                            );
                                                        }) 
                                                        : 
                                                        <TableRow>
                                                            <Grid container alignItems="center">
                                                                <Typography>data null</Typography>
                                                            </Grid>
                                                        </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                    </>
                                    // :
                                    // null
                                }

                                {/* <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                                    
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                        {t('product.multiPricings')}
                                    </Typography>

                                    <Button size="small" startIcon={<AddIcon />} onClick={() => setModelData({ method : "new" }) }>
                                        {t('button.new')}
                                    </Button>
                                    
                                </Grid>

                                <Grid item container sx={{p: 0}} direction="row" justify="flex-end" alignItems="flex-end" style={{padding: 0}}>
                                    <FormControl component="fieldset">
                                        <FormControlLabel
                                            // value="start"
                                            checked={ defaultCashData }
                                            disabled = { defaultCashData ? true : false }
                                            onChange={(e) => handleChange(e.target.checked)}
                                            control={<Checkbox />}
                                            label={t('product.cashWallet')}
                                            // label={t('product.selfShipping')}
                                            // label="Auto Create Cash Wallet"
                                            labelPlacement="start"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TableContainer>
                                        <Table
                                            aria-labelledby="tableTitle"
                                            size={'medium'}
                                            aria-label="table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('product.walletSettings')}</TableCell>
                                                    <TableCell>{t('product.action')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            
                                            <TableBody>
                                                {_.size(multiPriceSelected) > 0 ? 
                                                _.map(multiPriceSelected, (row, rowIndex) => {
                                                    return (
                                                        <TableRow key={rowIndex}>
                                                            <TableCell>
                                                                {
                                                                    _.size(row.wallet_settings) > 0
                                                                        ?
                                                                        _.map(row.wallet_settings, (setting, key) => {
                                                                            return (
                                                                                <Typography key={key}>
                                                                                    {setting.pricing_name + ' : ' + setting.min_amount + (setting.type === 'percent' ? ' % ' : '')}
                                                                                </Typography>
                                                                            );
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </TableCell>

                                                            <TableCell>

                                                                <Grid container alignItems="center" className={classes.actionRoot}>
                                                                    
                                                                    <IconButton aria-label="edit" size="small" color="primary" onClick={() => setModelData({ method : rowIndex })}>
                                                                        <EditIcon />
                                                                    </IconButton>

                                                                    <Divider orientation="vertical" flexItem />
                                                                    
                                                                    <IconButton aria-label="delete" size="small" color="secondary" onClick={() => setDeleteOptionMultiPriceIndex(rowIndex)}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>

                                                                </Grid>

                                                            </TableCell>

                                                        </TableRow>
                                                    );
                                                }) 
                                                : 
                                                <TableRow>
                                                    <Grid container alignItems="center">
                                                        <Typography>data null</Typography>
                                                    </Grid>
                                                </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid> */}
                                
                                {/* multi price setting dialog */}
                                <Modal
                                    open={optionMultiPriceDialog !== '' ? true : false}
                                    onClose={() => { setOptionMultiPriceDialog(""); }}
                                    // onClose={() => { setOptionIndex(""); resetAllFieldError(); }}
                                    disableBackdropClick
                                    style={{ 
                                        position: 'absolute',
                                        width: '95vw',
                                        backgroundColor: theme.palette.background.paper,
                                        border: '2px solid #000',
                                        boxShadow: theme.shadows[5],
                                        left: '2.5vw',
                                        top: '25%',
                                        margin: 'auto',
                                        overflow: 'scroll',
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">{selectedOptionIndex === "new" ? t('product.addWalletSetting') : t('product.editWalletSetting')}</Typography>
                                                        <Divider />
                                                    </Grid>
                                                    {walletIdsInput}
                                                    {pricingNameInput}
                                                    {amountTypeInput}
                                                    {minAmountInput}
                                                    {maxRpRedeemInput}
                                                    {/* {maxAmountInput} */}

                                                    <Grid item xs={12} sm={12} container spacing={3} justify="center">

                                                        <Grid item xs={6} sm={4} md={3}>
                                                            <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => { setOptionMultiPriceDialog(""); resetAllFieldError(); }}>
                                                                {t('button.close')}
                                                            </Button>
                                                        </Grid>

                                                        <Grid item xs={6} sm={4} md={3}>
                                                            <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {  changeMultipriceData({ method : optionMultiPriceDialog }) } }>
                                                                {t('button.save')}
                                                            </Button>
                                                        </Grid>

                                                    </Grid>

                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Modal> 

                                {/* delete multi pricing */}
                                <Dialog
                                    open={ deleteOptionMultiPriceIndex !== "" ? true : false}
                                    onClose={() => setDeleteOptionMultiPriceIndex("")}
                                    aria-labelledby="delete-option-dialog-title"
                                    aria-describedby="delete-option-dialog-description"
                                >

                                    <DialogTitle id="alert-dialog-title">
                                        {t('product.confirmationOnDelete')}
                                    </DialogTitle>

                                    <DialogContent>

                                        <DialogContentText id="alert-dialog-description">
                                            {t('product.askDeleteMultiPricing')}
                                        </DialogContentText>

                                    </DialogContent>

                                    <DialogActions>

                                        <Button onClick={() => setDeleteOptionMultiPriceIndex("")} color="secondary">
                                            {t('button.nope')}!
                                        </Button>

                                        <Button onClick={deleteOptionMultiPrice} color="primary" autoFocus>
                                            {t('button.yesPlease')}
                                        </Button>

                                    </DialogActions>

                                </Dialog>

                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => setOptionIndex("")}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={saveOptions}>{t('button.save')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                open={openImagesModal}
                onClose={() => {setOpenImagesModal(false);} }
                aria-labelledby="images"
                aria-describedby="images modal"
            >
                <Grid container spacing={3} className={classes.imagesModal}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{t('product.selectVariantImages')}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    { _.size(data.images_array) > 0 ?
                                    <GridList cellHeight={180} className={classes.gridList}>
                                        {_.map(data.images_array, (value, key) => {
                                            return(
                                                <GridListTile key={key} onClick={() => {setOptionImage(value);setOpenImagesModal(false) }}>
                                                    <img src={value} alt={`img${key}`} />
                                                </GridListTile>
                                            )
                                        })}
                                    </GridList>
                                    :  <Typography>{t('product.noImages')}</Typography> }
                                </Grid>
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => setOpenImagesModal(false)}>{t('button.close')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    actionRoot: {
        width: 'fit-content',
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
    imagesModal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
    large: {
        width: '100px',
        height: '100px',
    },
    gridList: {
        width: 500,
      },
}));