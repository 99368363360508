import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, useTheme, TextField,Button } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import RestoreIcon from '@material-ui/icons/Restore';

import * as XLSX from 'xlsx';

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';




export default function ReportBVOrders() {
    const classes = useStyles();
    const theme = useTheme();

    const { t ,i18n} = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    // const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    // const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    // const [page, setPage] = useState(pageNumber);
    // const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [wallets , setWallets]=useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [orderStatus , setOrderStatus]=useState([]);
    // const [dateFilter, setDateFilter] = useState({ sdate:'', edate:''});
    const [dateFilter, setDateFilter] = useState(new Date());

    function handleSubmit(event) {
        event.preventDefault();        
        // setPage(1);
        // setUrl(1, rowsPerPage, searchValue);
        setUrl( searchValue);
        setQueryValue(searchValue);
        // handleReset();
    }
    function TableToolbar(props) {
        const classes = useStyles();
        const { t  } = useTranslation();
        const [value, setValue] = useState(props.val);
        const searchInputRef = useRef(null);
        const handleClear = () => {
            setValue("");
            searchInputRef.current.focus();
        };
        const exportFile = () => {
            //let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
            //XLSX.writeFile(wb, `ReportBVOrders${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
            const wb = listing.map(row =>{
                let title_translate = _.split(row.title, '|');
                let prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                let order_status ='';
                let cp ='';
                let ap ='';
                let sp ='';
                let mp ='';
                let vp ='';
                let vpt ='';
                _.map(orderStatus,(status,index)=>{
                    let status_display=[];
                    if(parseInt(status.value) == row.order_status){
                        status_display = _.split(status.desc, '|');
                        order_status = i18n.language === 'cn' ? (status_display[1] ? status_display[1] : status_display[0]) :status_display[0];
                    }
                })
                _.map(wallets, (wallet, index) => {
                    if((Number(wallet.display_setting['showDashboard']) + Number(wallet.display_setting['showMember']) + Number(wallet.display_setting['showMerchant']))!==0){
                        let decimal =wallet.decimal;
                        if(row.payment[wallet.id]){
                            let amount =parseFloat(row.payment[wallet.id]).toFixed(decimal);
                            if(wallet.code === "CP"){
                                cp =amount;
                            }else if(wallet.code === "AP"){
                                ap =amount;
                            }else if(wallet.code === "MP"){
                                mp =amount;
                            }else if(wallet.code === "SP"){
                                sp =amount;
                            }else if(wallet.code === "VP"){
                                vp =amount;
                            }else if(wallet.code === "VPT"){
                                vpt =amount;
                            }
                        }else{
                            let amount =parseFloat(0).toFixed(decimal);
                            if(wallet.code === "CP"){
                                cp =amount;
                            }else if(wallet.code === "AP"){
                                ap =amount;
                            }else if(wallet.code === "MP"){
                                mp =amount;
                            }else if(wallet.code === "SP"){
                                sp =amount;
                            }else if(wallet.code === "VP"){
                                vp =amount;
                            }else if(wallet.code === "VPT"){
                                vpt =amount;
                            }
                        }
                    }
                })
                return {
                    'Order No': row.order_no,
                    'Initiator Username': row.buyer_username ? row.buyer_username:row.buyer_name ,   
                    'Buyer Username': row.recipient_username? row.recipient_username:row.recipient_name,
                    'Product': i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] ,
                    'Sell Price': row.price,
                    'BV': Math.round(row.bv),
                    'Quantity': row.quantity,
                    'CP Amount Paid': cp?cp:Math.round(0),
                    'AP Amount Paid': ap?ap:Math.round(0),
                    'MP Amount Paid': mp?mp:Math.round(0),
                    'SP Amount Paid': sp?sp:Math.round(0),
                    "VP Amount Paid": vp?vp:Math.round(0),
                    "VPT Amount Paid":vpt?vpt:Math.round(0),
                    "Cash Amount Paid":(row.payment['cash']?parseFloat(row.payment["cash"]).toFixed(2):parseFloat(0).toFixed(2)),
                    "Status": order_status,
                    "Created At":row.created_at
                };
            
            });
            var worksheet = XLSX.utils.json_to_sheet(
                wb, {header: ["Order No", "Initiator Username", "Buyer Username", "Product", "Sell Price", "BV", "Quantity", "CP Amount Paid","AP Amount Paid","MP Amount Paid","SP Amount Paid","VP Amount Paid","VPT Amount Paid","Cash Amount Paid","Status", "Created At"]}
                );
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1' );
            XLSX.writeFile(workbook, `ReportBVOrders${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
        }
    
    
        const toolbarSearch =
            <Toolbar className={classes.toolbarRoot}>
                <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="small" /></IconButton></Tooltip>
                <form noValidate onSubmit={props.handleSubmit}>
                    <FormControl>
                        <Input
                            id="search-input"
                            placeholder={t('table.search')}
                            startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                            endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                            aria-describedby="search"
                            inputProps={{
                                'aria-label': 'search',
                            }}
                            inputRef={searchInputRef}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </FormControl>
                </form>
            </Toolbar>;
        return [value, toolbarSearch];
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage+1);
    //     setUrl(newPage+1, '', searchValue);
    // };
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(1);
    //     setUrl(1, parseInt(event.target.value, 10), searchValue);
    // };

    const setUrl = (paging, rpp, sv) => {
        // let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        let params = { };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/bv-order${searchString !== '' ? '?'+searchString : ''}`);
    }

    // const handleChangeSDateRange=(data)=>{
    //     setDateFilter({...dateFilter,sdate:data});
    // }

    // const handleChangeEDateRange=(value)=>{
    //     if(dateFilter.sdate){
    //         setDateFilter({...dateFilter,edate:value});
    //     }else{
    //         addAlert('',t('reports.bvOrder.insertEdateErr'), 'error', '');
    //     }
    // }

    const handleFilter = () => {
        // let params = { 'page': page, 'per_page': rowsPerPage, 'q': querySearch, 'sdate': dateFilter.sdate, 'edate': dateFilter.edate };
        // let params = {  'q': querySearch, 'create_at': dateFilter };
        setTableLoading(true);

        // if(dateFilter.sdate == ''){
        //     setTableLoading(false);
        //     addAlert('',t('reports.bvOrder.insertEdateErr'), 'error', '');
        // }
        // else{
            getUrl(`admin/bv_orders?q=${querySearch}&create_at=${dateFilter}`).then(result => {
                setTableLoading(false);
                if (result.status === 1) {
                    setListing(result.data);
                    // setTotalCount(result.data.total);
                    setWallets(result.wallet);
                    setCurrencyData(result.currency_data);
                    setOrderStatus(result.order_status);
                }
            }).catch((error) => {
                setTableLoading(false);
                setListing([]);
                // setTotalCount(0);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        // }  
    }

    // const handleReset = ()=>{
    //     setDateFilter({...dateFilter, sdate:'',edate:''});
    //     callApi();
    // }

    function callApi(){
        // let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch  };
        // let params = { 'q': querySearch ,'create_at': dateFilter };
        setTableLoading(true);
        getUrl(`admin/bv_orders?q=${querySearch}&create_at=${dateFilter}`).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data);
                    // setTotalCount(result.data.total);
                    setWallets(result.wallet);
                    setCurrencyData(result.currency_data);
                    setOrderStatus(result.order_status);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                // setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [ addAlert, querySearch]);
   
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.bvOrder.title')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item>
                {/* <TextField
                    id="datetime-local"
                    label={t('reports.bvOrder.from')}
                    type="date"
                    value={dateFilter.sdate}
                    onChange={({ target }) => handleChangeSDateRange(target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                />
                <TextField
                    id="datetime-local"
                    label={t('reports.bvOrder.to')}
                    type="date"
                    value={dateFilter.edate}
                    onChange={({ target }) => handleChangeEDateRange(target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                /> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            views={["year", "month"]}
                            label={t('reports.bvOrder.createdAt')}
                            minDate={new Date("2020-01-01")}
                            maxDate={new Date("2070-01-01")}
                            value={dateFilter}
                            onChange={setDateFilter}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    {/* {
                        dateFilter.sdate || dateFilter.edate?
                        <Button size="medium" variant="outlined" color="inherit" onClick={() => handleReset()} startIcon={<RestoreIcon />}>{t('button.reset')}</Button>
                        :
                        null
                    } */}
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleFilter()} startIcon={<FilterListIcon />} style={{marginLeft:10}}>{t('button.filter')}</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            id="exportTable"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('reports.bvOrder.orderNo')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.initiatorUsername')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.buyerUsername')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.product')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.sellPrice')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.bv')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.quantity')}</TableCell>
                                    {
                                        wallets && _.size(wallets)?
                                        _.map(wallets,(wallet,index)=>{
                                            let wallet_translate = _.split(wallet.name, '|');
                                            return (
                                                (Number(wallet.display_setting['showDashboard']) + Number(wallet.display_setting['showMember']) + Number(wallet.display_setting['showMerchant']))>0?
                                                    <TableCell key={index}>{t('reports.bvOrder.walletAmountPaid',{wallet: i18n.language === 'cn' ? (wallet_translate[1] ? wallet_translate[1] : wallet_translate[0]) : wallet_translate[0] })}</TableCell>
                                                :null
                                            )
                                        })
                                        :null
                                    }
                                    <TableCell>{t('reports.bvOrder.cashAmountPaid')}</TableCell>
                                    {/* <TableCell>{t('reports.bvOrder.shopName')}</TableCell> */}
                                    <TableCell>{t('reports.bvOrder.status')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.createdAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    let title_translate = _.split(row.title, '|');
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row"><Link style={{color:"#929191"}} underline='none' to={`/orders/${row.order_no}`} component={RouterLink}>{row.order_no}</Link></TableCell>
                                            <TableCell>{ row.buyer_username ? row.buyer_username:row.buyer_name }</TableCell>
                                            <TableCell>{ row.recipient_username? row.recipient_username:row.recipient_name}</TableCell>
                                            <TableCell><Typography noWrap style={{width:'7vw'}}>{ i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }</Typography ></TableCell>
                                            <TableCell>{ row.price }</TableCell>
                                            <TableCell>{ row.bv }</TableCell>
                                            <TableCell>{ row.quantity }</TableCell>
                                            {
                                                wallets && _.size(wallets) ?
                                                    _.map(wallets, (wallet, index) => {
                                                        if((Number(wallet.display_setting['showDashboard']) + Number(wallet.display_setting['showMember']) + Number(wallet.display_setting['showMerchant']))!==0){
                                                            let decimal =wallet.decimal;
                                                            let name = _.split(wallet.name, '|');
                                                            let prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                                            if(row.payment[wallet.id]){
                                                                return (
                                                                    <TableCell key={index}>  {prefix? (prefix + ' ' + parseFloat(row.payment[wallet.id]).toFixed(decimal)) : parseFloat(row.payment[wallet.id]).toFixed(decimal)}</TableCell>
                                                                )
                                                            }
                                                            return (
                                                                <TableCell key={index}>  {prefix? (prefix + ' ' + parseFloat(0).toFixed(decimal)) : parseFloat(0).toFixed(decimal)}</TableCell>
                                                            )}
                                                    })
                                                    : null
                                            }
                                            {
                                                _.size(row.payment) ?
                                                    _.map(row.payment, (paymentItem, paymentIndex) => {
                                                        let decimal = 2;
                                                        let prefix = '';
                                                        if(paymentIndex =='cash'){
                                                            prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                                                            return (
                                                                <TableCell key={paymentIndex}>  {prefix? (prefix + ' ' + parseFloat(paymentItem).toFixed(decimal)) : parseFloat(paymentItem).toFixed(decimal)}</TableCell>
                                                            )
                                                        }
                                                        return null
                                                    })
                                                    : null
                                            }
                                            {/* <TableCell>{ row.shop_name }</TableCell> */}
                                            {
                                                _.map(orderStatus,(status,index)=>{
                                                    let status_display=[];
                                                    if(parseInt(status.value) == row.order_status){
                                                        status_display = _.split(status.desc, '|');
                                                        return (
                                                            <TableCell key={index}>{ i18n.language === 'cn' ? (status_display[1] ? status_display[1] : status_display[0]) :status_display[0] }</TableCell>
                                                        )
                                                    }
                                                    return null
                                                   
                                                })

                                            }
                                            <TableCell>{ row.created_at }</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={15} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    } */}
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));