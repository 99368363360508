import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams} from 'react-router-dom';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css'

import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Switch, useTheme } from '@material-ui/core';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import CategoryPanel from './CategoryPanel';

export default function CategoryEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    // const [categoryTreeData, setCategoryTreeData] = useState([]);
    const [state, setState] = useState({
        categoryName: '',
        categoryParent: '',
        status: false,
        // created_by: '',
        // updated_by: '',
        // created_at: '',
        // updated_at: '',
    });

    let { id } = useParams();

    useEffect(() => {
        refetchCategories();
    }, []);

    useEffect(() => {
        if(_.size(categoryProducts)) {
            const selectedCategory = _.find(categoryProducts, { id: parseInt(id) });
            if(_.size(selectedCategory)) {
                setState({
                    categoryName: selectedCategory.cat_name,
                    categoryParent: selectedCategory.parent_id,
                    status: selectedCategory.status,
                });
            }
        }
        
    }, [id, categoryProducts]);

    const refetchCategories = () => {
        getUrl(`admin/categories`).then(response => {
            if(response.status) {
                setCategoryProducts(response.data);
                // setCategoryTreeData(response.tree_data);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     setLoading(true);
    //     getUrl(`admin/categories/${id}`).then(response => {
    //         if(response.status === 1 && isMountedRef.current) {
    //             setLoading(false);
    //             setState({
    //                 categoryName: response.data.cat_name,
    //                 categoryParent: response.data.parent_id,
    //                 status: response.data.status,
    //                 created_by: response.data.created_by_display,
    //                 updated_by: response.data.updated_by_display,
    //                 created_at: response.data.created_at?response.data.created_at:'-',
    //                 updated_at: response.data.updated_at?response.data.updated_at:'-',
    //             });
    //             setCategoryTreeData(response.tree_data);
    //         }else{
    //             setLoading(false);
    //             let msg = response.data;
    //             addAlert('', msg, 'error', '');
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         let msg = error+"\n"+t('error.contactSupport');
    //         addAlert('', msg, 'error', '');
    //     });
    //     return () => { isMountedRef.current = false };
    //     // eslint-disable-next-line
    // }, [id, addAlert]);
    
    // const categoryOnChange = (currentNode, selectedNodes) => {
    //     if(_.size(selectedNodes)>0){
    //         setState({ ...state, categoryParent: currentNode.value });
    //         let temp = loopTreeData(categoryTreeData, currentNode.value);
    //         setCategoryTreeData(temp);
    //     }else{
    //         setState({ ...state, categoryParent: 0 });
    //         let temp = loopTreeData(categoryTreeData, 0);
    //         setCategoryTreeData(temp);
    //     }
    // }

    const loopTreeData = (treeData, parentId) => {
        let temp = treeData;
        _.map(treeData, (data, key) => {
            if(data.value === parentId){
                temp[key].checked = true;
            }else{
                temp[key].checked = false;
            }
            if(data.children){
                temp[key].children = loopTreeData(data.children, parentId);
            }
        })
        return temp;
    }

    const changeCategoryParent = parentId => {
        setState({ ...state, categoryParent: parentId });
    }

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            id : id,
            cat_name : state.categoryName,
            parent_id : state.categoryParent,
            status : state.status?1:0,
        }
        putUrl(`admin/categories/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                refetchCategories();
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('category.editCategory')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/categories`} component={RouterLink}>{t('category.categories')}</Link>
                        <Typography style={{ color: 'black' }}>{t('category.editCategory')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
            <Paper className={classes.paper} style={theme.box1}>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                    <Grid item xs={12} md={6} style={{paddingTop: "40px"}}>
                        <TextField 
                            id="category_name" 
                            label={t('category.name')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.categoryName}
                            helperText={inputErrors.cat_name?inputErrors.cat_name:''}
                            error={inputErrors.cat_name?true:false}
                            onChange={({ target }) => setState({ ...state, categoryName: target.value }) }
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <TextField 
                            id="category_parent" 
                            label={t('category.parent')} 
                            variant="outlined" 
                            fullWidth
                            select
                            value={state.categoryParent}
                            helperText={inputErrors.parent_id?inputErrors.parent_id:''}
                            error={inputErrors.parent_id?true:false}
                            onChange={({ target }) => setState({ ...state, categoryParent: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                        >
                        <option key='0' value='0'>
                            {t('category.primaryCat')} 
                        </option>
                        {categoryProducts.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.cat_name}
                            </option>
                        ))}
                        </TextField>
                        </Grid> */}
                    {/* <Grid item xs={12} md={6}>
                        <Typography variant="body2" style={{padding: 5}}>{t('category.parent')}</Typography>
                        <DropdownTreeSelect 
                            data={categoryTreeData}
                            mode="radioSelect"
                            keepTreeOnSearch
                            keepChildrenOnSearch
                            texts={{ placeholder: t('table.search') }}
                            onChange={categoryOnChange}
                        />
                        <Typography variant="caption" style={{color: "grey"}}>
                            {t('category.primaryCatNote')}
                        </Typography>
                        {
                            inputErrors && inputErrors.parent_id
                            ?
                            <Typography variant="caption" style={{color: "red"}}>
                                {inputErrors.parent_id}
                            </Typography>
                            :null
                        }
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                        <CategoryPanel initialShow={true} initialData={categoryProducts} selectedId={id} changeCategoryParent={changeCategoryParent} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                          <Switch
                            checked={state.status?true:false}
                            onChange={({ target }) => setState({ ...state, status: target.checked }) }
                            name="category_status"
                            color="primary"
                            />
                        }
                        labelPlacement="start"
                        label={t('category.status')} 
                    />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <TextField 
                            id="created_by" 
                            label={t('category.createdBy')}  
                            variant="outlined" 
                            fullWidth 
                            value={state.created_by}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="updated_by" 
                            label={t('category.updatedBy')}  
                            variant="outlined" 
                            fullWidth 
                            value={state.updated_by}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="created_at" 
                            label={t('category.createdAt')}  
                            variant="outlined" 
                            fullWidth 
                            value={state.created_at}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="updated_at" 
                            label={t('category.updatedAt')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.updated_at}
                            disabled
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={form_submit} >{t('button.save')} </Button>
                    </Grid>
                </Grid>
            </Paper>
            </Grid>
            
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));