import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, Switch, FormControlLabel, Checkbox, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Avatar, IconButton, Modal, RootRef, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';


export default function CollectionEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({});
    const [openProductsModal, setOpenProductsModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [newProducts, setNewProducts] = useState([]);
    const [oldProducts, setOldProducts] = useState([]);
    const [productLoading, setProductLoading] = useState(false);

    function callApi() {
        setProductLoading(true);
        getUrl(`admin/collections/products?collectionId=${id}`).then(result => {
            if (isMountedRef.current) {
                if (result.status === 1) {
                    setTableData(result.data);
                }
            }
            setProductLoading(false);
        }).catch((error) => {
            setProductLoading(false);
            if (isMountedRef.current) {
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            }
        });
    }

    function callCollectionApi() {
        getUrl(`admin/collections/${id}`).then(response => {
            if (isMountedRef.current) {
                if (response.status === 1) {
                    response.data.product_ids_array = response.data.product_ids_no_rank_array;
                    setState(response.data);
                    setOldProducts(JSON.parse(response.data.product_ids));
                    console.log(response.data);
                    if(response.data.sdate && response.data.edate) {

                        const sdate_split = _.split(response.data.sdate, ' ');
                        const edate_split = _.split(response.data.edate, ' ');
                        console.log("sdate_split", sdate_split);
                        console.log("edate_split", edate_split);

                        const sdate_time_split = _.split(sdate_split[1], ':');
                        const edate_time_split = _.split(edate_split[1], ':');

                        const new_sdate = `${sdate_split[0]}T${sdate_time_split[0]}:${sdate_time_split[1]}`;
                        const new_edate = `${edate_split[0]}T${edate_time_split[0]}:${edate_time_split[1]}`;
                        console.log("new_sdate", new_sdate);
                        console.log("new_edate", new_edate);

                        setState(state => ({ ...state, collectionTime: true, sdate: new_sdate, edate: new_edate }));
                    }
                } else {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callCollectionApi();
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert]);

    const form_submit = () => {
        setLoading(true);
        setInputErrors([]);
        let updateData = {
            title: state.title,
            status: state.status ? 1 : 0,
            product_ids: _.map(state.product_ids_array, 'id'),
            collectionTime: state.collectionTime ? state.collectionTime : false,
            sdate: state.sdate ? state.sdate : null,
            edate: state.edate ? state.edate : null,
        };
        putUrl(`admin/collections/${id}`, updateData).then(response => {
            setLoading(false);
            let { status, message, errors } = response;
            if (status === 1) {
                addAlert('', message, 'success', '');
            } else {
                addAlert('', message, 'error', '');
                if (errors) {
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    const addNewProducts = () => {
        setLoading(true);
        let apiData = {
            product_ids: newProducts
        }
        putUrl(`admin/collections/${id}/products`, apiData)
            .then((response) => {
                let { status, message, error, data: newData } = response;
                if (isMountedRef.current) {
                    setLoading(false);
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        setNewProducts([]);
                        callCollectionApi();
                        setOpenProductsModal(false);
                    } else {
                        addAlert('', message, 'error', '');
                        if (response.errors) {
                            setInputErrors(response.errors);
                        }
                    }
                }
            }).catch((error) => {
                if (isMountedRef.current) {
                    setLoading(false);
                    addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
                }
            });
    };

    const deleteProductFromCollection = (productId) => {
        let productIds = [];
        productIds.push(productId);

        setOldProducts(_.difference(oldProducts, productIds));

        let currentProductIdsArray = [...state.product_ids_array];
        let deleteIndex = currentProductIdsArray.findIndex(item => item.id === productId);
        currentProductIdsArray.splice(deleteIndex, 1);
        setState({ ...state, product_ids_array: currentProductIdsArray });
    };

    const reorder = (list, startIndex, endIndex) => {
        // const result = Array.from(list);
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);

        return list;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            state.product_ids_array,
            result.source.index,
            result.destination.index
        );
        setState({
            ...state, items
        });
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
    
        ...(isDragging && {
            background: "rgb(235,235,235)"
        })
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '',
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('collection.editCollection')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/collections`} component={RouterLink}>{t('collection.collections')}</Link>
                        <Typography style={{ color: 'black' }}>{t('collection.editCollection')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.mt30 }}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="title"
                                label={t('collection.title')}
                                variant="outlined"
                                fullWidth
                                value={state.title ? state.title : ''}
                                helperText={inputErrors.title ? inputErrors.title : ''}
                                error={inputErrors.title ? true : false}
                                onChange={({ target }) => setState({ ...state, title: target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.status ? true : false}
                                        onChange={({ target }) => setState({ ...state, status: target.checked })}
                                        name="status"
                                        color="primary"
                                    />
                                }
                                labelPlacement="start"
                                label={t('table.status')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.collectionTime ? state.collectionTime : false} onChange={({ target }) => setState({ ...state, collectionTime: target.checked })} />}
                                label={t('collection.applyDatetimeCondition')}
                            />
                        </Grid>
                        {
                            state.collectionTime ?
                            <>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="datetime-local"
                                        label={t('collection.startDate')}
                                        type="datetime-local"
                                        // defaultValue="2020-01-01T00:00"
                                        value={ state.sdate ? state.sdate : "2020-01-01T00:00" }
                                        onChange={({ target }) => setState({ ...state, sdate: target.value })}
                                        // InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                <TextField
                                        id="datetime-local"
                                        label={t('collection.endDate')}
                                        type="datetime-local"
                                        // defaultValue="2020-01-01T00:00"
                                        value={ state.edate ? state.edate : "2020-01-01T00:00" }
                                        onChange={({ target }) => setState({ ...state, edate: target.value })}
                                        // InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </>
                            : null
                        }
                        
                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="flex-end">
                            <Button size="small" variant="outlined" startIcon={<AddIcon />} onClick={() => setOpenProductsModal(true)}>{t('collection.addProduct')}</Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <RootRef rootRef={provided.innerRef}>
                                            <List dense style={getListStyle(snapshot.isDraggingOver)}>
                                                {_.map(state.product_ids_array, (row, index) => {
                                                    return (
                                                        <Draggable key={`listitem-${row.id}`} draggableId={`listitem-${row.id}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ListItem
                                                                    divider
                                                                    ContainerComponent="li"
                                                                    ContainerProps={{ ref: provided.innerRef }}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}>
                                                                    <ListItemAvatar>
                                                                        {row.images_array ? <Avatar alt="product" src={row.images_array[0]} /> : <Avatar>?</Avatar>}
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={row[`title_${i18n.language}`]}
                                                                        secondary={`${row[`currency_display_${i18n.language}`]} ${row.sell_price}`}
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteProductFromCollection(row.id)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </List>
                                        </RootRef>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Grid>
                        <Modal
                            open={openProductsModal}
                            onClose={() => setOpenProductsModal(false)}
                            aria-labelledby="product list"
                            aria-describedby="product list modal"
                        >
                            <Grid container spacing={3} className={classes.modal}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} style={{ display: 'flex', height: '100%' }}>
                                        <Grid item xs={12} style={{ minHeight: '90%', display: 'flex', justifyContent:"center" }}>
                                            {
                                                productLoading ?
                                                <Box display="flex" alignItems="center">
                                                    <CircularProgress />
                                                </Box>
                                                : 
                                                <MaterialTable
                                                    title=""
                                                    columns={[
                                                        { title: '', field: 'img', render: rowData => rowData.images_array ? <Avatar alt="product" src={rowData.images_array[0]} /> : <Avatar>?</Avatar> },
                                                        { title: t('product.productName'), field: 'title_display', render: rowData => rowData.title_display },
                                                        { title: t('product.price'), field: 'sell_price' },
                                                        { title: t('product.category'), field: `category_${i18n.language}`, render: rowData => rowData[`category_${i18n.language}`] },
                                                        { title: t('product.company'), field: 'merchant_company_name' }
                                                    ]}
                                                    data={tableData}
                                                    options={{
                                                        pageSize: 20,
                                                        selection: true,
                                                        selectionProps: rowData => ({
                                                            disabled: _.includes(oldProducts, rowData.id) ? true : false
                                                        })
                                                    }}
                                                    onSelectionChange={(rows) => setNewProducts(_.map(rows, 'id'))}
                                                    style={{ ...theme.box1, ...theme.p20 }}
                                                    localization={{
                                                        pagination: {
                                                            labelDisplayedRows: t('table.labelDisplayedRows'),
                                                            labelRowsSelect: t('table.rows'),
                                                            firstTooltip: t('table.firstPage'),
                                                            previousTooltip: t('table.previousPage'),
                                                            nextTooltip: t('table.nextPage'),
                                                            lastTooltip: t('table.lastPage'),
                                                        },
                                                        toolbar: {
                                                            searchTooltip: t('table.search'),
                                                            searchPlaceholder: t('table.search'),
                                                            nRowsSelected: t('table.rowsSelected')
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: t('table.noRecord'),
                                                        }
                                                    }}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                            <Grid item xs={6} sm={4} md={3}>
                                                <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => setOpenProductsModal(false)}>{t('button.close')}</Button>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3}>
                                                <Button type="button" fullWidth variant="contained" color="primary" onClick={addNewProducts}>{t('button.add')}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Modal>
                        <Grid item xs={12} md={12}>
                            <Button variant="contained" size="medium" color="primary" onClick={form_submit} >{t('button.save')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
}));