import React, { useState, useEffect, useRef, memo } from 'react'
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles, Link, Button, Box, Typography, Grid, useTheme, Dialog, Modal, RootRef, List, ListItem, ListItemText, DialogActions, DialogContent } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { AttachFileOutlined } from '@material-ui/icons';

export default function Posts() {
    const [tableData, setTableData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const { i18n, t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const isMountedRef = useRef();

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`admin/posts`).then(response => {            
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
            }
            setLoading(false);
        }).catch((error) => {            
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
            setLoading(false);
        })

        return () => isMountedRef.current = false;
    }, []);

    const submitSorting = sortedId => {
        if(_.size(sortedId)) {
            putUrl('admin/posts', {
                'priority': sortedId
            }).then(response => {
                let { status, data } = response;
                if(status) {
                    addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
                    if(_.size(data)) {
                        setTableData(data);
                    }
                }
            }).catch((error) => {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            });
        }
    }

    const toggleDialog = () => {
        setDialogOpen(!dialogOpen);
    }

    return (
        <>
            <MaterialTable
                title={ t('title.post') }
                columns={[
                    { title: '', field: `searchable_field`, filtering: true, render: rowData => 
                        <Box display='flex' flexDirection="row" justifyContent="space-between">
                            <Box display="flex" flexDirection="column" paddingRight={2} justifyContent="center" flex={3}>
                                <Link underline='none' to={`/course/${rowData.slug}`} component={RouterLink} color="textPrimary">
                                    <Typography variant="h6" component="p" className={classes.postContainer}>{ rowData?.title[i18n.language] }</Typography>
                                    <Typography classes={{ body2: classes.txtTruncate }} variant="body2" component="div" style={{ color: '#757575' }}><div dangerouslySetInnerHTML={{ __html: rowData?.description[i18n.language] }} /></Typography>
                                    {/* <Typography variant="body2" component="p" className={classes.postContainer} style={{ color: '#757575' }}>{ rowData?.description[i18n.language] }</Typography> */}
                                </Link>
                                { _.size(rowData.attachment) > 0 ? 
                                    <Link underline='none' href={ rowData.attachment.file_name } target="_blank" component="a" color="textPrimary"><AttachFileOutlined /></Link>
                                : null }
                                <Typography variant="caption" component="p" className={classes.postContainer} style={{ color: '#757575' }}>{ `${rowData.created_at_display}` }</Typography>
                            </Box>
                            <Box display="flex" paddingLeft={2} flex={2} justifyContent="center">
                                {rowData.video ? 
                                    <YoutubeEmbed url={rowData.video} />
                                    : <p></p>
                                }
                            </Box>
                        </Box>,
                        customFilterAndSearch: (term, rowData) => { 
                            console.log("term", term);
                            if(_.isArray(term)) {
                                if(_.size(term)) {
                                    return _.includes(rowData.file_type, term[0]) ? true : false;
                                } else {
                                    return true;
                                }
                            } else {
                                if(_.size(term)) {
                                    const string = rowData.searchable_field;
                                    return string.indexOf(term) !== -1 ? true : false
                                } else {
                                    return false;
                                }
                            }
                        },
                        lookup: { 'attachment': t('post.hasAttachment'), 'video': t('post.hasVideo') } 
                    },
                ]}
                data={tableData}
                components={{
                    Toolbar: props => (
                        <>
                            <div className={ classes.toolbarSubmit }>
                            { _.includes(permissions, "post_add") ? 
                                <Link underline='none' color="inherit" to="/course/add" component={RouterLink}>
                                    <Button className={classes.toolbarButton} variant="outlined" color="primary">{t('button.add')}</Button>
                                </Link>
                            : null }
                            { _.includes(permissions, "post_edit") && _.size(tableData) > 0 ? 
                                <Button className={classes.toolbarButton} variant="contained" color="primary" onClick={toggleDialog}>{t('post.editListing')}</Button>
                            : null }
                            </div>
                            <MTableToolbar {...props} />
                        </>
                    )
                }}
                options={{
                    pageSize: 10,
                    filtering: true,
                }}
                style={{ ...theme.box1, ...theme.p20 }}
                localization={{
                    pagination: {
                        labelDisplayedRows: t('table.labelDisplayedRows'),
                        labelRowsSelect: t('table.rows'),
                        firstTooltip: t('table.firstPage'),
                        previousTooltip: t('table.previousPage'),
                        nextTooltip: t('table.nextPage'),
                        lastTooltip: t('table.lastPage'),
                    },
                    toolbar: {
                        searchTooltip: t('table.search'),
                        searchPlaceholder: t('table.search'),
                        nRowsSelected: t('table.rowsSelected')
                    },
                    body: {
                        emptyDataSourceMessage: t('table.noRecord'),
                    }
                }}
            />
            <PriorityDialog open={dialogOpen} toggle={toggleDialog} data={tableData} submitSort={submitSorting} />
        </>
    )
}

const PriorityDialog = ({ open, toggle, data, submitSort }) => {
    const [sortData, setSortData] = useState([]);

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        setSortData(data);
    }, [data]);

    const sortPost = () => {
        console.log("sortData", sortData);
        let sortedId = [];
        _.map(sortData, dataItem => {
            sortedId.push(dataItem.id);
        });
        console.log("sortedId", sortedId);
        submitSort(sortedId);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            sortData,
            result.source.index,
            result.destination.index
        );
        setSortData(items);
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
    
        ...(isDragging && {
            background: "rgb(235,235,235)"
        })
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '',
    });

    return (
        <Modal
            open={open}
            onClose={toggle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Grid container spacing={3} justify="center">
                <Grid item xs={10} sm={8} md={6}>
                    <Grid container spacing={3} className={classes.modal} justify="center">
                        <Grid item xs={12}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <RootRef rootRef={provided.innerRef}>
                                            <List dense style={getListStyle(snapshot.isDraggingOver)}>
                                                {_.map(sortData, (row, index) => {
                                                    return ([
                                                        <Draggable key={`listitem-${row.id}`} draggableId={`listitem-${row.id}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ListItem
                                                                    divider
                                                                    ContainerComponent="li"                                                                        
                                                                    ContainerProps={{ ref: provided.innerRef }}
                                                                    innerRef={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}>
                                                                    <ListItemText
                                                                        primary={row.title[`${i18n.language}`]}
                                                                    />
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                    ])
                                                })}
                                                {provided.placeholder}
                                            </List>
                                        </RootRef>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            
                        </Grid>
                        <Grid item xs={12} sm={12} container spacing={3} justify="center">
                            <Grid item xs={6} sm={4} md={3}>
                                <Button type="button" fullWidth variant="contained" color="secondary" onClick={toggle}>{t('button.close')}</Button>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3}>
                                <Button type="button" fullWidth variant="contained" color="primary" onClick={sortPost}>{t('button.save')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

const YoutubeEmbed = ({ url }) => {
    const embedId = _.split(url, '?v=')[1];

    return (
        <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    )
}

const useStyles = makeStyles(theme => ({
    toolbarSubmit: { 
        width: '100%', 
        display: 'flex', 
        flexDirection: 'row-reverse', 
        padding: theme.spacing(1) 
    },
    toolbarButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    postContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        // height: '2.86em'
    },
    modal: {
        // position: 'absolute',
        // width: 'fit-content',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        marginTop: 'auto',
        overflow: 'auto',
    },
}))