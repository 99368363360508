import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles, FormControl, FormHelperText, Grid, Breadcrumbs, Link, Paper, Box, Typography, Container, TextField, Divider, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { AttachFileOutlined } from '@material-ui/icons';
import { Editor } from '@tinymce/tinymce-react';

import { deleteUrl, getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading'

export default function PostEdit() {
    const [post, setPost] = useState({});
    const [type, setType] = useState("preview");
    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = useParams();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const history = useHistory();

    const isMountedRef = useRef();

    const callApi = () => {
        getUrl(`admin/posts/${id}`).then(response => {            
            if (response.status === 1 && isMountedRef.current) {
                setPost(response.data);
            }
        }).catch((error) => {            
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;

        callApi();

        return () => isMountedRef.current = false;
    }, []);

    const deletePost = () => {
        deleteUrl(`admin/posts/${id}`).then(response => {            
            if (response.status === 1 && isMountedRef.current) {
                history.push('/courses');
            }
        }).catch((error) => {            
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    const toggleDialog = () => {
        setDialogOpen(!dialogOpen);
    }

    return (
        <>
            <Grid container spacing={3}> 
                <Grid item xs={12} >
                    <Box display="flex" direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Typography variant="h4" component="h1">{t('title.post')}</Typography>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                            <Link to={`/courses`} component={RouterLink}>{t('title.post')}</Link>
                            {/* <Typography style={{ color: 'black' }}>{t('point.addPoint')}</Typography> */}
                        </Breadcrumbs>
                    </Box>
                    <Divider />
                    <Box display="flex" direction="row" justifyContent="flex-end" alignItems="flex-end">
                        { type === 'preview' ?
                            <>
                                { _.includes(permissions, "post_edit") ?
                                    <Button variant="contained" color="primary" className={classes.buttonContainer} onClick={ () => setType('editor') } >
                                        <Typography variant="button">{ t('button.edit') }</Typography>
                                    </Button>
                                : null }
                                { _.includes(permissions, "post_delete") ?
                                    <Button variant="outlined" color="primary" className={classes.buttonContainer} onClick={ toggleDialog } >
                                        <Typography variant="button">{ t('button.delete') }</Typography>
                                    </Button>
                                : null }
                            </>
                        : 
                        <Button variant="contained" color="primary" className={classes.buttonContainer} onClick={ () => setType('preview') }>
                            <Typography variant="button">{ t('button.preview') }</Typography>
                        </Button>
                        }
                    </Box>
                </Grid>
                { _.size(post) ?
                    <Grid item xs={12}>
                        { type === 'preview' ? <PostPreview data={post} /> : <PostEditor data={post} setPost={setPost}/> }
                    </Grid>
                : null }
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={toggleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { t('dialog.deleteDialogContent') }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleDialog} color="primary">
                        { t('button.cancel') }
                    </Button>
                    <Button onClick={ deletePost } color="primary" autoFocus>
                        { t('button.confirm') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const PostPreview = ({ data }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Box paddingY={1}>
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    { _.size(data) > 0 ? data.title[i18n.language] : null }
                </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
                { _.size(data) > 0 ? data.created_at_display : null }
            </Typography>
            { _.size(data) > 0 ? 
                <>
                    { data.cover ? 
                        <Box paddingY={1}>
                            <img src={data.cover.file_name} width="100%" alt={data.title[i18n.language]} />
                        </Box> 
                    : null }
                </>
            : null}
            <Box marginY={2}>
                <Typography variant="body2" component="div">
                    { _.size(data) > 0 ? <div dangerouslySetInnerHTML={{ __html: data.description[i18n.language] }} />
                    : null }
                </Typography>
            </Box>
            { _.size(data) ?
                <Box>
                    { data.video ? <YoutubeEmbed url={data.video} /> : null }
                    
                    { data.attachment ? 
                        <div style={{ marginTop: 10 }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>{ t('posts.attachment') }:</Typography>
                            <Link underline='none' href={ data.attachment.file_name } target="_blank" component="a">
                                <AttachFileOutlined />
                            </Link> 
                        </div>
                    : null }
                </Box>
            : null }
        </>
    )
}



const INITIAL_STATE = {
    title_en: "",
    title_cn: "",
    description_en: "",
    description_cn: "",
    video: "",
    attachment: "",
    new_attachment: "",
    cover: "",
    new_cover: "",
};

const PostEditor = ({ data, setPost }) => {
    const [state, setState] = useState(INITIAL_STATE);
    const [inputErrors, setInputErrors] = useState(INITIAL_STATE);

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        if(_.size(data)) {
            const { title, description, video, attachment, cover } = data;
            setState({
                title_en: title.en,
                title_cn: title.cn,
                description_en: description.en,
                description_cn: description.cn,
                video,
                attachment,
                new_attachment: "",
                cover,
                new_cover: "",
            })
        }
    }, [data]);

    const handleChange = (event, fieldValue) => {
        const { name, value } = event.target;
        setState(state => ({ ...state, [name]: value }));
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const fileOnChange = async event => {
        let name = event.target.name;
        if(event.target.files[0]){
            let fileBase64 = await toBase64(event.target.files[0]);
            setState({...state, [name]: fileBase64});
        }else{
            setState({...state, [name]: null});
        }
    }

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            slug: _.kebabCase(state.title_en),
            title : {en: state.title_en, cn: state.title_cn},
            description : {en: state.description_en, cn: state.description_cn},
            video : state.video,
            new_attachment: state.new_attachment,
            new_cover: state.new_cover
        }
        console.log("updateData", updateData);
        putUrl(`admin/posts/${data.id}`, updateData).then(response => {
            setLoading(false);
            let { status, message, error, data } = response;
            if(status) {
                addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
                setPost(data);
            }else{
                if (_.size(error) > 0) {
                    _.map(error, (value, key) => {
                        message += "\n " + value[0];
                    })
                    setInputErrors(error);
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return (
        <Paper variant="outlined" elevation={3} square>
            <Box padding={1}>
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <TextField 
                                id="title_en" 
                                label={t('post.title_en')}
                                variant="outlined" 
                                fullWidth 
                                value={state.title_en}
                                name="title_en"
                                helperText={inputErrors['title.en'] ? inputErrors['title.en'] :''}
                                error={inputErrors['title.en'] ? true : false}
                                onChange={ handleChange }
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField 
                                id="title_cn" 
                                label={t('post.title_cn')}
                                variant="outlined" 
                                fullWidth
                                value={state.title_cn}
                                name="title_cn"
                                helperText={inputErrors['title.cn'] ? inputErrors['title.cn'] :''}
                                error={inputErrors['title.cn'] ? true : false}
                                onChange={ handleChange }
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth size="small" error={inputErrors.description_en ? true : false}>
                            <Typography variant="subtitle2" component="label">{ t('post.description_en') }</Typography>
                            <Editor
                                apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                value={state.description_en}
                                init={{
                                    height: "70vh",
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount quickbars'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                }}
                                onEditorChange={(content, editor) => setState({ ...state, description_en: content })}
                            />
                            { inputErrors['description.en'] ? <FormHelperText>{ inputErrors['description.en'] }</FormHelperText> : null }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth size="small" error={inputErrors.description_cn ? true : false}>
                            <Typography variant="subtitle2" component="label">{ t('post.description_cn') }</Typography>
                            <Editor
                                apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                value={state.description_cn}
                                init={{
                                    height: "70vh",
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount quickbars'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                }}
                                onEditorChange={(content, editor) => setState({ ...state, description_cn: content })}
                            />
                            { inputErrors['description.cn'] ? <FormHelperText>{ inputErrors['description.cn'] }</FormHelperText> : null }
                        </FormControl>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="video"
                                fullWidth
                                label={t('post.youtubeUrl')}
                                variant="outlined"
                                value={state.video}
                                name="video"
                                onChange={ handleChange }
                                type="text"
                                placeholder={"e.g: https://www.youtube.com/watch?v=dQw4w9WgXcQ"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        { state.video ?
                            <YoutubeEmbed url={state.video}/>
                            : <p></p>
                        }
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={9} sm={6}>
                            <TextField
                                label={t('post.attachment')}
                                fullWidth
                                onChange={fileOnChange}
                                variant="outlined"
                                type="file"
                                inputProps={{ name: 'new_attachment', accept: "image/*, .pdf" }}
                                InputLabelProps={{ shrink: true }}
                                error={inputErrors.new_attachment ? true : false}
                                helperText={inputErrors.new_attachment}
                            />
                        </Grid>
                        <Grid item xs={3} sm={6}>
                        { _.size(state.attachment) ?
                            <Link underline='none' href={ state.attachment.file_name } target="_blank" component="a" color="textPrimary"><AttachFileOutlined /></Link>
                            : null
                        }
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={9} sm={6}>
                            <TextField
                                label={t('post.coverImage')}
                                fullWidth
                                onChange={fileOnChange}
                                variant="outlined"
                                type="file"
                                inputProps={{ name: 'new_cover', accept: "image/*, .pdf" }}
                                InputLabelProps={{ shrink: true }}
                                error={inputErrors.new_cover ? true : false}
                                helperText={inputErrors.new_cover}
                            />
                        </Grid>
                        <Grid item xs={3} sm={6}>
                        { _.size(state.cover) ?
                            <img src={state.cover.file_name} style={{ width: '100%', height: 'auto' }} alt="" />
                            : null
                        }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button color="primary" variant="contained" onClick={form_submit}>{t('button.update')}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    )
}

const YoutubeEmbed = ({ url }) => {
    const embedId = _.split(url, '?v=')[1];

    return (
        <iframe
            width="100%"
            height="300"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    )
}

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        margin: theme.spacing(1),
    },
}))