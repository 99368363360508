import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Grid, Paper, Button, TextField, Box, Link, FormControlLabel, Checkbox, FormHelperText, Menu, MenuItem, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getUrl, postUrl, removeLoginAccess } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { APP_NAME } from '../configs/Config';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { DropzoneArea } from 'material-ui-dropzone';

import LanguageIcon from '@material-ui/icons/Language';
import { changeLanguage as reduxChangeLanguage } from '../actions';
import { useQuery } from '../helper/Tools';

import { MEMBER_URL } from '../configs/Config';
import { Alert } from '@material-ui/lab';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function SellerRegister() {
    const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const queryUsername = query.get("username");
    const companyid = query.get("company_id");
    const [inputErrors, setInputErrors] = useState([]);

    const [companyType, setCompanyType] = useState([]);
    const [logisticList, setLogisticList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateGroup, setStateGroup] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityGroup, setCityGroup] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [districtGroup, setDistrictGroup] = useState([]);
    const [state, setState] = useState({
        companyName: '',
        shopName: '',
        slug: '',
        email: '',
        mobile: '',
        register_no: '',
        type: 0,
        pic: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        logisticPartner: [],
        about: '',
        status: 1,
        // collaborator: '',
        business_nature: '',
        product_sell: '',
        referral: '',
        accept_tnc: false,
        commencementDate: ''
    });
    const [superMerchant, setSuperMerchant] = useState({
        existing_user: queryUsername ? true : false,
        name: '',
        username: queryUsername ? queryUsername : '',
        email: '',
        mobile: '',
        role: 'super-merchant',
        pwd: '',
        cfm_pwd: '',
        referral: ''
    });
    const [success, setSuccess] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const { permissions, role, isAdmin, isMerchant, companyStatus } = useSelector(state => state.general);
    let history = useHistory();

    const handleLogisticChange = (event, values) => {
        setState({ ...state, logisticPartner: values })
    }

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/companies/type`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCompanyType(response.data);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/address`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCountryList(response.country_list);
                setStateList(response.state_list);
                setStateGroup(response.state_group);
                setCityList(response.city_list);
                setCityGroup(response.city_group);
                setDistrictList(response.district_list);
                setDistrictGroup(response.district_group);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        if (state.country) {
            getUrl(`admin/logistic_list/${state.country}`).then(response => {
                if (response.status === 1) {
                    setLogisticList(response.easy_parcel_logistic);
                }
            }).catch((error) => {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            })
        }
        // eslint-disable-next-line
    }, [addAlert, state.country]);

    useEffect(() => {
        if (companyid != null) {
            let params = { "companyid": companyid }
            getUrl(`admin/company/detail/${companyid}`, params).then(response => {
                if (response.status === 1) {
                    setState({
                        companyName: response.data.company_name,
                        shopName: response.data.shop_name,
                        slug: response.data.slug,
                        email: response.data.email,
                        mobile: response.data.mobile,
                        register_no: response.data.register_no,
                        type: response.data.type,
                        pic: response.data.pic,
                        address: response.data.address,
                        address2: response.data.address2,
                        city: response.data.city,
                        state: response.data.state,
                        zip: response.data.zip,
                        country: response.data.country,
                        logisticPartner: response.data.log_partner ? response.data.log_partner : [],
                        about: response.data.about,
                        status: response.data.status,
                        business_nature: response.data.business_nature,
                        product_sell: response.data.product_sell,
                        referral: response.data.referral,
                        accept_tnc: false,
                        commencementDate: '',
                        reason: response.data.reject_reason
                    })
                    // setLogisticList(response.easy_parcel_logistic);
                }
            }).catch((error) => {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            })
        }
        // eslint-disable-next-line
    }, [companyid]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const gobacklogin = () => {
        removeLoginAccess();
        history.replace('/login');
    }

    const form_submit = async () => {
        if (state.accept_tnc === false) {
            addAlert('', t('register.readTncPlease'), 'error', '');
            return true;
        }

        setLoading(true);
        setInputErrors("");

        let newAttachmentsBase64 = [];

        if (_.size(attachments) > 0) {
            for (const attachment of attachments) {
                newAttachmentsBase64.push(await toBase64(attachment));
            }
        }

        const updateData = {
            company_name: state.companyName,
            shop_name: state.shopName,
            slug: state.slug,
            email: state.email,
            mobile: state.mobile,
            register_no: state.register_no,
            type: state.type,
            pic: state.pic,
            address: state.address,
            address2: state.address2,
            city: state.city,
            state: state.state,
            zip: state.zip,
            country: state.country,
            log_partner: state.logisticPartner,
            about: state.about,
            business_nature: state.business_nature,
            product_sell: state.product_sell,
            referral: state.referral,
            // status: state.status?1:0,
            manager_name: superMerchant.name,
            manager_username: superMerchant.username,
            manager_email: superMerchant.email,
            manager_mobile: superMerchant.mobile,
            manager_referral: superMerchant.referral,
            // manager_role: superMerchant.role,
            manager_password: superMerchant.pwd,
            manager_password_confirmation: superMerchant.cfm_pwd,
            // collaborator: state.collaborator,
            attachments: newAttachmentsBase64,
            commencement_date: state.commencementDate,
            existing_user: superMerchant.existing_user
        }
        postUrl(`admin/companies/register`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('snackbarMsg.registerSuccess');
                addAlert('', msg, 'success', '');
                setSuccess(true);
            } else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if (response.errors) {
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

    };
    const form_update = async () => {
        setLoading(true);
        setInputErrors("");

        let newAttachmentsBase64 = [];

        if (_.size(attachments) > 0) {
            for (const attachment of attachments) {
                newAttachmentsBase64.push(await toBase64(attachment));
            }
        }

        const updateData = {
            company_id: companyid,
            company_name: state.companyName,
            shop_name: state.shopName,
            slug: state.slug,
            email: state.email,
            mobile: state.mobile,
            register_no: state.register_no,
            type: state.type,
            pic: state.pic,
            address: state.address,
            address2: state.address2,
            city: state.city,
            state: state.state,
            zip: state.zip,
            country: state.country,
            log_partner: state.logisticPartner,
            about: state.about,
            business_nature: state.business_nature,
            product_sell: state.product_sell,
            referral: state.referral,
            // status: state.status?1:0,
            manager_name: superMerchant.name,
            manager_username: superMerchant.username,
            manager_email: superMerchant.email,
            manager_mobile: superMerchant.mobile,
            manager_referral: superMerchant.referral,
            // manager_role: superMerchant.role,
            manager_password: superMerchant.pwd,
            manager_password_confirmation: superMerchant.cfm_pwd,
            // collaborator: state.collaborator,
            attachments: newAttachmentsBase64,
            commencement_date: state.commencementDate,
        }
        postUrl(`admin/companies/register/update`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('snackbarMsg.registerSuccess');
                addAlert('', msg, 'success', '');
                setSuccess(true);
            } else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if (response.errors) {
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

    };

    const [languageAnchorEl, setLanguageAnchorEl] = useState(false);
    useEffect(() => {
        if (!_.includes(['en', 'cn'], i18n.language)) {
            i18n.changeLanguage('en');
            dispatch(reduxChangeLanguage('en'));
        }
    }, [i18n.language]);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        setLanguageAnchorEl(null);
    };

    return (
        <Box style={{ margin: "2% 10%" }}>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                        <Typography variant="h4" component="h1">{t('seller.registerSeller')}</Typography>
                        {/* <Link underline='none' to={`/login`} component={RouterLink}>
                            <LockOutlinedIcon />
                        </Link> */}
                        <Box style={{ display: "flex", justifyContent: "flex-end", margin: 10 }}>
                            <Button color="primary" aria-controls="language-menu" aria-haspopup="true" onClick={(event) => setLanguageAnchorEl(event.currentTarget)}>
                                <LanguageIcon />
                                <Typography variant="caption" style={{ paddingLeft: 5 }}>{t('button.language')}</Typography>
                            </Button>
                            <Menu
                                anchorEl={languageAnchorEl}
                                id="language-menu"
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={Boolean(languageAnchorEl)}
                                onClose={() => setLanguageAnchorEl(null)}
                            >
                                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                                <MenuItem onClick={() => changeLanguage('cn')}>中文</MenuItem>
                            </Menu>
                        </Box>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    {success ?
                        <Paper className={classes.paper} style={theme.box1}>
                            <Grid container spacing={3} direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' style={{ textAlign: 'center' }}>{t('seller.registerSuccessMsg')}</Typography>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                        {companyStatus == null ?
                                            <Link underline='none' to={`/login`} component={RouterLink}>
                                                <Button variant="contained" color='primary'>{t('seller.backToLogin')}</Button>
                                            </Link>
                                            :
                                            <Button variant="contained" color='primary' onClick={gobacklogin}>{t('seller.backToLogin')}</Button>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}  >
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                        <Link underline='none' onClick={() => window.open(`${MEMBER_URL}`)} >
                                            <Button variant="contained" color='primary' >{t('button.backMember')}</Button>
                                        </Link>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Paper>
                        :
                        <Paper className={classes.paper} style={theme.box1}>
                            <Grid container spacing={3} direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary' style={{ textAlign: 'center' }}>{t('seller.sellerDetails')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {companyStatus == 4 && <Alert severity="error" style={{ fontSize: 15 }}>{state.reason}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="type"
                                        label={t('seller.type')}
                                        variant="outlined"
                                        fullWidth
                                        select
                                        value={state.type}
                                        helperText={inputErrors.type ? inputErrors.type : ''}
                                        error={inputErrors.type ? true : false}
                                        onChange={({ target }) => setState({ ...state, type: target.value })}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                        {companyType.map((value, key) => (
                                            <option key={key} value={key}>
                                                {i18n.language === 'cn' ? value['cn'] : value['en']}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <TextField
                                        id="collaborator"
                                        label={t('seller.collaborator')}
                                        variant="outlined"
                                        fullWidth
                                        value={state.collaborator}
                                        helperText={inputErrors.collaborator ? inputErrors.collaborator : ''}
                                        error={inputErrors.collaborator ? true : false}
                                        onChange={({ target }) => setState({ ...state, collaborator: target.value })}
                                    />
                                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="company_name"
                                        label={t('seller.name')}
                                        variant="outlined"
                                        fullWidth
                                        value={state.companyName}
                                        helperText={inputErrors.company_name ? inputErrors.company_name : ''}
                                        error={inputErrors.company_name ? true : false}
                                        onChange={({ target }) => setState({ ...state, companyName: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="register_no"
                                        label={t('seller.registerNo')}
                                        variant="outlined"
                                        fullWidth
                                        value={state.register_no}
                                        helperText={inputErrors.register_no ? inputErrors.register_no : ''}
                                        error={inputErrors.register_no ? true : false}
                                        onChange={({ target }) => setState({ ...state, register_no: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="shop_name"
                                        label={t('seller.shopName') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.shopName}
                                        helperText={inputErrors.shop_name ? inputErrors.shop_name : ''}
                                        error={inputErrors.shop_name ? true : false}
                                        // onChange={({ target }) => setState({ ...state, shopName: target.value, slug: target.value.replace(/ /g, '-') }) }
                                        onChange={({ target }) => {
                                            const shop_name = target.value;
                                            let slug = shop_name.split("|");
                                            slug = slug[0].replace(/[^A-Za-z0-9| ]/g, '').replace(/ /g, '-').toLowerCase()

                                            setState({ ...state, shopName: shop_name, slug })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="slug"
                                        label={t('seller.slug') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.slug}
                                        helperText={inputErrors.slug ? inputErrors.slug : t('seller.slug_helperText')}
                                        error={inputErrors.slug ? true : false}
                                        // onChange={({ target }) => setState({ ...state, slug: target.value.replace(/ /g, '-') }) }
                                        onChange={({ target }) => {
                                            const shop_slug = target.value;
                                            let slug = shop_slug.split("|");
                                            slug = slug[0].replace(/[^A-Za-z0-9-]/g, '').replace(/ /g, '-').toLowerCase()

                                            setState({ ...state, slug })
                                            // setState({ ...state, slug: target.value.replace(/ /g, '-') }).toLowerCase()
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="email"
                                        label={t('profile.email') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.email}
                                        helperText={inputErrors.email ? inputErrors.email : ''}
                                        error={inputErrors.email ? true : false}
                                        onChange={({ target }) => setState({ ...state, email: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="mobile"
                                        label={t('profile.mobile') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.mobile}
                                        helperText={inputErrors.mobile ? inputErrors.mobile : ''}
                                        error={inputErrors.mobile ? true : false}
                                        onChange={({ target }) => setState({ ...state, mobile: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="pic"
                                        label={t('seller.pic') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.pic}
                                        helperText={inputErrors.pic ? inputErrors.pic : ''}
                                        error={inputErrors.pic ? true : false}
                                        onChange={({ target }) => setState({ ...state, pic: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="country"
                                        label={t('address.country') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        select
                                        value={state.country}
                                        helperText={inputErrors.country ? inputErrors.country : ''}
                                        error={inputErrors.country ? true : false}
                                        onChange={({ target }) => setState({ ...state, country: target.value, state: '', city: '', address2: '', logisticPartner: [] })}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                        <option key='' value='' disabled>
                                            {t('address.pleaseSelect') + t('address.country')}
                                        </option>
                                        {countryList.map((option) => (
                                            <option key={option.code} value={option.code}>
                                                {i18n.language === 'cn' ? option.name_cn : option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        multiple
                                        id="log_partner"
                                        options={logisticList}
                                        disableCloseOnSelect
                                        filterSelectedOptions
                                        onChange={handleLogisticChange}
                                        value={state.logisticPartner}
                                        getOptionLabel={(option) => option.desc}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                variant="outlined"
                                                label={t('seller.logisticPartner')}
                                                fullWidth
                                                helperText={(state.country ? '' : t('seller.selectCountryForLogistic')) + t('seller.logisticLeaveBlank')}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="about"
                                        label={t('seller.about') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.about}
                                        helperText={inputErrors.about ? inputErrors.about : ''}
                                        error={inputErrors.about ? true : false}
                                        onChange={({ target }) => setState({ ...state, about: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="business_nature"
                                        label={t('seller.business_nature') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        value={state.business_nature}
                                        helperText={inputErrors.business_nature ? inputErrors.business_nature : ''}
                                        error={inputErrors.business_nature ? true : false}
                                        onChange={({ target }) => setState({ ...state, business_nature: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="product_sell"
                                        label={t('seller.product_sell') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        value={state.product_sell}
                                        helperText={inputErrors.product_sell ? inputErrors.product_sell : ''}
                                        error={inputErrors.product_sell ? true : false}
                                        onChange={({ target }) => setState({ ...state, product_sell: target.value })}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <TextField
                                        id="referral"
                                        label={t('seller.referral')}
                                        variant="outlined"
                                        fullWidth
                                        value={state.referral}
                                        helperText={inputErrors.referral ? inputErrors.referral : ''}
                                        error={inputErrors.referral ? true : false}
                                        onChange={({ target }) => setState({ ...state, referral: target.value })}
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12} md={6}>
                                    <TextField
                                        id="commencementDate"
                                        label={t('seller.commencementDate')}
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        value={state.commencementDate}
                                        helperText={inputErrors.commencement_date ? inputErrors.commencement_date : ''}
                                        error={inputErrors.commencement_date ? true : false}
                                        onChange={({ target }) => setState({ ...state, commencementDate: target.value })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid> */}

                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary' style={{ textAlign: 'center' }}>{t('seller.addressDetails')}</Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="zip"
                                        label={t('address.zip') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.zip}
                                        helperText={inputErrors.zip ? inputErrors.zip : ''}
                                        error={inputErrors.zip ? true : false}
                                        onChange={({ target }) => setState({ ...state, zip: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    {
                                        stateGroup.includes(state.country)
                                            ?
                                            <TextField
                                                id="state"
                                                label={t('address.state') + ' *'}
                                                variant="outlined"
                                                fullWidth
                                                select
                                                value={state.state}
                                                helperText={inputErrors.state ? inputErrors.state : ''}
                                                error={inputErrors.state ? true : false}
                                                onChange={({ target }) => setState({ ...state, state: target.value, city: '', address2: '' })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect') + t('address.state')}
                                                </option>
                                                {stateList.map((option) => (
                                                    (option.country_code === state.country) ?
                                                        <option key={option.id} value={option.id}>
                                                            {i18n.language === 'cn' ? option.name_cn : option.name}
                                                        </option>
                                                        : null
                                                ))}
                                            </TextField>
                                            :
                                            <TextField
                                                id="state_text"
                                                label={t('address.state') + ' *'}
                                                variant="outlined"
                                                fullWidth
                                                value={state.state}
                                                helperText={inputErrors.state ? inputErrors.state : ''}
                                                error={inputErrors.state ? true : false}
                                                onChange={({ target }) => setState({ ...state, state: target.value })}
                                            />
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {
                                        cityGroup.includes(state.state)
                                            ?
                                            <TextField
                                                id="city"
                                                label={t('address.city') + ' *'}
                                                variant="outlined"
                                                fullWidth
                                                select
                                                value={state.city}
                                                helperText={inputErrors.city ? inputErrors.city : ''}
                                                error={inputErrors.city ? true : false}
                                                onChange={({ target }) => setState({ ...state, city: target.value, address2: '' })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect') + t('address.city')}
                                                </option>
                                                {cityList.map((option) => (
                                                    (option.state_id === state.state) ?
                                                        <option key={option.id} value={option.id}>
                                                            {i18n.language === 'cn' ? option.name_cn : option.name}
                                                        </option>
                                                        : null
                                                ))}
                                            </TextField>
                                            :
                                            <TextField
                                                id="city_text"
                                                label={t('address.city') + ' *'}
                                                variant="outlined"
                                                fullWidth
                                                value={state.city}
                                                helperText={inputErrors.city ? inputErrors.city : ''}
                                                error={inputErrors.city ? true : false}
                                                onChange={({ target }) => setState({ ...state, city: target.value })}
                                            />
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {
                                        districtGroup.includes(state.city)
                                            ?
                                            <TextField
                                                id="address2"
                                                label={t('address.address2')}
                                                variant="outlined"
                                                fullWidth
                                                select
                                                value={state.address2}
                                                helperText={inputErrors.address2 ? inputErrors.address2 : ''}
                                                error={inputErrors.address2 ? true : false}
                                                onChange={({ target }) => setState({ ...state, address2: target.value })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect') + t('address.address2')}
                                                </option>
                                                {districtList.map((option) => (
                                                    (option.city_id === state.city) ?
                                                        <option key={option.id} value={option.id}>
                                                            {i18n.language === 'cn' ? option.name_cn : option.name}
                                                        </option>
                                                        : null
                                                ))}
                                            </TextField>
                                            :
                                            <TextField
                                                id="address2_text"
                                                label={t('address.address2')}
                                                variant="outlined"
                                                fullWidth
                                                value={state.address2}
                                                helperText={inputErrors.address2 ? inputErrors.address2 : ''}
                                                error={inputErrors.address2 ? true : false}
                                                onChange={({ target }) => setState({ ...state, address2: target.value })}
                                            />
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="address"
                                        label={t('address.address') + ' *'}
                                        variant="outlined"
                                        fullWidth
                                        value={state.address}
                                        helperText={inputErrors.address ? inputErrors.address : ''}
                                        error={inputErrors.address ? true : false}
                                        onChange={({ target }) => setState({ ...state, address: target.value })}
                                    />
                                </Grid>
                            </Grid>
                            {companyid == null && <Grid container spacing={3} direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary' style={{ textAlign: 'center' }}>{t('seller.merchantDetails')}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ margin: "10px" }} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={superMerchant.existing_user}
                                                onChange={(event) => setSuperMerchant({ ...superMerchant, 'existing_user': event.target.checked })}
                                                name="existing_user"
                                                color="primary"
                                            />
                                        }
                                        label={t('register.imExistingUser')}
                                    />
                                </Grid>
                                {!superMerchant.existing_user ?
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="name"
                                            label={t('profile.name') + ' *'}
                                            variant="outlined"
                                            fullWidth
                                            value={superMerchant.name}
                                            helperText={inputErrors.manager_name ? inputErrors.manager_name : ''}
                                            error={inputErrors.manager_name ? true : false}
                                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, name: target.value })}
                                        />
                                    </Grid>
                                    : null}
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        id="username"
                                        label={t('profile.username')}
                                        variant="outlined"
                                        fullWidth
                                        value={superMerchant.username}
                                        helperText={inputErrors.manager_username ? inputErrors.manager_username : ''}
                                        error={inputErrors.manager_username ? true : false}
                                        onChange={({ target }) => setSuperMerchant({ ...superMerchant, username: target.value })}
                                    />
                                </Grid>
                                {!superMerchant.existing_user ?
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="email"
                                                label={t('profile.email')}
                                                variant="outlined"
                                                fullWidth
                                                value={superMerchant.email}
                                                helperText={inputErrors.manager_email ? inputErrors.manager_email : ''}
                                                error={inputErrors.manager_email ? true : false}
                                                onChange={({ target }) => setSuperMerchant({ ...superMerchant, email: target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="mobile"
                                                label={t('profile.mobile')}
                                                variant="outlined"
                                                fullWidth
                                                value={superMerchant.mobile}
                                                helperText={inputErrors.manager_mobile ? inputErrors.manager_mobile : ''}
                                                error={inputErrors.manager_mobile ? true : false}
                                                onChange={({ target }) => setSuperMerchant({ ...superMerchant, mobile: target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="referral"
                                                label={t('profile.referral')}
                                                variant="outlined"
                                                fullWidth
                                                value={superMerchant.referral}
                                                helperText={inputErrors.manager_referral ? inputErrors.manager_referral : ''}
                                                error={inputErrors.manager_referral ? true : false}
                                                onChange={({ target }) => setSuperMerchant({ ...superMerchant, referral: target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="pwd"
                                                label={t('register.password') + ' *'}
                                                variant="outlined"
                                                fullWidth
                                                type="password"
                                                value={superMerchant.pwd}
                                                helperText={inputErrors.manager_password ? inputErrors.manager_password : ''}
                                                error={inputErrors.manager_password ? true : false}
                                                onChange={({ target }) => setSuperMerchant({ ...superMerchant, pwd: target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="cfm_pwd"
                                                label={t('register.passwordConfirmation') + ' *'}
                                                variant="outlined"
                                                fullWidth
                                                type="password"
                                                value={superMerchant.cfm_pwd}
                                                helperText={inputErrors.manager_password_confirmation ? inputErrors.manager_password_confirmation : ''}
                                                error={inputErrors.manager_password_confirmation ? true : false}
                                                onChange={({ target }) => setSuperMerchant({ ...superMerchant, cfm_pwd: target.value })}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} md={4}>
                                    <TextField
                                        id="referral"
                                        label={t('seller.referral')}
                                        variant="outlined"
                                        fullWidth
                                        value={state.referral}
                                        helperText={inputErrors.referral ? inputErrors.referral : ''}
                                        error={inputErrors.referral ? true : false}
                                        onChange={({ target }) => setState({ ...state, referral: target.value })}
                                    />
                                </Grid> */}
                                    </>
                                    : null}
                            </Grid>}
                            <Grid container spacing={3} direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary' style={{ textAlign: 'center' }}>{t('register.attachments')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <DropzoneArea
                                        acceptedFiles={['image/*', '.pdf']}
                                        dropzoneText={t('register.attachments')}
                                        onChange={(files) => isMountedRef.current ? setAttachments(files) : null}
                                        filesLimit={12}
                                        dropzoneClass={classes.dropzone}
                                        maxFileSize={2000000}
                                    />
                                    <Typography variant="body2" style={{ margin: 5 }}>{t('general.maxFile', { max: 12 }) + t('general.fileSizeLimit', { limit: '2MB', fileType: 'jpeg. jpg. png. pdf. ' })}</Typography>
                                    {inputErrors.attachments ?
                                        <FormHelperText style={{ color: "red" }}>{inputErrors.attachments}</FormHelperText>
                                        : null
                                    }
                                    <Typography variant="body2" style={{ margin: 5 }}>
                                        {
                                            i18n.language === 'cn'
                                                ?
                                                <Grid item xs={12} style={{ fontSize: "1rem" }}>
                                                    <br />
                                                    请附上: <br /><br />
                                                    个人：<br />
                                                    - 身份证/护照副本或SSM公司注册证书及公司章程副本<br /><br />
                                                    企业：<br />
                                                    - 身份证/护照副本和SSM公司注册证书及公司章程副本<br />
                                                    <ExpansionPanel spacing={1} xs={12} sm={10} >
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                                                            以下文件（如管理层无额外要求，不需上传）:
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            - 根据第58条（董事、经理和秘书登记册变更通知）的SSM表格副本<br />
                                                            - 根据第78条（股份分配返还）的SSM表格副本<br />
                                                            - 商家的品牌标志副本<br />
                                                            - 商家的商业执照副本，如商家的业务属适用法律所规定的业务类型（如适用）<br />
                                                            - 清真证书的副本（如适用）<br />
                                                            - 与商家的业务/商家产品相关的任何其他相关证明和/或许可证的副本<br />
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </Grid>
                                                :
                                                <Grid item xs={12} style={{ fontSize: "1rem" }}>
                                                    <br />
                                                    Kindly Attach: <br /><br />
                                                    For Individual:<br />
                                                    - Copy of IC or SSM Certificate of Incorporation and Company’s Constitution<br /><br />
                                                    For Coorperate:<br />
                                                    - Copy of IC and SSM Certificate of Incorporation and Company’s Constitution <br />
                                                    <ExpansionPanel spacing={1} xs={12} sm={10} >
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                                                            Following Docs (not required unless requested):
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            - Copy of SSM Form pursuant to Section 58 (Notification of Change in the Register of Directors, Managers and Secretaries)<br />
                                                            - Copy of SSM Form pursuant to Section 78 (Return of Allotment of Shares)<br />
                                                            - Copy of the Merchant’s brand logo<br />
                                                            - Copy of business licence if the Merchant’s business is of regulated business type under the Applicable Laws (if applicable)<br />
                                                            - Copy of Halal Certification (if applicable)<br />
                                                            - Copy of any other relevant certification and/or licenses related to the Merchant’s business/Merchant Offering<br />
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </Grid>
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <Grid container spacing={3} direction="row" style={theme.p20}>
                            {
                                i18n.language === 'cn'
                                ?
                                <Grid item xs={12} style={{fontSize: "1rem"}}>
                                    订阅费：免费<br/>
                                    交易费：<br/>
                                    (i) 标准结算日期 交易价值的百分之二<br/>
                                    (ii) 快速结算日期 交易价值的百分之四
                                </Grid>
                                :
                                <Grid item xs={12} style={{fontSize: "1rem"}}>
                                    Subscription Fee: Free<br/>
                                    Transaction Fee:<br/>
                                    (i) Standard Closing Dates: 2% of the Transaction Value<br/>
                                    (ii) Express Closing Date: 4% of the Transaction Value
                                </Grid>
                            }
                            </Grid> */}
                            {companyid == null &&
                                <>
                                    <Grid item xs={12} style={{ margin: "10px" }} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.accept_tnc}
                                                    onChange={(event) => setState({ ...state, 'accept_tnc': event.target.checked })}
                                                    name="accept_tnc"
                                                    color="primary"
                                                />
                                            }
                                            label={<span>{t('register.iHaveReadTheWebsite1')}{t('register.iHaveReadTheWebsite2')}</span>}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ margin: "10px auto" }} >
                                        <Button variant="contained" color='primary' onClick={form_submit} fullWidth >{t('button.submit')}</Button>
                                    </Grid>
                                </>}
                            {companyid != null &&
                                <Grid item xs={6} style={{ margin: "10px auto" }} >
                                    <Button variant="contained" color='primary' onClick={form_update} fullWidth >{t('button.update')}</Button>
                                </Grid>}
                            <Grid item xs={12} style={{ margin: "10px auto" }} >
                                <Link underline='none' onClick={() => window.open(`${MEMBER_URL}`)} >
                                    <Button variant="contained" color='primary' fullWidth >{t('button.backMember')}</Button>
                                </Link>
                            </Grid>

                        </Paper>
                    }
                </Grid>
            </Grid>
            <Box style={{ margin: 20 }}>
                <Copyright />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dropzone: {
        outline: "none",
    },
}));