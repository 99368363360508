import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from '../helper/Tools';

import _ from 'lodash';
import { formatDate } from '../helper/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles'
import { Typography, Link, Grid, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Checkbox, FormControlLabel, InputAdornment, Tooltip, Icon, Toolbar, FormControl, Input, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

function TableToolbar(props) {
    const classes = useStyles();
    const { t  } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function Members() {
    // const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [statusType, setStatusType] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const statusTypeToCode = { 'activate': 1, 'suspend': 2, 'terminate': 3 };
    const [updateDialog, setOpenUpdateDialog] = useState(false);
    const [userId, setUserId] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    // const [originalData, setOriginalData] = useState([]);
    const [filterSub, setFilterSub] = useState(false);

    const history = useHistory();
    const rowsPerPageOptiosArray = [10, 20, 30];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 20;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [totalCount, setTotalCount] = useState(0);

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/members${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order, 'filter_sub': filterSub?1:0 };
        getUrl(`admin/members/listing`, params).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data.data);
                // setOriginalData(response.data.data);
                setTotalCount(response.data.total);
                setUserId(response.own_id);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, page, rowsPerPage, querySearch, orderBy, order, filterSub]);

    const alertChangeStatus = (event, data, type) => {
        setStatusType(type);
        setSelectedRow(data);
        setOpenUpdateDialog(true);
    };

    const updateStatus = () => {
        setLoading(true);

        let apiData = { status: statusTypeToCode[statusType], ids: _.map(selectedRow, 'id') };
        putUrl(`admin/members/status`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    _.map(selectedRow, (row, index) => {
                        let dataIndex = tableData.findIndex(item => item.id === row.id);
                        newTableData[dataIndex] = { ...row, status: statusTypeToCode[statusType] };
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateDialog(false);
    };

    useEffect(() => {
        setPage(1);
        setUrl(1, rowsPerPage, querySearch, orderBy, order);
    }, [filterSub]);

    const handlePageChange = (page) => {
        setPage(page+1);
        setUrl(page+1, rowsPerPage, querySearch, orderBy, order);
    }
    const handleRowsPerPageChange = (pageSize) => {
        setRowsPerPage(pageSize);
        setPage(1);
        setUrl(1, pageSize, querySearch, orderBy, order);
    }
    const handleOrderChange = (orderedColumnId, orderDirection) => {
        let tempOrderColumn = '';
        let tempOrder = 'asc';
        switch(orderedColumnId){
            case 0: tempOrderColumn = 'id'; break;
            case 1: tempOrderColumn = 'name'; break;
            case 2: tempOrderColumn = 'username'; break;
            case 3: tempOrderColumn = 'email'; break;
            case 4: tempOrderColumn = ''; break; // referral
            case 5: tempOrderColumn = ''; break; // placement
            case 6: tempOrderColumn = 'rank'; break;
            case 7: tempOrderColumn = 'crank'; break;
            case 8: tempOrderColumn = 'created_at'; break;
            case 9: tempOrderColumn = 'expiry_date'; break;
            case 10: tempOrderColumn = 'role'; break;
            case 11: tempOrderColumn = 'status'; break;
            default: tempOrderColumn = '';
        }
        if(tempOrderColumn === orderBy){
            tempOrder = order === "asc" ? "desc" : "asc";
        }else{
            tempOrder = orderDirection;
        }
        setOrderBy(tempOrderColumn);
        setOrder(tempOrder);
        setPage(1);
        setUrl(1, rowsPerPage, querySearch, tempOrderColumn, tempOrder);
    }
    const handleSearchChange = (value) => {
        setQueryValue(value);
        setPage(1);
        setUrl(1, rowsPerPage, value, orderBy, order);
    }
    function handleSubmit(event) {
        event.preventDefault();        
        setUrl( searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('member.members')}</Typography>
                    {_.includes(permissions, "member_add") ?
                        <Link underline='none' color="inherit" to="/members/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterSub}
                            onChange={({ target }) => setFilterSub(target.checked) }
                            color="primary"
                        />
                    }
                    label={t('profile.filterSubAcc')}
                />
                 {searchToolbar}
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('profile.name'), field: 'name', render: rowData => (rowData.id!==userId)?<Link underline='none' to={`/members/${rowData.id}`} component={RouterLink}>{rowData.name}</Link>:<Typography>{rowData.name}</Typography> },
                        { title: t('profile.username'), field: 'username' },
                        { title: t('profile.email'), field: 'email' },
                        { title: t('profile.referral'), field: 'genealogy.referral_username', render: rowData => <p>{ _.size(rowData.genealogy) > 0 ? (_.size(rowData.genealogy.referral_username) > 0 ? <Link underline='none' to={`/upline?username=${rowData.genealogy.referral_username}`} component={RouterLink}>{ rowData.genealogy.referral_username }</Link> : '-' ) : '-' }</p>, sorting: false },
                        { title: t('profile.placement'), field: 'genealogy.placement_username', render: rowData => {
                            if(_.size(rowData.genealogy) > 0) {
                                let placementPosition = '';
                                if(rowData.genealogy.position === 1) {
                                    placementPosition = t('profile.placementPosition.left');
                                } else if(rowData.genealogy.position === 2) {
                                    placementPosition = t('profile.placementPosition.right');
                                }
                                return (
                                    <>
                                        <p>{ _.size(rowData.genealogy.placement_username) > 0 ? rowData.genealogy.placement_username : '-' }</p>
                                        { _.size(placementPosition) > 0 ? <p>{ `(${t('profile.position')}: ${placementPosition})` }</p> : null}
                                    </>
                                )
                            }
                            return '-'
                        }, sorting: false },                        
                        { title: t('profile.rank'), render: rowData => <p>{ _.size(rowData.user_rank) > 0 ? rowData.user_rank.name_display : '-' }</p> },
                        { title: t('profile.crank'), render: rowData => <p>{ _.size(rowData.user_crank) > 0 ? rowData.user_crank.name_display : '-' }</p> },
                        // { title: t('profile.mobile'), field: 'mobile' },
                        { title: t('profile.createdAt'), field: 'created_at',render:rowData=><p>{formatDate(rowData.created_at)}</p> },
                        { title: t('profile.expiryDate'), field: 'expiry_date',render: rowData => <p>{ _.size(rowData.expiry_date) > 0 ? formatDate(rowData.expiry_date) : '-' }</p>  },
                        { title: t('user.role'), field: 'user_role', render: rowData => t('role.' + rowData.user_role) },
                        { title: t('table.status'), field: 'status', render: rowData => t('user.status.' + rowData.status) }
                    ]}
                    data={tableData}
                    options={{
                        pageSize: rowsPerPage,
                        selection: _.includes(permissions, "member_delete") ? true : false,
                        pageSizeOptions: rowsPerPageOptiosArray,
                        debounceInterval: 1000,
                        searchText: querySearch,
                        toolbar: false
                    }}
                    page={page-1}
                    totalCount={totalCount}
                    actions={_.includes(permissions, "member_delete") ? [
                        {
                            tooltip: t('member.activateSelectedMember'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'activate')
                        },
                        {
                            tooltip: t('member.suspendSelectedMember'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'suspend')
                        },
                        {
                            tooltip: t('member.terminateSelectedMember'),
                            icon: 'delete',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'terminate')
                        }
                    ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    onChangePage = {(page) => handlePageChange(page)}
                    onChangeRowsPerPage = {(pageSize) => handleRowsPerPageChange(pageSize)}
                    onOrderChange = {(orderedColumnId, orderDirection) => handleOrderChange(orderedColumnId, orderDirection)}
                    onSearchChange = {(value) => handleSearchChange(value)}
                />
                }
            </Grid>
            <Dialog
                open={updateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('user.confirmOnStatusUpdate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`user.askUpdateStatus.${statusTypeToCode[statusType]}`, { 'number': selectedRow ? selectedRow.length : 0 })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        // flexDirection: 'row-reverse'
    },
}));

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));