import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Link, Grid, Paper, Input, 
    InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, 
    IconButton, Icon, Box, AppBar, Table, TableBody, TableCell, 
    TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, 
    Tab, Tabs,Button,Dialog,DialogContent,DialogActions,TextField
} from '@material-ui/core';
import {  ReorderOutlined, ConfirmationNumberOutlined ,SaveAlt } from '@material-ui/icons';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import * as XLSX from 'xlsx';


function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];

export default function HalojeSales() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const query = useQuery();

    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [tabValue, setTabValue] = useState(0);
    const [usernamesearch, setUsernameSearch] = useState('');

    const isMountedRef = useRef(null);


    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    }, [addAlert])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setPage(1);
        setRowsPerPage(10);
        history.push('/reports/haloje')
    };

    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/haloje${searchString !== '' ? '?'+searchString : ''}`);
    }

    function callApi(){
        let thirdparty = "All";
        let username = "All";
        if(tabValue === 1){
            thirdparty = 'Accesstrade';
        }else if(tabValue === 2){
            thirdparty = 'Haloje';
        }
        if(usernamesearch !== ''){
            username = usernamesearch;
        }
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order,'thirdparty': thirdparty,'username': username};
        setTableLoading(true);
        getUrl('admin/HaloJeSales', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }
    
    const [filterDialog, setFilterDialog]=useState({
        open:false,
    });
    const handleFilterDialogOpen =()=>{
        setFilterDialog({...filterDialog, open:true});
    }

    const handleFilterDialogClose =()=>{
        setFilterDialog({...filterDialog, open:false});
        setUsernameSearch('');
    }

    const handleChangeFilter =(action)=>{
        setPage(1);
        setTableLoading(true);
        if (action === 'reset') {
            setFilterDialog({...filterDialog, open:true});
            setUsernameSearch('');
            setTableLoading(false);
        }else if(action ==='filter'){
            setFilterDialog({...filterDialog, open:false});
            setTableLoading(false);
            callApi();

        }
    }
    function TableToolbar(props) {
        const classes = useStyles();
        const { t } = useTranslation();
        const [value, setValue] = useState(props.val);
        const searchInputRef = useRef(null);
        const handleClear = () => {
            setValue("");
            searchInputRef.current.focus();
        };
        const exportFile = () => {
            let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
            XLSX.writeFile(wb, `ReportHalojeSales${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
        }
    
        const toolbarSearch =
            <Toolbar className={classes.toolbarRoot}>
                <Button className={classes.buttonFilter} onClick={()=>handleFilterDialogOpen()}>{'filter'}</Button>
                <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAlt fontSize="small" /></IconButton></Tooltip>
            </Toolbar>;
        return [value, toolbarSearch];
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, querySearch, orderBy, order, tabValue]);

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.halojesales.title')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:'#c11111',WebkitBoxShadow:'none'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered indicatorColor="primary">
                        <Tab icon={<ReorderOutlined />} label={t('reports.bonusDetails.all')}/>
                        <Tab icon={<ConfirmationNumberOutlined />} label={t('reports.halojesales.accessTrade')}/>
                        <Tab icon={<ConfirmationNumberOutlined />} label={t('reports.halojesales.Haloje')}/>
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            id="exportTable"
                        >
                            <TableHead>
                                <TableRow>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.halojesales.id')} cellId={'id'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.halojesales.user_id')} cellId={'user_id'} />
                                    <TableCell>
                                        <TableHead>
                                            {t('reports.halojesales.username')}
                                        </TableHead>
                                    </TableCell>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.halojesales.amount')} cellId={'total_amount'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.halojesales.distribut_amount')} cellId={'distribute_amount'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.halojesales.type')} cellId={'type'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.halojesales.create_date')} cellId={'created_at'} />
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    return(
                                        <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">{row.id}</TableCell>
                                                <TableCell component="th" scope="row">{row.user_id}</TableCell>
                                                <TableCell component="th" scope="row">{row.user.username}</TableCell>
                                                <TableCell component="th" scope="row">{row.total_amount}</TableCell>
                                                <TableCell component="th" scope="row">{row.distribute_amount}</TableCell>
                                                <TableCell component="th" scope="row">{row.type}</TableCell>
                                                <TableCell>{row.created_at}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={9} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={totalCount? [...rowsPerPageOptiosArray,{ value: totalCount, label: 'All' }]:rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
            <Dialog
                open={filterDialog.open}
                onClose={()=>handleFilterDialogClose()}
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <Grid container spacing={2} direction="row" alignItems="center" justify="flex-start">
                        <Grid item xs={12} style={{padding:15}}>
                            <TextField
                                id="orderno"
                                value={usernamesearch}
                                placeholder={t('reports.halojesales.username')}
                                fullWidth  
                                onChange={e => setUsernameSearch(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                        <Grid container spacing={2} direction="row" alignItems="center" justify="space-around" style={{padding:10}}>
                            <Button color="secondary" onClick={()=>handleFilterDialogClose()}>{t('button.nope')}!</Button>
                            <Button color="primary" onClick={()=>handleChangeFilter("reset")}>{t('button.reset')}</Button>
                            <Button color="primary" onClick={()=>handleChangeFilter("filter")} >{t('button.filter')}</Button>
                        </Grid>
                    </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));