import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import _ from 'lodash';

// import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles'
import { Typography, Link, Grid, Button, Breadcrumbs } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, RadioGroup, Radio, TextField, FormControlLabel, IconButton, Tooltip, Box } from '@material-ui/core';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

export default function LuckyDrawOrder() {
    // const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [selectedRow, setSelectedRow] = useState(null);
    const [updateStatusDialog, setUpdateStatusDialog] = useState({
        open: false,
        status: '',
    });
    const [refundDialog, setRefundDialog] = useState({
        open: false,
        refundType: '',
        interestType: '',
        interestValue: '',
        walletId: ''
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const refundType = [{code: "normal", label: t('order.refundType.normal')}, {code: "special", label: t('order.refundType.special')}];
    const interestType = [{code: "percent", label: t('order.interestType.percent')}, {code: "value", label: t('order.interestType.value')}];
    let { id } = useParams();
    const [event, setEvent] = useState([]);

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        getUrl(`admin/luckydraw/order_list/${id}`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
                setOrderStatus(response.order_status);
                setEvent(response.event);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl('admin/wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, refreshData]);

    const alertChangeStatus = (event, data, type) => {
        setSelectedRow(data);
        if(type === 'refund'){
            setRefundDialog({
                open: true,
                refundType: '',
                interestType: '',
                interestValue: '',
                walletId: ''
            });
        }else if(type === 'status'){
            setUpdateStatusDialog({
                open: true,
                status: orderStatus?orderStatus[0]:''
            });
        }
    };
    const handleRefundDialogClose = () => {
        setRefundDialog({
            open: false,
            refundType: '',
            interestType: '',
            interestValue: '',
            walletId: ''
        });
    }
    const handleUpdateStatusDialogClose = () => {
        setUpdateStatusDialog({
            open: false,
            status: ''
        });
    }

    const updateStatus = () => {
        setLoading(true);
        let updateData = {
            order_list: _.map(selectedRow, 'id'),
            status: updateStatusDialog.status
        };
        putUrl(`admin/orders_all/status`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('order.successFailList', {'success': response.success, 'fail': response.fail});
                addAlert('', msg, 'success', '');
                setRefreshData(!refreshData);
                handleUpdateStatusDialogClose();
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
    };

    const refund_order = () => {
        setInputErrors('');
        setLoading(true);
        let updateData = {
            order_list: _.map(selectedRow, 'id'),
            refund_type: refundDialog.refundType,
            interest_type: refundDialog.interestType,
            interest_value: refundDialog.interestValue,
            wallet_id: refundDialog.walletId,
        };
        putUrl(`admin/orders_all/refund`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('order.successFailList', {'success': response.success, 'fail': response.fail});
                addAlert('', msg, 'success', '');
                setRefreshData(!refreshData);
                handleRefundDialogClose();
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">
                        {t('luckydraw.buyerList')}
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/luckydraw/event-summary`} component={RouterLink}>{t('luckydraw.eventSummary')}</Link>
                        <Typography style={{ color: 'black' }}>{t('luckydraw.buyerList')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{margin: 10}}>
                <Link underline='none' to={`/products/${event.product_id}`} component={RouterLink}>
                    <IconButton size="small" variant="outlined">
                        <Tooltip title={t('luckydraw.viewProduct')}>
                            <ShoppingCartIcon color="primary" />
                        </Tooltip>
                    </IconButton>
                </Link>
                <Box>{t('luckydraw.id') + ': ' + event.id}</Box>
                <Box>{t('luckydraw.winnerQuota') + ': ' + event.winner_quota}</Box>
                <Box>{t('luckydraw.totalSlot') + ': ' + event.total_slot}</Box>
                <Box>{t('luckydraw.drawDate') + ': ' + (event.draw_date?event.draw_date:'-')}</Box>
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: t('luckydraw.id'), render: row => event.id  },
                        { title: t('order.orderNo'), field: 'order_no', render: row => <Link style={{color:"#929191"}} underline='none' to={`/orders/${row.order_no}`} component={RouterLink}>{row.order_no}</Link>  },
                        { title: t('order.buyerName'), field: 'buyer_name', render: rowData => <Link underline='none' to={`/members/${rowData.user_id}`} component={RouterLink}>{rowData.buyer_name}</Link> },
                        { title: t('order.status'), field: 'status_display' },
                        { title: t('luckydraw.purchasedDate'), field: 'created_at' },
                        { title: t('luckydraw.result.title'), field: 'result', render: rowData => t('luckydraw.result.' + rowData.result) },
                        { title: t('luckydraw.winnerPP'), type: 'numeric', field: 'winner_pp', render: rowData => <NumberFormat value={parseFloat(rowData.winner_pp).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} /> },
                        { title: t('luckydraw.loserPP'), type: 'numeric', field: 'loser_pp', render: rowData => <NumberFormat value={parseFloat(rowData.loser_pp).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} /> },
                        { title: t('luckydraw.interest'), type: 'numeric', field: 'interest', render: rowData => <NumberFormat value={parseFloat(rowData.interest).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} /> },
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        // selection: _.includes(permissions, "order_status") ? true : false,
                        filtering: true,
                        exportButton: true,
                        exportFileName: `OrderReport_${new Date().toISOString().slice(0, 10)}`
                    }}
                    // actions={_.includes(permissions, "order_status") ? [
                    //     {
                    //         tooltip: t('order.updateStatus'),
                    //         icon: () => <EditIcon />,
                    //         onClick: (evt, data) => alertChangeStatus(evt, data, 'status')
                    //     },
                    //     {
                    //         tooltip: t('order.refund'),
                    //         icon: () => <MoneyOffIcon/>,
                    //         onClick: (evt, data) => alertChangeStatus(evt, data, 'refund')
                    //     }
                    // ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected'),
                            exportTitle: t('table.export'),
                            exportPDFName: t('table.exportPDF'),
                            exportCSVName: t('table.exportCSV')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
                }
            </Grid>
            <Dialog
                open={updateStatusDialog.open}
                onClose={handleUpdateStatusDialogClose}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('order.updateStatus')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('order.selectedRows', {rows: selectedRow ? selectedRow.length : 0}) + ' ' + t('order.updateStatusInstruction')}</DialogContentText>
                    <Grid item xs={12}>
                        <TextField 
                            variant="outlined" 
                            fullWidth 
                            select
                            value={updateStatusDialog.status}
                            helperText={inputErrors.status?inputErrors.status:''}
                            error={inputErrors.status?true:false}
                            onChange={({ target }) => setUpdateStatusDialog({ ...updateStatusDialog, status: target.value })}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                        >
                        {orderStatus.map((value, key) => {
                            let name = _.split(value.desc, '|');
                            return (
                                <option key={key} value={value.value}>
                                    {i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}
                                </option>
                            )
                        })}
                        </TextField>
                        <Typography variant="caption" style={{color: "#808080", fontStyle: "italic"}}>{t('order.changeStatusReminder')}</Typography>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateStatusDialogClose} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={refundDialog.open}
                onClose={handleRefundDialogClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('order.refund')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('order.selectedRows', {rows: selectedRow ? selectedRow.length : 0})}</DialogContentText>
                    <Grid container spacing={1} justify="center">
                        <Grid item xs={12}>
                            <Typography>{ t('order.refundType.title') }</Typography>
                            {
                                inputErrors.refund_type ?
                                <Typography variant='caption' style={{color:"red"}}>
                                    {inputErrors.refund_type}
                                </Typography>
                                : null
                            }
                            <RadioGroup aria-label="refund type" name="refundType" value={refundDialog.refundType} onChange={(event) => { setRefundDialog({ ...refundDialog, refundType: event.target.value}) }}>
                                {
                                    _.map(refundType, (value, key) => {
                                        return(
                                            <FormControlLabel key={key} value={value.code} control={<Radio />} label={value.label} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </Grid>
                        {
                            refundDialog.refundType === 'special'
                            ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>{ t('order.interestType.title') }</Typography>
                                    {
                                        inputErrors.interest_type ?
                                        <Typography variant='caption' style={{color:"red"}}>
                                            {inputErrors.interest_type}
                                        </Typography>
                                        : null
                                    }
                                    <RadioGroup aria-label="interest type" name="interestType" value={refundDialog.interestType} onChange={(event) => { setRefundDialog({ ...refundDialog, interestType: event.target.value}) }}>
                                        {
                                            _.map(interestType, (value, key) => {
                                                return(
                                                    <FormControlLabel key={key} value={value.code} control={<Radio />} label={value.label} />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        id="interestValue" 
                                        label={refundDialog.interestType==='percent'?t('order.interestRate'):t('order.interestValue')}
                                        variant="outlined" 
                                        fullWidth 
                                        value={refundDialog.interestValue}
                                        helperText={inputErrors.interest_value ? inputErrors.interest_value :''}
                                        error={inputErrors.interest_value ? true : false}
                                        onChange={(event) => { setRefundDialog({ ...refundDialog, interestValue: event.target.value}) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        id="walletId" 
                                        label={t('wallet.walletType')}
                                        variant="outlined" 
                                        fullWidth 
                                        select
                                        value={refundDialog.walletId}
                                        helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                                        error={inputErrors.wallet_id ? true : false}
                                        onChange={(event) => { setRefundDialog({ ...refundDialog, walletId: event.target.value}) }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                    <option key='' value='' disabled>
                                        {t('general.pleaseSelect')}
                                    </option>
                                    {wallets.map((value, key) => {
                                        let name = _.split(value.name, '|');
                                        return(
                                            <option key={key} value={value.id}>
                                                { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                            </option>
                                        )
                                    })}
                                    </TextField>
                                </Grid>
                            </>
                            :
                            null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefundDialogClose} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={() => refund_order()} color="primary" autoFocus>{t('order.confirmRefund')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));