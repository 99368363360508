import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, TextField, MenuItem, Button, Grid, Paper, Avatar } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css'

// function useSelect({ data, label, val }) {
//     const classes = useStyles();
//     const selectedData = data[data.findIndex(item => item.id === val)]
//     const [value, setValue] = useState(selectedData);
//     const [error, setErrorValue] = useState("");
//     const select =
//         <Grid item xs={12} sm>
//             <FormControl variant="outlined" fullWidth error={error ? true : false}>
//                 <Autocomplete
//                     onChange={(event, newValue) => { setValue(newValue); }}
//                     value={selectedData}
//                     options={data}
//                     classes={{ option: classes.option }}
//                     autoHighlight
//                     getOptionSelected={(option, value) => option.id === value.id}
//                     getOptionLabel={option => option.cat_name}
//                     renderOption={(option) => (
//                         <>{option.cat_name}</>
//                     )}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label={label}
//                             fullWidth
//                             variant="outlined"
//                             inputProps={{
//                                 ...params.inputProps,
//                                 autoComplete: 'new-password', // disable autocomplete and autofill
//                             }}
//                             error={error ? true : false}
//                         />
//                     )}
//                 />
//                 {error ?
//                     <FormHelperText>{error}</FormHelperText>
//                     : null
//                 }
//             </FormControl>
//         </Grid>;
//     return [value, select, setErrorValue];
// }

export default function ImportProduct() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        warehouse: '',
        profitMargin: '0',
        company_index: "0",
        category_id: 0
    });
    // const [categories, setCategories] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [products, setProducts] = useState([]);
    // const [category, categorySelect] = useSelect({ data: categories, label: t('importProduct.selectCategory'), val: '' });
    const [categoryTreeData, setCategoryTreeData] = useState([]);

    function callApi(){
        setLoading(true);
        getUrl(`admin/categories`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                // setCategories(result.data);
                setCategoryTreeData(result.tree_data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        getUrl(`admin/companies_by_roles`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setCompanies(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        getPreimportProducts();
    }

    function getPreimportProducts(){
        getUrl(`admin/supplier/products`).then(result => {
            setLoading(false);
            if(result.status === 1 && isMountedRef.current) {
                setProducts(result.data);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        isMountedRef.current = true;        
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const importProduct = () => {
        setLoading(true);

        let apiData = {
            supplier: "cb",
            category: state.category_id ? state.category_id : '',
            // category: category ? category.id : '',
            // warehouse: state.warehouse,
            profit_percentage: state.profitMargin,
            company_id: _.size(companies) > 0 ? companies[state.company_index].id : ''
        }

        postUrl(`admin/supplier/products`, apiData).then(result => {
            if(isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if(status === 1) {
                    getPreimportProducts();
                    addAlert('', t('importProduct.successfullyImported'), 'success', '');
                } else {                
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            message+="\n"+value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    const categoryOnChange = (currentNode, selectedNodes) => {
        if(_.size(selectedNodes)>0){
            setState({ ...state, category_id: currentNode.value });
            let temp = loopTreeData(categoryTreeData, currentNode.value);
            setCategoryTreeData(temp);
        }else{
            setState({ ...state, category_id: "" });
            let temp = loopTreeData(categoryTreeData, "");
            setCategoryTreeData(temp);
        }
    }

    const loopTreeData = (treeData, parentId) => {
        let temp = treeData;
        _.map(treeData, (data, key) => {
            if(data.value === parentId){
                temp[key].checked = true;
            }else{
                temp[key].checked = false;
            }
            if(data.children){
                temp[key].children = loopTreeData(data.children, parentId);
            }
        })
        return temp;
    }

    const updateShippingFee = (ids) => {
        setLoading(true);
        let apiData = {
            ids
        };
        console.log(ids);
        putUrl(`admin/supplier/products_shipping_fee`, apiData).then(result => {
            if(isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if(status === 1) {
                    getPreimportProducts();
                    addAlert('', message, 'success', '');                    
                } else {                
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            message+="\n"+value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    const addProduct = (id) => {
        setLoading(true);
        let apiData = {
            id
        };
        postUrl(`admin/supplier/product_add`, apiData).then(result => {
            if(isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if(status === 1) {
                    getPreimportProducts();
                    addAlert('', message, 'success', '');                    
                } else {                
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            message+="\n"+value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    return(
        <Grid container spacing={3}>            
            <Grid item xs={12}>
                <Typography variant="h4" component="h1">{t('importProduct.importProducts')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}  style={theme.box1}>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField
                                    id="profit-margin"
                                    label={t('importProduct.profitMargin')}
                                    variant="outlined"
                                    fullWidth
                                    value={state.profitMargin}
                                    onChange={({ target }) => setState({ ...state, profitMargin: target.value }) }
                                />
                            </Grid>
                            {/* {categorySelect} */}
                            <Grid item xs={12} sm>
                                <TextField
                                    id="select-company"
                                    select
                                    label={t('importProduct.selectShop')}
                                    value={state.company_index}
                                    onChange={({ target }) => setState({ ...state, company_index: target.value })}
                                    variant="outlined"
                                    fullWidth
                                >
                                    {
                                        _.map(companies, (option, index) => {
                                            return (
                                                <MenuItem key={index} value={index}>
                                                    {`${option.shop_name} [ID: ${option.id}]`}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm>
                                <Typography variant="body2">{t('importProduct.selectCategory')}</Typography>
                                <DropdownTreeSelect 
                                    className="mdl-demo"
                                    data={categoryTreeData}
                                    mode="radioSelect"
                                    keepTreeOnSearch
                                    keepChildrenOnSearch
                                    texts={{ placeholder: t('table.search')}}
                                    onChange={categoryOnChange}
                                />
                            </Grid>
                           {/* { _.size(companies) > 0 && state.company_index !== "" && _.size(companies[state.company_index]) > 0 ?
                                <Grid item xs={12} sm>
                                    <TextField
                                        id="select-warehouse"
                                        select
                                        label={t('importProduct.selectWarehouse')}
                                        value={state.warehouse}
                                        onChange={({ target }) => setState({ ...state, warehouse: target.value })}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        {
                                            _.map(companies[state.company_index].warehouses, (option, index) => {
                                                return (
                                                    <MenuItem key={index} value={option.name}>
                                                        {option.name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            : null } */}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={importProduct}>{t('importProduct.importNow')}</Button>
                            </Grid>
                        </Grid>
                    </form>
                    <div style={{display:'flex',justifyContent:'flex-end',marginTop:'10px',padding:'20px'}}>
                        <img src="../images/truck1.png" style={{ width: '200px',maxWidth:'100%'}} alt=""/>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MaterialTable
                                title=""
                                columns={[
                                    { title: '', field: 'img', render: rowData => rowData.images_array ? <Avatar alt="product" src={rowData.images_array[0]} /> : <Avatar>?</Avatar> },
                                    { title: t('product.productName'), field: 'title' },
                                    { title: t('product.price'), field: 'sell_price' },
                                    { title: t('product.shippingFee'), field: 'shipping_fee' },
                                    { title: t('product.stock'), field: 'quantity' },
                                    { title: t('product.category'), field: `category_${i18n.language}`, render: rowData => rowData[`category_${i18n.language}`] },
                                    { title: t('product.company'), field: 'merchant_company_name' },                                    
                                    { title: t('table.action'), field: 'status',lookup: { 1: 'Pending', 2: t('product.addProduct'), 3: 'Added' },render: rowData => rowData.status === 2 ? <Button variant="contained" size="small" color="primary" onClick={() => addProduct(rowData.id)}>{t('product.addProduct')}</Button> : (rowData.status === 3 ? 'Added' : 'Pending') }
                                    ,
                                ]}
                                data={products}
                                options={{
                                    pageSize: 20,
                                    selection: true,
                                    selectionProps: rowData => {
                                        rowData.tableData.disabled = rowData.status > 1;
                                        return {
                                            disabled: rowData.status > 1
                                        }
                                    }
                                }}                                
                                actions={[
                                    {
                                        tooltip: 'Update Shipping Fee',
                                        icon: 'update',
                                        onClick: (evt, data) => updateShippingFee(_.map(data, 'id'))
                                    }
                                ]}
                                style={{ ...theme.box1, ...theme.p20 }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: t('table.labelDisplayedRows'),
                                        labelRowsSelect: t('table.rows'),
                                        firstTooltip: t('table.firstPage'),
                                        previousTooltip: t('table.previousPage'),
                                        nextTooltip: t('table.nextPage'),
                                        lastTooltip: t('table.lastPage'),
                                    },
                                    toolbar: {
                                        searchTooltip: t('table.search'),
                                        searchPlaceholder: t('table.search'),
                                        nRowsSelected: t('table.rowsSelected')
                                    },
                                    body: {
                                        emptyDataSourceMessage: t('table.noRecord'),
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));