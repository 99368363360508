import { red, orange, lightBlue, deepOrange } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#008645',
        },
        secondary: {
            main: '#868686',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f7f7f7',
        },
        green: {
            main: '#4caf50',
        },
        profile: {
            user: orange.A400,
            order: lightBlue.A400,
            notification: deepOrange.A400,
        },
        
    },
    
    p20: {
        padding:'20px'
    },
    m20: {
        margin:'50px'
    },
    mt30:{
        marginTop:'30px'
    },
    textline:{
        color:'#008645',
        // borderBottom:'2px solid #008645',
        // width:'fit-content'
    },
    box1: {
        boxShadow:'0 0 14px 0 #e0e0e0',
        borderRadius:'15px',
    },
    justify_center:{
        justifyContent:'center',
    },
    bg_noorder: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize:'cover',
        backgroundPosition:'left bottom',
        backgroundImage: `url(${"/images/bgnoorder.png"})`,
        justifyContent:'center',
        boxShadow:'0 0 14px 0 #e0e0e0',
        borderRadius:'15px',
    },
    bg_nomember: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize:'cover',
        backgroundPosition:'center bottom',
        backgroundImage: `url(${"/images/bgnomember.png"})`,
        boxShadow:'0 0 14px 0 #e0e0e0',
        borderRadius:'15px',
        // justifyContent:'center' 
    },
 
    // typography: {
    //     // fontFamily: [
    //     //     '-apple-system',
    //     //     'BlinkMacSystemFont',
    //     //     '"Segoe UI"',
    //     //     'Roboto',
    //     //     '"Helvetica Neue"',
    //     //     'Arial',
    //     //     'sans-serif',
    //     //     '"Apple Color Emoji"',
    //     //     '"Segoe UI Emoji"',
    //     //     '"Segoe UI Symbol"',
    //     // ].join(','),
    //     fontSize: 12,
    // },
    // props: {
    //     MuiButtonBase: {
    //       // The default props to change
    //         disableRipple: true, // No more ripple, on the whole application 💣!
    //     },
    // },
});

theme = responsiveFontSizes(theme);

export default theme;