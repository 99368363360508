import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import _, { toArray } from 'lodash';
import { getUrl, putUrl , deleteUrl , postUrl } from '../helper/ApiAction';
import { Editor } from '@tinymce/tinymce-react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    Typography, 
    TextField, 
    Paper, 
    Button, 
    Divider, 
    Grid, 
    GridList, 
    GridListTile, 
    GridListTileBar, 
    Modal, 
    MenuItem, 
    Radio, 
    RadioGroup, 
    FormControl, 
    FormControlLabel, 
    FormHelperText, 
    Checkbox, 
    Slider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';

import { DropzoneArea } from 'material-ui-dropzone';

// import ProductPointSection from './ProductPointSection';
import CategoryPanel from './CategoryPanel';
// import DropdownTreeSelect from 'react-dropdown-tree-select';
// import 'react-dropdown-tree-select/dist/styles.css'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';


function useInput({ type, label, val, placeholder, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} placeholder={placeholder} />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, input, setErrorValue];
}

function useInputNew({ type, label, val, helpText }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error ? error : helpText} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} />
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

function useCheckbox({ label, val, note='' }) {
    const [value, setValue] = useState(val ? true : false);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

function useSelectAmountType({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useSelect({ data, label, val }) {
    const classes = useStyles();
    const selectedData = data[data.findIndex(item => item.id === val)]
    const [value, setValue] = useState(selectedData);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
                <Autocomplete
                    onChange={(event, newValue) => { setValue(newValue); }}
                    value={selectedData}
                    // defaultValue={[]}
                    size="small"
                    options={data}
                    classes={{ option: classes.option }}
                    autoHighlight
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.cat_name}
                    renderOption={(option) => (
                        <>{option.cat_name}</>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            error={error ? true : false}
                        />
                    )}
                />
                {error ?
                    <FormHelperText>{error}</FormHelperText>
                    : null
                }
            </FormControl>
        </Grid>;
    return [value, select, setErrorValue];
}

function useCompanySelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {`${option.shop_name} [ID: ${option.id}]`}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useRefundSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useMultiSelection({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12}>
            <Autocomplete
                disablePortal
                id="wallets-listing"
                size="small"
                disableCloseOnSelect
                options={data}
                getOptionLabel={(option) => (option && option.id ? option.id.toString() : '')}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} />
                )}
            />
            {
                error
                    ?
                        _.map(error, err => {
                            return(
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {err}
                                </Typography>
                            )
                        })
                    : null
            }
        </Grid>
    return [value, select, setErrorValue, setValue];
}

// selectedOptionIndex
function useMultiSelect({ data, label, val , method}) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12}>
            <Autocomplete
                // disableCloseOnSelect
                // multiple
                disableCloseOnSelect = {!method}
                multiple = {method}
                id="select-wallet"
                size="small"
                options={data}
                getOptionLabel = {(option) => value == {id: "cash", wallet_name: "Cash"} ? "Cash" : option?.wallet_name || ""}
                value={ value }
                disabled = { value.id == {id: "cash", wallet_name: "Cash"} || value.id == "cash" || value.id == "Cash" ? true : false }
                onChange={(event, newValue) => {
                    setValue(newValue);
                    // if(value == {id: "cash", wallet_name: "Cash"}){
                    //     setValue("cash");
                    // }else{
                    //     setValue(newValue);
                    // }
                }}
                
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} />
                )}
            />
            {
                error
                    ?
                        _.map(error, err => {
                            return(
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {err}
                                </Typography>
                            )
                        })
                    : null
            }
        </Grid>
    return [value, select, setErrorValue, setValue];
}

function useWalletSelect({ data, label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => {
                    let name = _.split(option.name, '|');
                    return(
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })}
            </TextField>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useEditor({ label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
                <Typography variant="subtitle2" component="label">{label}</Typography>
                <Editor
                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                    value={value}
                    init={{
                        height: "70vh",
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount quickbars'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                    }}
                    onEditorChange={(content, editor) => setValue(content)}
                />
                {error ?
                    <FormHelperText>{error}</FormHelperText>
                    : null
                }
            </FormControl>
        </Grid>;
    return [value, input, setErrorValue, error];
}

// function useGroupCheckbox({ data, label, val, note='' }) {
//     const [value, setValue] = useState(val);
//     const [error, setErrorValue] = useState("");
//     const handleChange = (event) => {
//         let tempData = value;
//         if(event.target.checked){
//             tempData = tempData.concat(event.target.value);
//         }else{
//             tempData = tempData.filter(item => parseInt(item) !== parseInt(event.target.value));
//         }
//         setValue(tempData);
//     }
//     const checkbox =
//         <Grid item xs={12} sm={6}>
//             <Typography>{ label }</Typography>
//             {data.map((detail) => {
//                 let checked = false;
//                 _.map(value, (rankId) => {
//                     if(parseInt(rankId) === parseInt(detail.id)){
//                         checked = true;
//                     }
//                 });
//                 if(parseInt(detail.id) === 0){
//                     detail.source = detail.name;
//                     detail.name_display = '';
//                 }
//                 return(
//                     <Grid item key={detail.id} style={{padding: 0}}>
//                         <FormControlLabel
//                             key={detail.id}
//                             control={<Checkbox value={detail.id} name={detail.name} checked={checked} onChange={(event) => handleChange(event)} />}
//                             label={detail.source + ' ' + detail.name_display}
//                         />
//                     </Grid>
//                 )
//             })}
//             <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
//         </Grid>;
//     return [value, checkbox, setErrorValue];
// }

// function useSlider({ label, val }) {
//     const [value, setValue] = useState(val);
//     const [error, setErrorValue] = useState("");
//     const handleChange = (e, value) => {
//         setValue(value);
//     }
//     const slider = 
//     <Grid item xs={12} sm={6}>
//         <Typography gutterBottom variant="body2" style={{color: "#808080"}}>
//             {label + ": " + value + "%"}
//         </Typography>
//         <Slider
//             aria-label={label}
//             defaultValue={val}
//             valueLabelDisplay="auto"
//             step={1}
//             marks
//             min={0}
//             max={16}
//             onChange={handleChange}
//             error={error ? true : false}
//             helperText={error}
//         />
//     </Grid>
//     return [value, slider, setErrorValue, setValue];
// }

export default function ProductEditGeneral({ data, companies, setLoading, addAlert, setData, pointList, pointData, setPointData, categoryProducts, eventData, setEventData, eventIdList, walletsMethod,mranks,ranks, isMountedRef }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { role, permissions, isAdmin } = useSelector(state => state.general);
    const [wallets, setWallets] = useState([]);

    let setSelectionMultiple = false;

    const productType = isAdmin? [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}/* , {code: "repurchase", label: t('product.type.repurchase')} */] : [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}];
    const amountType = [{code: "fix", label: t('product.aType.fix')}, {code: "percent", label: t('product.aType.percent')}];
    
    // const refundType = [{code: "normal", label: t('order.refundType.normal')}, {code: "special", label: t('order.refundType.special')}, {code: "no-refund", label: t('order.refundType.noRefund')}];
    // const interestType = [{code: "percent", label: t('order.interestType.percent')}, {code: "value", label: t('order.interestType.value')}];
    // const [mistorePoint, setMistorePoint] = useState({
    //     cv: 0,
    //     mp: 0,
    // });
    // const productType = [{code: "normal", label: t('product.type.normal')}, {code: "rank", label: t('product.type.rank')}];
    
    /* const [claimPoint, setClaimPoint] = useState({
        sp: 0,
        vp: 0
    }); */

    const [type, productTypeInput, setProductTypeError] = useRefundSelect({ data: productType, label: `${t('product.type.title')}*`, val: data.type });
    const [title, titleInput, setNameError] = useInput({ type: "text", label: `${t('product.productName')}*`, val: data.title, placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sku, skuInput, setSkuError] = useInput({ type: "text", label: `${t('product.sku')}`, val: data.sku || "" });
    const [goods_brand, brandInput, setBrandError] = useInput({ type: "text", label: t('product.brand'), val: data.goods_brand || "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: t('product.price'), val: data.sell_price });
    const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.costPrice'), val: data.cost_price/* , note: 'Claimable Income: '+claimPoint.sp+' SP, '+claimPoint.vp+' VP' */ });
    const [retail_price, retailPriceInput, setRetailPriceError] = useInput({ type: "number", label: t('product.retailPrice'), val: data.retail_price });
    const [rpredeem, rpredeemInput, setRpRedeemError] = useInput({ type: "number", label: t('product.rpredeem'), val: data.rp_redeem });
    const [merchant_company_id, companySelect, setCompanyError] = useCompanySelect({ data: companies, label: `${t('product.shopName')}*`, val: data.merchant_company_id });
    const [descr_en, descrEnInput, setDescrEnError, descrEnError] = useEditor({ label: t('product.descEn'), val: data.descr_en });
    const [descr_cn, descrCnInput, setDescrCnError, descrCnError] = useEditor({ label: t('product.descCn'), val: data.descr_cn });
    const [quantity, quantityInput, setQuantityError] = useInput({ type: "number", label: `${t('product.stock')}*`, val: data.quantity });
    const [shipping_fee, shippingFeeInput, setShippingFeeError] = useInput({ type: "number", label: t('product.shippingFee'), val: data.shipping_fee });
    const [west_shipping_fee, westShippingFeeInput, setWestShippingFeeError] = useInput({  type: "number", label: t('product.westShippingFee'), val: data.shipping_fee });
    const [east_shipping_fee, eastShippingFeeInput, setEastShippingFeeError] = useInput({  type: "number", label: t('product.eastShippingFee'), val: data.shipping_fee2 });
    const [ship_weight, weightInput, setWeightError] = useInput({ type: "number", label: `${t('product.weightKg')}*`, val: data.ship_weight || "" });
    const [package_length, lengthInput, setLengthError] = useInput({ type: "number", label: t('product.lengthCm'), val: data.package_length || "" });
    const [package_width, widthInput, setWidthError] = useInput({ type: "number", label: t('product.widthCm'), val: data.package_width || ""  });
    const [package_height, heightInput, setHeightError] = useInput({ type: "number", label: t('product.heightCm'), val: data.package_height || ""  });
    const [prepare_time, prepareTimeInput, setPrepareTimeError] = useInput({ type: "number", label: `${ t('product.prepareTime')}*`, val: data.prepare_time || "", note: t('product.prepareTimeNote') });
    const [purchase_limit, purchaseLimitInput, setPurchaseLimitError] = useInput({ type: "number", label: `${ t('product.purchaseLimit')}*`, val: data.purchase_limit, note: t('product.purchaseLimitNote') });
    const [order_limit, orderLimitInput, setOrderLimitError] = useInput({ type: "number", label: `${ t('product.orderLimit')}*`, val: data.order_limit, note: t('product.orderLimitNote') });
    
    const [bv, bvInput, setBvError] = useInput({ type: "number", label: t('product.bv'), val: data.bv || "" });
    
    const [rank_id, rankIdInput, setRankIdError] = useWalletSelect({ data: ranks, label: `${t('product.relatedRank')}*`, val: data.rank_id?data.rank_id:0, note: t('product.relatedRankNote') });
    const [mrank_id, mrankIdInput, setMRankIdError] = useWalletSelect({ data: mranks, label: `${t('product.leadershipRank')}*`, val: data.mrank_id?data.mrank_id:0, note: t('product.leadershipRankNote') });
    const [seller_cover_shipping, sellerCoverShippingInput, setSellerCoverShippingInputError] = useCheckbox({ label: `${ t('product.sellerCoverShipping')}`, val: data.seller_cover_shipping ? true : false});
    const [sell_price_repurchase, sellingPriceReInput, setSellingPriceReError] = useInput({ type: "number", label: t('product.priceRepurchase'), val: data.price_repurchase?data.price_repurchase:'' });
    const [bv_repurchase, bvReInput, setBvReError] = useInput({ type: "number", label: t('product.bvRepurchase'), val: data.bv_repurchase?data.bv_repurchase:'' });



    const [imagesArray, setImagesArray] = useState(data.images_array);
    const [newImages, setNewImages] = useState([]);
    const [newImagesError, setNewImagesError] = useState("");
    const [defaultCategoryId, setDefaultCategoryId] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [newVideo, setNewVideo] = useState(null);
    const [newEmbedVideo, setNewEmbedVideo] = useState(data.embed_video ? data.embed_video : "");
    const [newVideoError, setNewVideoError] = useState("");


    const [shipping_channel, setShippingChannel] = useState(data.shipping_channel);
    const [malaysiaMerchant, setMalaysiaMerchant] = useState(false); 
    // if true, can set shipping for east and west msia
    


    // const [commission, commissionInput, setCommissionError] = useSlider({ label: t('product.commission'), val: data.commission?data.commission:0 });
    
    // const [mp_reward_type, mpRewardTypeInput, setMpRewardTypeError] = useRefundSelect({ data: amountType, label: `VP ${t('product.aType.title')}`, val: data.mp_reward?data.mp_reward.type:'fix' });
    // const [mp_reward, mpRewardInput, setMpRewardError] = useInput({ type: "number", label: t('product.mpReward'), val: data.mp_reward?data.mp_reward.amount:'' }); 
    // const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: type == 'rank' ? t('product.priceStarter') : t('product.price'), val: data.type == 'rank' ? data.price_starter : data.sell_price });
    // const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.costPrice'), val: data.cost_price, note: "CV: "+mistorePoint.cv+" MP: "+mistorePoint.mp });
    // const [category, categorySelect, setCategoryError] = useSelect({ data: categories, label: `${t('product.category')}*`, val: data.category_id });
    // const [bonus, bonusInput, setBonusError] = useCheckbox({ label: `${ t('product.bonus')}`, val: data.bonus ? true : false, note: t('product.bonusNote') });
    // const [bv, bvInput, setBvError] = useInput({ type: "number", label: type == 'rank' ? t('product.bvStarter') : t('product.bv'), val: data.type == 'rank' ? data.bv_starter : data.bv || "" });
    // const [type, typeInput, setTypeError, setTypeValue] = useSelect({ data: walletType, label: t('product.wType.title'), val: data.type});
    // const [wallet_settings, setWalletSettings] = useState([]);
    // const [maxCap, maxCapInput, setMaxCapError] = useInput({ type: "number", label: t('product.maxCap'), val: data.max_cap});
    // const [cv, cvInput, setCvError] = useInput({ type: "number", label: t('product.cv'), val: data.cv || "" });
    // const [auto_create_event, autoCreateEventInput, setAutoCreateEventError] = useCheckbox({ label: t('product.autoCreateEvent'), val: data.auto_create_event, note: t('product.autoCreateEventNote') });
    // const [winner_quota, winnerQuotaInput, setWinnerQuotaError] = useInput({ type: "number", label: t('luckydraw.winnerQuota'), val: eventData?eventData.winner_quota:"" });
    // const [total_slot, totalSlotInput, setTotalSlotError] = useInput({ type: "number", label: t('luckydraw.totalSlot'), val: eventData?eventData.total_slot:"", note: t('luckydraw.totalSlotNote') });
    // const [refund_type, refundTypeInput, setRefundTypeError] = useRefundSelect({ data: refundType, label: t('order.refundType.title'), val: eventData?eventData.refund_type:"" });
    // const [wallet_id, walletIdInput, setWalletIdError] = useWalletSelect({ data: walletsMethod, label: t('wallet.walletType'), val: eventData?eventData.wallet_id:"" });
    // const [interest_type, interestTypeInput, setInterestTypeError] = useRefundSelect({ data: interestType, label: t('order.interestType.title'), val: eventData?eventData.interest_type:"" });
    // const [interest_value, interestValueInput, setInterestValueError] = useInput({ type: "number", label: interest_type==='percent'?t('order.interestRate'):t('order.interestValue'), val: eventData?eventData.interest_value:"" });
    // const [allowed_rank_purchase, allowedRankPurchaseInput, setAllowedRankPurchaseError] = useGroupCheckbox({ data: ranks, label: t('product.allowedRankPurchase'), val: data.allowed_rank_purchase, note: t('product.allowedRankPurchaseNote') });


    const [wallet_ids, walletIdsInput, setWalletIdsError, setWalletIdsValue] = useMultiSelect({ data: wallets, label: t('product.walletIds'), val: [] , method: setSelectionMultiple });
    const [pricing_name, pricingNameInput, setPricingNameError, setPricingNameValue] = useInputNew({ type: "text", label: t('product.pricingName'), val: '' });
    const [amount_type, amountTypeInput, setAmountTypeError, setAmountTypeValue] = useSelectAmountType({ data: amountType, label: t('product.aType.title'), val: 'fix' });
    const [min_amount, minAmountInput, setMinAmountError, setMinAmountValue] = useInputNew({ type: "number", label: t('product.minAmount'), val: '' });
    const [max_rp_redeem, maxRpRedeemInput, setMaxRpRedeemInputError, setMaxRpRedeemInputValue] = useInputNew({ type: "number", label: t('product.redeemAmount'), val: '' });




    const [walletsSelected, setWalletsSelected] = useState([]);

    const [defaultCashData, setDefaultCashData] = useState(false);

    const [selectedOptionIndex, setOptionIndex] = useState('');

    const [deleteOptionIndex, setOpenDeleteOptionDialog] = useState("");
    const [openModal, setOpenModal] = useState("");


    const validate = { 
        title: setNameError, 
        sku: setSkuError, 
        goods_brand: setBrandError, 
        sell_price: setSellingPriceError, 
        cost_price: setCostPriceError,
        retail_price: setRetailPriceError, 
        category_id: setCategoryError, 
        descr_en: setDescrEnError, 
        descr_cn: setDescrCnError, 
        quantity: setQuantityError,
        new_images: setNewImagesError, 
        merchant_company_id: setCompanyError, 
        shipping_fee: setShippingFeeError, 
        shipping_fee2: setEastShippingFeeError, 
        ship_weight: setWeightError, 
        package_length: setLengthError,
        package_width: setWidthError, 
        package_height: setHeightError, 
        prepare_time: setPrepareTimeError, 
        purchase_limit: setPurchaseLimitError,
        order_limit:setOrderLimitError, 
        new_video: setNewVideoError, 
        embed_video: setNewVideoError,
        bv: setBvError, 
        rank_id: setRankIdError,
        mrank_id: setMRankIdError, rpredeem: setRpRedeemError,
        seller_cover_shipping: setSellerCoverShippingInputError, 
        type: setProductTypeError, 
        sell_price_repurchase: setSellingPriceReError,  
        bv_repurchase: setBvReError,
        wallet_ids : setWalletIdsError,
        pricing_name : setPricingNameError,
        amount_type : setAmountTypeError,
        min_amount : setMinAmountError,
        max_rp_redeem : setMaxRpRedeemInputError
        // mp_reward: setMpRewardError, mp_reward_type: setMpRewardTypeError,
        // bonus: setBonusError, cv: setCvError, auto_create_event: setAutoCreateEventError, winner_quota: setWinnerQuotaError, total_slot: setTotalSlotError, refund_type: setRefundTypeError, 
        // wallet_id: setWalletIdError, interest_type: setInterestTypeError, interest_value: setInterestValueError, allowed_rank_purchase: setAllowedRankPurchaseError, commission: setCommissionError, 
        
        /* , maxCap:setMaxCapError */
    };

    let deleteImage = (item) => {
        setImagesArray(_.difference(imagesArray, [item]));
    }

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        })
    };

    function callApiWallets() {
        getUrl('admin/wallets').then((response) => {
            setLoading(false);
            if (response.status === 1) {
                let w = response.data;
                // w.push({id: 'cash', wallet_name: t('product.cash')});
                setWallets(w);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
    }


    function setCashWallestChecking() {
        return (
            _.size(walletsSelected) > 0 &&
            _.map(walletsSelected, (data, key) => {
                _.map(data?.wallet_settings[0].wallet_ids, (data2, key2) => {
                    if (data2 == 'cash'){
                        setDefaultCashData(true);
                    }else{
                        setDefaultCashData(false);
                    }
                })
            })
        )
    }

    function getMultiPrice(){

        let apiData = {
            id: data.id
        }

        getUrl(`get_multi_price/${data.id}`, apiData).then(response => {
            if(response.status == 1){

                setWalletsSelected(response.data)
                setDefaultCashData(false); 

                _.map(response.data, (data, key) => {

                    if ( data.wallet_settings[0].wallet_ids[0] == "cash" )
                    {
                        setDefaultCashData(true); 
                    }

                })
            }

        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }


    useEffect(() => {
        setLoading(true);
        callApiWallets();
        
        let tempPointData = [];
        _.map((data.points), (data, key) => {
            let visible = false;
            _.map((pointList), (point) => {
                if(parseInt(point.id) === data.point_id){
                    visible = true;
                }
            });
            if(visible){
                tempPointData[key] = {id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: false}
            }
        });
        let filteredArray = tempPointData.filter(item => item !== null);
        setPointData(filteredArray);
        setImagesArray(data.images_array);
        setCategoryId(data.category_id);
        setDefaultCategoryId(data.category_id);

        getMultiPrice()
        // setCashWallestChecking()

        // setWalletsSelected(data?.multi_pricings);

        // _.size(walletsSelected) > 0 &&
        // _.map(walletsSelected, (data, key) => {
        //     _.map(data?.wallet_settings[0].wallet_ids, (data2, key2) => {
        //         if (data2 == 'cash'){
        //             setDefaultCashData(true);
        //         }else{
        //             setDefaultCashData(false);
        //         }
        //     })
        // })

        

        setLoading(false);

    }, [data, pointList, setPointData, eventData]);

    // grab merchant company country for checking
    useEffect(() => {
        setLoading(true);

        isMountedRef.current = true;
        getUrl(`admin/companies/${merchant_company_id}`).then(response => {
            if(response.status && isMountedRef.current) {
                if(response.data.country === 'MY') {
                    setMalaysiaMerchant(true);
                } else {
                    setMalaysiaMerchant(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        setLoading(false);

        return () => isMountedRef.current = false;
    }, [merchant_company_id]);

    const changeCategory = currentId => {
        setCategoryId(currentId);
    }

    const categoryTreeSelect = () => {
        return (
        <Grid item xs={12} sm={12}>
            {/* <DropdownTreeSelect 
                data={categoryTreeData}
                mode="radioSelect"
                keepTreeOnSearch
                keepChildrenOnSearch
                texts={{ placeholder: t('table.search')+ ' ' +t('product.category') }}
                onChange={categoryOnChange}
            /> */}
            <CategoryPanel initialData={categoryProducts} selectedId={defaultCategoryId} changeCategoryParent={changeCategory} />
            {
                categoryError
                ?
                <Typography variant="caption" style={{color: "red"}}>
                    {categoryError}
                </Typography>
                :null
            }
        </Grid>
        )
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    let handleSubmit = async () => {
        setLoading(true);
        resetAllFieldError();

        let wallet_ids_data = [];

        if (setSelectionMultiple == false){
            wallet_ids_data = [wallet_ids];
        }else{
            wallet_ids_data = wallet_ids;
        }

        let newImagesBase64 = [];

        if(_.size(newImages) > 0){            
            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }

        let newVideoBase64 = "";
        if(_.size(newVideo) > 0){
            newVideoBase64 = await toBase64(newVideo[0]);
        }

        let apiData = {
            title,
            category_id: categoryId,
            // category_id: category.id,
            merchant_company_id,
            goods_brand,
            sell_price,
            cost_price,
            retail_price,
            quantity,
            descr_en,
            descr_cn,
            images: imagesArray,
            new_images: newImagesBase64,
            shipping_channel,
            shipping_fee: malaysiaMerchant ? west_shipping_fee : shipping_fee,
            shipping_fee2: malaysiaMerchant ? east_shipping_fee : 0,
            ship_weight,
            package_length,
            package_width,
            package_height,
            prepare_time,
            purchase_limit,
            sku,
            // point_data: pointData,
            new_video: newVideoBase64,
            embed_video: newEmbedVideo,
            // bonus: bonus ? 1 : 0,
            bv,
            // cv,
            // auto_create_event: auto_create_event ? 1 : 0,
            // winner_quota,
            // total_slot,
            // refund_type,
            // wallet_id,
            // interest_type,
            // interest_value,
            rank_id: type == 'normal' ? null : rank_id,
            mrank_id: type == 'normal' ? null : mrank_id,
            // maxCap: type == 'normal' ? null : maxCap,
            // allowed_rank_purchase,
            seller_cover_shipping: seller_cover_shipping ? 1: 0,
            // commission,
            type,
            sell_price_repurchase,
            bv_repurchase,
            // mp_reward_type,
            // mp_reward,
            order_limit,
            rpredeem,
            walletsSelected
        }

        putUrl(`admin/products/${data.id}`, apiData)
        .then((response) => {
            setLoading(false);
            let {status, message, error, data:newData, event_data:eventData} = response;
            if(status === 1){
                addAlert('', message, 'success', '');
                setData(newData);
                setEventData(eventData);
                setNewEmbedVideo(newData.embed_video ? newData.embed_video : "");
            }else{                
                if(_.size(error) > 0){
                    _.map(error, (value, key) => {
                        if(validate[key])
                            validate[key](value[0]);
                        else
                            message += "\n "+value[0];
                    })
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = (newValue) => {
        setDefaultCashData(newValue);
        if (newValue == true){
            setLoading(true);
            let newData = { wallet_ids: ["cash"], pricing_name: "Cash", type: "fix", min_amount: "100", max_amount: "100", max_rp_redeem: "0" };

            let wSettings = [];
            wSettings.push(newData)
            
            let apiData = {
                type,
                page: "general",
                wallet_settings: wSettings
            }

            postUrl(`admin/products/${data.id}/multi_pricing`, apiData)
                .then((response) => {
                    
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        setOptionIndex("");
                        getMultiPrice()
                        setLoading(false);
                    } else {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                if (validate[key])
                                    validate[key](value[0]);
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                        setLoading(false);
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });

        }else{
            setLoading(false);
        }
    };

    const deleteOption = () => {
        setLoading(true);
        // let currentMultiPricings = [...data.multi_pricings];
        // currentMultiPricings.splice(deleteOptionIndex, 1);
        deleteUrl(`admin/products/${data.id}/multi_pricing/${walletsSelected[deleteOptionIndex].id}`)
            .then((response) => {
                setLoading(false);
                let { status, message } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    getMultiPrice();
                    // setData({ ...data, 'multi_pricings': [...currentMultiPricings] });
                } else {
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
        setOpenDeleteOptionDialog("");
    };

    
    const setModelData = ({method}) => {
        setOptionIndex(method);

        if(method !== "new")
        {
            let multiPricingSelected = _.find(walletsSelected, (data, key) => { return key == method; });

            let walletSelected = _.find(wallets, (data, key) => { return data.id == multiPricingSelected.wallet_settings[0].wallet_ids; });

            _.map(multiPricingSelected.wallet_settings[0].wallet_ids, (data, key) => {
                if (data == "cash" || data == "Cash"){
                    setWalletIdsValue({id: "cash", wallet_name: "Cash"});
                }else{
                    setWalletIdsValue(walletSelected);
                }
            })
            setPricingNameValue(multiPricingSelected.wallet_settings[0].pricing_name);
            setAmountTypeValue(multiPricingSelected.wallet_settings[0].type);
            setMinAmountValue(multiPricingSelected.wallet_settings[0].min_amount);
            setMaxRpRedeemInputValue(multiPricingSelected.wallet_settings[0].max_rp_redeem);
        }else{
            setWalletIdsValue([]);
            setPricingNameValue('');
            setAmountTypeValue('');
            setMinAmountValue('');
            setMaxRpRedeemInputValue("");
        }
    }

    const changeMultipriceData = ({ method }) => {

        if(method !== "new")
        {
            let set_max_rp_redeem = 0;
            if(max_rp_redeem < 0 || !max_rp_redeem){
                set_max_rp_redeem = 0;
            }else{
                set_max_rp_redeem = max_rp_redeem;
            }
            if(wallet_ids && pricing_name && amount_type && min_amount){
                let newData = { wallet_ids: [wallet_ids.id], pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: min_amount, max_rp_redeem: set_max_rp_redeem };
                let wSettings = [];
                wSettings.push(newData)
                let apiData = {
                    type,
                    page: "general",
                    wallet_settings: wSettings
                }
                putUrl(`admin/products/${data.id}/multi_pricing/${walletsSelected[method].id}`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        setOptionIndex("");
                        getMultiPrice()
                        // setCashWallestChecking()
                    } else {
    
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                if (validate[key])
                                    validate[key](value[0]);
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
            }else{
                addAlert(t('error.fieldFieldError'));
            }
        }
        else
        {
            let set_max_rp_redeem = 0;

            if(max_rp_redeem < 0 || !max_rp_redeem){
                set_max_rp_redeem = 0;
            }else{
                set_max_rp_redeem = max_rp_redeem;
            }

            if(wallet_ids && pricing_name && amount_type && min_amount){
                let newData = { wallet_ids: [wallet_ids.id], pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: min_amount, max_rp_redeem: set_max_rp_redeem };
                let wSettings = [];
                wSettings.push(newData)
                let apiData = {
                    type,
                    page: "general",
                    wallet_settings: wSettings
                }
                postUrl(`admin/products/${data.id}/multi_pricing`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        // callApiWallets()
                        setOptionIndex("");
                        getMultiPrice()
                        // setCashWallestChecking()
                    } else {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                if (validate[key])
                                    validate[key](value[0]);
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
            }
            else
            {
                addAlert(t('error.fieldFieldError'));
            }
        }
        

    }

    return (
        <Grid container spacing={3} style={theme.p20}>

            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.aboutProduct')}</Typography>
            </Grid>

            <Grid item xs={12} container spacing={3}>
               
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.generalInfo')}</Typography>
                </Grid>
                {titleInput}
                {/* {_.size(ranks) > 1 ? productTypeInput : null} */}
                {productTypeInput}
                {_.size(companies) ? companySelect : null}
                {skuInput}
                {brandInput}
                {/* {categorySelect} */}
                {categoryTreeSelect()}


                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.priceAndBv')}</Typography>
                </Grid>
                {sellingPriceInput}
                {/* {type == 'rank' ? sellingPriceReInput : null} */}
                {costPriceInput}
                {retailPriceInput}
                {_.includes(permissions, "product_bonus") ? bvInput : null}
                {/* {_.includes(permissions, "product_bonus") ? (type == 'rank' ? bvReInput : null) : null}
                {_.includes(permissions, "product_bonus") ? mpRewardTypeInput : null}
                {_.includes(permissions, "product_bonus") ? mpRewardInput : null} */}
                {_.size(ranks) > 1 ? (type == 'normal' ? null : rankIdInput) : null}
                {_.size(mranks) > 1 ? (type == 'normal' ? null : mrankIdInput) : null}
                {/* {type === 'normal'?null:maxCapInput} */}
                {rpredeemInput}


                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.stock')}</Typography>
                </Grid>
                {_.size(data.options[0]) === 0 ? quantityInput : null}
                {purchaseLimitInput}
                {orderLimitInput}


                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.weightAndDimensional')}</Typography>
                </Grid>
                {weightInput}
                {lengthInput}
                {widthInput}
                {heightInput}


                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.others')}</Typography>
                </Grid>
                {prepareTimeInput}
                

                {/* multi pricing */}
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.multiPricings')}</Typography>
                    <Button size="small" startIcon={<AddIcon />} onClick={() => setModelData({ method : "new" }) }>{t('button.new')}</Button>
                </Grid>
                <Grid item container sx={{p: 0}} direction="row" justify="flex-end" alignItems="flex-end" style={{padding: 0}}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            // value="start"
                            checked={ defaultCashData }
                            disabled = { defaultCashData ? true : false }
                            onChange={(e) => handleChange(e.target.checked)}
                            control={<Checkbox />}
                            label={t('product.cashWallet')}
                            // label={t('product.selfShipping')}
                            // label="Auto Create Cash Wallet"
                            labelPlacement="start"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TableContainer>
                        <Table
                            // className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>{t('product.wType.title')}</TableCell> */}
                                    <TableCell>{t('product.walletSettings')}</TableCell>
                                    <TableCell>{t('product.action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.size(walletsSelected) > 0 ? 
                                _.map(walletsSelected, (row, rowIndex) => {
                                    return (
                                        <TableRow key={rowIndex}>
                                            {/* <TableCell>{t('product.wType.' + row.type)}</TableCell> */}
                                            <TableCell>
                                                {
                                                    _.size(row.wallet_settings) > 0
                                                        ?
                                                        _.map(row.wallet_settings, (setting, key) => {
                                                            return (
                                                                <Typography key={key}>
                                                                    {setting.pricing_name + ' : ' + setting.min_amount + (setting.type === 'percent' ? ' % ' : '')}
                                                                </Typography>
                                                            );
                                                        })
                                                        :
                                                        null
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Grid container alignItems="center" className={classes.actionRoot}>
                                                    {/* <IconButton aria-label="edit" size="small" color="primary" ><EditIcon /></IconButton> */}
                                                    <IconButton aria-label="edit" size="small" color="primary" onClick={() => setModelData({ method : rowIndex })}><EditIcon /></IconButton>
                                                    <Divider orientation="vertical" flexItem />
                                                    {/* <IconButton aria-label="delete" size="small" color="secondary" ><DeleteForeverIcon /></IconButton> */}
                                                    <IconButton aria-label="delete" size="small" color="secondary" onClick={() => setOpenDeleteOptionDialog(rowIndex)}><DeleteForeverIcon /></IconButton>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) 
                                : 
                                <Typography>data null</Typography>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {/* delete multi pricing */}
                <Dialog
                    open={deleteOptionIndex !== "" ? true : false}
                    onClose={() => setOpenDeleteOptionDialog("")}
                    aria-labelledby="delete-option-dialog-title"
                    aria-describedby="delete-option-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('product.confirmationOnDelete')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{t('product.askDeleteMultiPricing')}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteOptionDialog("")} color="secondary">{t('button.nope')}!</Button>
                        <Button onClick={deleteOption} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                    </DialogActions>
                </Dialog>

                {/* multi price setting */}
                <Grid item xs={12} container>
                    {data.video ?
                        <Grid item xs={6}>
                            <video width="100%" controls style={{ paddingRight: 15 }}>
                                <source src={data.video} type="video/mp4" />
                                Your browser does not support HTML video.
                            </video>
                        </Grid>
                        : null
                    }
                    {data.embed_video ?
                        <Grid item xs={6}>
                            <iframe width="100%" height="100%" src={data.embed_video} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                        </Grid>
                        : null
                    }
                    <Grid item xs={6}>
                        <DropzoneArea
                            acceptedFiles={['video/mp4']}
                            dropzoneText={t('product.dragDropVideoHere')}
                            onChange={(file) => setNewVideo(file)}
                            filesLimit={1}
                            dropzoneClass={classes.dropzone}
                            maxFileSize={5000000}
                        />
                        <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 1}) + t('general.fileSizeLimit', {limit: '5MB', fileType: 'MP4. '})}</Typography>
                        <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <Divider variant="middle" style={{ flex: 1}}/><Typography style={{ textTransform: "uppercase"}}>{t('product.or')}</Typography><Divider variant="middle" style={{ flex: 1}}/>
                        </Grid>
                        <TextField fullWidth size="small" label={t('product.embedVideoUrl')} variant="outlined" value={newEmbedVideo} onChange={e => setNewEmbedVideo(e.target.value)} type="text" placeholder={"e.g: https://www.youtube.com/embed/dQw4w9WgXcQ"} />
                        { newVideoError ?
                            <FormHelperText style={{color:"red"}}>{newVideoError}</FormHelperText>
                            : null
                        }
                    </Grid>
                </Grid>

                {/* multi price setting dialog*/}
                <Modal
                    open={selectedOptionIndex !== '' ? true : false}
                    onClose={() => { setOptionIndex("");  }}
                    // onClose={() => { setOptionIndex(""); resetAllFieldError(); }}
                    disableBackdropClick
                    style={{ 
                        position: 'absolute',
                        width: '95vw',
                        backgroundColor: theme.palette.background.paper,
                        border: '2px solid #000',
                        boxShadow: theme.shadows[5],
                        left: '2.5vw',
                        top: '25%',
                        margin: 'auto',
                        overflow: 'scroll',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{selectedOptionIndex === "new" ? t('product.addWalletSetting') : t('product.editWalletSetting')}</Typography>
                                        <Divider />
                                    </Grid>
                                    {walletIdsInput}
                                    {pricingNameInput}
                                    {amountTypeInput}
                                    {minAmountInput}
                                    {maxRpRedeemInput}
                                    {/* {maxAmountInput} */}
                                    <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => { setOptionIndex(""); resetAllFieldError(); }}>{t('button.close')}</Button>
                                            {/* <Button type="button" fullWidth variant="contained" color="secondary" onClick={handleWalletSettingDialogClose}>{t('button.close')}</Button> */}
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {  changeMultipriceData({ method : selectedOptionIndex }) } }>{t('button.save')}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                </Modal> 


                {/* {commissionInput} */}
                {/* {_.includes(permissions, "product_bonus") ? cvInput : null}
                {_.includes(permissions, "product_bonus") ? bonusInput : null} */}
                {/* {(_.includes(permissions, "event") && eventIdList.includes(String(categoryId))) ? 
                    <>
                        {autoCreateEventInput}
                        {winnerQuotaInput}
                        {totalSlotInput}
                        {refundTypeInput}
                        {
                            refund_type === 'special'
                            ?
                            <>
                            {walletIdInput}
                            {interestTypeInput}
                            {interestValueInput}
                            </>
                            : null
                        }
                    </>
                    : null
                }
                {isAdmin ? allowedRankPurchaseInput : null} */}
            </Grid>


            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.productDescription')}</Typography>
                {/* <Divider /> */}
            </Grid>


            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_en")}>{t('product.english')}</Button>
                    {descrEnError ?
                        <FormHelperText style={{ color: "red" }}>{descrEnError}</FormHelperText>
                        : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_cn")}>{t('product.mandarin')}</Button>
                    {descrCnError ?
                        <FormHelperText style={{ color: "red" }}>{descrCnError}</FormHelperText>
                        : null
                    }
                </Grid>
            </Grid>


            <Modal
                open={openModal ? true : false}
                onClose={() => setOpenModal("")}
                aria-labelledby="product description"
                aria-describedby="product description modal"
            >
                <Grid container spacing={3} justify="center" className={classes.modal}>
                    { openModal === "descr_cn" ? descrCnInput : descrEnInput }
                    <Grid item xs={12} sm={6}>
                        <Button type="button" fullWidth variant="contained" color="primary" onClick={() => setOpenModal("")}>{t('button.close')}</Button>
                    </Grid>
                </Grid>
            </Modal>


            {data.shipping_channel === "source" ? null :
                <>
                    <Grid item xs={12} style={theme.mt30}>
                        <Typography variant="h5" style={theme.textline} component="h1">{t('product.shippingChannel')}</Typography>
                        {/* <Divider /> */}
                    </Grid>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                {/* <FormLabel component="legend">{t('product.shippingChannel')}</FormLabel> */}
                                <RadioGroup aria-label="shipping channel" name="shippingChannel" value={shipping_channel} onChange={(event) => { setShippingChannel(event.target.value) }}>
                                    <FormControlLabel value="self" control={<Radio />} label={t('product.selfShipping')} />
                                    <FormControlLabel value="mall" control={<Radio />} label={t('product.mallShipping')} />
                                </RadioGroup>
                                <FormHelperText style={{paddingBottom: 5}}>{t('product.shippingWeightNote')}</FormHelperText>
                            </FormControl>
                            { shipping_channel === "self" ? 
                                <>
                                    { malaysiaMerchant ? 
                                        <>
                                            { westShippingFeeInput }
                                            <div style={{ marginTop: 10 }}>
                                                { eastShippingFeeInput }
                                            </div>
                                        </>
                                    : shippingFeeInput }
                                </>
                            : null }
                            {shipping_channel === "mall" ? sellerCoverShippingInput : null}
                            
                        </Grid>
                    </Grid>
                </>
            }


            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.images')}</Typography>
                {/* <Divider /> */}
            </Grid>


            <Grid item xs={12} container className={classes.imageRoot} >
                <GridList className={classes.gridList} cols={2.5} style={{height:"unset"}}>
                    {_.map(imagesArray, (item, itemIndex) => {
                        return(
                            <GridListTile key={itemIndex} style={{height:"unset", width:"auto"}}>
                                <img src={item} alt="product" style={{width:"250px"}} />
                                <GridListTileBar
                                    title={`Image ${itemIndex+1}`}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <IconButton onClick={() => deleteImage(item)}>
                                            <DeleteOutlineIcon className={classes.title} />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        )
                    })}
                </GridList>
                
            </Grid>


            <Grid item xs={12} style={{marginTop:"30px"}}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.uploadImages')}</Typography>
                {/* <Divider /> */}
            </Grid>


            <Grid item xs={12} container className={classes.imageRoot}>
                <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={t('product.dragDropImageHere')}
                    onChange={(files) => isMountedRef.current ? setNewImages(files) : null}
                    filesLimit={10-_.size(imagesArray)}
                    dropzoneClass={classes.dropzone}
                    maxFileSize={2000000}
                />
                <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10-_.size(imagesArray)}) + t('general.productfileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                { newImagesError ?
                    <FormHelperText style={{color:"red"}}>{newImagesError}</FormHelperText>
                    : null
                }
            </Grid>


            <Grid item xs={12} style={{marginTop:"30px"}}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.uploadVideo')}</Typography>
                {/* <Divider /> */}
            </Grid>


            {/* {
                _.size(pointList) > 0
                ?
                <ProductPointSection pointList={pointList} pointData={pointData} setPointData={setPointData} />
                :
                null
            } */}


            <Grid container spacing={3} justify="flex-end">
                <Grid item xs={8} sm={6} md={4} style={{marginTop: 30}}>
                    <Button type="button" fullWidth variant="contained" color="primary" onClick={handleSubmit}>{t('button.update')}</Button>
                </Grid>
            </Grid>


        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        flexGrow: 1
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    },

}));