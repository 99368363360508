import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Button, Box, LinearProgress, TextField } from '@material-ui/core';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { MTableToolbar } from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function Notifications() {
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [selectedRecipient, setSelectedRecipient] = useState({id: 'all', name:'All'});

    function callApi(){
        if (isMountedRef.current) setTableLoading(true);

        getUrl(`admin/notifications?recipient=${selectedRecipient.id}`).then(response => {            
            if (response.status === 1 && isMountedRef.current) {
                setTableLoading(false);
                setTableData(response.data);
                //setMemberList(response.members);
            }
        }).catch((error) => {            
            if (isMountedRef.current) {
                setTableLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(selectedRecipient)) callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [selectedRecipient]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('notification.notifications')}</Typography>
                    {_.includes(permissions, "notification_add") ?
                        <Link underline='none' color="inherit" to="/notifications/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('notification.subject'), field: `subject_${i18n.language}`},
                        { title: t('Reciepient'), field: `recipient_type`},
                        //{ title: t('notification.message'), field: `message_${i18n.language}` , render: rowData => <Typography classes={{ body2: classes.txtTruncate }} variant="body2" component="span">{rowData[`message_${i18n.language}`]}</Typography> ,width: "4%"},
                        { title: t('table.created_at'), field: 'created_at' },
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        tableLayout : 'fixed',
                    }}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    actions={[
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => history.push(`/notifications/${rowData.id}`)
                        }
                      ]}
                    //components={{
                        //Toolbar: props => (
                            //<div>
                               // <MTableToolbar {...props} />
                                //<Box display="flex" flexDirection="row" alignItems="center">
                                    //<Autocomplete
                                        //onChange={(event, newValue) => { setSelectedRecipient(newValue); }}
                                        //value={selectedRecipient}
                                        //options={[{id: 'all', name: "All"}, ...memberList]}
                                        //classes={{ option: classes.option }}
                                        //style={{ width: 400 }}
                                        //autoHighlight
                                        //getOptionSelected={(option, value) => option.id === value.id}
                                        //getOptionLabel={option => `${option.name}${option.email ? `-${option.email}`: ''}${option.id !== "all" ? `[ID: ${option.id}]`: ''}`}
                                        //renderOption={(option) => (
                                           // <>{option.name}{option.email ? `-${option.email}`: ''}{option.id !== "all" ? `[ID: ${option.id}]`: ''}</>
                                        //)}
                                        //renderInput={(params) => (
                                            //<TextField
                                                //{...params}
                                                //fullWidth
                                                //variant="outlined"
                                                //inputProps={{
                                                    //...params.inputProps,
                                                    //autoComplete: 'new-password', // disable autocomplete and autofill
                                                //}}
                                            ///>
                                        //)}
                                    ///>

                                //</Box>
                            //</div>
                        //),
                    //}}

                />
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        height: '2.86em',
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
}));