import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '../helper/Tools';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function MemberAdd() {
    const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();
    const placement = query.get("placement");
    const position = query.get("position");

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [state, setState] = useState({
        name: '',
        username: '',
        email: '',
        nric: '',
        mobile: '',
        role: '',
        pwd: '',
        cfm_pwd: '',
        referral: '',
        placement: placement,
        position: position,
        autoPlacement: false
    });
    const placementPosition = [{value: 1, label: t("profile.placementPosition.left")}, {value: 2, label: t("profile.placementPosition.right")}];

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/members/add`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setUserRoles(response.data);
                setState({...state, role: response.data[0].name});
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            name: state.name,
            username: state.username,
            email: state.email,
            nric: state.nric,
            mobile: state.mobile,
            role: state.role,
            password: state.pwd,
            password_confirmation: state.cfm_pwd,
            referral: state.referral,
            placement: state.autoPlacement?'':state.placement,
            position: state.autoPlacement?null:(state.position>0?state.position:null),
            auto_placement: state.autoPlacement?1:0
        }
        postUrl(`admin/members/add`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = t('snackbarMsg.updateSuccess')+' ID: ' + response.data.id;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('member.addMember')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/members`} component={RouterLink}>{t('member.members')}</Link>
                        <Typography style={{ color: 'black' }}>{t('member.addMember')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="name" 
                            label={t('profile.name')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.name}
                            helperText={inputErrors.name?inputErrors.name:''}
                            error={inputErrors.name?true:false}
                            onChange={({ target }) => setState({ ...state, name: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="username" 
                            label={t('profile.username')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.username}
                            helperText={inputErrors.username?inputErrors.username:''}
                            error={inputErrors.username?true:false}
                            onChange={({ target }) => setState({ ...state, username: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="email" 
                            label={t('profile.email')}
                            variant="outlined" 
                            fullWidth 
                            value={state.email}
                            helperText={inputErrors.email?inputErrors.email:''}
                            error={inputErrors.email?true:false}
                            onChange={({ target }) => setState({ ...state, email: target.value }) }
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <TextField 
                            id="nric" 
                            label={t('kyc.nric')}
                            variant="outlined" 
                            fullWidth 
                            value={state.nric}
                            helperText={inputErrors.nric?inputErrors.nric:''}
                            error={inputErrors.nric?true:false}
                            onChange={({ target }) => setState({ ...state, nric: target.value }) }
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="mobile" 
                            label={t('profile.mobile')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.mobile}
                            helperText={inputErrors.mobile?inputErrors.mobile:''}
                            error={inputErrors.mobile?true:false}
                            onChange={({ target }) => setState({ ...state, mobile: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="referral" 
                            label={t('profile.referral')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.referral}
                            helperText={inputErrors.referral?inputErrors.referral:''}
                            error={inputErrors.referral?true:false}
                            onChange={({ target }) => setState({ ...state, referral: target.value }) }
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.autoPlacement}
                                    onChange={({ target }) => setState({ ...state, autoPlacement: target.checked }) }
                                    color="primary"
                                />
                            }
                            label={t('profile.autoPlacement')}
                        />
                    </Grid> */}
                    {/* {
                        state.autoPlacement
                        ?
                        null
                        :
                        <>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="placement" 
                                label={t('profile.placement')} 
                                variant="outlined" 
                                fullWidth 
                                value={state.placement}
                                helperText={inputErrors.placement?inputErrors.placement:''}
                                error={inputErrors.placement?true:false}
                                onChange={({ target }) => setState({ ...state, placement: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="position" 
                                label={t('profile.position')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.position}
                                helperText={inputErrors.position?inputErrors.position:''}
                                error={inputErrors.position?true:false}
                                onChange={({ target }) => setState({ ...state, position: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value={0}>
                                {t('general.pleaseSelect')}
                            </option>
                            {placementPosition.map((value, key) => (
                                <option key={key} value={value.value}>
                                    {value.label}
                                </option>
                            ))}
                            </TextField>
                        </Grid>
                        </>
                    } */}
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="role" 
                            label={t('user.role')} 
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.role}
                            helperText={inputErrors.role?inputErrors.role:''}
                            error={inputErrors.role?true:false}
                            onChange={({ target }) => setState({ ...state, role: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('general.pleaseSelect')}
                        </option>
                        {userRoles.map((value, key) => {
                            const displayName = _.split(value.display_name, '|');
                            return (
                                <option key={key} value={value.name}>
                                    { i18n.language === 'cn' ? _.size(displayName) > 0 ? displayName[1] : displayName[0] : displayName[0] }
                                </option>
                            )
                        })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="pwd" 
                            label={t('register.password')}
                            variant="outlined" 
                            fullWidth 
                            type="password"
                            value={state.pwd}
                            helperText={inputErrors.password?inputErrors.passwprd:''}
                            error={inputErrors.password?true:false}
                            onChange={({ target }) => setState({ ...state, pwd: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="cfm_pwd" 
                            label={t('register.passwordConfirmation')}
                            variant="outlined" 
                            fullWidth 
                            type="password"
                            value={state.cfm_pwd}
                            helperText={inputErrors.password_confirmation?inputErrors.password_confirmation:''}
                            error={inputErrors.password_confirmation?true:false}
                            onChange={({ target }) => setState({ ...state, cfm_pwd: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={form_submit} >{t('button.submit')}</Button>
                    </Grid>
                </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));