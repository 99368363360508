import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';

// import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Typography, Link, Grid, Button, LinearProgress } from '@material-ui/core';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';

export default function Categories() {
    // const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const [tableData, setTableData] = useState([]);
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        getUrl(`admin/categories`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setTableLoading(false);
                setTableData(response.data);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('category.categories')}</Typography>
                    { _.includes(permissions, "category_add") ?
                        <Link underline='none' color="inherit" to="/categories/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                { tableLoading
                ? 
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('category.name'), field: 'cat_name', cellStyle: { minWidth: 300 }, hearderStyle: { minWidth: 300 }, render: rowData => <Link underline='none' to={`/categories/${rowData.id}`} component={RouterLink}>{i18n.language === 'cn' ? rowData.name_cn : rowData.name_en}</Link> },
                        { title: t('category.status'), field: 'status', render: rowData => rowData.status ? t('category.active') : t('category.inactive') },
                        // { title: t('category.createdBy'), field: 'created_by_display' },
                        // { title: t('category.updatedBy'), field: 'updated_by_display' },
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                    }}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
                }
            </Grid>
        </Grid>
    );
}

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));