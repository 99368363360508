import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, useTheme, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import BarChartIcon from '@material-ui/icons/BarChart';

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function ReportAccountLink() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [15, 20, 25];
    const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) : 1;
    const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 15;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const [page, setPage] = useState(pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [unlinkDialog, setUnlinkDialog] = useState({
        open: false,
        uid: 0,
        source: '',
        username: ''
    });

    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    function handleSubmit(event) {
        event.preventDefault();        
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
        setQueryValue(searchValue);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue);
    };

    const setUrl = (paging, rpp, sv) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/account-link${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);

        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch };
        getUrl('admin/get_account_links', params).then(response => {
            setTableLoading(false);
            if(isMountedRef.current && response.status===1) {
                setListing(response.data.data);
                setTotalCount(response.data.total);
            }
        }).catch(error => {
            setTableLoading(false);
            setListing([]);
            setTotalCount(0);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, refreshData]);

    const handleUnlinkDialogOpen = (row) => {
        setUnlinkDialog({
            open: true,
            uid: row.user_id,
            source: row.source,
            username: row.username,
        });
    }
    const handleUnlinkDialogClose = () => {
        setUnlinkDialog({
            open: false,
            uid: 0,
            source: '',
            username: ''
        });
    }
    const unlinkUser = () => {
        setLoading(true);
        setUnlinkDialog({ ...unlinkDialog, open: false});
        let params = {
            id: unlinkDialog.uid,
            source: unlinkDialog.source,
        };
        postUrl('admin/account-link/admin-unlink', params).then(response => {
            setLoading(false);
            if(response.status===1){
                addAlert(t('reports.accountLink.unlinkSuccess', {username: unlinkDialog.username, source: unlinkDialog.source}), '', 'success');
                setRefreshData(!refreshData);
                handleUnlinkDialogClose();
            }else{
                if(response.message){
                    addAlert('', response.message, 'error', '');
                }else{
                    addAlert('', t('reports.accountLink.unlinkFail'), 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }
    
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.accountLink.title')}</Typography>
                    <Tooltip title={t('title.reports')}><Link underline='none' to="/reports" component={RouterLink}><IconButton color="primary"><BarChartIcon /></IconButton></Link></Tooltip>
                </Grid>
            </Grid>
            <Paper className={classes.paper} style={{ ...theme.box1, ...theme.mt30 }}>
                <Grid item xs={12}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('reports.accountLink.userId')}</TableCell>
                                    <TableCell>{t('reports.accountLink.username')}</TableCell>
                                    <TableCell>{t('reports.accountLink.source')}</TableCell>
                                    <TableCell>{t('reports.accountLink.sourceUserId')}</TableCell>
                                    <TableCell>{t('reports.accountLink.sourceUsername')}</TableCell>
                                    <TableCell>{t('reports.accountLink.createdAt')}</TableCell>
                                    <TableCell>{t('reports.accountLink.action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    return(
                                        <TableRow key={row.user_id}>
                                            <TableCell>{ row.user_id }</TableCell>
                                            <TableCell>{ row.username }</TableCell>
                                            <TableCell>{ row.source }</TableCell>
                                            <TableCell>{ row.source_user_id }</TableCell>
                                            <TableCell>{ row.source_username }</TableCell>
                                            <TableCell>{ row.created_at }</TableCell>
                                            <TableCell><Button variant="contained" color="primary" onClick={() => handleUnlinkDialogOpen(row)}>{t('reports.accountLink.unlink')}</Button></TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={7} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Grid>
            </Paper>
            <Dialog
                open={unlinkDialog.open}
                onClose={() => handleUnlinkDialogClose()}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('reports.accountLink.unlink')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('reports.accountLink.unlinkNote', {username: unlinkDialog.username, source: unlinkDialog.source})}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleUnlinkDialogClose()} color="secondary">{t('button.nope')}!</Button>
                    <Button variant="contained" onClick={() => unlinkUser()} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));