import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
// import { useQuery } from '../helper/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, LinearProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


export default function Vouchers() {
    const classes = useStyles();
    // const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions, role } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    // const history = useHistory();
    // const query = useQuery();

    const [listing, setListing] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [duplicateOpen, setDuplicateOpen] = useState({
        productId: 0, open: false
    });


    function callApi(){
        setTableLoading(true);
        getUrl('admin/vouchers').then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const renderVouchers = (list) => {
        return _.map(list, (row, index) => {
            let color = "green";
            let text = <><span style={{ color: "gray" }}>{t('voucher.eventUntil')} </span>{row.voucher_header && row.voucher_header.redeem_edate ? row.voucher_header.redeem_edate : "-"}</>;
            let layerColor = "rgb(105, 202, 158, 81%)";
            // let enableEdit = true;
            if(row.status <= 0){
                color = "#dfbc04";
                text = t('voucher.draft');
                layerColor = "rgb(228, 192, 6, 81%)";
                // enableEdit = true;
            }
            if(row.voucher_header && row.voucher_header.redeem_edate && new Date(row.voucher_header.redeem_edate) < new Date()){
                color = "gray";
                text = t('voucher.expired');
                layerColor = "rgb(198, 197, 197, 81%)";
                // enableEdit = false;
            }

            return (
                <Grid key={index} item xs={6} sm={4} md={3} lg={2} style={{ marginBottom: 15, paddingRight: 30 }}>
                    <Grid container alignContent="center" justify="center" className={classes.hoverLayer} style={{ borderBottom: `5px solid ${color}`, backgroundImage: `url('${row.images_array[0]}')` }} >
                        <Grid className={classes.layer} style={{ backgroundColor: `${layerColor}` }}>
                            {/* { enableEdit ? <Link underline='none' color="inherit" to={`/vouchers/${row.id}`} component={RouterLink}><Button variant="outlined" className={classes.layerButton}>{t('button.edit')}</Button></Link> : null } */}
                            <Link underline='none' color="inherit" to={`/vouchers/${row.id}`} component={RouterLink}><Button variant="outlined" className={classes.layerButton}>{t('voucher.view')}</Button></Link>
                            <Button variant="outlined" className={classes.layerButton} onClick={() => setDuplicateOpen({open: true, productId: row.id})} style={{ marginTop: 10 }}>{t('voucher.duplicate')}</Button>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" className={classes.twoLine}>{row[`title_${i18n.language}`]}</Typography>
                    <Typography variant="body2" noWrap style={{ color: color }}>{text}</Typography>
                </Grid>
            )
        });
    }

    const duplicateProduct = () => {
        setTableLoading(true);
        postUrl(`admin/vouchers/${duplicateOpen.productId}/duplicate`).then(response => {
            if(isMountedRef.current) {
                if(response.status === 1){
                    callApi();
                    if(response.new_id){
                        let msg = t('snackbarMsg.duplicateSuccess') + ' ID: ' + response.new_id ;
                        addAlert('', msg, 'success', '');
                        window.open("/vouchers/"+response.new_id, "_blank");
                    }else{
                        let msg = t('snackbarMsg.duplicateSuccess');
                        addAlert('', msg, 'success', '');
                    }
                }else{
                    setTableLoading(false);
                    if(response.message){
                        let msg = response.message;
                        addAlert('', msg, 'error', '');
                    }else{
                        let msg = t('snackbarMsg.duplicateError');
                        addAlert('', msg, 'error', '');
                    }
                }
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="center" alignItems="center" style={{ background: "black" }}>
                    <Typography variant="h4" component="h1" style={{ color: "white", marginTop: 50, marginBottom: 50 }}>eVoucher Banner</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12}><Typography variant="subtitle1">{t('voucher.vouchers')}</Typography></Grid>
                <Grid item xs={12} container>
                    { _.includes(permissions, "voucher_add") ?
                        <Grid item xs={6} sm={4} md={3} lg={2} style={{ marginBottom: 15, paddingRight: 30 }}>
                            <Link underline='none' color="inherit" to="/vouchers/add" component={RouterLink}>
                                <Button size="large" variant="outlined" style={{ borderRadius: 0, borderWidth: 3, borderStyle: "dashed", height: 126, width: "100%" }}><AddIcon style={{ fontSize: 70, color: "gray" }}/></Button>
                            </Link>
                            <Typography variant="body1">{t('voucher.createNew')}</Typography>
                        </Grid>
                        : null
                    }
                    {
                        tableLoading
                        ?
                        <Grid item xs={6} sm={8} md={9} lg={10}><LinearProgress /></Grid>
                        :
                        renderVouchers(listing)
                    }
                </Grid>
            </Grid>
            <Dialog
                open={duplicateOpen.open}
                onClose={() => setDuplicateOpen({open: false})}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle>{t('dialog.reminderTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.duplicate') })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDuplicateOpen({open: false})} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={() => {duplicateProduct(); setDuplicateOpen({open: false})}} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    twoLine: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    layer: {
        display: "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    hoverLayer: {
        height: 126,
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        '&:hover > $layer': {
            display: "flex"
         },
    },
    layerButton: {
        borderColor: "white",
        color: "white",
        width: 110,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 0,
        textTransform: "capitalize"
    }
}));