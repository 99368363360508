import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { FormControl, FormHelperText, Grid, Breadcrumbs, Link, Paper, Box, Typography, TextField, Divider, Button } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

import { postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function PostAdd() {
    const { t } = useTranslation();

    return (
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Box display="flex" direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.post')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/courses`} component={RouterLink}>{t('title.post')}</Link>
                        <Typography style={{ color: 'black' }}>{t('post.addPost')}</Typography>
                    </Breadcrumbs>
                </Box>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <PostEditor />
            </Grid>
        </Grid>
    )
}

const INITIAL_STATE = {
    title_en: "",
    title_cn: "",
    description_en: "",
    description_cn: "",
    video: "",
    new_attachment: "",
    new_cover: "",
};

const PostEditor = () => {
    const [state, setState] = useState(INITIAL_STATE);
    const [inputErrors, setInputErrors] = useState(INITIAL_STATE);

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(state => ({ ...state, [name]: value }));
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const fileOnChange = async event => {
        let name = event.target.name;
        if(event.target.files[0]){
            let fileBase64 = await toBase64(event.target.files[0]);
            setState({...state, [name]: fileBase64});
        }else{
            setState({...state, [name]: null});
        }
    }

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const postData = {
            slug: _.kebabCase(state.title_en),
            title : {en: state.title_en, cn: state.title_cn},
            description : {en: state.description_en, cn: state.description_cn},
            video : state.video,
            new_attachment: state.new_attachment,
            new_cover: state.new_cover
        }
        postUrl(`admin/posts`, postData).then(response => {
            setLoading(false);
            let { status, message, error, data } = response;
            if(status) {
                addAlert( `${t('snackbarMsg.addSuccess')}`, '', 'success');
                history.replace(`/course/${data.slug}`);
            }else{
                if (_.size(error) > 0) {
                    _.map(error, (value, key) => {
                        message += "\n " + value[0];
                    })
                    setInputErrors(error);
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.addError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return (
        <Paper variant="outlined" elevation={3} square>
            <Box padding={1}>
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <TextField 
                                id="title_en" 
                                label={t('post.title_en')}
                                variant="outlined" 
                                fullWidth 
                                value={state.title_en}
                                name="title_en"
                                helperText={inputErrors['title.en'] ? inputErrors['title.en'] :''}
                                error={inputErrors['title.en'] ? true : false}
                                onChange={ handleChange }
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField 
                                id="title_cn" 
                                label={t('post.title_cn')}
                                variant="outlined" 
                                fullWidth
                                value={state.title_cn}
                                name="title_cn"
                                helperText={inputErrors['title.cn'] ? inputErrors['title.cn'] :''}
                                error={inputErrors['title.cn'] ? true : false}
                                onChange={ handleChange }
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth size="small" error={inputErrors['description.en'] ? true : false}>
                            <Typography variant="subtitle2" component="label">{ t('post.description_en') }</Typography>
                            <Editor
                                apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                value={state.description_en}
                                init={{
                                    height: "70vh",
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount quickbars'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                }}
                                onEditorChange={(content, editor) => setState({ ...state, description_en: content })}
                            />
                            { inputErrors['description.en']? <FormHelperText>{ inputErrors['description.en']}</FormHelperText> : null }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth size="small" error={inputErrors['description.cn'] ? true : false}>
                            <Typography variant="subtitle2" component="label">{ t('post.description_cn') }</Typography>
                            <Editor
                                apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                value={state.description_cn}
                                init={{
                                    height: "70vh",
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount quickbars'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                }}
                                onEditorChange={(content, editor) => setState({ ...state, description_cn: content })}
                            />
                            { inputErrors['description.cn'] ? <FormHelperText>{ inputErrors['description.cn'] }</FormHelperText> : null }
                        </FormControl>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="video"
                                fullWidth
                                label={t('post.youtubeUrl')}
                                variant="outlined"
                                value={state.video}
                                name="video"
                                onChange={ handleChange }
                                type="text"
                                placeholder={"e.g: https://www.youtube.com/watch?v=dQw4w9WgXcQ"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        { state.video ?
                            <YoutubeEmbed url={state.video}/>
                            : <p></p>
                        }
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('post.attachment')}
                                fullWidth
                                onChange={fileOnChange}
                                variant="outlined"
                                type="file"
                                inputProps={{ name: 'new_attachment', accept: "image/*, .pdf" }}
                                InputLabelProps={{ shrink: true }}
                                error={inputErrors.new_attachment ? true : false}
                                helperText={inputErrors.new_attachment}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('post.coverImage')}
                                fullWidth
                                onChange={fileOnChange}
                                variant="outlined"
                                type="file"
                                inputProps={{ name: 'new_cover', accept: "image/*" }}
                                InputLabelProps={{ shrink: true }}
                                error={inputErrors.new_cover ? true : false}
                                helperText={inputErrors.new_cover}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end" padding={2}>
                    <Button color="primary" variant="contained" onClick={form_submit}>{t('button.add')}</Button>
                </Box>
            </Box>
        </Paper>
    )
}

const YoutubeEmbed = ({ url }) => {
    const embedId = _.split(url, '?v=')[1];

    return (
        <iframe
            width="100%"
            height="300"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    )
}