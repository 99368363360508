import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, MenuItem, FormHelperText } from '@material-ui/core';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import { DropzoneArea } from 'material-ui-dropzone';

export default function VoucherAdd() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const { role, isAdmin } = useSelector(state => state.general);
    const history = useHistory();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        redeem_sdate: '',
        redeem_edate: '',
        sell_price: '',
        retail_price: '',
        max_purchase: '',
        title: '',
        descr_en: '',
        descr_cn: '',
        package_offer_en: '',
        package_offer_cn: '',
        fine_print_en: '',
        fine_print_cn: '',
        redeem_instruction_en: '',
        redeem_instruction_cn: '',
        merchant_company_id: '',
        quantity: '',
        sku: '',
        voucher_type: 'cash'
    });
    const [categoryTreeData, setCategoryTreeData] = useState([]);
    const [categoryId, setCategoryId] = useState(2779);
    const [companies, setCompanies] = useState([]);
    const [newImages, setNewImages] = useState([]);

    const voucherTypes = [{id: 'cash', value: t('voucher.cash')},{id: 'discount', value: t('voucher.discount')},{id: 'redeem', value: t('voucher.redeem')}];

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`admin/categories`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setCategoryTreeData(loopTreeData(result.tree_data, 2779));
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        if(isAdmin){
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert])

    const categoryOnChange = (currentNode, selectedNodes) => {
        if(_.size(selectedNodes)>0){
            setCategoryId(currentNode.value);
            let temp = loopTreeData(categoryTreeData, currentNode.value);
            setCategoryTreeData(temp);
        }else{
            setCategoryId("");
            let temp = loopTreeData(categoryTreeData, "");
            setCategoryTreeData(temp);
        }
    }
    const loopTreeData = (treeData, parentId) => {
        let temp = treeData;
        _.map(treeData, (data, key) => {
            if(data.value === parentId){
                temp[key].checked = true;
            }else{
                temp[key].checked = false;
            }
            if(data.children){
                temp[key].children = loopTreeData(data.children, parentId);
            }
        })
        return temp;
    }

    const form_submit = async () => {
        setLoading(true);
        setInputErrors([]);

        let newImagesBase64 = [];

        if(_.size(newImages) > 0){
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }

        let apiData = {
            title: state.title,
            category_id: categoryId,
            merchant_company_id: state.merchant_company_id,
            sell_price: state.sell_price,
            retail_price: state.retail_price,
            quantity: state.quantity,
            descr_en: state.descr_en,
            descr_cn: state.descr_cn,
            images: newImagesBase64,
            sku: state.sku,
            package_offer_en: state.package_offer_en,
            package_offer_cn: state.package_offer_cn,
            fine_print_en: state.fine_print_en,
            fine_print_cn: state.fine_print_cn,
            redeem_instruction_en: state.redeem_instruction_en,
            redeem_instruction_cn: state.redeem_instruction_cn,
            redeem_sdate: state.redeem_sdate,
            redeem_edate: state.redeem_edate,
            max_purchase: state.max_purchase,
            voucher_type: state.voucher_type
        }

        postUrl(`admin/vouchers`, apiData).then(response => {
            setLoading(false);
            if(response.status) {
                if(response.data.id){
                    addAlert( `${t('snackbarMsg.addSuccess')} ID: ${response.data.id}`, '', 'success');
                    history.replace(`/vouchers/${response.data.id}`);
                }
            }else{
                if(response.message){
                    addAlert('', response.message, 'error', '');
                }else{
                    addAlert('', t('snackbarMsg.addError'), 'error', '');
                }
                if(response.error){
                    setInputErrors(response.error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.addError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return(
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('voucher.addVoucher')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/vouchers`} component={RouterLink}>{t('title.vouchers')}</Link>
                        <Typography style={{ color: 'black' }}>{t('voucher.addVoucher')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="sku"
                                label={t('voucher.sku')}
                                variant="outlined"
                                fullWidth
                                value={state.sku}
                                helperText={inputErrors.sku ? inputErrors.sku[0] :''}
                                error={inputErrors.sku ? true : false}
                                onChange={({ target }) => setState({ ...state, sku: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="title"
                                label={t('voucher.title')}
                                variant="outlined"
                                fullWidth
                                value={state.title}
                                helperText={inputErrors.title ? inputErrors.title[0] :''}
                                error={inputErrors.title ? true : false}
                                onChange={({ target }) => setState({ ...state, title: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="fdate"
                                label={t('voucher.from')}
                                variant="outlined"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={state.redeem_sdate}
                                helperText={inputErrors.redeem_sdate ? inputErrors.redeem_sdate[0] :''}
                                error={inputErrors.redeem_sdate ? true : false}
                                onChange={({ target }) => setState({ ...state, redeem_sdate: target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="tdate"
                                label={t('voucher.to')}
                                variant="outlined"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={state.redeem_edate}
                                helperText={inputErrors.redeem_edate ? inputErrors.redeem_edate[0] :''}
                                error={inputErrors.redeem_edate ? true : false}
                                onChange={({ target }) => setState({ ...state, redeem_edate: target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="retail_price"
                                label={t('voucher.originalPriceRM')}
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={state.retail_price}
                                helperText={inputErrors.retail_price ? inputErrors.retail_price[0] :''}
                                error={inputErrors.retail_price ? true : false}
                                onChange={({ target }) => setState({ ...state, retail_price: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="sell_price"
                                label={t('voucher.offerPriceRM')}
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={state.sell_price}
                                helperText={inputErrors.sell_price ? inputErrors.sell_price[0] :''}
                                error={inputErrors.sell_price ? true : false}
                                onChange={({ target }) => setState({ ...state, sell_price: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="max_purchase"
                                label={t('voucher.maxPurchasePerCustomer')}
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={state.max_purchase}
                                helperText={inputErrors.max_purchase ? inputErrors.max_purchase[0] :''}
                                error={inputErrors.max_purchase ? true : false}
                                onChange={({ target }) => setState({ ...state, max_purchase: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DropdownTreeSelect
                                data={categoryTreeData}
                                mode="radioSelect"
                                keepTreeOnSearch
                                keepChildrenOnSearch
                                texts={{ placeholder: t('table.search')+ ' ' +t('product.category') }}
                                onChange={categoryOnChange}
                            />
                            {
                                inputErrors.categoryError
                                ?
                                <Typography variant="caption" style={{color: "red"}}>
                                    {inputErrors.categoryError[0]}
                                </Typography>
                                :null
                            }
                        </Grid> 
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                label={t('voucher.voucherType')}
                                value={state.voucher_type}
                                onChange={({ target }) => setState({ ...state, voucher_type: target.value })}
                                variant="outlined"
                                fullWidth
                                error={inputErrors.voucher_type ? true : false}
                                helperText={inputErrors.voucher_type ? inputErrors.voucher_type[0] : ''}
                            >
                                {voucherTypes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {`${option.value}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="descr_en"
                                label={t('voucher.descr_en')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.descr_en}
                                helperText={inputErrors.descr_en ? inputErrors.descr_en[0] :''}
                                error={inputErrors.descr_en ? true : false}
                                onChange={({ target }) => setState({ ...state, descr_en: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="descr_cn"
                                label={t('voucher.descr_cn')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.descr_cn}
                                helperText={inputErrors.descr_cn ? inputErrors.descr_cn[0] :''}
                                error={inputErrors.descr_cn ? true : false}
                                onChange={({ target }) => setState({ ...state, descr_cn: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="package_offer_en"
                                label={t('voucher.package_offer_en')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.package_offer_en}
                                helperText={inputErrors.package_offer_en ? inputErrors.package_offer_en[0] :''}
                                error={inputErrors.package_offer_en ? true : false}
                                onChange={({ target }) => setState({ ...state, package_offer_en: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="package_offer_cn"
                                label={t('voucher.package_offer_cn')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.package_offer_cn}
                                helperText={inputErrors.package_offer_cn ? inputErrors.package_offer_cn[0] :''}
                                error={inputErrors.package_offer_cn ? true : false}
                                onChange={({ target }) => setState({ ...state, package_offer_cn: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="fine_print_en"
                                label={t('voucher.fine_print_en')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.fine_print_en}
                                helperText={inputErrors.fine_print_en ? inputErrors.fine_print_en[0] :''}
                                error={inputErrors.fine_print_en ? true : false}
                                onChange={({ target }) => setState({ ...state, fine_print_en: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="fine_print_cn"
                                label={t('voucher.fine_print_cn')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.fine_print_cn}
                                helperText={inputErrors.fine_print_cn ? inputErrors.fine_print_cn[0] :''}
                                error={inputErrors.fine_print_cn ? true : false}
                                onChange={({ target }) => setState({ ...state, fine_print_cn: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="redeem_instruction_en"
                                label={t('voucher.redeem_instruction_en')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.redeem_instruction_en}
                                helperText={inputErrors.redeem_instruction_en ? inputErrors.redeem_instruction_en[0] :''}
                                error={inputErrors.redeem_instruction_en ? true : false}
                                onChange={({ target }) => setState({ ...state, redeem_instruction_en: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="redeem_instruction_cn"
                                label={t('voucher.redeem_instruction_cn')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.redeem_instruction_cn}
                                helperText={inputErrors.redeem_instruction_cn ? inputErrors.redeem_instruction_cn[0] :''}
                                error={inputErrors.redeem_instruction_cn ? true : false}
                                onChange={({ target }) => setState({ ...state, redeem_instruction_cn: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} container className={classes.imageRoot}>
                            <DropzoneArea
                                acceptedFiles={['image/*']}
                                dropzoneText={t('product.dragDropImageHere')}
                                onChange={(files) => setNewImages(files)}
                                filesLimit={10}
                                dropzoneClass={classes.dropzone}
                                maxFileSize={2000000}
                            />
                            <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10}) + t('general.fileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                            {inputErrors.images ?
                                <FormHelperText style={{ color: "red" }}>{inputErrors.images[0]}</FormHelperText>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="quantity"
                                label={t('voucher.quantity')}
                                variant="outlined"
                                fullWidth
                                type='number'
                                value={state.quantity}
                                helperText={inputErrors.quantity ? inputErrors.quantity[0] :''}
                                error={inputErrors.quantity ? true : false}
                                onChange={({ target }) => setState({ ...state, quantity: target.value }) }
                            />
                        </Grid>
                        { _.size(companies) ?
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    label={t('product.shopName')}
                                    value={state.merchant_company_id}
                                    onChange={({ target }) => setState({ ...state, merchant_company_id: target.value })}
                                    variant="outlined"
                                    fullWidth
                                    error={inputErrors.merchant_company_id ? true : false}
                                    helperText={inputErrors.merchant_company_id ? inputErrors.merchant_company_id[0] : ''}
                                >
                                    {companies.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {`${option.shop_name} [ID: ${option.id}]`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        : null }
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit}>{t('button.add')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    dropzone: {
        outline: "none",
    }
}));