import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

// import _ from 'lodash';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, useTheme, Box, AppBar, Tab, Tabs,Badge } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import ReorderIcon from '@material-ui/icons/Reorder';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CancelIcon from '@material-ui/icons/Cancel';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function Orders() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const [page, setPage] = useState(pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [tabValue, setTabValue] = useState(2);
    const [orderCount, setOrderCount]=useState({});

    function handleSubmit(event) {
        event.preventDefault();        
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue);
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    };

    const setUrl = (paging, rpp, sv) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/orders${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('admin/orders_total_by_status').then(result => {
            if (isMountedRef.current) {
                if (result.status === 1) {
                    setOrderCount(result.data);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setOrderCount({});
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, tabValue]);

    useEffect(() => {
        isMountedRef.current = true;
        let status = '';
        if(tabValue === 0){
            status = '';
        }else if(tabValue === 1){
            status = 'New';
        }else if(tabValue === 2){
            status = 'Paid';
        }else if(tabValue === 3){
            status = 'Processing';
        }else if(tabValue === 4){
            status = 'Shipping';
        }else if(tabValue === 5){
            status = 'Delivered';
        }else if(tabValue === 6){
            status = 'Completed';
        }else if(tabValue === 7){
            status = 'Cancelled';
        }else if(tabValue === 8){
            status = 'Refund-pending';
        }else if(tabValue === 9){
            status = 'Refunded';
        }else if(tabValue === 10){
            status = 'Returned';
        }
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'status': status };
        setTableLoading(true);
        getUrl('admin/orders', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, tabValue]);
    
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('order.orders')}</Typography>
                </Grid>
            </Grid>
            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:'#c11111',WebkitBoxShadow:'none'}} >
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="on" >
                    <Tab icon={<ReorderIcon />} label={t('order.all')} {...a11yProps(0)} />
                    <Tab icon={<Badge badgeContent={orderCount.new} color="secondary"><MoneyOffIcon /></Badge>} label={t('order.unpaid')} {...a11yProps(1)} />
                    <Tab icon={<Badge badgeContent={orderCount.paid} color="secondary"><AttachMoneyIcon /></Badge>} label={t('order.paid')} {...a11yProps(2)} />
                    <Tab icon={<Badge badgeContent={orderCount.processing} color="secondary"><HourglassEmptyIcon color="primary"/></Badge>} label={t('order.processing')} {...a11yProps(3)} />
                    <Tab icon={<Badge badgeContent={orderCount.shipping} color="secondary"><LocalShippingIcon color="primary" /></Badge>} label={t('order.shipping')} {...a11yProps(4)} />
                    <Tab icon={<Badge badgeContent={orderCount.delivered} color="secondary"><MarkunreadMailboxIcon color="primary"/></Badge>} label={t('order.delivered')} {...a11yProps(5)} />
                    <Tab icon={<Badge badgeContent={orderCount.completed} color="secondary"><CheckCircleIcon color="primary"/></Badge>} label={t('order.completed')} {...a11yProps(6)} />
                    <Tab icon={<Badge badgeContent={orderCount.cancelled} color="secondary"><CancelIcon /></Badge>} label={t('order.cancelled')} {...a11yProps(7)} />
                    <Tab icon={<Badge badgeContent={orderCount['refund-pending']} color="secondary"><PausePresentationIcon /></Badge>} label={t('order.refundPending')} {...a11yProps(8)} />
                    <Tab icon={<Badge badgeContent={orderCount.refunded} color="secondary"><LocalAtmIcon /></Badge>} label={t('order.refunded')} {...a11yProps(9)} />
                    {/* <Tab icon={<MonetizationOnIcon />} label={t('order.returned')} {...a11yProps(10)} /> */}
                </Tabs>
            </AppBar>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('order.orderNo')}</TableCell>
                                    <TableCell>{t('order.buyerUsername')}</TableCell>
                                    <TableCell>{t('order.buyerName')}</TableCell>
                                    <TableCell>{t('order.buyerFrom')}</TableCell>
                                    <TableCell>{t('order.recipientName')}</TableCell>
                                    <TableCell>{t('order.bvRecipient')}</TableCell>
                                    <TableCell>{t('order.status')}</TableCell>
                                    <TableCell>{t('order.amount')}</TableCell>
                                    <TableCell>{t('seller.shopName')}</TableCell>
                                    <TableCell>{t('order.createdAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row"><Link style={{color:"#929191"}} underline='none' to={`/orders/${row.order_no}`} component={RouterLink}>{row.order_no}</Link></TableCell>
                                            <TableCell>{ row.buyer_username }</TableCell>
                                            <TableCell>{ row.buyer_name }</TableCell>
                                            <TableCell>{ row.buyer_source }</TableCell>
                                            <TableCell>{ row.recipient }</TableCell>
                                            <TableCell>{ row.bv_recipient }</TableCell>
                                            <TableCell>{ row.status_display }</TableCell>
                                            <TableCell style={{textAlign: "right"}}>{ (parseFloat(row.total_ordered_product_price)+parseFloat(row.total_ori_shipping)).toFixed(2) }</TableCell>
                                            <TableCell>{ row.shop_name }</TableCell>
                                            <TableCell>{ row.created_at }</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={9} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));