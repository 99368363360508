import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import PropTypes from 'prop-types';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, Box, AppBar, Button, RadioGroup, Radio, TextField, FormControlLabel } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tab, Tabs } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ReorderIcon from '@material-ui/icons/Reorder';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// Dialog
import { Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';


function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}
function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function EventSummary() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { role, isMerchant } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [tabValue, setTabValue] = useState(0);
    const [winnerListDialog, setWinnerListDialog] = useState({
        open: false,
        winnerList: []
    });
    const [refundDialog, setRefundDialog] = useState({
        open: false,
        eventId: '',
        refundType: '',
        interestType: '',
        interestValue: '',
        walletId: ''
    });
    const [cancelDialog, setCancelDialog] = useState({
        open: false,
        eventId: '',
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [wallets, setWallets] = useState([]);
    const refundType = [{code: "normal", label: t('order.refundType.normal')}, {code: "special", label: t('order.refundType.special')}, {code: "no-refund", label: t('order.refundType.noRefund')}];
    const interestType = [{code: "percent", label: t('order.interestType.percent')}, {code: "value", label: t('order.interestType.value')}];
    const [refreshData, setRefreshData] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/luckydraw/event-summary${searchString !== '' ? '?'+searchString : ''}`);
    }

    const handleWinnerListDialogOpen = (winnerList) => {
        setWinnerListDialog({
            open: true,
            winnerList: winnerList
        });
    }
    const handleWinnerListDialogClose = () => {
        setWinnerListDialog({
            open: false,
            winnerList: []
        });
    }
    const handleRefundDialogOpen = (data) => {
        setRefundDialog({
            open: true,
            eventId: data.id,
            refundType: data.refund_type,
            interestType: data.interest_type,
            interestValue: data.interest_value,
            walletId: data.wallet_id
        });
    };
    const handleRefundDialogClose = () => {
        setRefundDialog({
            open: false,
            eventId: '',
            refundType: '',
            interestType: '',
            interestValue: '',
            walletId: ''
        });
    }
    const handleCancelDialogOpen = (id) => {
        setCancelDialog({
            open: true,
            eventId: id,
        });
    };
    const handleCancelDialogClose = () => {
        setCancelDialog({
            open: false,
            eventId: '',
        });
    }

    function callApi(){
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order, 'event': tabValue };
        setTableLoading(true);
        getUrl('admin/luckydraw/event_summary', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        getUrl('admin/wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, querySearch, orderBy, order, tabValue, refreshData]);

    const refund_event = () => {
        setInputErrors('');
        setLoading(true);
        let updateData = {
            refund_type: refundDialog.refundType,
            interest_type: refundDialog.interestType,
            interest_value: refundDialog.interestValue,
            wallet_id: refundDialog.walletId,
        };
        postUrl(`admin/luckydraw/order_refund/${refundDialog.eventId}`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('order.successFailList', {'success': response.success, 'fail': response.fail});
                addAlert('', msg, 'success', '');
                handleRefundDialogClose();
                setRefreshData(!refreshData);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const cancel_event_order = () => {
        setInputErrors('');
        setLoading(true);
        postUrl(`admin/luckydraw/order_cancel/${cancelDialog.eventId}`).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('order.successFailList', {'success': response.success, 'fail': response.fail});
                addAlert('', msg, 'success', '');
                handleCancelDialogClose();
                setRefreshData(!refreshData);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('luckydraw.eventSummary')}</Typography>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:'#c11111',WebkitBoxShadow:'none'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered indicatorColor="primary">
                        <Tab icon={<ReorderIcon />} label={t('luckydraw.all')} {...a11yProps(0)} />
                        <Tab icon={<ConfirmationNumberIcon />} label={t('luckydraw.event1')} {...a11yProps(1)} />
                        <Tab icon={<ConfirmationNumberIcon />} label={t('luckydraw.event2')} {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('luckydraw.id')} cellId={'id'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.productName')} cellId={'title'} />
                                    {tabValue === 0 ? <TableCell>{t('product.category')}</TableCell> : null}
                                    {isMerchant ? null : <TableCell>{t('product.shopName')}</TableCell> }
                                    {/* <TableCell>{t('product.source')}</TableCell> */}
                                    <TableCell>{t('luckydraw.winnerQuota')}</TableCell>
                                    <TableCell>{t('luckydraw.totalSlot')}</TableCell>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('luckydraw.createDate')} cellId={'created_at'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('luckydraw.drawDate')} cellId={'draw_date'} />
                                    {/* <TableCell>{t('luckydraw.drawDate')}</TableCell> */}
                                    <TableCell>{t('luckydraw.action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    let title_translate = _.split(row.product_title, '|');
                                    let cat_name_translate = _.split(row.cat_name, '|');
                                    return(
                                        <TableRow key={row.id}>
                                                <TableCell>
                                                    {/* <Link to={`/luckydraw/order-list/${row.id}`} component={RouterLink} underline="none" target="_blank">
                                                        <IconButton size="small" variant="outlined">
                                                            <Tooltip title={t('luckydraw.viewEventOrders')}>
                                                                <LibraryBooksIcon color="primary" />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Link> */}
                                                    {row.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row"><Link  style={{color:"#929191"}} underline='none' to={`/products/${row.product_id}`} component={RouterLink}>{ i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }</Link></TableCell>
                                                {tabValue === 0 ? <TableCell>{ i18n.language === 'cn' ? (cat_name_translate[1] ? cat_name_translate[1] : cat_name_translate[0]) : cat_name_translate[0] }</TableCell> : null}
                                                {isMerchant ? null : <TableCell>{row.shop_name}</TableCell> }
                                                {/* <TableCell>{row.source}</TableCell> */}
                                                <TableCell>{row.winner_quota}</TableCell>
                                                <TableCell>{row.total_slot}</TableCell>
                                                <TableCell>{row.created_at}</TableCell>
                                                <TableCell>
                                                    {
                                                        row.draw_date && row.winner_list
                                                        ?
                                                        <>
                                                        <Box>{ t('luckydraw.completed') }</Box>
                                                        <Box>{ row.draw_date }</Box>
                                                        </>
                                                        :
                                                        <Box>{ t('luckydraw.processing') }</Box>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {/* {
                                                        row.draw_date && row.winner_list
                                                        ?
                                                        <Button variant="contained" size="small" color="primary" style={{margin: "5px"}} onClick={() => handleWinnerListDialogOpen(row.winner_list_array)}>
                                                            {t('luckydraw.winnerList')}
                                                        </Button>
                                                        : null
                                                    } */}
                                                    <Link to={`/luckydraw/order-list/${row.id}`} component={RouterLink} underline="none">
                                                        <Button variant="contained" size="small" color="primary" style={{margin: "5px"}}>
                                                            {t('luckydraw.buyerList')}
                                                        </Button>
                                                    </Link>
                                                    {
                                                        row.available_refund
                                                        ?
                                                        <>
                                                        <Button variant="contained" size="small" color="primary" style={{margin: "5px"}} onClick={() => handleRefundDialogOpen(row)}>
                                                            {t('luckydraw.refund')}
                                                        </Button>
                                                        {/* <Button variant="contained" size="small" color="primary" style={{margin: "5px"}} onClick={() => handleCancelDialogOpen(row.id)}>
                                                            {t('luckydraw.cancelWithNoRefund')}
                                                        </Button> */}
                                                        </>
                                                        : null
                                                    }
                                                </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={8} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
            <Dialog
                open={refundDialog.open}
                onClose={handleRefundDialogClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('luckydraw.refund')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center">
                        {
                            _.map(refundType, (value, key) => {
                                if(value.code === refundDialog.refundType){
                                    return(
                                        <Grid item xs={12} key={value.id}>
                                            <TextField 
                                                id="refundType" 
                                                label={t('order.refundType.title')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={value.label}
                                                readOnly
                                            />
                                        </Grid>
                                    )
                                }
                            })
                        }
                        {
                            refundDialog.refundType === 'special'
                            ?
                            <>
                                {
                                    _.map(interestType, (value, key) => {
                                        if(value.code === refundDialog.interestType){
                                            return(
                                                <Grid item xs={12} key={value.id}>
                                                    <TextField 
                                                        id="interestType" 
                                                        label={t('order.interestType.title')}
                                                        variant="outlined" 
                                                        fullWidth 
                                                        value={value.label}
                                                        readOnly
                                                    />
                                                </Grid>
                                            )
                                        }
                                    })
                                }
                                {
                                    wallets.map((value, key) => {
                                        if(value.id === refundDialog.walletId){
                                            let name = _.split(value.name, '|');
                                            return(
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField 
                                                            id="interestValue" 
                                                            label={refundDialog.interestType==='percent'?t('order.interestRate'):t('order.interestValue')}
                                                            variant="outlined" 
                                                            fullWidth 
                                                            value={refundDialog.interestValue}
                                                            readOnly
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField 
                                                            id="walletId" 
                                                            label={t('wallet.walletType')}
                                                            variant="outlined" 
                                                            fullWidth 
                                                            value={i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}
                                                            readOnly
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }
                                    })
                                }
                            </>
                            :
                            null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefundDialogClose} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={() => refund_event()} color="primary" autoFocus>{t('order.confirmRefund')}</Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog onClose={handleWinnerListDialogClose} open={winnerListDialog.open} fullWidth>
                <DialogTitle onClose={handleWinnerListDialogClose}>
                    {t('luckydraw.winnerList')}
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('luckydraw.winner.buyerName')}</TableCell>
                                    <TableCell>{t('luckydraw.winner.buyerMobile')}</TableCell>
                                    <TableCell>{t('luckydraw.winner.buyerEmail')}</TableCell>
                                    <TableCell>{t('luckydraw.winner.orderNo')}</TableCell>
                                    <TableCell>{t('luckydraw.winner.createdAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(winnerListDialog.winnerList, (winner, key) => {
                                        return(
                                            <TableRow key={key}>
                                                <TableCell>{winner.buyer_name}</TableCell>
                                                <TableCell>{winner.buyer_mobile}</TableCell>
                                                <TableCell>{winner.buyer_email}</TableCell>
                                                <TableCell><Link to={`/orders/${winner.order_no}`} component={RouterLink} underline="none" target="_blank">{winner.order_no}</Link></TableCell>
                                                <TableCell>{winner.created_at}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
            <Dialog
                open={refundDialog.open}
                onClose={handleRefundDialogClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('luckydraw.refund')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center">
                        <Grid item xs={12}>
                            <Typography>{ t('order.refundType.title') }</Typography>
                            {
                                inputErrors.refund_type ?
                                <Typography variant='caption' style={{color:"red"}}>
                                    {inputErrors.refund_type}
                                </Typography>
                                : null
                            }
                            <RadioGroup aria-label="refund type" name="refundType" value={refundDialog.refundType} onChange={(event) => { setRefundDialog({ ...refundDialog, refundType: event.target.value}) }}>
                                {
                                    _.map(refundType, (value, key) => {
                                        return(
                                            <FormControlLabel key={key} value={value.code} control={<Radio />} label={value.label} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </Grid>
                        {
                            refundDialog.refundType === 'special'
                            ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>{ t('order.interestType.title') }</Typography>
                                    {
                                        inputErrors.interest_type ?
                                        <Typography variant='caption' style={{color:"red"}}>
                                            {inputErrors.interest_type}
                                        </Typography>
                                        : null
                                    }
                                    <RadioGroup aria-label="interest type" name="interestType" value={refundDialog.interestType} onChange={(event) => { setRefundDialog({ ...refundDialog, interestType: event.target.value}) }}>
                                        {
                                            _.map(interestType, (value, key) => {
                                                return(
                                                    <FormControlLabel key={key} value={value.code} control={<Radio />} label={value.label} />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        id="interestValue" 
                                        label={refundDialog.interestType==='percent'?t('order.interestRate'):t('order.interestValue')}
                                        variant="outlined" 
                                        fullWidth 
                                        value={refundDialog.interestValue}
                                        helperText={inputErrors.interest_value ? inputErrors.interest_value :''}
                                        error={inputErrors.interest_value ? true : false}
                                        onChange={(event) => { setRefundDialog({ ...refundDialog, interestValue: event.target.value}) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        id="walletId" 
                                        label={t('wallet.walletType')}
                                        variant="outlined" 
                                        fullWidth 
                                        select
                                        value={refundDialog.walletId}
                                        helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                                        error={inputErrors.wallet_id ? true : false}
                                        onChange={(event) => { setRefundDialog({ ...refundDialog, walletId: event.target.value}) }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                    <option key='' value='' disabled>
                                        {t('general.pleaseSelect')}
                                    </option>
                                    {wallets.map((value, key) => {
                                        let name = _.split(value.name, '|');
                                        return(
                                            <option key={key} value={value.id}>
                                                { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                            </option>
                                        )
                                    })}
                                    </TextField>
                                </Grid>
                            </>
                            :
                            null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefundDialogClose} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={() => refund_event()} color="primary" autoFocus>{t('order.confirmRefund')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cancelDialog.open}
                onClose={handleCancelDialogClose}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('luckydraw.cancelWithNoRefund')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center">
                        <Grid item xs={12}>
                            <Typography>{ t('luckydraw.cancelWithNoRefundReminder') }</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={() => cancel_event_order()} color="primary" autoFocus>{t('order.confirmCancelOrder')}</Button>
                </DialogActions>
            </Dialog> */}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));