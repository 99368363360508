import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';

// import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Typography, Link, Grid, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@material-ui/core';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

export default function Admins() {
    // const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [statusType, setStatusType] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const statusTypeToCode = { 'activate': 1, 'suspend': 2, 'terminate': 3 };
    const [updateDialog, setOpenUpdateDialog] = useState(false);
    const [userId, setUserId] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        getUrl(`admin/admins`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
                setUserId(response.own_id);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const alertChangeStatus = (event, data, type) => {
        setStatusType(type);
        setSelectedRow(data);
        setOpenUpdateDialog(true);
    };

    const updateStatus = () => {
        setLoading(true);

        let apiData = { status: statusTypeToCode[statusType], ids: _.map(selectedRow, 'id') };
        putUrl(`admin/admins/status`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    _.map(selectedRow, (row, index) => {
                        let dataIndex = tableData.findIndex(item => item.id === row.id);
                        newTableData[dataIndex] = { ...row, status: statusTypeToCode[statusType] };
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateDialog(false);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('admin.admins')}</Typography>
                    { _.includes(permissions, "admin_add") ?
                        <Link underline='none' color="inherit" to="/admins/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('profile.name'), field: 'name', render: rowData => (rowData.id!==userId)?<Link underline='none' to={`/admins/${rowData.id}`} component={RouterLink}>{rowData.name}</Link>:<Typography>{rowData.name}</Typography> },
                        // { title: t('profile.username'), field: 'username' },
                        { title: t('profile.email'), field: 'email' },
                        // { title: t('profile.mobile'), field: 'mobile' },
                        { title: t('user.role'), field: 'user_role', render: rowData => t('role.' + rowData.user_role) },
                        { title: t('table.status'), field: 'status', render: rowData => t('user.status.' + rowData.status) }
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        selection: _.includes(permissions, "admin_delete") ? true : false
                    }}
                    actions={_.includes(permissions, "admin_delete") ? [
                        {
                            tooltip: t('admin.activateSelectedAdmin'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'activate')
                        },
                        {
                            tooltip: t('admin.suspendSelectedAdmin'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'suspend')
                        },
                        {
                            tooltip: t('admin.terminateSelectedAdmin'),
                            icon: 'delete',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'terminate')
                        }
                    ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
                }
            </Grid>
            <Dialog
                open={updateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('user.confirmOnStatusUpdate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`user.askUpdateStatus.${statusTypeToCode[statusType]}`, { 'number': selectedRow ? selectedRow.length : 0 })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));