import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, useTheme, Box, AppBar, Tab, Tabs } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import ReorderIcon from '@material-ui/icons/Reorder';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import * as XLSX from 'xlsx';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };
    const exportFile = () => {
        let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
        XLSX.writeFile(wb, `ReportMemberWithdrawals${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
    }


    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
             <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="small" /></IconButton></Tooltip>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function WithdrawalsMember() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const [page, setPage] = useState(pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [tabValue, setTabValue] = useState(2);
    const [inputErrors, setInputErrors] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const { role, isAdmin } = useSelector(state => state.general);
    const [withdrawalStatus, setWithdrawalStatus] = useState([]);
    const [withdrawalStatusDialog, setWithdrawalStatusDialog] = useState({
        open: false,
        id: '',
        status: '',
        reference: '',
        remark: '',
    });
    const [wallets, setWallets] = useState([]);
    const [withdrawalRules, setWithdrawalRules] = useState([]);
    const [withdrawalRulesDialog, setWithdrawalRulesDialog] = useState({
        open: false,
        min: 0,
        max: 0,
        walletId: 0,
        charges: '',
        chargesType: '',
        chargesValue: 0,
    });
    const charges = [{code: "add", label: t('withdrawal.rule.charges.add')}, {code: "deduct", label: t('withdrawal.rule.charges.deduct')}];
    const chargesType = [{code: "percent", label: t('withdrawal.rule.chargesType.percent')}, {code: "amount", label: t('withdrawal.rule.chargesType.amount')}];


    function handleSubmit(event) {
        event.preventDefault();        
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue);
    };
    const handleTabChange = (event, newValue) => {
        setPage(1);
        setRowsPerPage(10);
        setTabValue(newValue);
        history.push(`/withdrawals-member?page=1&per_page=10&search=${searchValue}`);
    };

    const setUrl = (paging, rpp, sv) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/withdrawals-member${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        getUrl('admin/withdrawals-member/rules').then(result => {
            if(result.status === 1){
                setWithdrawalRulesDialog({ ...withdrawalRulesDialog, 
                    min: result.data.min?result.data.min:0,
                    max: result.data.max?result.data.max:0,
                    walletId: result.data.wallet_id?result.data.wallet_id:0,
                    charges: result.data.charges?result.data.charges:'',
                    chargesType: result.data.charges_type?result.data.charges_type:'',
                    chargesValue: result.data.charges_value?result.data.charges_value:'',
                });
                setWithdrawalRules(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        getUrl('admin/wallets').then(result => {
            if(result.status === 1){
                setWallets(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }, [addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        let status = '';
        if(tabValue === 0){
            status = '';
        }else if(tabValue === 1){
            status = 'Pending';
        }else if(tabValue === 2){
            status = 'Processing';
        }else if(tabValue === 3){
            status = 'Completed';
        }else if(tabValue === 4){
            status = 'Cancelled';
        }
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'status': status };
        setTableLoading(true);
        getUrl('admin/withdrawals-member', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                    setWithdrawalStatus(result.withdrawal_status);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, tabValue, refreshData]);

    const handleWithdrawalStatusOpen = (wdata) => {
        setWithdrawalStatusDialog({
            open: true,
            id: wdata.id,
            status: wdata.status,
            reference: wdata.reference,
            remark: wdata.remark,
        })
    }
    const handleWithdrawalStatusClose = () => {
        setWithdrawalStatusDialog({
            open: false,
            id: '',
            status: '',
            reference: '',
            remark: '',
        })
    }
    const handleWithdrawalRulesOpen = () => {
        setWithdrawalRulesDialog({ ...withdrawalRulesDialog, open: true })
    }
    const handleWithdrawalRulesClose = () => {
        setWithdrawalRulesDialog({ 
            open: false,
            min: withdrawalRules.min?withdrawalRules.min:0,
            max: withdrawalRules.max?withdrawalRules.max:0,
            walletId: withdrawalRules.wallet_id?withdrawalRules.wallet_id:0,
            charges: withdrawalRules.charges?withdrawalRules.charges:'',
            chargesType: withdrawalRules.charges_type?withdrawalRules.charges_type:'',
            chargesValue: withdrawalRules.charges_value?withdrawalRules.charges_value:0,
        })
    }

    const updateStatus = () => {
        setLoading(true);
        setInputErrors("");
        setWithdrawalStatusDialog({ ...withdrawalStatusDialog, open: false});
        const updateData = {
            status: withdrawalStatusDialog.status,
            reference: withdrawalStatusDialog.reference,
            remark: withdrawalStatusDialog.remark,
        };
        postUrl(`admin/withdrawals/${withdrawalStatusDialog.id}`, updateData)
        .then(response => {
            setLoading(false);
            if(response.status){
                handleWithdrawalStatusClose();
                setRefreshData(!refreshData);
                addAlert('', t('snackbarMsg.updateSuccess'), 'success', '');
            }else{
                setWithdrawalStatusDialog({ ...withdrawalStatusDialog, open: true});
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch(error => {
            setWithdrawalStatusDialog({ ...withdrawalStatusDialog, open: true});
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const updateWithdrawalRules = () => {
        setLoading(true);
        setInputErrors("");
        setWithdrawalRulesDialog({ ...withdrawalRulesDialog, open: false});
        let updateData = {
            min: withdrawalRulesDialog.min,
            max: withdrawalRulesDialog.max,
            wallet_id: withdrawalRulesDialog.walletId,
            charges: withdrawalRulesDialog.charges,
            charges_type: withdrawalRulesDialog.chargesType,
            charges_value: withdrawalRulesDialog.chargesValue,
        };
        postUrl('admin/withdrawals-member/rules', updateData).then(response => {
            setLoading(false);
            if(response.status === 1){
                setWithdrawalRules({ 
                    min: withdrawalRulesDialog.min,
                    max: withdrawalRulesDialog.max,
                    wallet_id: withdrawalRulesDialog.walletId,
                    charges: withdrawalRulesDialog.charges,
                    charges_type: withdrawalRulesDialog.chargesType,
                    charges_value: withdrawalRulesDialog.chargesValue,
                });
                addAlert('', t('snackbarMsg.updateSuccess'), 'success', '');
            }else{
                setWithdrawalRulesDialog({ ...withdrawalRulesDialog, open: true});
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            setWithdrawalRulesDialog({ ...withdrawalRulesDialog, open: true});
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('withdrawal.withdrawalsMember')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            {
                isAdmin
                ?
                <Button variant="contained" color="primary" onClick={ () => handleWithdrawalRulesOpen() }>
                    {t('withdrawal.withdrawalRules')}
                </Button>
                :
                null
            }
            </Grid>
            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:'#c11111',WebkitBoxShadow:'none'}} >
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="on" >
                    <Tab icon={<ReorderIcon />} label={t('withdrawal.all')} {...a11yProps(0)} />
                    <Tab icon={<ScheduleIcon />} label={t('withdrawal.pending')} {...a11yProps(1)} />
                    <Tab icon={<HourglassEmptyIcon />} label={t('withdrawal.processing')} {...a11yProps(2)} />
                    <Tab icon={<CheckCircleIcon />} label={t('withdrawal.completed')} {...a11yProps(3)} />
                    <Tab icon={<CancelIcon />} label={t('withdrawal.cancelled')} {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    <Typography variant="h6" component="h1">{t('withdrawal.withdrawalHistory')}</Typography>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            title={t('withdrawal.history')}
                            id="exportTable"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('withdrawal.id')}</TableCell>
                                    <TableCell>{t('withdrawal.name')}</TableCell>
                                    <TableCell>{t('withdrawal.requestAmount')}</TableCell>
                                    <TableCell>{t('withdrawal.amount')}</TableCell>
                                    <TableCell>{t('profile.bankName')}</TableCell>
                                    <TableCell>{t('profile.bankHolderName')}</TableCell>
                                    <TableCell>{t('profile.bankAccount')}</TableCell>
                                    <TableCell>{t('profile.bankSwift')}</TableCell>
                                    <TableCell>{t('withdrawal.reference')}</TableCell>
                                    <TableCell>{t('withdrawal.remark')}</TableCell>
                                    <TableCell>{t('withdrawal.createdAt')}</TableCell>
                                    <TableCell>{t('withdrawal.status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    let editStatus = false;
                                    if(isAdmin){
                                        editStatus = true;
                                    }
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">{ row.id }</TableCell>
                                            <TableCell>{ row.username }</TableCell>
                                            <TableCell><NumberFormat value={row.request_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell>
                                            <TableCell><NumberFormat value={row.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell>
                                            <TableCell>{row.bank_name}</TableCell>
                                            <TableCell>{row.bank_holder_name}</TableCell>
                                            <TableCell>{row.bank_account}</TableCell>
                                            <TableCell>{row.bank_swift}</TableCell>
                                            <TableCell>{ row.reference }</TableCell>
                                            <TableCell>{ row.remark }</TableCell>
                                            <TableCell>{ row.created_at }</TableCell>
                                            <TableCell>
                                                { row.status_display }
                                                {
                                                    editStatus && row.status<60 ?
                                                    <IconButton color="primary" variant="contained" onClick={() => handleWithdrawalStatusOpen({id: row.id, status: row.status, reference: row.reference, remark: row.remark})}><EditIcon fontSize="small" /></IconButton>
                                                    :null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={12} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                        rowsPerPageOptions={totalCount ? [...rowsPerPageOptiosArray, { value: totalCount, label: 'All' }] : rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
            <Dialog open={ withdrawalStatusDialog.open } onClose={ handleWithdrawalStatusClose } fullWidth >
                <DialogTitle>
                    { t('withdrawal.updateStatus') }
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center" >
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.id')}
                                value={ withdrawalStatusDialog.id }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>        
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.status')}
                                value={ withdrawalStatusDialog.status }
                                select
                                onChange={ (event) => { setWithdrawalStatusDialog({ ...withdrawalStatusDialog, status: event.target.value})} }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {withdrawalStatus.map((data, key) => {
                                let status_translate = _.split(data.desc, '|');
                                return(
                                    <option key={key} value={data.value}>
                                        { i18n.language === 'cn' ? (status_translate[1] ? status_translate[1] : status_translate[0]) : status_translate[0] }
                                    </option>
                                );
                            })}
                            </TextField>
                        </Grid>
                        {
                            parseInt(withdrawalStatusDialog.status) === 60 // completed
                            ?
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true} 
                                    variant="outlined" 
                                    label={t('withdrawal.reference')}
                                    value={ withdrawalStatusDialog.reference?withdrawalStatusDialog.reference:'' }
                                    onChange={ (event) => { setWithdrawalStatusDialog({ ...withdrawalStatusDialog, reference: event.target.value})} }
                                    />
                            </Grid>
                            :
                            null
                        }
                        {
                            parseInt(withdrawalStatusDialog.status) === 80 // cancelled
                            ?
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true} 
                                    variant="outlined" 
                                    label={t('withdrawal.remark')}
                                    value={ withdrawalStatusDialog.remark?withdrawalStatusDialog.remark:'' }
                                    helperText={inputErrors.remark ? inputErrors.remark : ''}
                                    error={inputErrors.remark ? true : false}
                                    onChange={ (event) => { setWithdrawalStatusDialog({ ...withdrawalStatusDialog, remark: event.target.value})} }
                                />
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleWithdrawalStatusClose}>
                        <Typography variant="overline">{ t('button.cancel') }</Typography>
                    </Button>
                    <Button variant="contained" color="primary" onClick={ () => updateStatus()} style={{ margin: "5px 22px" }}>
                        <Typography variant="overline">{ t('withdrawal.confirm') }</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ withdrawalRulesDialog.open } onClose={ handleWithdrawalRulesClose } fullWidth >
                <DialogTitle>
                    { t('withdrawal.withdrawalRules') }
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center" >
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.rule.min')}
                                value={withdrawalRulesDialog.min}
                                helperText={inputErrors.min ? inputErrors.min : ''}
                                error={inputErrors.min ? true : false}
                                onChange={ (event) => {setWithdrawalRulesDialog({ ...withdrawalRulesDialog, min: event.target.value})} }
                                disabled={isAdmin ? false : true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.rule.max')}
                                value={withdrawalRulesDialog.max}
                                helperText={inputErrors.max ? inputErrors.max : ''}
                                error={inputErrors.max ? true : false}
                                onChange={ (event) => {setWithdrawalRulesDialog({ ...withdrawalRulesDialog, max: event.target.value})} }
                                disabled={isAdmin ? false : true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.rule.walletId')}
                                value={withdrawalRulesDialog.walletId}
                                select
                                onChange={ (event) => {setWithdrawalRulesDialog({ ...withdrawalRulesDialog, walletId: event.target.value})} }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {wallets.map((w, key) => {
                                return(
                                    <option key={key} value={w.id} disabled={isAdmin ? false : true}>
                                        { w.wallet_name }
                                    </option>
                                );
                            })}
                            </TextField>
                            {
                                inputErrors.wallet_id ?
                                <Typography variant="body2" style={{color: "red"}}>{ inputErrors.wallet_id }</Typography>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.rule.charges.title')}
                                value={withdrawalRulesDialog.charges}
                                select
                                onChange={ (event) => {setWithdrawalRulesDialog({ ...withdrawalRulesDialog, charges: event.target.value})} }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {charges.map((c, key) => {
                                return(
                                    <option key={key} value={c.code} disabled={isAdmin ? false : true}>
                                        { c.label }
                                    </option>
                                );
                            })}
                            </TextField>
                            {
                                inputErrors.charges ?
                                <Typography variant="body2" style={{color: "red"}}>{ inputErrors.charges }</Typography>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.rule.chargesType.title')}
                                value={withdrawalRulesDialog.chargesType}
                                select
                                onChange={ (event) => {setWithdrawalRulesDialog({ ...withdrawalRulesDialog, chargesType: event.target.value})} }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {chargesType.map((c, key) => {
                                return(
                                    <option key={key} value={c.code} disabled={isAdmin ? false : true}>
                                        { c.label }
                                    </option>
                                );
                            })}
                            </TextField>
                            {
                                inputErrors.charges_type ?
                                <Typography variant="body2" style={{color: "red"}}>{ inputErrors.charges_type }</Typography>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('withdrawal.rule.chargesValue.' + (withdrawalRulesDialog.chargesType==='percent'?'percent':'amount'))}
                                value={withdrawalRulesDialog.chargesValue}
                                helperText={inputErrors.charges_value ? inputErrors.charges_value : ''}
                                error={inputErrors.charges_value ? true : false}
                                onChange={ (event) => {setWithdrawalRulesDialog({ ...withdrawalRulesDialog, chargesValue: event.target.value})} }
                                disabled={isAdmin ? false : true}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleWithdrawalRulesClose}>
                        <Typography variant="overline">{ t('button.cancel') }</Typography>
                    </Button>
                    <Button variant="contained" color="primary" onClick={ () => updateWithdrawalRules() } style={{ margin: "5px 22px" }}>
                        <Typography variant="overline">{ t('withdrawal.confirm') }</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));