import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authSuccess, authFail } from '../actions';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import _ from 'lodash';
import { APP_NAME, CLIENT_SECRET, BACKEND_URL } from '../configs/Config';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Paper, FormControlLabel, Checkbox, Avatar, Hidden, Menu, MenuItem } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ErrorIcon from '@material-ui/icons/Error';

import jwt from 'jwt-decode';

import LanguageIcon from '@material-ui/icons/Language';
import { changeLanguage as reduxChangeLanguage } from '../actions';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function useMessage(initialValue){
    const [value, setValue] = useState(initialValue);
    return {
        value,
        setValue,
        reset: () => setValue(""),
    };
}

export default function Login() {

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const [state, setState] = useState({
        username: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    const { value:errorGeneral, setValue:setErrorGeneral, reset:resetErrorGeneral } = useMessage('');

    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/dashboard" } };

    const dispatch = useDispatch();

    let loginUser = () => {
        setLoading(true);
        resetErrorGeneral();
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, {
            grant_type: 'password',
            client_id: '3',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        })
        .then((response) => {
            setLoading(false);
            if(response.status === 200) {
                const decoded_jwt = jwt(response.data.access_token);
                console.log('decoded_jwt', decoded_jwt);
                const { isAdmin, isMerchant, company_status ,company_id } = decoded_jwt;
                if(isAdmin || isMerchant) {
                    if(isMerchant && !company_status) {
                        setErrorGeneral(t('error.companyPendingApproval'));
                    }else{
                        if(company_status == '4'){
                            dispatch(authSuccess(response.data));
                            history.push(`/seller/register?company_id=${company_id} `);
                        }else{
                            dispatch(authSuccess(response.data));
                            history.replace(from);
                        }
                    }
                }else{
                    setErrorGeneral(t('error.invalidCredentials'));
                }
            }   
        }).catch((error) => {
            console.log(error.request);
            if (error.response && error.response.status === 400) {
                setErrorGeneral(t('error.invalidCredentials'));
            }else if (error.response && error.response.status === 401) {                
                let errorResponse = JSON.parse(error.request.response);
                setErrorGeneral(errorResponse.message);
            }else{
                setErrorGeneral(t('error.contactSupport'));
            }            
            dispatch(authFail());
            setLoading(false);            
        });   
    };

    const [languageAnchorEl, setLanguageAnchorEl] = useState(false);
    useEffect(() => {
        if(!_.includes(['en', 'cn'], i18n.language)) {
            i18n.changeLanguage('en');
            dispatch(reduxChangeLanguage('en'));
        }
    },[i18n.language]);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        setLanguageAnchorEl(null);
    };
    
    return (
        <Grid container component="main" className={classes.root}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Hidden xsDown>
            <Grid item xs={false} sm={4} md={7} style={{backgroundImage:"url(../images/login-texture.png)", backgroundSize:'cover', display: 'flex', justifyContent: 'center', alignItems: 'center'}} /* className={classes.image} */ >
                <img src="../images/login-graphic.png" style={{ width: '800px', maxWidth:'100%' }} alt=""/>
            </Grid>
            </Hidden>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square style={{padding:'0 20px'}}>
                <Box style={{display: "flex", justifyContent: "flex-end", margin: 10}}>
                    <Button color="primary" aria-controls="language-menu" aria-haspopup="true" onClick={(event) => setLanguageAnchorEl(event.currentTarget)}>
                        <LanguageIcon />
                        <Typography variant="caption" style={{paddingLeft: 5}}>{t('button.language')}</Typography>
                    </Button>
                    <Menu
                        anchorEl={languageAnchorEl}
                        id="language-menu"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={Boolean(languageAnchorEl)}
                        onClose={() => setLanguageAnchorEl(null)}
                    >
                        <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                        <MenuItem onClick={() => changeLanguage('cn')}>中文</MenuItem>
                    </Menu>
                </Box>
                
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div className={classes.paper} style={{width:'500px', maxWidth:'100%', marginTop:'5%'}}>
                        {/* <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <center><img src="../images/logo.png" style={{ width: 114, maxWidth:'100%', paddingBottom:25 }} alt=""/></center>
                        <Typography component="h1" variant="h5" color="primary">
                            {t('title.login')}
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); loginUser() }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('profile.username') + '/' + t('register.email')}
                                name="email"
                                value={state.username}
                                onChange={({ target }) => setState({ ...state, username: target.value })}
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('register.password')}
                                value={state.password}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                            {errorGeneral &&
                                <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{errorGeneral}</Typography>
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            // onClick={loginUser}
                            >
                                {t('button.login')}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link underline='none' to={`/forgot-password`} component={RouterLink}>
                                        {t('title.forgotPassword')}
                                    </Link>
                                </Grid>
                                <Grid item xs>
                                <Link underline='none' to={`/seller/register`} component={RouterLink}>
                                    { t('title.registerSeller') }
                                </Link>
                            </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));