import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, LinearProgress, TextField, FormControl, FormLabel, Radio, RadioGroup, Modal, Paper, Divider, FormControlLabel, FormHelperText } from '@material-ui/core';

import MaterialTable from 'material-table';

export default function Kyc() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedOptionIndex, setOptionIndex] = useState('');
    let initialState = {
        status: '',
        remark: "",
    };
    const [state, setState] = useState(initialState);
    const [inputErrors, setInputErrors] = useState([]);

    function callApi(){
        getUrl(`admin/kyc`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setTableLoading(false);
                setTableData(response.data);
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setTableLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setInputErrors([]);
        if(selectedOptionIndex){
            getUrl(`admin/kyc/${selectedOptionIndex}`).then(response => {
                if (response.status === 1 && isMountedRef.current) {
                    setState({...state, ...response.data});
                }
            }).catch((error) => {
                if (isMountedRef.current) {
                    let msg = error + "\n" + t('error.contactSupport');
                    addAlert('', msg, 'error', '');
                }
            })

        }else if(!selectedOptionIndex){
            setState(initialState);
        }
    }, [selectedOptionIndex]);

    const saveOptions = () => {
        setInputErrors([]);
        setLoading(true);
        let apiData = {
            status: state.status,
            remark: state.remark,
        };

        putUrl(`admin/kyc/${selectedOptionIndex}`, apiData).then(response => {
            setLoading(false);
            let { status, message, error } = response;
            if (status === 1) {
                addAlert('', message, 'success', '');
                callApi();
                setOptionIndex('');
            } else {
                if (_.size(error) > 0) {
                    _.map(error, (value, key) => {
                        message += "\n " + value[0];
                    })
                    setInputErrors(error);
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {
                    tableLoading
                    ?
                    <LinearProgress />
                    :
                    <MaterialTable
                        title= { t('kyc.kyc') }
                        columns={[
                            { title: t('kyc.name'), field: 'name' },
                            { title: t('kyc.email'), field: 'email' },
                            { title: t('kyc.status'), field: 'user.verified', lookup: { 1: t('kyc.statuses.1'), 2: t('kyc.statuses.2'), 3: t('kyc.statuses.3') } },
                            { title: t('table.ldate'), field: 'updated_at'},
                        ]}
                        data={tableData}
                        options={{
                            pageSize: 10
                        }}
                        style={{ ...theme.box1, ...theme.p20 }}
                        actions={[
                            {
                                icon: 'visibility',
                                tooltip: t('kyc.viewKyc'),
                                onClick: (event, rowData) => { setOptionIndex(rowData.id); },
                                disabled: !_.includes(permissions, "member"),
                                hidden: !_.includes(permissions, "member")
                            }
                        ]}
                        localization={{
                            pagination: {
                                labelDisplayedRows: t('table.labelDisplayedRows'),
                                labelRowsSelect: t('table.rows'),
                                firstTooltip: t('table.firstPage'),
                                previousTooltip: t('table.previousPage'),
                                nextTooltip: t('table.nextPage'),
                                lastTooltip: t('table.lastPage'),
                            },
                            toolbar: {
                                searchTooltip: t('table.search'),
                                searchPlaceholder: t('table.search'),
                                nRowsSelected: t('table.rowsSelected')
                            },
                            body: {
                                emptyDataSourceMessage: t('table.noRecord'),
                            },
                            header: {
                                actions: t('table.actions')
                            },
                        }}
                    />
                }
            </Grid>
            <Modal
                open={selectedOptionIndex !== '' ? true : false}
                onClose={() => {setOptionIndex("");} }
                aria-labelledby="kyc"
                aria-describedby="kyc modal"
                disableBackdropClick
            >
                <Grid container spacing={3} className={classes.modal}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{t('kyc.kycDetails')}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{t('kyc.name')}: {state.name}</Typography>
                                    <Typography>{t('kyc.email')}: {state.email}</Typography>
                                    <Typography>{t('kyc.mobile')}: {state.mobile}</Typography>
                                    <Typography>{t('kyc.nric')}: {state.nric}</Typography>
                                    <Typography>{t('kyc.address')}: {state.address ? state.address.full_address : ""}</Typography>
                                    <Typography>{t('kyc.status')}: { state.user ? <span style={{ color: state.user.verified === 3 ? "green" : state.user.verified === 2 ? "red" : "black"}}>{t(`kyc.statuses.${state.user.verified}`)}</span> : ""}</Typography>
                                    <Typography>{t('kyc.uploadedAttachment')}:<br/>
                                    { _.size(state.upload_nric) ?
                                        <>
                                        <a href={state.upload_nric.file_name} target="_blank" className={classes.attachments}>{t('kyc.nric')}</a>
                                        <a href={state.upload_address.file_name} target="_blank" className={classes.attachments}>{t('kyc.address')}</a>
                                        <a href={state.upload_selfie.file_name} target="_blank" className={classes.attachments}>{t('kyc.selfie')}</a>
                                        </>

                                        : null
                                    }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" error={inputErrors.status?true:false}>
                                        <FormLabel component="legend">{t('kyc.action')}</FormLabel>
                                        <RadioGroup row aria-label="status" name="status" value={state.status} onChange={(event) => setState({ ...state, status:event.target.value })}>
                                            <FormControlLabel value="2" control={<Radio />} label={t('kyc.reject')} />
                                            <FormControlLabel value="3" control={<Radio />} label={t('kyc.approve')} />
                                        </RadioGroup>
                                        <FormHelperText>{inputErrors.status?inputErrors.status:''}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="remark"
                                        label={t('kyc.remark')}
                                        variant="outlined"
                                        fullWidth
                                        value={(state && state.remark ? state.remark : "")}
                                        onChange={({ target }) => setState({ ...state, remark: target.value })}
                                        helperText={inputErrors.remark?inputErrors.remark:''}
                                        error={inputErrors.remark?true:false}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => {setOptionIndex("");}}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={saveOptions}>{t('button.submit')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
    attachments: {
        background: '#868686',
        marginRight: 5,
        padding: '2px 15px 2px 15px',
        textDecoration: "none",
        borderRadius: 25,
        color: "white"
    }
}));