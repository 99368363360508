import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';
import MembershipProductPoints from './MembershipProductPoints';
import MembershipMultiPricings from './MembershipMultiPricings';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Paper, LinearProgress, Tooltip, Box, TextField, Checkbox, FormControlLabel, Toolbar, Icon, Input, InputAdornment, FormControl } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClearIcon from '@material-ui/icons/Clear';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

export default function Memberships() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [inputErrors, setInputErrors] = useState([]);
    
    const [rankDisType] = useState(['incremental', 'decremental']);
    const [rankDisAmountType] = useState(['value', 'percent']);
    const [rankDiscount, setRankDiscount] = useState([]);
    const [rankVisible, setRankVisible] = useState([]);
    const [rankList, setRankList] = useState([]);
    const [duplicatedProductlisting, setDuplicatedProductListing] = useState([]);
    const [duplicatedProductDialog, setDuplicatedProductDialog] = useState({
        open: false,
        productId: '',
        sellPrice: ''
    });
    const [pointList, setPointList] = useState([]);

    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/memberships${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order };
        getUrl('admin/memberships', params).then(result => {
            setTableLoading(false);
            if(isMountedRef.current) {
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            setTableLoading(false);
            if(isMountedRef.current) {
                setListing([]);
                setTotalCount(0)
            }
        });
        getUrl(`admin/point_list`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setPointList(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, page, rowsPerPage, querySearch, orderBy, order]);

    const handleDuplicatedProductOpen = (data) => {
        // get duplicated product
        getUrl(`admin/memberships/${data.id}`).then(response => {
            if(isMountedRef.current) {
                if(response.status === 1){
                    setDuplicatedProductDialog({
                        open: true,
                        productId: data.id,
                        sellPrice: data.sellPrice
                    });
                    setDuplicatedProductListing(response.data);
                    setRankDiscount(response.rank_discount);
                    setRankVisible(response.rank_visible);
                    setRankList(response.rank_list);
                }else{
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    const handleDuplicatedProductClose = (data) => {
        setDuplicatedProductDialog({
            open: false,
            productId: '',
            sellPrice: ''
        });
        setDuplicatedProductListing([]);
        setRankDiscount([]);
        setRankVisible([]);
        setRankList([]);
    }

    const duplicateProduct = (data) => {
        getUrl(`admin/products/${data.id}/duplicate`).then(response => {
            if(isMountedRef.current) {
                if(response.status === 1){
                    if(response.new_id){
                        let msg = t('snackbarMsg.duplicateSuccess') + ' ID: ' + response.new_id ;
                        addAlert('', msg, 'success', '');
                    }else{
                        let msg = t('snackbarMsg.duplicateSuccess');
                        addAlert('', msg, 'success', '');
                    }
                }else{
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'error', '');
                    }else{
                        let msg = t('snackbarMsg.duplicateError');
                        addAlert('', msg, 'error', '');
                    }
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const updateRankDiscount = () => {
        setInputErrors('');
        let updateData = {
            rank_discount: rankDiscount,
            rank_visible: rankVisible
        }
        postUrl(`admin/memberships/${duplicatedProductDialog.productId}`, updateData).then(response => {
            if(isMountedRef.current) {
                if(response.status === 1){
                    handleDuplicatedProductClose();
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'success', '');
                    }else{
                        let msg = t('snackbarMsg.updateSuccess');
                        addAlert('', msg, 'success', '');
                    }
                }else{
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'error', '');
                    }else{
                        let msg = t('snackbarMsg.updateError');
                        addAlert('', msg, 'error', '');
                    }
                    if(response.errors){
                        setInputErrors(response.errors);
                    }
                }
            }
        }).catch((error) => {
            handleDuplicatedProductClose();
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.memberships')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{ t('membership.action') }</TableCell>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('membership.id')} cellId={'id'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('membership.productTitle')} cellId={'title'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('membership.sku')} cellId={'sku'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('membership.sellPrice')} cellId={'sell_price'} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {                                    
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <Tooltip title={t('membership.duplicate')}>
                                                    <IconButton variant="outlined" color="primary" onClick={() => duplicateProduct({id: row.id})}>
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t('membership.editRankDisVis')}>
                                                    <IconButton variant="outlined" color="primary" onClick={() => handleDuplicatedProductOpen({id: row.id, sellPrice: row.sell_price})}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{ row.id }</TableCell>
                                            <TableCell>{ row.title_display }</TableCell>
                                            <TableCell>{ row.sku }</TableCell>
                                            <TableCell>{ row.sell_price }</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={5} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
            <Dialog open={ duplicatedProductDialog.open } onClose={ handleDuplicatedProductClose } fullWidth maxWidth={duplicatedProductlisting.length === 0 ? 'sm' : 'lg'} >
                <DialogTitle onClose={ handleDuplicatedProductClose }>
                    { t('membership.editRankDisVis') }
                </DialogTitle>
                <DialogContent dividers>
                    {
                        duplicatedProductlisting.length === 0
                        ?
                        <>
                        {
                            tableLoading
                            ?
                            <LinearProgress />
                            :
                            <Box style={{ textAlign: "center", margin: 30 }}>
                                {t('table.noRecord')}
                            </Box>
                        }
                        </>
                        :
                        <>
                        <Box style={{ marginLeft: 20 }}>
                            <Typography variant="h6">
                                {t('membership.baseSellPrice') + ' : ' + duplicatedProductDialog.sellPrice}
                            </Typography>
                        </Box>
                        <TableContainer style={theme.p20}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{ t('membership.id') }</TableCell>
                                        {/* <TableCell>{ t('membership.productTitle') }</TableCell>
                                        <TableCell>{ t('membership.sku') }</TableCell> */}
                                        <TableCell>{ t('membership.sellPrice') }</TableCell>
                                        <TableCell>{ t('membership.rankDiscount') }</TableCell>
                                        <TableCell>
                                            <Typography variant="body2" style={{fontWeight: "500"}}>{ t('membership.rankVisibility') }</Typography>
                                            <Typography variant="caption" style={{color: "#808080", fontStyle: "italic"}}>{t('membership.emptyIsPublic')}</Typography>
                                        </TableCell>
                                        <TableCell>{ t('membership.setting') }</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {duplicatedProductlisting.map((row, key) => {
                                        return(
                                            <TableRow key={row.id}>
                                                <TableCell>{ row.id }</TableCell>
                                                {/* <TableCell>{ row.title_display }</TableCell>
                                                <TableCell>{ row.sku }</TableCell> */}
                                                <TableCell>
                                                    <Box>
                                                    { row.sell_price }
                                                    </Box>
                                                    {
                                                        rankDiscount[row.id] && rankDiscount[row.id].flag
                                                        ?
                                                        <Box style={{fontStyle: "italic", color: "gray", fontSize: "0.8rem"}}>
                                                            *** {t('membership.flagOn')}
                                                        </Box>
                                                        :
                                                        null
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <TextField 
                                                                label={t('membership.rankDisVis.type')} 
                                                                variant="outlined" 
                                                                fullWidth 
                                                                select
                                                                value={rankDiscount[row.id]?rankDiscount[row.id].type:''}
                                                                helperText={inputErrors['rank_discount.'+row.id+'.type']?inputErrors['rank_discount.'+row.id+'.type']:''}
                                                                error={inputErrors['rank_discount.'+row.id+'.type']?true:false}
                                                                onChange={(event) => setRankDiscount({ ...rankDiscount, [row.id]: {...rankDiscount[row.id], type: event.target.value}})}
                                                                SelectProps={{
                                                                    native: true,
                                                                }}
                                                                InputLabelProps={{ shrink: true }}
                                                            >
                                                            <option key='' value='' disabled>
                                                                {t('address.pleaseSelect')}{t('membership.rankDisVis.type')}
                                                            </option>
                                                            {rankDisType.map((value, key) => (
                                                                <option key={key} value={value}>
                                                                    {t('membership.rankDisVis.'+value)}
                                                                </option>
                                                            ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField 
                                                                label={t('membership.rankDisVis.amountType')} 
                                                                variant="outlined" 
                                                                fullWidth 
                                                                select
                                                                value={rankDiscount[row.id]?rankDiscount[row.id].amount_type:''}
                                                                helperText={inputErrors['rank_discount.'+row.id+'.amount_type']?inputErrors['rank_discount.'+row.id+'.amount_type']:''}
                                                                error={inputErrors['rank_discount.'+row.id+'.amount_type']?true:false}
                                                                onChange={(event) => setRankDiscount({ ...rankDiscount, [row.id]: {...rankDiscount[row.id], amount_type: event.target.value}})}
                                                                SelectProps={{
                                                                    native: true,
                                                                }}
                                                                InputLabelProps={{ shrink: true }}
                                                            >
                                                            <option key='' value='' disabled>
                                                                {t('address.pleaseSelect')}{t('membership.rankDisVis.amountType')}
                                                            </option>
                                                            {rankDisAmountType.map((value, key) => (
                                                                <option key={key} value={value}>
                                                                    {t('membership.rankDisVis.'+value)}
                                                                </option>
                                                            ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField 
                                                                label={((rankDiscount[row.id]?rankDiscount[row.id].amount_type:'')==='percent') ? (t('membership.rankDisVis.amount') + ' (0-100%)') : t('membership.rankDisVis.amount')} 
                                                                variant="outlined" 
                                                                fullWidth 
                                                                value={rankDiscount[row.id]?rankDiscount[row.id].amount:0}
                                                                helperText={inputErrors['rank_discount.'+row.id+'.amount']?inputErrors['rank_discount.'+row.id+'.amount']:''}
                                                                error={inputErrors['rank_discount.'+row.id+'.amount']?true:false}
                                                                onChange={(event) => setRankDiscount({ ...rankDiscount, [row.id]: {...rankDiscount[row.id], amount: event.target.value}})}
                                                                InputLabelProps={{ shrink: true }}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container spacing={3} direction="column">
                                                        {rankList.map((rankL) => {
                                                            return(
                                                                <Grid item key={rankL.id} style={{padding: 0}}>
                                                                    <FormControlLabel
                                                                        key={rankL.id}
                                                                        control={<Checkbox key={rankL.id} name={rankL.name} checked={rankVisible[row.id][rankL.id]}  onChange={(event) => setRankVisible({ ...rankVisible, [row.id]: { ...rankVisible[row.id], [rankL.id]: event.target.checked}})} />}
                                                                        label={rankL.source + ' ' + rankL.name_display}
                                                                    />
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    { _.size(pointList) > 0 ? <MembershipProductPoints id={row.id} pointList={pointList} /> : null }
                                                    <MembershipMultiPricings id={row.id} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </DialogContent>
                {
                    duplicatedProductlisting.length === 0
                    ?
                    null
                    :
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={ () => updateRankDiscount()} style={{ margin: "5px 22px" }}>
                            <Typography variant="overline">{ t('button.save') }</Typography>
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));