import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import { getUrl, putUrl } from '../helper/ApiAction';
import _ from 'lodash';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Switch, MenuItem, Box, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function BonusEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useParams();
    const isMountedRef = useRef(null);

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        bonusType: '',
        name: '',
        rankPercent: [],
        applyForAll: true,
        wallets: [],
        level: '',
        distributeType: 'daily',
        limit: 0,
        description: '',
        extraSetting: '',
        status: true,
    });
    const distributeType = [{code: "real", label: t('bonus.distribute.real')}, {code: "daily", label: t('bonus.distribute.daily')}, {code: "monthly", label: t('bonus.distribute.monthly')}];
    const [wallets, setWallets] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [dialog, setDialog] = useState({
        open: false,
        type: '',
        data: [],
        label: '',
        id: '',
        percent: '',
        key: '',
        percentNote: ''
    });

    const handleDialogOpen = (type, key, id, percent) => {
        let data = [];
        let label = '';
        let percentNote = '';
        if(type === 'ranks'){
            data = ranks;
            label = t('bonus.rank');
            percentNote = t('bonus.percentNote');
        }else if(type === 'wallets'){
            data = wallets;
            label = t('bonus.wallets');
        }
        setDialog({
            open: true,
            type: type,
            data: data,
            label: label,
            id: id,
            percent: percent,
            key: key,
            percentNote: percentNote
        });
    }
    const handleDialogClose = () => {
        setDialog({
            open: false,
            type: '',
            data: [],
            label: '',
            id: '',
            percent: '',
            key: '',
            percentNote: ''
        });
    }

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`admin/bonuses/${id}`).then(response => {
            if(isMountedRef.current) {
                setLoading(false);
                if(response.status === 1){
                    setState({
                        bonusType: response.data.bonus_type?response.data.bonus_type:'',
                        name: response.data.name?response.data.name:'',
                        rankPercent: response.data.rank_array?response.data.rank_array:[],
                        applyForAll: response.data.apply_for_all?true:false,
                        wallets: response.data.wallet_array?response.data.wallet_array:[],
                        level: response.data.level?response.data.level:0,
                        distributeType: response.data.distribute_type?response.data.distribute_type:'daily',
                        limit: response.data.limit?response.data.limit:0,
                        description: response.data.description?response.data.description:'',
                        extraSetting: response.data.extra_setting?response.data.extra_setting:'',
                        status: response.data.status?true:false,
                    });
                }else{
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'error', '');
                    }
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(t('snackbarMsg.getError', error+"\n"+t('error.contactSupport')));
        });

        getUrl('admin/wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        getUrl('admin/rank_list').then(response => {
            if (isMountedRef.current && response.status === 1) {
                let tempRank = response.data;
                tempRank.push({id: 0, name: t('rank.noRank')});
                setRanks(tempRank);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert])

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            bonus_type: state.bonusType,
            name: state.name,
            rank_percent: state.rankPercent,
            apply_for_all: state.applyForAll,
            wallets: state.wallets,
            level: state.level,
            distribute_type: state.distributeType,
            limit: state.limit,
            description: state.description,
            extra_setting: state.extraSetting,
            status: state.status?1:0
        }
        putUrl(`admin/bonuses/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status) {
                addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    const dialog_add = () => {
        if(isNaN(dialog.id) || dialog.percent <= 0){
            addAlert('', t('bonus.fillAllColumn'), 'error', '');
        }else{
            let tempData = [];
            let repeat = false;
            if(dialog.type === 'ranks'){
                tempData = state.rankPercent;
            }else if(dialog.type === 'wallets'){
                tempData = state.wallets;
            }
            if(isNaN(dialog.key)){ // add
                _.map(tempData, (data, key) => {
                    if(parseInt(data.id) === parseInt(dialog.id)){
                        repeat = true;
                    }
                });
                if(repeat){
                    addAlert('', t('bonus.duplicatedID'), 'error', '');
                }else{
                    tempData = tempData.concat({id: dialog.id, percent: dialog.percent});
                    handleDialogClose();
                    if(dialog.type === 'ranks'){
                        setState({...state, rankPercent: tempData});
                    }else if(dialog.type === 'wallets'){
                        setState({...state, wallets: tempData});
                    }
                }
            }else{ // edit
                _.map(tempData, (data, key) => {
                    if(dialog.key !== key){
                        if(parseInt(data.id) === parseInt(dialog.id)){
                            repeat = true;
                        }
                    }
                });
                if(repeat){
                    addAlert('', t('bonus.duplicatedID'), 'error', '');
                }else{
                    _.map(tempData, (data, key) => {
                        if(dialog.key === key){
                            tempData[key]['id'] = dialog.id;
                            tempData[key]['percent'] = dialog.percent;
                        }
                    });
                    handleDialogClose();
                    if(dialog.type === 'ranks'){
                        setState({...state, rankPercent: tempData});
                    }else if(dialog.type === 'wallets'){
                        setState({...state, wallets: tempData});
                    }
                }
            }
        }
    }

    const dialog_delete = (type, id) => {
        if(type === 'ranks'){
            let tempData = state.rankPercent;
            console.log(tempData);
            tempData = tempData.filter(item => parseInt(item.id) !== parseInt(id));
            setState({...state, rankPercent: tempData});
        }else if(type === 'wallets'){
            let tempData = state.wallets;
            tempData = tempData.filter(item => parseInt(item.id) !== parseInt(id));
            setState({...state, wallets: tempData});
        }
    }

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('bonus.editBonus')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/bonuses`} component={RouterLink}>{t('bonus.bonuses')}</Link>
                        <Typography style={{ color: 'black' }}>{t('bonus.editBonus')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="bonus_type" 
                                label={t('bonus.bonusType')}
                                variant="outlined" 
                                fullWidth 
                                value={state.bonusType}
                                helperText={inputErrors.bonus_type ? inputErrors.bonus_type :''}
                                error={inputErrors.bonus_type ? true : false}
                                onChange={({ target }) => setState({ ...state, bonusType: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label={t('bonus.name')}
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name ? inputErrors.name :''}
                                error={inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <TextField 
                                id="rank_percent" 
                                label={t('bonus.rankPercent')}
                                variant="outlined" 
                                fullWidth 
                                value={state.rankPercent}
                                helperText={inputErrors.rank_percent ? inputErrors.rank_percent :''}
                                error={inputErrors.rank_percent ? true : false}
                                onChange={({ target }) => setState({ ...state, rankPercent: target.value }) }
                            /> */}
                            <Typography>{t('bonus.rankPercent')}</Typography>
                            {
                                _.map(state.rankPercent, (data, key) => {
                                    let rank_name = '';
                                    if(ranks){
                                        _.map(ranks, rank => {
                                            if(rank.id === parseInt(data.id)){
                                                rank_name = rank.name;
                                            }
                                        })
                                        rank_name = _.split(rank_name, '|');
                                    }
                                    return(
                                        <Box key={key}>
                                            <IconButton color="primary" onClick={() => handleDialogOpen('ranks', key, data.id, data.percent)}><EditIcon /></IconButton>
                                            <IconButton color="primary" onClick={() => dialog_delete('ranks', data.id)}><DeleteForeverIcon /></IconButton>
                                            {( i18n.language === 'cn' ? (rank_name[1] ? rank_name[1] : rank_name[0]) : rank_name[0] ) + ': ' + data.percent + '%'}
                                        </Box>
                                    )
                                })
                            }
                            <Box>
                                <IconButton color="primary" onClick={() => handleDialogOpen('ranks', 'none', 'select', '')}><AddIcon /></IconButton>
                            </Box>
                            {
                                inputErrors.rank_percent ?
                                <Typography variant="body2" style={{color: "red"}}>{ inputErrors.rank_percent }</Typography>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <TextField 
                                id="wallets" 
                                label={t('bonus.wallets')}
                                variant="outlined" 
                                fullWidth 
                                value={state.wallets}
                                helperText={inputErrors.wallets ? inputErrors.wallets :''}
                                error={inputErrors.wallets ? true : false}
                                onChange={({ target }) => setState({ ...state, wallets: target.value }) }
                            /> */}
                            <Typography>{t('bonus.wallets')}</Typography>
                            {
                                _.map(state.wallets, (data, key) => {
                                    let wallet_name = '';
                                    if(wallets){
                                        _.map(wallets, wallet => {
                                            if(wallet.id === parseInt(data.id)){
                                                wallet_name = wallet.name;
                                            }
                                        })
                                        wallet_name = _.split(wallet_name, '|');
                                    }
                                    return(
                                        <Box key={key}>
                                            <IconButton color="primary" onClick={() => handleDialogOpen('wallets', key, data.id, data.percent)}><EditIcon /></IconButton>
                                            <IconButton color="primary" onClick={() => dialog_delete('wallets', data.id)}><DeleteForeverIcon /></IconButton>
                                            {( i18n.language === 'cn' ? (wallet_name[1] ? wallet_name[1] : wallet_name[0]) : wallet_name[0] ) + ': ' + data.percent + '%'}
                                        </Box>
                                    )
                                })
                            }
                            <Box>
                                <IconButton color="primary" onClick={() => handleDialogOpen('wallets', 'none', 'select', '')}><AddIcon /></IconButton>
                            </Box>
                            {
                                inputErrors.wallets ?
                                <Typography variant="body2" style={{color: "red"}}>{ inputErrors.wallets }</Typography>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.applyForAll}
                                        onChange={({ target }) => setState({ ...state, applyForAll: target.checked }) }
                                        color="primary"
                                    />
                                }
                                label={t('bonus.applyForAll')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="level" 
                                label={t('bonus.level')}
                                variant="outlined" 
                                fullWidth 
                                value={state.level}
                                helperText={inputErrors.level ? inputErrors.level :''}
                                error={inputErrors.level ? true : false}
                                onChange={({ target }) => setState({ ...state, level: target.value }) }
                            />
                            <Typography variant="body2" style={{color: "#808080"}}>{ t('bonus.levelNote') }</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label={t('bonus.distributeType')}
                                value={state.distributeType}
                                onChange={({ target }) => setState({ ...state, distributeType: target.value }) }
                                variant="outlined"
                                fullWidth
                                helperText={inputErrors.distribute_type ? inputErrors.distribute_type :''}
                                error={inputErrors.distribute_type ? true : false}
                            >
                                {distributeType.map((option) => {
                                    return(
                                        <MenuItem key={option.code} value={option.code}>
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="limit" 
                                label={t('bonus.limit')}
                                variant="outlined" 
                                fullWidth 
                                value={state.limit}
                                helperText={inputErrors.limit ? inputErrors.limit :''}
                                error={inputErrors.limit ? true : false}
                                onChange={({ target }) => setState({ ...state, limit: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="description" 
                                label={t('bonus.description')}
                                variant="outlined" 
                                fullWidth 
                                value={state.description}
                                helperText={inputErrors.description ? inputErrors.description :''}
                                error={inputErrors.description ? true : false}
                                onChange={({ target }) => setState({ ...state, description: target.value }) }
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="extraSetting" 
                                label={t('bonus.extraSetting')}
                                variant="outlined" 
                                fullWidth 
                                value={state.extraSetting}
                                helperText={inputErrors.extra_setting ? inputErrors.extra_setting :''}
                                error={inputErrors.extra_setting ? true : false}
                                onChange={({ target }) => setState({ ...state, extraSetting: target.value }) }
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.status ? true : false}
                                        onChange={({ target }) => setState({ ...state, status: target.checked })}
                                        name="status"
                                        color="primary"
                                    />
                                }
                                labelPlacement="start"
                                label={t('bonus.status.title')}
                                helperText={inputErrors.status ? inputErrors.status :''}
                                error={inputErrors.status ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit}>{t('button.update')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Dialog onClose={handleDialogClose} open={dialog.open} fullWidth>
                <DialogTitle onClose={handleDialogClose}>
                    {dialog.label}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <TextField 
                                label={dialog.label} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={dialog.id}
                                onChange={({ target }) => setDialog({ ...dialog, id: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value='select' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {dialog.data.map((value, key) => (
                                <option key={key} value={value.id}>
                                    { value.name }
                                </option>
                            ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField 
                                label={t('bonus.percent')} 
                                variant="outlined" 
                                fullWidth 
                                value={dialog.percent}
                                onChange={({ target }) => setDialog({ ...dialog, percent: target.value }) }
                            />
                            <Typography variant="body2" style={{color: "#808080"}}>{dialog.percentNote}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" onClick={dialog_add} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));