import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
// import _ from 'lodash';

export default function RankEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useParams();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        source: '',
        code: '',
        name: '',
        status: '',
        extrasetting: '',
    });
    const isMountedRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`admin/ranks/${id}`).then(response => {
            if(isMountedRef.current) {
                setLoading(false);
                if(response.status === 1){
                    setState({
                        source: response.data.source,
                        code: response.data.code,
                        name: response.data.name,
                        status: response.data.status,
                        extrasetting: response.data.extra_setting,
                    });
                }else{
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'error', '');
                    }
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(t('snackbarMsg.getError', error+"\n"+t('error.contactSupport')));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert])

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            source: state.source,
            code: state.code,
            name: state.name,
            status: state.status?1:0,
            extrasetting: state.extrasetting,
        }
        putUrl(`admin/ranks/${id}`, updateData).then(response => {
            console.log("response", response);
            setLoading(false);
            if(response.status) {
                addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('rank.editRank')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/ranks`} component={RouterLink}>{t('rank.ranks')}</Link>
                        <Typography style={{ color: 'black' }}>{t('rank.editRank')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="source" 
                                label={t('rank.source')}
                                variant="outlined" 
                                fullWidth 
                                value={state.source}
                                helperText={inputErrors.source ? inputErrors.source :''}
                                error={inputErrors.source ? true : false}
                                onChange={({ target }) => setState({ ...state, source: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="code" 
                                label={t('rank.code')}
                                variant="outlined" 
                                fullWidth 
                                value={state.code}
                                helperText={inputErrors.code ? inputErrors.code :''}
                                error={inputErrors.code ? true : false}
                                onChange={({ target }) => setState({ ...state, code: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label={t('rank.name')}
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name ? inputErrors.name :''}
                                error={inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="extrasetting" 
                                label={t('rank.extrasetting')}
                                variant="outlined" 
                                fullWidth 
                                value={state.extrasetting}
                                helperText={inputErrors.extrasetting ? inputErrors.extrasetting :''}
                                error={inputErrors.extrasetting ? true : false}
                                onChange={({ target }) => setState({ ...state, extrasetting: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                    checked={state.status?true:false}
                                    onChange={({ target }) => setState({ ...state, status: target.checked })}
                                    name="status"
                                    color="primary"
                                    />
                                }
                                label={t('rank.status')}
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit}>{t('button.update')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));