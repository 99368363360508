import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Divider, Button, Paper, Dialog, DialogTitle, DialogContent, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import _ from 'lodash';

import { getUrl, postUrl } from '../helper/ApiAction'; 
import useNotificationLoading from '../helper/useNotificationLoading';

export default function WalletBalance() {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const classes = useStyles();

    const isMountedRef = useRef(null);
    const { permissions } = useSelector(state => state.general);
    const [inputErrors, setInputErrors] = useState([]);
    const [walletBalance, setWalletBalance] = useState([]);
    const [walletList, setWalletList] = useState([]);
    const [walletHistory, setWalletHistory] = useState([]);
    const [walletTransfer, setWalletTransfer] = useState({
        source: '',
        email: '',
        amount: '',
        walletId: '',
        toAmount: '',
        remark: ''
    });
    const [walletDialog, setWalletDialog] = useState({
        open: false,
        action: '',
        id: 0,
        name: '',
        balance: 0,
        decimal: '',
    });

    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/wallets/balance`).then(response => {
            if(isMountedRef.current){
                if(response.status === 1){
                    setWalletBalance(response.data);
                }else{
                    let msg = response.data;
                    addAlert('', msg, 'error');
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error');
        });
        getUrl(`admin/wallets`, {'company_transfer_member': true}).then(response => {
            if(isMountedRef.current){
                if(response.status === 1){
                    setWalletList(response.data);
                }else{
                    let msg = response.data;
                    addAlert('', msg, 'error');
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        if(walletTransfer.walletId > 0 && walletTransfer.amount > 0){
            let fromCashRate = 1;
            let toCashRate = 1;
            let toDecimal = 0;
            _.map(walletList, (wallet) => {
                if(parseInt(wallet.id) === parseInt(walletTransfer.walletId)){
                    if(wallet.cash_rate > 0){
                        toCashRate = wallet.cash_rate;
                    }
                    toDecimal = wallet.decimal;
                }
                if(parseInt(wallet.id) === parseInt(walletDialog.id)){
                    if(wallet.cash_rate > 0){
                        fromCashRate = wallet.cash_rate;
                    }
                }
            })
            let toAmount = parseFloat((walletTransfer.amount*fromCashRate)/toCashRate).toFixed(toDecimal);
            setWalletTransfer({...walletTransfer, toAmount: toAmount});
        }else{
            setWalletTransfer({...walletTransfer, toAmount: 0});
        }
        return;
        // eslint-disable-next-line
    }, [walletTransfer.amount, walletTransfer.walletId]);

    const handleWalletDialogOpen = (wallet) => {
        setWalletDialog({
            open: true,
            action: wallet.action,
            id: wallet.id,
            name: wallet.name,
            balance: wallet.balance,
            decimal: wallet.decimal
        });
        if(wallet.action === 'history'){
            getUrl(`admin/wallets/${wallet.id}/history?all=1`).then(response => {
                if(response.status === 1){
                   setWalletHistory(response.data);
                }else{
                    let msg = response.data;
                    addAlert('', msg, 'error');
                }
            }).catch((error) => {
                let msg = error+"\n"+t('error.contactSupport');
                addAlert('', msg, 'error');
            });
        }
    }
    const handleWalletDialogClose = () => {
        setWalletDialog({
            open: false,
            action: '',
            id: 0,
            name: '',
            balance: 0,
            decimal: ''
        });
        setInputErrors([]);
        setWalletHistory([]);
        setWalletTransfer({
            source: '',
            email: '',
            amount: '',
            walletId: '',
            toAmount: '',
            remark: ''
        });
    }

    const walletDialogTitle = () => {
        let name = _.split(walletDialog.name, '|');
        switch(walletDialog.action){
            case('history'): return <DialogTitle>
                                        {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ' ' + t('wallet.history')}
                                    </DialogTitle>
            case('transfer'): return <DialogTitle>
                                        {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ' ' + t('wallet.transfer')}
                                    </DialogTitle>
            default: return null;
        }
    }
    const walletDialogContent = () => {
        let name = _.split(walletDialog.name, '|');
        name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
        switch(walletDialog.action){
            case('history'): return <DialogContent>
                                        <MaterialTable
                                            title=""
                                            columns={[
                                                { title: t('wallet.type'), field: 'type', render: rowData => rowData.type_display[i18n.language] },
                                                { title: t('wallet.amount'), field: 'amount', render: rowData => <NumberFormat value={rowData.factor*rowData.amount} decimalScale={walletDialog.decimal} fixedDecimalScale={walletDialog.decimal} displayType={'text'} thousandSeparator={true} style={{color: (rowData.factor>0?"green":"red")}} /> },
                                                { title: t('wallet.remark'), field: 'remark', render: rowData => rowData.remark?rowData.remark:'-' },
                                                { title: t('wallet.transactionTime'), field: 'created_at' },
                                            ]}
                                            data={walletHistory}
                                            options={{
                                                pageSize: 10,
                                            }}
                                            style={{ padding: 20 }}
                                            localization={{
                                                pagination: {
                                                    labelDisplayedRows: t('table.labelDisplayedRows'),
                                                    labelRowsSelect: t('table.rows'),
                                                    firstTooltip: t('table.firstPage'),
                                                    previousTooltip: t('table.previousPage'),
                                                    nextTooltip: t('table.nextPage'),
                                                    lastTooltip: t('table.lastPage'),
                                                },
                                                toolbar: {
                                                    searchTooltip: t('table.search'),
                                                    searchPlaceholder: t('table.search'),
                                                },
                                                body: {
                                                    emptyDataSourceMessage: t('table.noRecord'),
                                                }
                                            }}
                                        />
                                    </DialogContent>
            case('transfer'): return <DialogContent>
                                    <Grid container spacing={1} style={{marginBottom: 20}}>
                                        {/* <Grid item xs={12} md={6}>
                                            <TextField 
                                                id="source" 
                                                label={t('wallet.source')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={walletTransfer.source}
                                                helperText={inputErrors.source ? inputErrors.source :''}
                                                error={inputErrors.source ? true : false}
                                                onChange={({ target }) => setWalletTransfer({ ...walletTransfer, source: target.value }) }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                id="login_method" 
                                                label={t('profile.email')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={walletTransfer.email}
                                                helperText={inputErrors.login_method ? inputErrors.login_method :''}
                                                error={inputErrors.login_method ? true : false}
                                                onChange={({ target }) => setWalletTransfer({ ...walletTransfer, email: target.value }) }
                                            />
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Typography>{t('wallet.transferFrom')}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                label={t('wallet.walletType')}
                                                variant="outlined" 
                                                fullWidth 
                                                disabled
                                                value={name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                id="amount" 
                                                label={t('wallet.amount')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={walletTransfer.amount}
                                                helperText={inputErrors.amount ? inputErrors.amount :''}
                                                error={inputErrors.amount ? true : false}
                                                onChange={({ target }) => setWalletTransfer({ ...walletTransfer, amount: target.value }) }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('wallet.transferTo')}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                id="wallet_id" 
                                                label={t('wallet.walletType')}
                                                variant="outlined" 
                                                fullWidth 
                                                select
                                                value={walletTransfer.walletId}
                                                helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                                                error={inputErrors.wallet_id ? true : false}
                                                onChange={({ target }) => setWalletTransfer({ ...walletTransfer, walletId: target.value }) }
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            <option key='' value='' disabled>
                                                {t('general.pleaseSelect')}
                                            </option>
                                            {
                                                Object(_.find(walletList, {id: (walletDialog.id)})).company_transfer_to_member_wallet.map((value, key) => {
                                                    return(
                                                        <option key={key} value={value.id}>
                                                            { value.code }
                                                        </option>
                                                    )
                                                })
                                            }
                                            {/* {walletList.map((value, key) => {
                                                let name = _.split(value.name, '|');
                                                return(
                                                    <option key={key} value={value.id}>
                                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                                    </option>
                                                )
                                            })} */}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                label={t('wallet.amount')}
                                                variant="outlined" 
                                                fullWidth 
                                                disabled
                                                value={walletTransfer.toAmount}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                id="remark" 
                                                label={t('wallet.remark')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={walletTransfer.remark}
                                                helperText={inputErrors.remark ? inputErrors.remark :''}
                                                error={inputErrors.remark ? true : false}
                                                onChange={({ target }) => setWalletTransfer({ ...walletTransfer, remark: target.value }) }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button variant="contained" color="primary" onClick={transfer_submit}>{t('button.confirm')}</Button>
                                        </Grid>
                                    </Grid>
                                    </DialogContent>
            default: return null;
        }
    }

    const transfer_submit = () => {
        setLoading(true);
        setInputErrors([]);
        let updateData = {
            // source: walletTransfer.source,
            // email: walletTransfer.email,
            amount: walletTransfer.amount,
            to_wallet_id: walletTransfer.walletId,
            remark: walletTransfer.remark
        };
        postUrl(`admin/wallets/${walletDialog.id}/transfer_member`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1){
                let msg = response.data;
                addAlert('', msg, 'success');
                handleWalletDialogClose();
                getUrl(`admin/wallets/balance`).then(response => {
                    if(isMountedRef.current){
                        if(response.status === 1){
                            setWalletBalance(response.data);
                        }else{
                            let msg = response.data;
                            addAlert('', msg, 'error');
                        }
                    }
                }).catch((error) => {
                    let msg = error+"\n"+t('error.contactSupport');
                    addAlert('', msg, 'error');
                });
            }else{
                let msg = response.data;
                addAlert('', msg, 'error');
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error');
        });
    }

    return (
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('wallet.walletDetails')}</Typography>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        {
                            walletBalance
                            ?
                            _.map(walletBalance, (wallet, key) => {
                                let name = _.split(wallet.name, '|');
                                return(
                                    <Grid item xs={12} sm={5} md={3} key={key} className={ styles.walletBox }>
                                        <Grid container style={{ padding: 5 }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': '}
                                                    <NumberFormat value={wallet.balance} decimalScale={wallet.decimal} fixedDecimalScale={wallet.decimal} displayType={'text'} thousandSeparator={true} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button onClick={() => handleWalletDialogOpen({id: wallet.id, action: 'history', name: wallet.name, balance: wallet.balance, decimal: wallet.decimal})}>
                                                    {t('wallet.history')} 
                                                </Button>
                                                {
                                                    (_.includes(permissions, "wallet_transfer") && Object(_.find(walletList, {code: (wallet.code)})).company_allow_transfer) ?
                                                    <>
                                                        |
                                                        <Button onClick={() => handleWalletDialogOpen({id: wallet.id, action: 'transfer', name: wallet.name, balance: wallet.balance, decimal: wallet.decimal})}>
                                                            {t('wallet.transfer')} 
                                                        </Button>
                                                    </>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            :
                            t('wallet.noWallet')
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Dialog open={ walletDialog.open } onClose={ handleWalletDialogClose } >
                {walletDialogTitle()}
                {walletDialogContent()}
            </Dialog>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    backgroundBox: {
        backgroundColor: "#fff",
        boxShadow: '0 0 6px #bbbbbb', 
        borderRadius: 15,
        padding: "10px",
        margin: "10px",
        width: "95%"
    },
    walletBox: {
        border: "1px solid gray",
        borderRadius: 10,
        textAlign: "center",
        margin: "10px",
        boxShadow: '0 0 2px #bbbbbb'
    }
}));