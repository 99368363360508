import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '../helper/useNotificationLoading';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Grid, Tooltip } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import ViewListIcon from '@material-ui/icons/ViewList';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function MembershipProductPoint({id, pointList}) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    
    const [productPoint, setProductPoint] = useState([]);
    const [productPointDialog, setProductPointDialog] = useState({
        open: false
    });
    const [pointDialog, setPointDialog] = useState({
        open: false,
        key: 'none',
        id: 0,
        pointId: 0,
        pointValue: 0
    });
    
    useEffect(() => {
        return;
    }, []);

    const handleProductPointOpen = (data) => {
        setProductPointDialog({
            open: true,
        });
        getUrl(`admin/memberships/${id}/points`).then(result => {
            if(result.status === 1){
                let tempProductPoint = Object.values(result.data);
                _.map((result.data), (data, key) => {
                // Object.values(result.data).map((data, key) => {
                    let tempPointData = [];
                    _.map((data.points), (pointData, pointKey) => {
                        let visible = false;
                        _.map((pointList), (point) => {
                            if(parseInt(point.id) === pointData.point_id){
                                visible = true;
                            }
                        });
                        if(visible){
                            tempPointData[pointKey] = {id: pointData.id, point_id: pointData.point_id, point_source: pointData.point_source, point_name: pointData.point_name, value: pointData.value, deleted: false}
                        }
                    });
                    let filteredArray = tempPointData.filter(item => item !== null);
                    tempProductPoint[key] = data;
                    tempProductPoint[key].points = filteredArray;
                });
                setProductPoint(tempProductPoint);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };
    const handleProductPointClose = () => {
        setProductPointDialog({
            open: false,
        });
        setProductPoint([]);
    };
   
    const handlePointDialogOpen = (data) => {
        setPointDialog({
            open: true,
            ppkey: data.ppkey,
            pid: data.product_id,
            poid: data.product_option_id, 
            key: data?data.key:'none',
            id: data?data.id:0,
            pointId: data?data.pointId:0,
            pointValue: data?data.pointValue:0,
        });
    };
    const handlePointDialogClose = () => {
        setPointDialog({
            open: false,
            ppkey: 'none',
            pid: 0,
            poid: 0, 
            key: 'none',
            id: 0,
            pointId: 0,
            pointValue: 0
        });
    };
    const pointDialogSave = () => {
        if(pointDialog.pointId === 0){
            addAlert('', t('general.pleaseSelect')+' '+t('product.pointType'), 'error', '');
        }else{
            let tempData = productPoint[pointDialog.ppkey].points;
            let error = false;
            _.map((tempData), (data, key) => {
                if(data.deleted === false && data.point_id === parseInt(pointDialog.pointId)){
                    if((pointDialog.id === 0 && pointDialog.key === 'none')||(data.id !== parseInt(pointDialog.id))||(pointDialog.key !== key)){
                        error = true;
                        addAlert('', t('product.duplicatePoint'), 'error', '');
                    }
                }
            })
            if(!error){
                let pointName = 'none';
                let pointSource = 'none';
                _.map((pointList), (data, key) => {
                    let name = _.split(data.name, '|');
                    if(data.id === parseInt(pointDialog.pointId)){
                        pointName = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                        pointSource = data.source;
                    }
                })
                if(pointDialog.id === 0 && pointDialog.key === 'none'){
                    tempData.push({id: pointDialog.id, point_id: parseInt(pointDialog.pointId), point_source: pointSource, point_name: pointName, value: pointDialog.pointValue, deleted: false});
                }else if(pointDialog.id !== 0){
                    _.map((tempData), (data, key) => {
                        if(data.id === parseInt(pointDialog.id)){
                            tempData[key] = {id: pointDialog.id, point_id: parseInt(pointDialog.pointId), point_source: pointSource, point_name: pointName, value: pointDialog.pointValue, deleted: false};
                        }
                    })
                }else{
                    tempData[pointDialog.key] = {id: pointDialog.id, point_id: parseInt(pointDialog.pointId), point_source: pointSource, point_name: pointName, value: pointDialog.pointValue, deleted: false};
                }
                let tempProductPoint = productPoint;
                tempProductPoint[pointDialog.ppkey].points = tempData;
                setProductPoint(tempProductPoint);
                handlePointDialogClose();
            }
        }
    }
    const pointDialogDelete = (idata) => {
        let tempData = [];
        _.map((productPoint[idata.ppkey].points), (data, key) => {    
            if(key === idata.key){
                if(data.id !== 0){
                    tempData.push({id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: true});
                }
            }else{
                tempData.push({id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: data.deleted});
            }
        })
        let tempProductPoint = productPoint;
        tempProductPoint[idata.ppkey].points = tempData;
        setProductPoint(tempProductPoint);
        handlePointDialogClose();
    }

    const productPointSave = () => {
        let updateData = {
            product: productPoint,
        }
        postUrl(`admin/memberships/${id}/points`, updateData).then(result => {
            if(result.status === 1){
                handleProductPointClose();
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
            }else{
                if(result.data){
                    let msg = result.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }
    
    return (
        <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Button variant="outlined" color="primary" style={{margin: "5px"}} onClick={() => handleProductPointOpen({id: id})}>
                    {t('membership.productPoint')}
                </Button>
                {/* <Tooltip title={t('membership.productPoint')}><IconButton variant="outlined" type="button" color="primary" onClick={() => handleProductPointOpen({id: id})}><ViewListIcon /></IconButton></Tooltip> */}
            </Grid>
            <Dialog onClose={handleProductPointClose} open={productPointDialog.open} fullWidth maxWidth={'md'}>
                <DialogTitle onClose={handleProductPointClose}>
                    {t('membership.productPoint')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <TableContainer style={theme.p20}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{ t('membership.productOptionId') }</TableCell>
                                        <TableCell>{ t('membership.productDescription') }</TableCell>
                                        <TableCell>{ t('membership.points') }</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (productPoint).map((row, ppkey) => {
                                            return(
                                                <TableRow key={ppkey}>
                                                    <TableCell>{ row.product_option_id }</TableCell>
                                                    <TableCell>
                                                        { 
                                                            row.product_option_id
                                                            ?
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12}>
                                                                    {t('product.color') + ': ' + (row.color?row.color:'-')}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {t('product.size') + ': ' + (row.size?row.size:'-')}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {(row.attribute_name?row.attribute_name:t('product.customAttribute')) + ': ' + (row.attribute_value?row.attribute_value:'-')}
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            '-'
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title={t('product.addProductPoint')}><IconButton variant="outlined" type="button" color="primary" onClick={() => handlePointDialogOpen({ppkey: ppkey, key: 'none', id: 0, pointId: 0, pointValue: 0})}><AddIcon /></IconButton></Tooltip>
                                                    {
                                                        _.size(row.points) > 0
                                                        ?
                                                        <TableContainer>
                                                            <Table
                                                                className={classes.table}
                                                                aria-labelledby="tableTitle"
                                                                size={'medium'}
                                                                aria-label="table"
                                                            >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>{ t('product.action') }</TableCell>
                                                                        <TableCell>{ t('product.pointType') }</TableCell>
                                                                        <TableCell>{ t('product.pointValue') }</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {(row.points).map((data, key) => {
                                                                        if(data.deleted === false){
                                                                            let name = _.split(data.point_name, '|');
                                                                            return(
                                                                                <TableRow key={key}>
                                                                                    <TableCell>
                                                                                        <Tooltip title={t('product.editProductPoint')}>
                                                                                            <IconButton variant="outlined" color="primary" onClick={() => handlePointDialogOpen({ppkey: ppkey, key: key, id: data.id, pointId: data.point_id, pointValue: data.value})}>
                                                                                                <EditIcon />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        <Tooltip title={t('product.deleteProductPoint')}>
                                                                                            <IconButton variant="outlined" color="primary" onClick={() => pointDialogDelete({ppkey: ppkey, key: key})}>
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </TableCell>
                                                                                    <TableCell>{ data.point_source + ' ' + (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) }</TableCell>
                                                                                    <TableCell>{ data.value }</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }else{
                                                                            return null;
                                                                        }
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        null
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={productPointSave} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handlePointDialogClose} open={pointDialog.open} fullWidth>
                <DialogTitle onClose={handlePointDialogClose}>
                    {
                        pointDialog.id === 0 && pointDialog.key === 'none'
                        ?
                        t('product.addProductPoint')
                        :
                        t('product.editProductPoint')
                    }
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                id="point_id" 
                                label={t('product.pointType')}
                                variant="outlined" 
                                fullWidth 
                                select
                                value={pointDialog.pointId?pointDialog.pointId:''}
                                onChange={({ target }) => setPointDialog({ ...pointDialog, pointId: target.value})}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key={''} value={0}>
                                { t('general.pleaseSelect')+' '+t('product.pointType') }
                            </option>
                            {pointList.map((data, key) => {
                                let name = _.split(data.name, '|');
                                return(
                                    <option key={key} value={data.id}>
                                        { data.source + ' ' + (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) }
                                    </option>
                                );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="value" 
                                variant="outlined" 
                                fullWidth
                                label={t('product.pointValue')}
                                value={pointDialog.pointValue?pointDialog.pointValue:''}
                                onChange={({ target }) => setPointDialog({ ...pointDialog, pointValue: target.value})}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={pointDialogSave} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        flexGrow: 1
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    }

}));