import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUrl, putUrl, postUrl, deleteUrl } from '../helper/ApiAction';
import _ from 'lodash';

import MaterialTable from 'material-table';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Button, Grid, Modal, TextField, Divider, Paper, MenuItem,FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

export default function CompanyEditOutlet({ id, setLoading, addAlert,companyInfo }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);

    const [outlets, setOutlets] = useState([]);
    const [state, setState] = useState({});
    const [selectedOptionIndex, setOptionIndex] = useState('');
    const [inputErrors, setInputErrors] = useState([]);
    const [deleteOptionData, setOpenDeleteOptionDialog] = useState({});
    const outlet_types = [{id: "hq", value: t('outlet.hq')}, {id: "outlet", value: t('outlet.outlet')}];
    const [allowSelfPickup, setAllowSelfPickup]=useState(companyInfo.allow_self_pickup);
    const [selfPickUpInfo, setSelfPickUpInfo] = useState(false);

    const getOutlets = () => {
        getUrl(`admin/companies/${id}/outlets`).then(response => {
            setLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setOutlets(response.data);
            } else {
                let msg = response.message;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
            setLoading(false);
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getOutlets();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const saveOptions = () => {
        setLoading(true);
        setInputErrors([]);

        let apiData = {
            outlet_name: state.outlet_name,
            outlet_type: state.outlet_type,
            pic_name: state.pic_name,
            pic_mobile: state.pic_mobile,
            address: state.address,
        }
        if (selectedOptionIndex !== "" && selectedOptionIndex !== "new") {
            putUrl(`admin/outlets/${state.id}`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        getOutlets();
                    } else {
                        if (_.size(error) > 0) {
                            setInputErrors(error);
                            if(error["general"])
                                message+="\n"+error["general"];
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
        } else {
            postUrl(`admin/companies/${id}/outlets`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        getOutlets();
                        setOptionIndex("");
                    } else {
                        if (_.size(error) > 0) {
                            setInputErrors(error);
                            if(error["general"])
                                message+="\n"+error["general"];
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
        }
    };

    const deleteOption = () => {
        setLoading(true);
        deleteUrl(`admin/outlets/${deleteOptionData.id}`)
            .then((response) => {
                setLoading(false);
                let { status, message } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    getOutlets();
                } else {
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
        setOpenDeleteOptionDialog({});
    };

    const handleChangeAllowSelfPickup =()=>{
        setLoading(true);
        setAllowSelfPickup(!allowSelfPickup);
        let params={
            allow_self_pickup:!allowSelfPickup,
        };
        // console.log('self_pickup',params);
        putUrl(`admin/companies/${id}/pickup`,params).then(response=>{
            setLoading(false);
            if(response.status ===1){
                let msg=t('snackbarMsg.updateSuccess');
                addAlert('',msg,'success','');
            }else{
                addAlert('',response.data,'error','');
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={6}>
                <Button variant="outlined" style={{ marginBottom: "30px" }}  onClick={() => {setOptionIndex("new"); setState({});}}><AddIcon />{t('outlet.addOutlet')}</Button>
            </Grid>
            <Grid item xs={6}>
                <FormGroup row style={{ float: "right" }}>
                    <FormControlLabel control={<Switch color="primary" checked={allowSelfPickup? allowSelfPickup:false}  onChange={handleChangeAllowSelfPickup}/>} label={t('seller.allowSelfPickup')} color="primary" style={{color:allowSelfPickup? theme.palette.primary.main : '#000000'}} disabled={_.size(outlets)>0? false:true} />
                </FormGroup>
                <Button type="button" onClick={() => setSelfPickUpInfo(true)} style={{float:"right"}}><HelpRoundedIcon /></Button>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('outlet.outlet_name'), field: 'outlet_name'},
                        { title: t('outlet.outlet_type'), field: 'outlet_type', render: rowData => t('outlet.' + rowData.outlet_type)},
                        { title: t('outlet.code'), field: 'code'},
                    ]}
                    data={outlets}
                    options={{
                        pageSize: 10,
                    }}
                    actions={[
                        {
                          icon: 'edit',
                          tooltip: 'Edit Outlet',
                          onClick: (event, rowData) => {setState(rowData); setOptionIndex('edit');}
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete Outlet',
                          onClick: (event, rowData) => setOpenDeleteOptionDialog(rowData)
                        }
                      ]}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                        },
                        header:{ 
                            actions: t('table.actions'),
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
            </Grid>
            <Modal
                open={selectedOptionIndex !== '' ? true : false}
                onClose={() => {setOptionIndex(""); setInputErrors({});} }
                aria-labelledby="outlet"
                aria-describedby="outlet modal"
                disableBackdropClick
            >
                <Grid container spacing={3} className={classes.modal}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{selectedOptionIndex === "new" ? t('outlet.addOutlet') : t('outlet.editOutlet')}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="outlet_name"
                                        label={t('outlet.outlet_name')}
                                        variant="outlined"
                                        fullWidth
                                        value={(state && state.outlet_name ? state.outlet_name : "")}
                                        onChange={({ target }) => setState({ ...state, outlet_name: target.value })}
                                        helperText={inputErrors.outlet_name ? inputErrors.outlet_name[0] : ''}
                                        error={inputErrors.outlet_name ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="outlet_type"
                                        label={t('outlet.outlet_type')}
                                        variant="outlined"
                                        fullWidth
                                        select
                                        value={(state && state.outlet_type ? state.outlet_type : "")}
                                        onChange={({ target }) => setState({ ...state, outlet_type: target.value })}
                                        helperText={inputErrors.outlet_type ? inputErrors.outlet_type[0] : ''}
                                        error={inputErrors.outlet_type ? true : false}
                                    >
                                        {outlet_types.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {`${option.value}`}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="pic_name"
                                        label={t('outlet.pic_name')}
                                        variant="outlined"
                                        fullWidth
                                        value={(state && state.pic_name ? state.pic_name : "")}
                                        onChange={({ target }) => setState({ ...state, pic_name: target.value })}
                                        helperText={inputErrors.pic_name ? inputErrors.pic_name[0] : ''}
                                        error={inputErrors.pic_name ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="pic_mobile"
                                        label={t('outlet.pic_mobile')}
                                        variant="outlined"
                                        fullWidth
                                        value={(state && state.pic_mobile ? state.pic_mobile : "")}
                                        onChange={({ target }) => setState({ ...state, pic_mobile: target.value })}
                                        helperText={inputErrors.pic_mobile ? inputErrors.pic_mobile[0] : ''}
                                        error={inputErrors.pic_mobile ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="address"
                                        label={t('outlet.address')}
                                        variant="outlined"
                                        fullWidth
                                        value={(state && state.address ? state.address : "")}
                                        onChange={({ target }) => setState({ ...state, address: target.value })}
                                        helperText={inputErrors.address ? inputErrors.address[0] : ''}
                                        error={inputErrors.address ? true : false}
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => {setOptionIndex(""); setInputErrors({});}}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={saveOptions}>{t('button.save')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
            <Dialog
                open={_.size(deleteOptionData) ? true : false}
                onClose={() => setOpenDeleteOptionDialog({})}
                aria-labelledby="delete-option-dialog-title"
                aria-describedby="delete-option-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('outlet.confirmationOnDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('outlet.askDeleteOutlet')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteOptionDialog({})} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={deleteOption} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={selfPickUpInfo}
                onClose={() => setSelfPickUpInfo(false)}
            >
                <DialogTitle>{t('seller.allowSelfPickup')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('helperText.self-pickup.description')}</DialogContentText>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
}));