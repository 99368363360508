import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { getUrl, putUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import * as XLSX from 'xlsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Switch, IconButton, Box, Tabs, Tab, Modal, Backdrop, Fade, MenuItem, Chip, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import Visibility from '@material-ui/icons/Visibility';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Grid container spacing={3}>{children}</Grid>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function VoucherEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useParams();
    const isMountedRef = useRef(null);
    const { role, isAdmin, isMerchant } = useSelector(state => state.general);

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        sell_price: '',
        retail_price: '',
        title: '',
        descr_en: '',
        descr_cn: '',
        merchant_company_id: '',
        quantity: '',
        sku: ''
    });

    const [categoryTreeData, setCategoryTreeData] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [companies, setCompanies] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [unpublishOpen, setUnpublishOpen] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);
    const [shareEmails, setShareEmails] = useState([]);
    const [voucherCount, setVoucherCount] = useState({});
    const [uploadedShareEmails, setUploadedShareEmails] = useState([]); 

    const voucherTypes = [{id: 'cash', value: t('voucher.cash')},{id: 'discount', value: t('voucher.discount')},{id: 'redeem', value: t('voucher.redeem')}];

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleTabChangeIndex = (index) => {
        setTabValue(index);
    };

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`admin/vouchers/${id}`).then(response => {
            if(isMountedRef.current) {
                setLoading(false);
                if(response.status === 1){
                    setState(response.data);
                    setCategoryId(response.data.category_id);
                    setVoucherCount(response.voucher);
                }else{
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'error', '');
                    }
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(t('snackbarMsg.getError', error+"\n"+t('error.contactSupport')));
        });

        if(isAdmin){
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }

        getUrl(`admin/products/${id}/categories`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setCategoryTreeData(result.tree_data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert])

    const deleteImage = (item) => {
        setLoading(true);
        setInputErrors("");
        putUrl(`admin/vouchers/${id}`, { images: _.difference(state.images_array, [item]) }).then(response => {
            setLoading(false);
            let { status, message, error, data } = response;
            if (status === 1) {
                addAlert('', message, 'success', '');
                setState({ ...state, images_array: data.images_array });
            } else {
                if(error.general){
                    message += error.general[0];
                } 
                addAlert('', message, 'error', '');
                if (error) {
                    setInputErrors(error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");

        const updateData = {
            title: state.title,
            category_id: categoryId,
            merchant_company_id: state.merchant_company_id,
            sell_price: state.sell_price,
            retail_price: state.retail_price,
            quantity: state.quantity,
            descr_en: state.descr_en,
            descr_cn: state.descr_cn,
            sku: state.sku,
            redeem_sdate: state.voucher_header.redeem_sdate,
            redeem_edate: state.voucher_header.redeem_edate,
            max_purchase: state.voucher_header.max_purchase,
            voucher_type: state.voucher_header.voucher_type,
        }
        putUrl(`admin/vouchers/${id}`, updateData).then(response => {
            setLoading(false);
            let { status, message, error, data } = response;
            if(status) {
                addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
                setState({...state, title_en: data.title_en, title_cn: data.title_cn, descr_en: data.descr_en, descr_cn: data.descr_cn})
            }else{
                if(message){
                    let msg = message;
                    if(error && error.general){
                        msg += error.general[0];
                    }
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(error){
                    setInputErrors(error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    const form_submit2 = () => {
        setLoading(true);
        setInputErrors("");

        const updateData = {
            package_offer_en: state.voucher_header.package_offer.en,
            package_offer_cn: state.voucher_header.package_offer.cn,
            fine_print_en: state.voucher_header.fine_print.en,
            fine_print_cn: state.voucher_header.fine_print.cn,
            redeem_instruction_en: state.voucher_header.redeem_instruction.en,
            redeem_instruction_cn: state.voucher_header.redeem_instruction.cn,
        }
        putUrl(`admin/vouchers/${id}`, updateData).then(response => {
            setLoading(false);
            let { status, message, error } = response;
            if(status) {
                addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
            }else{
                let msg = t('snackbarMsg.updateError');
                if(message){
                    msg = message;
                    if(error.general){
                        msg += error.general[0];
                    }                
                }
                addAlert('', msg, 'error', '');
                if(error){
                    setInputErrors(error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    const categoryOnChange = (currentNode, selectedNodes) => {
        if(_.size(selectedNodes)>0){
            setCategoryId(currentNode.value);
            let temp = loopTreeData(categoryTreeData, currentNode.value);
            setCategoryTreeData(temp);
        }else{
            setCategoryId("");
            let temp = loopTreeData(categoryTreeData, "");
            setCategoryTreeData(temp);
        }
    }
    const loopTreeData = (treeData, parentId) => {
        let temp = treeData;
        _.map(treeData, (data, key) => {
            if(data.value === parentId){
                temp[key].checked = true;
            }else{
                temp[key].checked = false;
            }
            if(data.children){
                temp[key].children = loopTreeData(data.children, parentId);
            }
        })
        return temp;
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const uploadImages = async event => {
        if(event.target.files[0]){
            setLoading(true);
            setInputErrors([]);

            let newImagesBase64 = [];
            newImagesBase64.push(await toBase64(event.target.files[0]));

            putUrl(`admin/vouchers/${id}`, {new_images: newImagesBase64, images: state.images_array}).then(response => {
                setLoading(false);
                let { status, message, error, data } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    setState({...state, images_array: data.images_array });
                } else {
                    if(error.general){
                        message += error.general[0];
                    }
                    addAlert('', message, 'error', '');
                    if (error) {
                        setInputErrors(error);
                    }
                }
            }).catch((error) => {
                setLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            });
        }
    };

    const changeStatus = (event) => {
        if(state.status == 1){
            setUnpublishOpen(true);
        }else{
            updateStatus(event.target.checked ? 1 : 0);
        }
    };

    const updateStatus = (checked) => {
        setLoading(true);
        setInputErrors("");

        putUrl(`admin/vouchers/${id}/status`, {status: checked ? 1 : 0 })
        .then((response) => {
            setLoading(false);
            let {status, message, data:newData, error} = response;
            if(status === 1){
                setState({ ...state, ['status']: newData.status ,['status_display_en']: newData.status_display_en, ['status_display_cn']: newData.status_display_cn});
                addAlert('', message, 'success', '');
            }else{
                let errorMsg = message+" ";
                _.forOwn(error, function(value, key) {
                    errorMsg+=`${value} `;
                });
                addAlert('', errorMsg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };

    const handleFiles = (e) => {
        const files = e.target.files;
        let file = "";
        if(files && files[0]){
            file = files[0]
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (evt) => { // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                let data = XLSX.utils.sheet_to_csv(ws, {header:1});
                /* replace line break with , */
                data = data.replace(/(?:\r\n|\r|\n)/g, ',');
                data = data.replaceAll('"',"");
                /* convert to array */
                data = data.split(",")
                /* remove empty values */
                data = _.compact(data);
                setUploadedShareEmails(data);
            };
            if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        }
    };

    const sendVoucher = () => {
        setLoading(true);
        setInputErrors("");

        postUrl(`admin/vouchers/${id}/send`, {emails: [...shareEmails, ...uploadedShareEmails] })
        .then((response) => {
            setLoading(false);
            let {status, message, error, data} = response;
            if(status === 1){
                setState({ ...state, ['quantity']: data.quantity });
                addAlert('', message, 'success', '');
            }else{
                addAlert('', message, 'error', '');
                let emailError = "";
                _.forOwn(error, function(value, key) {
                    emailError+=`${value} `;
                });
                setInputErrors({emails: [emailError]});
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };

    return(
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1"></Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/vouchers`} component={RouterLink}>{t('voucher.vouchers')}</Link>
                        <Typography style={{ color: 'black' }}>{t('voucher.voucher')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1 }}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} container justify="space-between">
                            <Grid>
                                { state.status !== 1 ?
                                    <Button variant="contained" color="primary" size="small" className={classes.buttonCapi} onClick={() => setEditOpen(true)} style={{ backgroundColor: "green", marginRight: 15 }} startIcon={<EditOutlinedIcon />}>{t('button.edit')}</Button>
                                    :
                                    <Button variant="contained" color="primary" size="small" className={classes.buttonCapi} onClick={() => setShareOpen(true)} style={{ backgroundColor: "green", marginRight: 15 }} startIcon={<ShareOutlinedIcon />}>{t('voucher.share')}</Button>
                                 }
                                {/* <Button variant="contained" color="primary" size="small" className={classes.buttonCapi} onClick={() => alert("Function Coming Soon")} startIcon={<DeleteOutlinedIcon />}>{t('voucher.deleteVoucher')}</Button> */}
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isMerchant ? (state.status ? true : false) : (state.status === 0 || state.status === 2 ? false : true)}
                                            onChange={(event) => changeStatus(event)}
                                            name="status"
                                            color="primary"
                                        />
                                    }
                                    label={state[`status_display_${i18n.language}`]}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container spacing={3} alignItems="center">
                            <Grid item xs={12} sm={5} style={{ textAlign: "center" }}>
                                <img src={state.images_array ? state.images_array[0] : ""} style={{ height: 175, width: "auto", maxWidth: "100%" }}/>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Grid container direction="row" alignItems="center">
                                <Typography><span style={{ marginRight: 5, backgroundColor: theme.palette.primary.main, color: "white", borderRadius: "50%", padding: 5, display: "inline-flex", verticalAlign: "middle" }}><CalendarTodayOutlinedIcon fontSize="small"/></span> {t('voucher.redeemOfferFrom', {date: state.voucher_header && state.voucher_header.redeem_sdate ? state.voucher_header.redeem_sdate : t('voucher.today')})} <span style={{ color: theme.palette.primary.main }}>{state.voucher_header && state.voucher_header.redeem_edate ? state.voucher_header.redeem_edate : "-"}</span></Typography>
                                </Grid>
                                <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                    <Typography style={{ textDecoration: "line-through", color: "gray", marginRight: 5 }}>{state[`currency_display_${i18n.language}`]}{state.retail_price}</Typography>
                                    <Typography variant="h6" style={{ color: "green", marginRight: 5 }}>{state[`currency_display_${i18n.language}`]}{state.sell_price}</Typography>
                                    { state.retail_price ?
                                        <Typography variant="caption" display="block" style={{ backgroundColor: theme.palette.primary.main, color:"white", borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>{ Math.round((state.retail_price - state.sell_price)/state.retail_price*100)}% {t('voucher.off')}</Typography>
                                    : null }
                                </Grid>
                                <Grid style={{ marginTop: 10 }}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 900 }}>{state[`title_${i18n.language}`]}</Typography>
                                    <Typography variant="body2">{state[`descr_${i18n.language}`]}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{ fontWeight: 900 }}>{t('voucher.voucherQuantity')}</Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid style={{ textAlign: "center "}}>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{((state.quantity ? state.quantity : 0) +(voucherCount.total ? voucherCount.total: 0))}</Typography>
                                    <Typography>{t('voucher.total')}</Typography>
                                </Grid>
                                <Grid style={{ textAlign: "center "}}>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{voucherCount.total_purchased}</Typography>
                                    <Typography>{t('voucher.purchase')}</Typography>
                                </Grid>
                                <Grid style={{ textAlign: "center "}}>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{voucherCount.total_redeemed}</Typography>
                                    <Typography>{t('voucher.used')}</Typography>
                                </Grid>
                                <Grid style={{ textAlign: "center "}}>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{state.quantity}</Typography>
                                    <Typography>{t('voucher.balance')}</Typography>
                                </Grid>
                                <Grid style={{ textAlign: "center "}}>                                    
                                    <Link underline='none' color="inherit" to={`/vouchers/${state.id}/details`} component={RouterLink}><Button variant="contained" color="primary" size="large" className={classes.buttonCapi}>{t('voucher.viewDetails')}</Button></Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: "100%" }}><Divider style={{ padding: 5 }}/></Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{ fontWeight: 900 }}>{t('voucher.images')} <span style={{ fontWeight:"normal" }}>({_.size(state.images_array)} {t('voucher.photos')})</span></Typography>
                            <Grid className={ classes.gridList}>
                                {
                                    state.status !== 1 ?
                                        <Grid className={classes.gridListItem}>
                                            <Button variant="contained" component="label" className={classes.uploadBtn}>
                                                <CameraAltOutlinedIcon fontSize="large" style={{ fontSize: "3rem" }} /> {t('voucher.uploadPhoto')}
                                                <input type="file" onChange={uploadImages} hidden accept="image/*" />
                                            </Button>
                                        </Grid>
                                        : null
                                }
                                {_.map(state.images_array, (item, itemIndex) => {
                                    return (
                                        <Grid className={classes.gridListItem} key={itemIndex}>
                                            <img src={item} alt="product" className={classes.gridListImg}/>
                                            {
                                                state.status !== 1 ?
                                                    <IconButton aria-label="delete" className={classes.gridListDeleteIcon} onClick={() => deleteImage(item)}><CancelIcon /></IconButton>
                                                : null
                                            }
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            { inputErrors.new_images ?
                                <Typography style={{ color: "red" }}>{inputErrors.new_images[0]}</Typography>
                            : null}
                        </Grid>
                        <Grid style={{ width: "100%" }}><Divider  style={{ padding: 5 }}/></Grid>
                        <Grid item xs={12}>
                            <div>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="tabs"
                                >
                                    <Tab label={t('voucher.whatYouGet')} {...a11yProps(0)} style={{ textTransform: "Capitalize" }}/>
                                    <Tab label={t('voucher.finePrint')} {...a11yProps(1)} style={{ textTransform: "Capitalize" }}/>
                                    <Tab label={t('voucher.redemptionInstructions')} {...a11yProps(2)} style={{ textTransform: "Capitalize" }}/>
                                </Tabs>
                            </div>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={tabValue}
                                onChangeIndex={handleTabChangeIndex}
                            >
                                <TabPanel value={tabValue} index={0} dir={theme.direction}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="package_offer_en"
                                            className={classes.dashedTextArea}
                                            label={t('voucher.package_offer_en')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={20}
                                            value={state.voucher_header ? state.voucher_header.package_offer.en : ""}
                                            helperText={inputErrors.package_offer_en ? inputErrors.package_offer_en[0] : ''}
                                            error={inputErrors.package_offer_en ? true : false}
                                            onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, package_offer:{ ...state.voucher_header.package_offer, en: target.value } } })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="package_offer_cn"
                                            className={classes.dashedTextArea}
                                            label={t('voucher.package_offer_cn')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={20}
                                            value={state.voucher_header ? state.voucher_header.package_offer.cn : ""}
                                            helperText={inputErrors.package_offer_cn ? inputErrors.package_offer_cn[0] : ''}
                                            error={inputErrors.package_offer_cn ? true : false}
                                            onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, package_offer:{ ...state.voucher_header.package_offer, cn: target.value } } })}
                                        />
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1} dir={theme.direction}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="fine_print_en"
                                            className={classes.dashedTextArea}
                                            label={t('voucher.fine_print_en')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={20}
                                            value={state.voucher_header ? state.voucher_header.fine_print.en : ""}
                                            helperText={inputErrors.fine_print_en ? inputErrors.fine_print_en[0] : ''}
                                            error={inputErrors.fine_print_en ? true : false}
                                            onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, fine_print:{ ...state.voucher_header.fine_print, en: target.value } } })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="fine_print_cn"
                                            className={classes.dashedTextArea}
                                            label={t('voucher.fine_print_cn')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={20}
                                            value={state.voucher_header ? state.voucher_header.fine_print.cn : ""}
                                            helperText={inputErrors.fine_print_cn ? inputErrors.fine_print_cn[0] : ''}
                                            error={inputErrors.fine_print_cn ? true : false}
                                            onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, fine_print:{ ...state.voucher_header.fine_print, cn: target.value } } })}
                                        />
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2} dir={theme.direction}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="redeem_instruction_en"
                                            className={classes.dashedTextArea}
                                            label={t('voucher.redeem_instruction_en')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={20}
                                            value={state.voucher_header ? state.voucher_header.redeem_instruction.en : ""}
                                            helperText={inputErrors.redeem_instruction_en ? inputErrors.redeem_instruction_en[0] : ''}
                                            error={inputErrors.redeem_instruction_en ? true : false}
                                            onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, redeem_instruction:{ ...state.voucher_header.redeem_instruction, en: target.value } } })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="redeem_instruction_cn"
                                            className={classes.dashedTextArea}
                                            label={t('voucher.redeem_instruction_cn')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={20}
                                            value={state.voucher_header ? state.voucher_header.redeem_instruction.cn : ""}
                                            helperText={inputErrors.redeem_instruction_cn ? inputErrors.redeem_instruction_cn[0] : ''}
                                            error={inputErrors.redeem_instruction_cn ? true : false}
                                            onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, redeem_instruction:{ ...state.voucher_header.redeem_instruction, cn: target.value } } })}
                                        />
                                    </Grid>
                                </TabPanel>
                            </SwipeableViews>
                            <Grid item xs={12} container spacing={6} justify="flex-end" style={{ paddingTop: 8 }}>
                            { 
                                state.status !== 1 ?
                                    <Button variant="contained" onClick={form_submit2} color="primary" style={{ borderRadius: 20 }}>{t('button.update')}</Button>
                                : null
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Modal
                aria-labelledby="edit voucher"
                aria-describedby="edit voucher details"
                className={classes.modal}
                open={editOpen}
                onClose={() => setEditOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={editOpen}>
                    <Grid container spacing={3} className={classes.modalBody} style={theme.p20}>
                        <Grid item xs={12}>
                            <h2 id="transition-modal-title">{t('voucher.editInfo')}</h2>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                inputErrors.general
                                ?
                                <Typography variant="caption" style={{color: "red"}}>
                                    {inputErrors.general[0]}
                                </Typography>
                                :null
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="sku"
                                label={t('voucher.sku')}
                                variant="outlined"
                                fullWidth
                                value={state.sku}
                                helperText={inputErrors.sku ? inputErrors.sku[0] :''}
                                error={inputErrors.sku ? true : false}
                                onChange={({ target }) => setState({ ...state, sku: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="title"
                                label={t('voucher.title')}
                                variant="outlined"
                                fullWidth
                                value={state.title}
                                helperText={inputErrors.title ? inputErrors.title[0] :''}
                                error={inputErrors.title ? true : false}
                                onChange={({ target }) => setState({ ...state, title: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="fdate"
                                label={t('voucher.from')}
                                variant="outlined"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={state.voucher_header && state.voucher_header.redeem_sdate ? state.voucher_header.redeem_sdate : ""}
                                helperText={inputErrors.redeem_sdate ? inputErrors.redeem_sdate[0] :''}
                                error={inputErrors.redeem_sdate ? true : false}
                                onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, redeem_sdate: target.value }})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="tdate"
                                label={t('voucher.to')}
                                variant="outlined"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={state.voucher_header && state.voucher_header.redeem_edate ? state.voucher_header.redeem_edate : ""}
                                helperText={inputErrors.redeem_edate ? inputErrors.redeem_edate[0] :''}
                                error={inputErrors.redeem_edate ? true : false}
                                onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, redeem_edate: target.value }})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="retail_price"
                                label={t('voucher.originalPriceRM')}
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={state.retail_price}
                                helperText={inputErrors.retail_price ? inputErrors.retail_price[0] :''}
                                error={inputErrors.retail_price ? true : false}
                                onChange={({ target }) => setState({ ...state, retail_price: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="sell_price"
                                label={t('voucher.offerPriceRM')}
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={state.sell_price}
                                helperText={inputErrors.sell_price ? inputErrors.sell_price[0] :''}
                                error={inputErrors.sell_price ? true : false}
                                onChange={({ target }) => setState({ ...state, sell_price: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="max_purchase"
                                label={t('voucher.maxPurchasePerCustomer')}
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={state.voucher_header ? state.voucher_header.max_purchase : ""}
                                helperText={inputErrors.max_purchase ? inputErrors.max_purchase[0] :''}
                                error={inputErrors.max_purchase ? true : false}
                                onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, max_purchase: target.value }})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DropdownTreeSelect
                                data={categoryTreeData}
                                mode="radioSelect"
                                keepTreeOnSearch
                                keepChildrenOnSearch
                                texts={{ placeholder: t('table.search')+ ' ' +t('product.category') }}
                                onChange={categoryOnChange}
                            />
                            {
                                inputErrors.categoryError
                                ?
                                <Typography variant="caption" style={{color: "red"}}>
                                    {inputErrors.categoryError[0]}
                                </Typography>
                                :null
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                label={t('voucher.voucherType')}
                                value={(state.voucher_header ? state.voucher_header.voucher_type : "")}
                                onChange={({ target }) => setState({ ...state, voucher_header: { ...state.voucher_header, voucher_type: target.value } })}
                                variant="outlined"
                                fullWidth
                                error={inputErrors.voucher_type ? true : false}
                                helperText={inputErrors.voucher_type ? inputErrors.voucher_type[0] : ''}
                            >
                                {voucherTypes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {`${option.value}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="descr_en"
                                label={t('voucher.descr_en')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.descr_en}
                                helperText={inputErrors.descr_en ? inputErrors.descr_en[0] :''}
                                error={inputErrors.descr_en ? true : false}
                                onChange={({ target }) => setState({ ...state, descr_en: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="descr_cn"
                                label={t('voucher.descr_cn')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={state.descr_cn}
                                helperText={inputErrors.descr_cn ? inputErrors.descr_cn[0] :''}
                                error={inputErrors.descr_cn ? true : false}
                                onChange={({ target }) => setState({ ...state, descr_cn: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="quantity"
                                label={t('voucher.quantity')}
                                variant="outlined"
                                fullWidth
                                type='number'
                                value={state.quantity}
                                helperText={inputErrors.quantity ? inputErrors.quantity[0] :''}
                                error={inputErrors.quantity ? true : false}
                                onChange={({ target }) => setState({ ...state, quantity: target.value }) }
                            />
                        </Grid>
                        { _.size(companies) ?
                            <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    label={t('product.shopName')}
                                    value={state.merchant_company_id}
                                    onChange={({ target }) => setState({ ...state, merchant_company_id: target.value })}
                                    variant="outlined"
                                    fullWidth
                                    error={inputErrors.merchant_company_id ? true : false}
                                    helperText={inputErrors.merchant_company_id ? inputErrors.merchant_company_id[0] : ''}
                                >
                                    {companies.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {`${option.shop_name} [ID: ${option.id}]`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        : null }
                        <Grid item xs={12} style={{ textAlign: "center"}}>
                            <Button variant="contained" color="secondary" size="large" className={classes.buttonCapi} onClick={() => setEditOpen(false)} style={{ marginRight: 15, minWidth: 110 }}>{t('button.cancel')}</Button>
                            <Button variant="contained" color="primary" size="large" className={classes.buttonCapi} onClick={form_submit} style={{ minWidth: 110 }}>{t('button.update')}</Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="update voucher status"
                aria-describedby="update voucher status"
                className={classes.modal}
                open={unpublishOpen}
                onClose={() => setUnpublishOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={unpublishOpen}>
                    <Grid container spacing={3} className={classes.modalBody} style={theme.p20} justify="center">
                        <Grid item xs={12}>
                            <h2 id="transition-modal-title" style={{ textAlign: "center", whiteSpace: "pre-line" }}>{t('voucher.unpublishThisVoucher')}</h2>
                        </Grid>
                        <Grid item xs={10} container spacing={3} alignItems="center" style={{ border: "1px solid gray"}}>
                            <Grid item xs={12} sm={5} style={{ textAlign: "center" }}>
                                <img src={state.images_array ? state.images_array[0] : ""} style={{ height: 165, width: "auto", maxWidth: "100%" }}/>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Grid container direction="row" alignItems="center">
                                    <Typography><span style={{ marginRight: 5, backgroundColor: theme.palette.primary.main, color: "white", borderRadius: "50%", padding: 5, display: "inline-flex", verticalAlign: "middle" }}><CalendarTodayOutlinedIcon fontSize="small"/></span> {t('voucher.redeemOfferFrom', {date: state.voucher_header && state.voucher_header.redeem_sdate ? state.voucher_header.redeem_sdate : t('voucher.today')})} <span style={{ color: theme.palette.primary.main }}>{state.voucher_header && state.voucher_header.redeem_edate ? state.voucher_header.redeem_edate : "-"}</span></Typography>
                                </Grid>
                                <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                    <Typography style={{ textDecoration: "line-through", color: "gray", marginRight: 5 }}>{state[`currency_display_${i18n.language}`]}{state.retail_price}</Typography>
                                    <Typography variant="h6" style={{ color: "green", marginRight: 5 }}>{state[`currency_display_${i18n.language}`]}{state.sell_price}</Typography>
                                    { state.retail_price ?
                                        <Typography variant="caption" display="block" style={{ backgroundColor: theme.palette.primary.main, color:"white", borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>{ Math.round((state.retail_price - state.sell_price)/state.retail_price*100)}% {t('voucher.off')}</Typography>
                                    : null }
                                </Grid>
                                <Grid style={{ marginTop: 10 }}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 900 }}>{state[`title_${i18n.language}`]}</Typography>
                                    <Typography variant="body2" className={classes.twoLine}>{state[`descr_${i18n.language}`]}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} style={{ marginTop: 15 }}>
                            <Typography variant="subtitle1" style={{ fontWeight: 900 }}>{t('voucher.note')}</Typography>
                            <Typography variant="body2" style={{ whiteSpace: "pre-line"}}>{t('voucher.unpublishNoteDescription')}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center"}}>
                            <Button variant="contained" color="secondary" size="large" className={classes.buttonCapi} onClick={() => setUnpublishOpen(false)} style={{ marginRight: 15, minWidth: 110 }}>{t('button.cancel')}</Button>
                            <Button variant="contained" color="primary" size="large" className={classes.buttonCapi} onClick={() => {updateStatus(false); setUnpublishOpen(false)}} style={{ minWidth: 110 }}>{t('voucher.unpublish')}</Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="share voucher"
                aria-describedby="send  voucher"
                className={classes.modal}
                open={shareOpen}
                onClose={() => setShareOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={shareOpen}>
                    <Grid container spacing={3} className={classes.modalBody} style={theme.p20} justify="center">
                        <Grid item xs={10}>
                            <h2 id="transition-modal-title" style={{ margin: 0 }}>{t('voucher.shareVoucher')}</h2>
                        </Grid>
                        <Grid item xs={10} container>
                            <Grid item xs={12} sm={5}>
                                <img src={state.images_array ? state.images_array[0] : ""} style={{ height: 165, width: "auto", maxWidth: "100%" }}/>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Grid container direction="row" alignItems="center">
                                    <Typography><span style={{ marginRight: 5, backgroundColor: theme.palette.primary.main, color: "white", borderRadius: "50%", padding: 5, display: "inline-flex", verticalAlign: "middle" }}><CalendarTodayOutlinedIcon fontSize="small"/></span> {t('voucher.redeemOfferFrom', {date: state.voucher_header && state.voucher_header.redeem_sdate ? state.voucher_header.redeem_sdate : t('voucher.today')})} <span style={{ color: theme.palette.primary.main }}>{state.voucher_header && state.voucher_header.redeem_edate ? state.voucher_header.redeem_edate : "-"}</span></Typography>
                                </Grid>
                                <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
                                    <Typography style={{ textDecoration: "line-through", color: "gray", marginRight: 5 }}>{state[`currency_display_${i18n.language}`]}{state.retail_price}</Typography>
                                    <Typography variant="h6" style={{ color: "green", marginRight: 5 }}>{state[`currency_display_${i18n.language}`]}{state.sell_price}</Typography>
                                    { state.retail_price ?
                                        <Typography variant="caption" display="block" style={{ backgroundColor: theme.palette.primary.main, color:"white", borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>{ Math.round((state.retail_price - state.sell_price)/state.retail_price*100)}% {t('voucher.off')}</Typography>
                                    : null }
                                </Grid>
                                <Grid style={{ marginTop: 10 }}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 900 }}>{state[`title_${i18n.language}`]}</Typography>
                                    <Typography variant="body2" className={classes.twoLine}>{state[`descr_${i18n.language}`]}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} style={{ marginTop: 15 }}>
                            <Autocomplete
                                multiple
                                id="share-emails"
                                options={[]}
                                // defaultValue={[gg.com]}
                                value={shareEmails}
                                onChange={(event, newValue) => {
                                    setShareEmails(newValue);
                                }}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={t('voucher.emailAddress')} placeholder="john@example.com" />
                                )}
                            />
                            {
                                inputErrors.emails
                                ?
                                <Typography variant="caption" style={{color: "red"}}>
                                    {inputErrors.emails[0]}
                                </Typography>
                                :null
                            }
                        </Grid>
                        <Grid item xs={10} style={{ marginTop: 15 }} container>
                            <Grid item xs={12}>
                                <TextField variant="outlined" label={t('voucher.uploadFile')} onChange={handleFiles} 
                                    type="file" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <Tooltip title={t('voucher.preview')} aria-label="preview"><IconButton
                                            aria-label="preview upload"
                                            onClick={() => alert(uploadedShareEmails)}
                                        ><Visibility /></IconButton></Tooltip>,
                                    }}
                                    accept=".csv, .xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center"}}>
                            <Button variant="contained" color="secondary" size="large" className={classes.buttonCapi} onClick={() => setShareOpen(false)} style={{ marginRight: 15, minWidth: 110 }}>{t('button.cancel')}</Button>
                            <Button variant="contained" color="primary" size="large" className={classes.buttonCapi} onClick={() => sendVoucher(false)} style={{ minWidth: 110 }}>{t('voucher.sendNow')}</Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    buttonCapi: {
        borderRadius: 20,
        textTransform: "Capitalize"
    },
    gridList: {
        overflowY: "hidden",
        overflowX: "auto",
        display: "flex",
        height: 150,
    },
    gridListItem: {
        padding: 10,
        position: "relative",
        width: "20%",
        minWidth: 200
    },
    gridListDeleteIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 0,
        background: "white",
        color: "black"
    },
    gridListImg:{
        top: "50%",
        width: "100%",
        position: "relative",
        transform: "translateY(-50%)"
    },
    uploadBtn: {
        width: "100%",
        height: "100%",
        background: "none",
        border: "2px dashed gray",
        borderRadius: 0,
        color: "gray",
        '&> span': {
            display: "flex",
            flexDirection: "column",
            textTransform: "Capitalize"
        }
    },
    dashedTextArea: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "1px dashed gray",
                borderRadius: 0,
            },
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalBody: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: "90%",
        maxHeight: "90%",
        overflow: "auto"
    },
    twoLine: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    uploadInput: {
        border: "1px solid rgb(0, 0, 0, 23%)",
        borderRadius: 5,
        padding: 16.5
    }
}));