import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, FormControl, FormHelperText, InputLabel, Select, MenuItem, Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, Checkbox, FormGroup, FormControlLabel, Box } from '@material-ui/core';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function RoleEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { id } = useParams();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({ 
        name: '',
        display_name: '',
        role_level: '',
        type: '',
    });
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setLoading(true);
        getUrl(`admin/roles/${id}`).then(result => {
            setLoading(false);
            setState({ 
                name: result.data.name,
                display_name: result.data.display_name,
                role_level: result.data.role_level,
                type: result.data.type,
            });
        }).catch(error => {
            setLoading(false);
            addAlert(t('snackbarMsg.getError'), error+"\n"+t('error.contactSupport'), 'error');
        });
        // eslint-disable-next-line
    },[id]);

    useEffect(() => {
        setLoading(true);
        getUrl(`admin/roles/${id}/permissions`).then(result => {
            setLoading(false);
            setPermissions(result.data);
            // setState({ name: result.data.name });
        }).catch(error => {
            setLoading(false);
            addAlert(t('snackbarMsg.getError'), error+"\n"+t('error.contactSupport'), 'error');
        });
        // eslint-disable-next-line
    },[id]);

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");

        let postPermission = [];
        _.map(permissions, (permission) => {
            if(permission.authorize) {
                postPermission.push(permission.name);
            }
        })
        const updateData = {
            name : state.name,
            display_name : state.display_name,
            type : state.type,
            role_level : state.role_level,
            permission: postPermission,
        };

        // console.log("updateData", updateData);
        putUrl(`admin/roles/${id}`, updateData).then(response => {
            // console.log("response", response);
            setLoading(false);
            if(response.status) {
                if(response.data.id){
                    addAlert( `${t('snackbarMsg.updateSuccess')} ID: ${response.data.id}`, '', 'success');
                }else{
                    addAlert( `${t('snackbarMsg.updateSuccess')}`, '', 'success');
                }
            }else{
                if(response.data){
                    addAlert(t('snackbarMsg.updateError'), '', 'error');
                }else{
                    addAlert(t('snackbarMsg.updateError'), '', 'error');
                }
                if(response.error){
                    setInputErrors(response.error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    const changeAuthorize = id => {
        let newPermissions = permissions;
        _.map(newPermissions, (permission, permissionIndex) => {
            if(permission.id === id) {
                newPermissions[permissionIndex]['authorize'] = newPermissions[permissionIndex]['authorize'] ? false : true;
            }
        })
        console.log("newPermissions", newPermissions);

        setPermissions(newPermissions => ({ ...newPermissions }));
    }

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('role.editRole')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/roles`} component={RouterLink}>{t('role.roles')}</Link>
                        <Typography style={{ color: 'black' }}>{t('role.editRole')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                {
                    _.size(permissions) > 0 ? 
                    <Paper className={classes.paper} style={theme.box1}>
                        <Grid container spacing={3} direction="row" style={theme.p20}>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="role_name" 
                                    label={t('role.name')}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.name}
                                    helperText={inputErrors.name ? inputErrors.name : ''}
                                    error={inputErrors.name ? true : false}
                                    onChange={({ target }) => setState({ ...state, name: target.value }) }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    id="role_display_name" 
                                    label={t('role.displayName')}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.display_name}
                                    helperText={inputErrors.display_name ? inputErrors.display_name : ''}
                                    error={inputErrors.display_name ? true : false}
                                    onChange={({ target }) => setState({ ...state, display_name: target.value }) }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    id="role_level" 
                                    label={t('role.level')}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.role_level}
                                    helperText={inputErrors.role_level ? inputErrors.role_level : ''}
                                    error={inputErrors.role_level ? true : false}
                                    onChange={({ target }) => setState({ ...state, role_level: target.value }) }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="role-type">{t('role.type')}</InputLabel>
                                    <Select
                                        value={state.type}
                                        onChange={ ({target}) => setState({ ...state, type: target.value }) }
                                        // style={{ minWidth: 100, textAlign: 'center' }}
                                        label={t('role.type')}
                                        inputProps={{
                                            name: 'roleType',
                                            id: 'ole-type',
                                        }}
                                    >
                                        {
                                            _.map(['admins', 'merchants', 'members'], rolesType => {
                                                return <MenuItem key={rolesType} value={rolesType}>{ t(`role.types.${rolesType}`) }</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText style={{ color: 'red' }}>{ inputErrors.type ? inputErrors.type : '' }</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormGroup>
                                    <Grid container>
                                    {
                                        _.size(permissions) > 0 ? _.map(permissions, permission => {
                                            return (
                                                <Grid item md={6} key={permission.id}>
                                                    {
                                                        permission.parent_id === "0" ?
                                                        <>
                                                            <FormControlLabel
                                                                control={<Checkbox color="primary" checked={permission.authorize} onChange={ () => changeAuthorize(permission.id) } />}
                                                                label={permission.name}
                                                            /> 
                                                            {
                                                                _.map(permissions, secondaryPermission => {
                                                                    if(parseInt(secondaryPermission.parent_id) === permission.id) {
                                                                        return (
                                                                            <Box key={secondaryPermission.id} spacing={2} paddingLeft={5}>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox color="primary" checked={secondaryPermission.authorize} onChange={ () => changeAuthorize(secondaryPermission.id) } />}
                                                                                    label={secondaryPermission.name}
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </>
                                                        : null
                                                    }
                                                </Grid>
                                            )
                                            // return (
                                            //     <Grid item md={6} key={mainPermission.id}>
                                            //         {
                                            //             mainPermission.parent_id === 0 ?
                                            //             <FormControlLabel
                                            //                 control={<Checkbox color="primary" checked={mainPermission.authorize} onChange={ () => changeAuthorize(mainPermission.id, 'main') } />}
                                            //                 label={mainPermission.name}
                                            //             /> : null
                                            //         }
                                            //         {/* <FormControlLabel
                                            //             control={<Checkbox color="primary" checked={mainPermission.authorize} onChange={ () => changeAuthorize(mainPermission.id, 'main') } />}
                                            //             label={mainPermission.name}
                                            //         />
                                            //         {
                                            //             mainPermission.child ?
                                            //             _.map(mainPermission.child, secondaryPermission => {
                                            //                 return (
                                            //                     <Box key={secondaryPermission.id} spacing={2} paddingLeft={5}>
                                            //                         <FormControlLabel
                                            //                             control={<Checkbox color="primary" checked={secondaryPermission.authorize} onChange={ () => changeAuthorize(secondaryPermission.id, 'secondary') } />}
                                            //                             label={secondaryPermission.name}
                                            //                         />
                                            //                     </Box>
                                            //                 )
                                            //             })
                                            //             : null
                                            //         } */}
                                            //     </Grid>
                                            // )
                                        })
                                        : null
                                    }
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" color="primary" onClick={form_submit} >{t('button.submit')}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    : null
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));