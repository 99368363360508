import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { postUrl } from '../helper/ApiAction';
import { APP_NAME } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Paper, Divider } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordForgot() {

    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [email, setEmail] = useState('');

    useEffect(() => {
        isMountedRef.current = true;

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const forgotPassword = () => {
        setLoading(true);

        postUrl(`forgot_password`, { email: email }).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n" + value[0];
                        })
                        setInputErrors(error);
                    }
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            }
        });
    }

    return (
        <Grid container component="main" className={classes.root} justify="center">
            <Grid item xs={12} sm={8} md={5} component={Paper} square style={{backgroundImage:"url(../images/forgot-bg.png)", backgroundSize:'contain', backgroundRepeat:'no-repeat'}}>
                <div className={classes.paper} >
                 
                    <Link color="inherit" href="/login">
                        <img src="/images/logo.png" style={{ height: '140px' }} alt="" className={classes.logoImg} />
                    </Link>
                    <Typography component="h1" variant="h5" style={{ marginTop: '20px', paddingBottom:20 }}>
                        Forgot your password?
                    </Typography>
                    <Divider variant="middle" style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }} />
                    <Typography variant="body2">
                        Please insert your email in the input below and we will send an email with the link to reset your password.
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); forgotPassword() }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                            autoComplete="email"
                            autoFocus
                            error={inputErrors.email ? true : false}
                        />
                        {inputErrors.email &&
                            <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{inputErrors.email}</Typography>
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {t('button.reset')}
                        </Button>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));