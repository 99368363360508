import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Paper, Collapse, Toolbar, Tooltip, Icon, IconButton, FormControl, Input, InputAdornment, makeStyles, withStyles, useTheme, Box, fade } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import { AddBoxOutlined, ClearOutlined, IndeterminateCheckBoxOutlined, PersonOutline } from '@material-ui/icons';
import { useSpring, animated } from 'react-spring';

import useNotificationLoading from '../helper/useNotificationLoading';
import { getUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import { useHistory } from 'react-router-dom';

function TableToolbar(props) {
    const [value, setValue] = useState(props.val);

    const classes = useStyles();
    const { t } = useTranslation();
    
    const searchInputRef = useRef(null);

    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };
  
    const toolbarSearch = (
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('genealogy.searchUsername')}
                        startAdornment={
                            <InputAdornment position="start">
                                <Tooltip title={t('table.search')}>
                                    <Icon fontSize="small" onClick={props.handleSubmit}>search</Icon>
                                </Tooltip>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip title={t('table.clear')}>
                                    <IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search">
                                        <ClearOutlined fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>
    );

    return [value, toolbarSearch];
}

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });
  
    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

export default function Uplines() {
    const [uplineData, setUplineData] = useState([]);
    const [username, setUsername] = useState("");

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [searchValue, searchToolbar] = TableToolbar({ handleSubmit, val: username });
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();
    
    const query = useQuery();
    const usernameQuery = query.get("username") || "";

    const isMountedRef = useRef(null);

    useEffect(() => {
        setUsername(usernameQuery);
    }, [usernameQuery])

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`admin/genealogy/upline`, { username }).then(response => {
            let {status, message, error, data} = response;
            if (isMountedRef.current) {
                if (status == 1) {
                    setUplineData(data.tree_data);
                    setLoading(false);
                }
                else if (error) {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                        message += "\n " + value[0];
                        })
                    }
                    setLoading(false);
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
    
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [username]);

    function handleSubmit(event) {
        event.preventDefault();
        setUsername(searchValue);
        history.push(`/upline?username=${searchValue}`);
    }

    const renderTree = (nodes) => (
        <StyledTreeItem key={nodes.id} nodeId={`${nodes.id}`} label={nodes.username} endIcon={<PersonOutline color={"primary"} />}>
            { _.isArray(nodes.children) ? 
                nodes.children.map((node) => renderTree(node)) 
            : null }
        </StyledTreeItem>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1">{t('upline.memberUpline')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }} >
                    {searchToolbar}
                    { _.size(uplineData) > 0 ?
                        <TreeView
                            className={classes.root}
                            defaultExpanded={['6']}
                            defaultCollapseIcon={<IndeterminateCheckBoxOutlined color="primary" />}
                            defaultExpandIcon={<AddBoxOutlined color="primary" />}
                        >
                            {renderTree(uplineData)}
                        </TreeView>
                        : <Box className={classes.msgBox}>
                            <Typography variant="body1">{t('sponsor.noUpline')}</Typography>
                        </Box>
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}

const StyledTreeItem = withStyles((theme) => ({
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
    label: {
      fontSize: 17,
    },
    iconContainer: {
      fontSize: 20,
    }
  }))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);
  

const useStyles = makeStyles(theme => ({
    root: {
        height: 'auto',
        flexGrow: 1,
        maxWidth: 400,
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        minHeight: 440,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse',
    },
    msgBox: {
        textAlign: 'center',
        paddingBottom: 150,
        paddingTop: 150,
    },
}))