import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import _ from 'lodash';

import { Grid, Breadcrumbs, Typography, Link, TextField, Tooltip, IconButton, MenuItem, makeStyles, Button, useTheme, Table, TableContainer, TableRow, TableCell, TableHead, TableBody, Box } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { AttachFileOutlined, VisibilityOutlined } from '@material-ui/icons';

import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

const UPLOAD_FIELDS = [
    'sku', 
    'category', 
    'brand',
    'title_en',
    'title_cn',
    'product_type',
    'quantity',
    'sell_price',
    'cost_price',
    'retail_price',
    'VP_amount_type',
    'VP_amount',
    'description_en',
    'description_cn',
    'cashback_rate',
    'purchase_limit',
    'shipping_channel',
    'cover_shipping',
    'prepare_time',
    'west_shipping_fee',
    'east_shipping_fee',
    'pack_weight',
    'pack_height',
    'pack_length',
    'pack_width',
    'variant_title_en',
    'variant_title_cn',
    'variant_sku',
    'variant_sell_price',
    'variant_quantity',
    'variant_pack_weight',
    'variant_pack_height',
    'variant_pack_length',
    'variant_pack_width',
]

export default function ProductBulkUpload() {
    const [showPreview, setShowPreview] = useState(false);
    const [uploadData, setUploadData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [uploadMerchantId, setUploadMerchantId] = useState();

    const { isAdmin } = useSelector(state => state.general);
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { setLoading, addAlert } = useNotificationLoading();

    const isMountedRef = useRef();

    useEffect(() => {
        isMountedRef.current = true;
        if(isAdmin) {
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                    if(_.size(result.data) > 0) {
                        setUploadMerchantId(result.data[0].id);
                    }
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [isAdmin])

    useEffect(() => {
        // console.log(uploadData);
        let newTableData = [];
        if(_.size(uploadData) > 0) {
            const chunksData = _.chunk(uploadData, 34);
            console.log("chunksData", chunksData);
            _.map(chunksData, (item, chunkIndex) => {
                if(chunkIndex !== 0) {
                    if(item[0] !== '') {
                        let newData = {};
                        _.map(UPLOAD_FIELDS, (field, index) => {
                            newData[field] = item[index]
                        });
                        newTableData.push(newData);
                    }
                }
            })
        }

        if(_.size(newTableData) > 0) {
            setTableData(newTableData);
            setShowPreview(true);
            renderColumn();
        }
        // eslint-disable-next-line
    }, [uploadData]);

    const handleFiles = (e) => {
        const files = e.target.files;
        let file = "";
        if(files && files[0]){
            file = files[0]
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (evt) => { // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                let data = XLSX.utils.sheet_to_csv(ws, {header:1});
                /* replace line break with , */
                data = data.replace(/(?:\r\n|\r|\n)/g, ',');
                // data = data.replaceAll('"',"");
                // console.log("data2", splitCSV(data));
                data = splitCSV(data);
                /* convert to array */
                // data = data.split(",")
                /* remove empty values */
                // data = _.compact(data);
                setUploadData(data);
            };
            if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        }
    };

    const submitProductList = () => {
        if(_.size(tableData)) {
            setLoading(true);

            let postData = {};
            _.map(UPLOAD_FIELDS, field => {
                postData[field] = [];
            });

            if(isAdmin) {
                postData['merchant_company_id'] = [];
            }

            _.map(tableData, item => {
                _.map(UPLOAD_FIELDS, field => {
                    postData[field].push(item[field] ? item[field] : null);
                });
                if(isAdmin) {
                    postData['merchant_company_id'].push(uploadMerchantId);
                }
            })

            console.log("postData", postData)
            postUrl(`admin/products-bulk-upload`, postData).then(response => {
                setLoading(false);
                let { status, message, error } = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                    setShowPreview(false);
                    setTableData([]);
                    setUploadData([]);
                    history.push('/products');
                } else {
                    let newErrorField = {};
                    let displayErrorMsg = [];
                    _.map(error, (errorItem, errorIndex) => {
                        newErrorField[errorIndex] = errorItem[0]
                        displayErrorMsg.push(errorItem[0]);
                    })
                    addAlert(message + ' ' + _.map(displayErrorMsg).join(' '));
    
                    // setInputErrors({emails: [emailError]});
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
        }
    }

    const renderColumn = () => {
        let newColumn = [];
        _.map(UPLOAD_FIELDS, field => {
            newColumn = [ ...newColumn, { 'title': t(`product.uploadFields.${field}`), 'field': field } ]
        });

        return newColumn;
    }

    return (
        <Grid container spacing={3}>            
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('product.bulkUploadProduct')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/products`} component={RouterLink}>{t('product.products')}</Link>
                        <Typography style={{ color: 'black' }}>{t('product.bulkUploadProduct')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Link href="/downloads/Product_Upload_Excel.xlsx" download underline="none">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AttachFileOutlined />}
                    >{ t('product.downloadExcelTemplate') }</Button>
                </Link>
            </Grid>
            { isAdmin && uploadMerchantId ? 
                <Grid item xs={6}>
                    <Box style={{ display: 'flex', alignItems: 'center', minHeight:37 }}>
                        <Typography variant="body2" style={{color: "#808080"}}>{ t('product.shopName') }</Typography>
                    </Box>
                    <TextField
                        select
                        size="small"
                        // label={label}
                        value={uploadMerchantId}
                        onChange={({ target }) => setUploadMerchantId(target.value)}
                        variant="standard"
                        fullWidth
                        // error={error ? true : false}
                        // helperText={error}
                    >
                        {companies.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {`${option.shop_name} [ID: ${option.id}]`}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            : null }
            <Grid item xs={12}>
                <TextField 
                    variant="outlined" 
                    label={t('voucher.uploadFile')} 
                    onChange={handleFiles} 
                    type="file" 
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <Tooltip title={t('voucher.preview')} aria-label="preview">
                                <IconButton aria-label="preview upload" onClick={ () => setShowPreview(!showPreview) }>
                                    <VisibilityOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    }}
                    inputProps={{
                        accept: ".xlsx,.xlsm"
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                { showPreview && _.size(tableData) > 0 &&
                    <>
                        <MaterialTable
                            title=""
                            columns={renderColumn()}
                            data={tableData}
                            components={{
                                Toolbar: props => (
                                    <>
                                        <div className={ classes.toolbarSubmit }>
                                            <Button variant="contained" color="primary" onClick={submitProductList}>{t('button.submit')}</Button>
                                        </div>
                                        <MTableToolbar {...props} />
                                    </>
                                )
                            }}
                            // detailPanel={rowData => {
                            //     return (
                            //         <TableContainer>
                            //             <Table>
                            //                 <TableHead>
                            //                     <TableRow>
                            //                         <TableCell>Dessert (100g serving)</TableCell>
                            //                         <TableCell align="right">Calories</TableCell>
                            //                         <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            //                         <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            //                         <TableCell align="right">Protein&nbsp;(g)</TableCell>
                            //                     </TableRow>
                            //                 </TableHead>
                            //                 <TableBody>
                            //                     <TableRow>
                            //                         <TableCell>Dessert (100g serving)</TableCell>
                            //                         <TableCell align="right">Calories</TableCell>
                            //                         <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            //                         <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            //                         <TableCell align="right">Protein&nbsp;(g)</TableCell>
                            //                     </TableRow>
                            //                 </TableBody>
                            //             </Table>  
                            //         </TableContainer>
                            //     )
                            // }}
                            options={{
                                pageSize: 5,
                            }}
                            style={{ ...theme.box1, ...theme.p20, marginTop: 10 }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: t('table.labelDisplayedRows'),
                                    labelRowsSelect: t('table.rows'),
                                    firstTooltip: t('table.firstPage'),
                                    previousTooltip: t('table.previousPage'),
                                    nextTooltip: t('table.nextPage'),
                                    lastTooltip: t('table.lastPage'),
                                },
                                toolbar: {
                                    searchTooltip: t('table.search'),
                                    searchPlaceholder: t('table.search'),
                                    nRowsSelected: t('table.rowsSelected')
                                },
                                body: {
                                    emptyDataSourceMessage: t('table.noRecord'),
                                }
                            }}
                        />
                    </>
                }
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    toolbarSubmit: { 
        width: '100%', 
        display: 'flex', 
        flexDirection: 'row-reverse', 
        padding: 5 
    }
}));

function splitCSV(text) {
    var matches = text.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
    for (var n = 0; n < matches.length; ++n) {
        matches[n] = matches[n].trim();
        if (matches[n] == ',') matches[n] = '';
    }
    if (text[0] == ',') matches.unshift("");
    return matches;
}