import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Grid, Paper, Input, 
    InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, 
    IconButton, Icon, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, TableFooter,TextField,Button
} from '@material-ui/core';

import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import FilterListIcon from '@material-ui/icons/FilterList';
import RestoreIcon from '@material-ui/icons/Restore';

import * as XLSX from 'xlsx';

// function TableToolbar(props) {
//     const classes = useStyles();
//     const { t } = useTranslation();
//     const [value, setValue] = useState("");
//     const searchInputRef = useRef(null);
//     const handleClear = () => {
//         setValue("");
//         searchInputRef.current.focus();
//     };
//     const exportFile = () => {
//         let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
//         XLSX.writeFile(wb, `ReportBonusSummary${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
//     }

//     const toolbarSearch =
//         <Toolbar className={classes.toolbarRoot}>
//             <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="small" /></IconButton></Tooltip>
//             <form noValidate onSubmit={props.handleSubmit}>
//                 <FormControl>
//                     <Input
//                         id="search-input"
//                         placeholder={t('table.search')}
//                         startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
//                         endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearOutlinedIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
//                         aria-describedby="search"
//                         inputProps={{
//                             'aria-label': 'search',
//                         }}
//                         type="date"
//                         inputRef={searchInputRef}
//                         value={value}
//                         onChange={e => setValue(e.target.value)}
//                     />
//                 </FormControl>
//             </form>
            
//         </Toolbar>;
//     return [value, toolbarSearch];
// }

export default function ReportBonusSummary() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);

    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    // const [searchValue, searchToolbar] = TableToolbar({handleSubmit: callApi});
    //hardcoded sales amount wallet to wallet id 3 & 7 (AP and VPT)
    const [walletList,setwalletList] = useState(['3','7']);

    function callApi(){
        let params = { 'sdate': dateFilter.sdate,'edate':dateFilter.edate };
        setTableLoading(true);
        getUrl('admin/commissions/month/summary2', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setData(result.data);
                    setDates(result.data.dates);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const [dateFilter, setDateFilter] = useState({ sdate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }), edate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })});
    const [reset, setReset]=useState(false);
    const exportFile = () => {
        let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
        XLSX.writeFile(wb, `ReportBonusSummary_${Date.now()}.xlsx`);
    }
    const handleChangeSDateRange=(data)=>{
        setDateFilter({...dateFilter,sdate:data});
    }
    const handleChangeEDateRange=(value)=>{
        if(dateFilter.sdate){
            setDateFilter({...dateFilter,edate:value});
        }else{
            addAlert('',t('reports.bvOrder.insertEdateErr'), 'error', '');
        }
    }
    const [value, setValue] = useState("");
    const handleReset = ()=>{
        setDateFilter({...dateFilter, sdate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),edate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })});
        setReset(true);
    }
    const handleFilter = () => {
        setTableLoading(true);

        if(dateFilter.sdate == ''){
            setTableLoading(false);
            addAlert('',t('reports.bvOrder.insertEdateErr'), 'error', '');
        }
        else{
            callApi();
        }  
    }
    useEffect(()=>{
        if(reset ==true){
            callApi();
        }
    },[reset]);


    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.bonusSummary.title')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item>
                <TextField
                    id="datetime-local"
                    label={t('reports.bvOrder.from')}
                    type="date"
                    value={dateFilter.sdate}
                    onChange={({ target }) => handleChangeSDateRange(target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                />
                <TextField
                    id="datetime-local"
                    label={t('reports.bvOrder.to')}
                    type="date"
                    value={dateFilter.edate}
                    onChange={({ target }) => handleChangeEDateRange(target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                />
                <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="medium" /></IconButton></Tooltip>
                </Grid>
                <Grid item>
                    <Button size="medium" variant="outlined" color="inherit" onClick={() => handleReset()} startIcon={<RestoreIcon />}>{t('button.reset')}</Button>
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleFilter()} startIcon={<FilterListIcon />} style={{marginLeft:10}}>{t('button.filter')}</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {/* {searchToolbar} */}
                    
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            id="exportTable"
                        >
                            <TableHead>
                                {data.length !== 0 &&
                                <>
                                    <TableRow>
                                        <TableCell align="center" rowSpan="2">{t('reports.bonusSummary.date')}</TableCell>
                                        <TableCell align="center" colSpan="2">{t('reports.bonusSummary.salesSummary')}</TableCell>
                                        <TableCell align="center" rowSpan="2">{t('reports.bonusSummary.bv')}</TableCell>
                                        
                                        {
                                            data && _.size(data.bonus_type) ? 
                                            _.map(data.bonus_type, (bonus_type, index) => {
                                                let bonusName = _.split(bonus_type.name, '|');
                                                let colspan = _.size(bonus_type.wallet_array);
                                                return (<TableCell key={index} colSpan={colspan} align="center">{i18n.language === "cn" && bonusName[1] ? bonusName[1] : bonusName[0]}</TableCell>)
                                            })
                                        : null
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            _.map(walletList, (wallet, index) => {
                                                let walletName = _.split(data.wallets[wallet]?.["name"], '|');
                                                return (<TableCell key={index} align="right">{i18n.language === "cn" && walletName[1] ? walletName[1] : walletName[0]}</TableCell>)
                                            })
                                        }
                                        {
                                            data && _.size(data.bonus_type) ?
                                            _.map(data.bonus_type, (bonus_type) => (
                                                _.map(bonus_type.wallet_array, (wallet, index) => {
                                                    let walletName = _.split(data.wallets[wallet.id]?.["name"], '|');
                                                    return (<TableCell key={index} align="right">{i18n.language === "cn" && walletName[1] ? walletName[1] : walletName[0]}</TableCell>)
                                                })
                                            ))
                                        : null
                                        }
                                    </TableRow>
                                </>
                                }
                            </TableHead>
                            <TableBody>
                                {data.length !== 0 && _.map(dates, (row, date) => {
                                    return(
                                        <TableRow key={date}>
                                            <TableCell align="center">{date}</TableCell>
                                            {
                                                _.map(walletList, (wallet, index) => {
                                                    return(
                                                        <TableCell key={'sales'+date+index} align="right">{ parseFloat((date in data.sales)?
                                                            (wallet in data.sales[date])?
                                                                data.sales[date][wallet]:0
                                                            :0
                                                        ).toFixed(2)}</TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell key={'sales'+date+'bv'} align="right">
                                                { parseFloat((date in data.sales)?
                                                            ('bv' in data.sales[date])?
                                                                data.sales[date]['bv']:0
                                                            :0
                                                        ).toFixed(2)}
                                            </TableCell>
                                            {
                                                data && _.size(data.bonus_type) ? 
                                                _.map(data.bonus_type, (bonus_type, index) => (
                                                    _.map(bonus_type.wallet_array, (wallet, index) => {
                                                        if(date in data.list)
                                                            return (
                                                                data.list[date][bonus_type.id]? 
                                                                <TableCell key={index} align="right">
                                                                    {parseFloat(data.list[date][bonus_type.id][wallet.id]).toFixed(data.wallets[wallet.id]["decimal"])}
                                                                </TableCell>
                                                                :
                                                                <TableCell key={index} align="right">{(0).toFixed(data.wallets[wallet.id]["decimal"])}</TableCell>
                                                            )
                                                        else
                                                            return (<TableCell key={index} align="right">{(0).toFixed(data.wallets[wallet.id]["decimal"])}</TableCell>)
                                                    })
                                                ))
                                            : null
                                            }
                                        </TableRow>
                                    );
                                })}
                                {data.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            { _.size(data) && (_.size(data['total_comm']) || _.size(data['total_sales'])) ? 
                                <TableFooter>
                                    <TableRow>
                                        <TableCell align="center" className={classes.footerRow}>{t('reports.bonusSummary.total')}</TableCell>
                                        <TableCell align="right" className={classes.footerRow}>{parseFloat(data['total_sales'][3]?data['total_sales'][3]:0).toFixed(2)}</TableCell>
                                        <TableCell align="right" className={classes.footerRow}>{parseFloat(data['total_sales'][7]?data['total_sales'][7]:0).toFixed(2)}</TableCell>
                                        <TableCell align="right" className={classes.footerRow}>{parseFloat(data['total_sales']['bv']?data['total_sales']['bv']:0).toFixed(2)}</TableCell>
                                        {
                                            data && _.size(data.bonus_type) ? 
                                            _.map(data.bonus_type, (bonus_type, index) => (
                                                _.map(bonus_type.wallet_array, (wallet, index) => {
                                                    return (
                                                        data['total_comm'][bonus_type.id] ? 
                                                        <TableCell key={index} align="right" className={classes.footerRow}>{parseFloat(data['total_comm'][bonus_type.id][wallet.id]).toFixed(data.wallets[wallet.id]["decimal"])}</TableCell>
                                                        :
                                                        <TableCell key={index} align="right" className={classes.footerRow}>{(0).toFixed(data.wallets[wallet.id]["decimal"])}</TableCell>
                                                    )
                                                })
                                            ))
                                        : null
                                        }
                                    </TableRow>
                                </TableFooter>
                            : null }
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    footerRow:{
        fontWeight: 900,
        fontSize: 14,
        color: "black"
    }
}));