import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useNotificationLoading from '../helper/useNotificationLoading';

import _ from 'lodash';
import { getUrl } from '../helper/ApiAction';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, Paper, LinearProgress} from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LensIcon from '@material-ui/icons/Lens';

export default function Currency() {
    const classes = useStyles();
    const theme = useTheme();
    const { t,i18n } = useTranslation();
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);
    const { addAlert } = useNotificationLoading();
    

    const[currenct_list,setCurrencyList] = useState([])
    useEffect(() => {
        setTableLoading(true);
        getUrl(`admin/get_currency_list`).then(result => {
            setTableLoading(false);
            if(result.status == 1){
                setCurrencyList(result.currency_display);
            }
        }).catch(error => {
            setTableLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1"> {t('currency.title')}</Typography>
                    {_.includes(permissions, "currency_add") ?
                        <Link underline='none' color="inherit" to="/currency/add" component={RouterLink}>
                            <Button size="medium"  variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                    :null}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    {_.includes(permissions, "currency_edit") ?
                                    <TableCell>{t('parameter.action')}</TableCell>
                                    :null}
                                    <TableCell>{t('currency.name')}</TableCell>
                                    <TableCell>{t('currency.code')}</TableCell>
                                    <TableCell>{t('currency.value')}</TableCell>
                                    <TableCell>{t('currency.status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currenct_list.map((row) => {
                                    return (
                                        <TableRow key={row.id}>
                                             {_.includes(permissions, "currency_edit") ?
                                            <TableCell><Link underline='none' to={`/currency/${row.id}`} component={RouterLink}><EditIcon /></Link></TableCell>:null}
                                            <TableCell component="th" scope="row">{i18n.language =='cn'?row.display_name_cn:row.display_name_en}</TableCell>
                                            <TableCell>{row.code}</TableCell>
                                            <TableCell>{row.value}</TableCell>
                                            <TableCell style={{ textAlign: 'left' }}><LensIcon style={{ color: row.status === 1 ? 'green' : 'red' }} /></TableCell>
                                        </TableRow>
                                    );
                                })}
                                {currenct_list.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={6} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));