import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import { Editor } from '@tinymce/tinymce-react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, TextField, Button, Grid, FormControl, FormHelperText, GridList, GridListTile, GridListTileBar, Modal, MenuItem, Radio, RadioGroup, FormControlLabel, Checkbox } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';

import { DropzoneArea } from 'material-ui-dropzone';

import ProductPointSection from './ProductPointSection';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css'

import Divider from '@material-ui/core/Divider';

function useInput({ type, label, val, placeholder, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} placeholder={placeholder} />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, input, setErrorValue];
}

function useCheckbox({ label, val, note='' }) {
    const [value, setValue] = useState(val ? true : false);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

// function useSelect({ data, label, val }) {
//     const classes = useStyles();
//     const selectedData = data[data.findIndex(item => item.id === val)]
//     const [value, setValue] = useState(selectedData);
//     const [error, setErrorValue] = useState("");
//     const select =
//         <Grid item xs={12} sm={6}>
//             <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
//                 <Autocomplete
//                     onChange={(event, newValue) => { setValue(newValue); }}
//                     value={selectedData}
//                     // defaultValue={[]}
//                     size="small"
//                     options={data}
//                     classes={{ option: classes.option }}
//                     autoHighlight
//                     getOptionSelected={(option, value) => option.id === value.id}
//                     getOptionLabel={option => option.cat_name}
//                     renderOption={(option) => (
//                         <>{option.cat_name}</>
//                     )}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label={label}
//                             fullWidth
//                             variant="outlined"
//                             inputProps={{
//                                 ...params.inputProps,
//                                 autoComplete: 'new-password', // disable autocomplete and autofill
//                             }}
//                             error={error ? true : false}
//                         />
//                     )}
//                 />
//                 {error ?
//                     <FormHelperText>{error}</FormHelperText>
//                     : null
//                 }
//             </FormControl>
//         </Grid>;
//     return [value, select, setErrorValue];
// }

function useCompanySelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {`${option.shop_name} [ID: ${option.id}]`}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useRefundSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useWalletSelect({ data, label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => {
                    let name = _.split(option.name, '|');
                    return(
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })}
            </TextField>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useEditor({ label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
                <Typography variant="subtitle2" component="label">{label}</Typography>
                <Editor
                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                    initialValue={value}
                    init={{
                        height: "70vh",
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount quickbars'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                    }}
                    onEditorChange={(content, editor) => setValue(content)}
                />
                {error ?
                    <FormHelperText>{error}</FormHelperText>
                    : null
                }
            </FormControl>
        </Grid>;
    return [value, input, setErrorValue, error];
}

export default function EventProductEditGeneral({ data, companies, setLoading, addAlert, setData, pointList, pointData, setPointData, categoryTreeData, setCategoryTreeData, eventData, setEventData, eventIdList, wallets, ranks, isMountedRef }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { permissions } = useSelector(state => state.general);

    const refundType = [{code: "normal", label: t('order.refundType.normal')}, {code: "special", label: t('order.refundType.special')}, {code: "no-refund", label: t('order.refundType.noRefund')}];
    const interestType = [{code: "percent", label: t('order.interestType.percent')}, {code: "value", label: t('order.interestType.value')}];

    const [title, titleInput, setNameError] = useInput({ type: "text", label: `${t('product.productName')}*`, val: data.title, placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sku, skuInput, setSkuError] = useInput({ type: "text", label: `${t('product.sku')}`, val: data.sku || "" });
    const [goods_brand, brandInput, setBrandError] = useInput({ type: "text", label: t('product.brand'), val: data.goods_brand || "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: t('product.slotPrice'), val: data.sell_price });
    const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.originalPrice'), val: data.cost_price });
    const [retail_price, retailPriceInput, setRetailPriceError] = useInput({ type: "number", label: t('product.offerPrice'), val: data.retail_price });
    // const [category, categorySelect, setCategoryError] = useSelect({ data: categories, label: `${t('product.category')}*`, val: data.category_id });
    const [merchant_company_id, companySelect, setCompanyError] = useCompanySelect({ data: companies, label: `${t('product.shopName')}*`, val: data.merchant_company_id });
    const [descr_en, descrEnInput, setDescrEnError, descrEnError] = useEditor({ label: t('product.descEn'), val: data.descr_en });
    const [descr_cn, descrCnInput, setDescrCnError, descrCnError] = useEditor({ label: t('product.descCn'), val: data.descr_cn });
    const [quantity, quantityInput, setQuantityError] = useInput({ type: "number", label: t('product.quantity'), val: data.quantity });
    const [shipping_fee, shippingFeeInput, setShippingFeeError] = useInput({ type: "number", label: t('product.shippingFee'), val: data.shipping_fee });
    const [ship_weight, weightInput, setWeightError] = useInput({ type: "number", label: `${t('product.weightKg')}*`, val: data.ship_weight || "" });
    const [package_length, lengthInput, setLengthError] = useInput({ type: "number", label: t('product.lengthCm'), val: data.package_length || "" });
    const [package_width, widthInput, setWidthError] = useInput({ type: "number", label: t('product.widthCm'), val: data.package_width || ""  });
    const [package_height, heightInput, setHeightError] = useInput({ type: "number", label: t('product.heightCm'), val: data.package_height || ""  });
    const [prepare_time, prepareTimeInput, setPrepareTimeError] = useInput({ type: "number", label: `${ t('product.prepareTime')}*`, val: data.prepare_time || "", note: t('product.prepareTimeNote') });
    const [purchase_limit, purchaseLimitInput, setPurchaseLimitError] = useInput({ type: "number", label: `${ t('product.purchaseLimit')}*`, val: data.purchase_limit, note: t('product.purchaseLimitNote') });
    const [bonus, bonusInput, setBonusError] = useCheckbox({ label: `${ t('product.bonus')}`, val: data.bonus ? true : false, note: t('product.bonusNote') });
    const [bv, bvInput, setBvError] = useInput({ type: "number", label: t('product.bv'), val: data.bv || "" });
    const [auto_create_event, autoCreateEventInput, setAutoCreateEventError] = useCheckbox({ label: t('product.autoCreateEvent'), val: data.auto_create_event, note: t('product.autoCreateEventNote') });
    const [winner_quota, winnerQuotaInput, setWinnerQuotaError] = useInput({ type: "number", label: t('luckydraw.winnerQuota'), val: eventData?eventData.winner_quota:"" });
    const [total_slot, totalSlotInput, setTotalSlotError] = useInput({ type: "number", label: t('luckydraw.totalSlot'), val: eventData?eventData.total_slot:"", note: t('luckydraw.totalSlotNote') });
    const [refund_type, refundTypeInput, setRefundTypeError] = useRefundSelect({ data: refundType, label: t('order.refundType.title'), val: eventData?eventData.refund_type:"" });
    const [wallet_id, walletIdInput, setWalletIdError] = useWalletSelect({ data: wallets, label: t('wallet.walletType'), val: eventData?eventData.wallet_id:"" });
    const [interest_type, interestTypeInput, setInterestTypeError] = useRefundSelect({ data: interestType, label: t('order.interestType.title'), val: eventData?eventData.interest_type:"" });
    const [interest_value, interestValueInput, setInterestValueError] = useInput({ type: "number", label: interest_type==='percent'?t('order.interestRate'):t('order.interestValue'), val: eventData?eventData.interest_value:"" });
    const [rank_id, rankIdInput, setRankIdError] = useWalletSelect({ data: ranks, label: t('product.relatedRank'), val: data.rank_id, note: t('product.relatedRankNote') });

    const [imagesArray, setImagesArray] = useState(data.images_array);
    const [newImages, setNewImages] = useState([]);
    const [newImagesError, setNewImagesError] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [newVideo, setNewVideo] = useState(null);
    const [newEmbedVideo, setNewEmbedVideo] = useState(data.embed_video ? data.embed_video : "");
    const [newVideoError, setNewVideoError] = useState("");

    const [shipping_channel, setShippingChannel] = useState(data.shipping_channel);

    const validate = { title: setNameError, sku: setSkuError, goods_brand: setBrandError, sell_price: setSellingPriceError, cost_price: setCostPriceError,
        retail_price: setRetailPriceError, category_id: setCategoryError, descr_en: setDescrEnError, descr_cn: setDescrCnError, quantity: setQuantityError,
        new_images: setNewImagesError, merchant_company_id: setCompanyError, shipping_fee: setShippingFeeError, ship_weight: setWeightError, package_length: setLengthError,
        package_width: setWidthError, package_height: setHeightError, prepare_time: setPrepareTimeError, purchase_limit: setPurchaseLimitError, new_video: setNewVideoError, embed_video: setNewVideoError,
        bonus: setBonusError, bv: setBvError, auto_create_event: setAutoCreateEventError, winner_quota: setWinnerQuotaError, total_slot: setTotalSlotError, refund_type: setRefundTypeError, 
        wallet_id: setWalletIdError, interest_type: setInterestTypeError, interest_value: setInterestValueError, rank_id: setRankIdError };

    let deleteImage = (item) => {
        setImagesArray(_.difference(imagesArray, [item]));
    }
    const [openModal, setOpenModal] = useState("");

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        })
    };

    useEffect(() => {
        let tempPointData = [];
        _.map((data.points), (data, key) => {
            let visible = false;
            _.map((pointList), (point) => {
                if(parseInt(point.id) === data.point_id){
                    visible = true;
                }
            });
            if(visible){
                tempPointData[key] = {id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: false}
            }
        });
        let filteredArray = tempPointData.filter(item => item !== null);
        setPointData(filteredArray);
        setImagesArray(data.images_array);
        setCategoryId(data.category_id);
    }, [data, pointList, setPointData, eventData]);

    const categoryOnChange = (currentNode, selectedNodes) => {
        if(_.size(selectedNodes)>0){
            setCategoryId(currentNode.value);
            let temp = loopTreeData(categoryTreeData, currentNode.value);
            setCategoryTreeData(temp);
        }else{
            setCategoryId("");
            let temp = loopTreeData(categoryTreeData, "");
            setCategoryTreeData(temp);
        }
    }
    const loopTreeData = (treeData, parentId) => {
        let temp = treeData;
        _.map(treeData, (data, key) => {
            if(data.value === parentId){
                temp[key].checked = true;
            }else{
                temp[key].checked = false;
            }
            if(data.children){
                temp[key].children = loopTreeData(data.children, parentId);
            }
        })
        return temp;
    }
    const categoryTreeSelect = () => {
        return (
        <Grid item xs={12} sm={6}>
            <DropdownTreeSelect 
                data={categoryTreeData}
                mode="radioSelect"
                keepTreeOnSearch
                keepChildrenOnSearch
                texts={{ placeholder: t('table.search')+ ' ' +t('product.category') }}
                onChange={categoryOnChange}
            />
            {
                categoryError
                ?
                <Typography variant="caption" style={{color: "red"}}>
                    {categoryError}
                </Typography>
                :null
            }
        </Grid>
        )
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    let handleSubmit = async () => {
        setLoading(true);
        resetAllFieldError();
        let newImagesBase64 = [];

        if(_.size(newImages) > 0){            
            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }

        let newVideoBase64 = "";
        if(_.size(newVideo) > 0){
            newVideoBase64 = await toBase64(newVideo[0]);
        }

        let apiData = {
            title,
            category_id: categoryId,
            // category_id: category.id,
            merchant_company_id,
            goods_brand,
            sell_price,
            cost_price,
            retail_price,
            quantity,
            descr_en,
            descr_cn,
            images: imagesArray,
            new_images: newImagesBase64,
            shipping_channel,
            shipping_fee,
            ship_weight,
            package_length,
            package_width,
            package_height,
            prepare_time,
            purchase_limit,
            sku,
            point_data: pointData,
            new_video: newVideoBase64,
            embed_video: newEmbedVideo,
            bonus: bonus ? 1 : 0,
            bv,
            auto_create_event: auto_create_event ? 1 : 0,
            winner_quota,
            total_slot,
            refund_type,
            wallet_id,
            interest_type,
            interest_value,
            rank_id
        }

        putUrl(`admin/products/${data.id}`, apiData)
        .then((response) => {
            setLoading(false);
            let {status, message, error, data:newData, event_data:eventData} = response;
            if(status === 1){
                addAlert('', message, 'success', '');
                setData(newData);
                setEventData(eventData);
                setNewEmbedVideo(newData.embed_video ? newData.embed_video : "");
            }else{                
                if(_.size(error) > 0){
                    _.map(error, (value, key) => {
                        if(validate[key])
                            validate[key](value[0]);
                        else
                            message += "\n "+value[0];
                    })
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid container spacing={3} style={theme.p20}>
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.aboutProduct')}</Typography>

            </Grid>
            <Grid item xs={12} container spacing={3}>
                {titleInput}
                {_.size(companies) ? companySelect : null}
                {/* {categorySelect} */}
                {categoryTreeSelect()}
                {skuInput}
                {brandInput}
                {sellingPriceInput}
                {_.size(data.options[0]) === 0 ? quantityInput : null}
                {costPriceInput}
                {retailPriceInput}
                {weightInput}
                {lengthInput}
                {widthInput}
                {heightInput}
                {prepareTimeInput}
                {purchaseLimitInput}
                {_.includes(permissions, "product_bonus") ? bvInput : null}
                {_.includes(permissions, "product_bonus") ? bonusInput : null}
                {_.includes(permissions, "rank") ? rankIdInput : null}                                                                  
                {(_.includes(permissions, "event") && eventIdList.includes(String(categoryId))) ? 
                    <>
                        {autoCreateEventInput}
                        {winnerQuotaInput}
                        {totalSlotInput}
                        {refundTypeInput}
                        {
                            refund_type === 'special'
                            ?
                            <>
                            {walletIdInput}
                            {interestTypeInput}
                            {interestValueInput}
                            </>
                            : null
                        }
                    </>
                    : null
                }
            </Grid>
            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.productDescription')}</Typography>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_en")}>{t('product.english')}</Button>
                    {descrEnError ?
                        <FormHelperText style={{ color: "red" }}>{descrEnError}</FormHelperText>
                        : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_cn")}>{t('product.mandarin')}</Button>
                    {descrCnError ?
                        <FormHelperText style={{ color: "red" }}>{descrCnError}</FormHelperText>
                        : null
                    }
                </Grid>
            </Grid>
            <Modal
                open={openModal ? true : false}
                onClose={() => setOpenModal("")}
                aria-labelledby="product description"
                aria-describedby="product description modal"
            >
                <Grid container spacing={3} justify="center" className={classes.modal}>
                    { openModal === "descr_cn" ? descrCnInput : descrEnInput }
                    <Grid item xs={12} sm={6}>
                        <Button type="button" fullWidth variant="contained" color="primary" onClick={() => setOpenModal("")}>{t('button.close')}</Button>
                    </Grid>
                </Grid>
            </Modal>
            {data.shipping_channel === "source" ? null :
                <>
                    <Grid item xs={12} style={theme.mt30}>
                        <Typography variant="h5" style={theme.textline} component="h1">{t('product.shippingChannel')}</Typography>
                        {/* <Divider /> */}
                    </Grid>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                {/* <FormLabel component="legend">{t('product.shippingChannel')}</FormLabel> */}
                                <RadioGroup aria-label="shipping channel" name="shippingChannel" value={shipping_channel} onChange={(event) => { setShippingChannel(event.target.value) }}>
                                    <FormControlLabel value="self" control={<Radio />} label={t('product.selfShipping')} />
                                    <FormControlLabel value="mall" control={<Radio />} label={t('product.mallShipping')} />
                                </RadioGroup>
                            </FormControl>
                            {shipping_channel === "self" ? shippingFeeInput : null}
                        </Grid>
                    </Grid>
                </>
            }
            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.images')}</Typography>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12} container className={classes.imageRoot} >
                <GridList className={classes.gridList} cols={2.5} style={{height:"unset"}}>
                    {_.map(imagesArray, (item, itemIndex) => {
                        return(
                            <GridListTile key={itemIndex} style={{height:"unset", width:"auto"}}>
                                <img src={item} alt="product" style={{width:"250px"}} />
                                <GridListTileBar
                                    title={`Image ${itemIndex+1}`}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <IconButton onClick={() => deleteImage(item)}>
                                            <DeleteOutlineIcon className={classes.title} />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        )
                    })}
                </GridList>
            </Grid>
            <Grid item xs={12} style={{marginTop:"30px"}}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.uploadImages')}</Typography>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12} container className={classes.imageRoot}>
                <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={t('product.dragDropImageHere')}
                    onChange={(files) => isMountedRef.current ? setNewImages(files) : null}
                    filesLimit={10-_.size(imagesArray)}
                    dropzoneClass={classes.dropzone}
                    maxFileSize={2000000}
                />
                <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10-_.size(imagesArray)}) + t('general.fileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                { newImagesError ?
                    <FormHelperText style={{color:"red"}}>{newImagesError}</FormHelperText>
                    : null
                }
            </Grid>
            <Grid item xs={12} style={{marginTop:"30px"}}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.uploadVideo')}</Typography>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12} container>
                {data.video ?
                    <Grid item xs={6}>
                        <video width="100%" controls style={{ paddingRight: 15 }}>
                            <source src={data.video} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </Grid>
                    : null
                }
                {data.embed_video ?
                    <Grid item xs={6}>
                        <iframe width="100%" height="100%" src={data.embed_video} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </Grid>
                    : null
                }
                <Grid item xs={6}>
                    <DropzoneArea
                        acceptedFiles={['video/mp4']}
                        dropzoneText={t('product.dragDropVideoHere')}
                        onChange={(file) => setNewVideo(file)}
                        filesLimit={1}
                        dropzoneClass={classes.dropzone}
                        maxFileSize={5000000}
                    />
                    <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 1}) + t('general.fileSizeLimit', {limit: '5MB', fileType: 'MP4. '})}</Typography>
                    <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                        <Divider variant="middle" style={{ flex: 1}}/><Typography style={{ textTransform: "uppercase"}}>{t('product.or')}</Typography><Divider variant="middle" style={{ flex: 1}}/>
                    </Grid>
                    <TextField fullWidth size="small" label={t('product.embedVideoUrl')} variant="outlined" value={newEmbedVideo} onChange={e => setNewEmbedVideo(e.target.value)} type="text" placeholder={"e.g: https://www.youtube.com/embed/dQw4w9WgXcQ"} />
                    { newVideoError ?
                        <FormHelperText style={{color:"red"}}>{newVideoError}</FormHelperText>
                        : null
                    }
                </Grid>
            </Grid>
            {
                _.size(pointList) > 0
                ?
                <ProductPointSection pointList={pointList} pointData={pointData} setPointData={setPointData} />
                :
                null
            }
            <Grid container spacing={3} justify="flex-end">
                <Grid item xs={8} sm={6} md={4} style={{marginTop: 30}}>
                    <Button type="button" fullWidth variant="contained" color="primary" onClick={handleSubmit}>{t('button.update')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        flexGrow: 1
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    },

}));