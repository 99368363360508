import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import _ from 'lodash';
import { getUrl, putUrl } from '../helper/ApiAction';

import { TextField, Button, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ProductEditShippingRegion({ id, countryList, shippingRegions, setShippingRegions, stateGroup, stateList, companyId, setLoading, addAlert }) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [state, setState] = useState({
        type: 'allow',
        countries: [],
        states: []
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [availableStates, setAvailableStates] = useState([]);
    const [companyRegions, setCompanyRegions] = useState([]);

    useEffect(() => {
        if(_.size(shippingRegions)){
            let selectedData = [];
            
            _.map(shippingRegions.countries_array, (country_code, key) => {
                selectedData.push(countryList[countryList.findIndex(item => item.code === country_code)]);               
            });
            
            setState({ ...state, countries: selectedData, type: shippingRegions.type });
        }else if(_.size(companyRegions)){
            let selectedData = [];
            
            _.map(companyRegions.countries_array, (country_code, key) => {
                selectedData.push(countryList[countryList.findIndex(item => item.code === country_code)]);               
            });
            
            setState({ ...state, countries: selectedData, type: companyRegions.type });
        }
    }, [shippingRegions, companyRegions]);

    useEffect(() => {
        if(!_.size(shippingRegions) && companyId > 0){
            getUrl(`admin/companies/${companyId}/shipping_regions`).then(result => {
                if(result.status === 1) {
                    setCompanyRegions(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }
    }, [companyId]);

    useEffect(() => {
        let selectedStates = [];
        if(_.size(state.countries) > 0 && _.size(stateGroup)>0){
            let chosenCountriesArray = _.map(state.countries, 'code');
            let withStatesCountries = _.intersection(chosenCountriesArray, stateGroup);
            if(_.size(withStatesCountries)){
                let newStateList = [];
                _.map(stateList, (option, key) => {
                    if(chosenCountriesArray.includes(option.country_code)){
                        newStateList.push(option);
                    }
                });
                setAvailableStates(newStateList);

                _.map(withStatesCountries,(country_code) => {
                    if(_.size(shippingRegions)){     
                        if(_.size(shippingRegions.states_array[country_code])){
                            _.map(shippingRegions.states_array[country_code], (code, key) => {
                                selectedStates.push(newStateList[newStateList.findIndex(item => item.code === code && item.country_code === country_code)]);
                            });
                        }                   
                    }else if(_.size(companyRegions)){
                        if(_.size(companyRegions.states_array[country_code])){
                            _.map(companyRegions.states_array[country_code], (code, key) => {
                                selectedStates.push(newStateList[newStateList.findIndex(item => item.code === code && item.country_code === country_code)]);
                            });
                        }
                    }
                });
                setState({ ...state, states: selectedStates });
            }else{
                setAvailableStates([]);
            }
        }else{
            setAvailableStates([]);
        }
    }, [state.countries, stateGroup]);

    const selectAllCountries = () => {
        setState({ ...state, countries: countryList });
    }
    const selectAllStates = () => {
        setState({ ...state, states: availableStates });
    }    

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");

        let countries = [];
        let states = {};

        if(_.size(state.countries) > 0){
            countries = _.map(state.countries, 'code');

            if(_.size(state.states) > 0){
                _.map(state.states, (stateOption) => {
                    if(countries.includes(stateOption.country_code)){
                        if(!states[stateOption.country_code]){
                            states[stateOption.country_code] = [];
                        }
                        states[stateOption.country_code].push(stateOption.code);
                    }
                });
            }
        }

        const updateData = {
            type: state.type,
            countries: countries,
            states: states,
        }

        putUrl(`admin/products/${id}/shipping_regions`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                setShippingRegions({ ...shippingRegions, type: state.type, countries_array: countries, states_array: states });
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
            } else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if (response.errors) {
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    return (
        <Grid container spacing={3} direction="row" style={theme.p20}>
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.shippingRegion')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="type"
                    label={t('company.shippingAllowDisallow')}
                    variant="outlined"
                    fullWidth
                    select
                    value={state.type}
                    helperText={inputErrors.type ? inputErrors.type : ''}
                    error={inputErrors.type ? true : false}
                    onChange={({ target }) => setState({ ...state, type: target.value })}
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                >
                    <option key={'allow'} value='allow'>
                        {t('company.allow')}
                    </option>
                    <option key={'disallow'} value='disallow'>
                        {t('company.disallow')}
                    </option>
                </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
                <Autocomplete
                    multiple
                    id="countries-select"
                    options={countryList}
                    getOptionLabel={option => i18n.language === "cn" ? option.name+" - "+option.name_cn : option.name}
                    defaultValue={[]}
                    value={state.countries}
                    filterSelectedOptions
                    onChange={(event, newValue) => { setState({ ...state, countries: newValue }); }}
                    disableCloseOnSelect
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={t('company.countries')}
                            placeholder={t('company.countries')}
                            helperText={inputErrors.countries ? inputErrors.countries : ''}
                            error={inputErrors.countries ? true : false}
                        />
                    )}
                />
                <Button color="primary" onClick={selectAllCountries}>{t('company.all')}</Button>
            </Grid>
            { _.size(availableStates) ? 
                <Grid item xs={12} md={12}>
                    <Autocomplete
                        multiple
                        id="states-select"
                        options={availableStates}
                        getOptionLabel={option => i18n.language === "cn" ? option.name+" - "+option.name_cn+` (${option.country_code})` : option.name+` (${option.country_code})`}
                        defaultValue={[]}
                        value={state.states}
                        filterSelectedOptions
                        onChange={(event, newValue) => { setState({ ...state, states: newValue }); }}
                        disableCloseOnSelect
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={t('company.states')}
                                placeholder={t('company.states')}
                                helperText={inputErrors.states ? inputErrors.states : ''}
                                error={inputErrors.states ? true : false}
                            />
                        )}
                    />
                    <Button color="primary" onClick={selectAllStates}>{t('company.all')}</Button>
                </Grid>
                : null 
            }
            <Grid item xs={12} md={12}>
                <Button variant="contained" size="medium" color="primary" onClick={form_submit}>{t('button.save')}</Button>
            </Grid>
        </Grid>
    );
}