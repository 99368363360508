import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { fade, Collapse, Grid, Typography, Paper, Toolbar, FormControl, Input, InputAdornment, Tooltip, IconButton, Icon, Box, Button } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { useSpring, animated } from 'react-spring';

import { useTranslation } from 'react-i18next';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';
import { useQuery } from '../helper/Tools';

import PersonIcon from '@material-ui/icons/Person';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ClearIcon from '@material-ui/icons/Clear';

function TableToolbar(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(props.val);
  const searchInputRef = useRef(null);
  const handleClear = () => {
    setValue("");
    searchInputRef.current.focus();
  };

  const toolbarSearch =
    <Toolbar className={classes.toolbarRoot}>
      <form noValidate onSubmit={props.handleSubmit}>
        <FormControl>
          <Input
            id="search-input"
            placeholder={t('genealogy.searchUsername')}
            startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
            endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
            aria-describedby="search"
            inputProps={{
              'aria-label': 'search',
            }}
            inputRef={searchInputRef}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </FormControl>
      </form>
    </Toolbar>;
  return [value, toolbarSearch];
}


function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};


export default function SponsorGenealogy() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const query = useQuery();
  const isMountedRef = useRef(null);

  const { addAlert, setLoading } = useNotificationLoading();
  const [sponsorData, setSponsorData] = useState([]);
  const [errMsg, setErrMsg] = useState([]);
  const [username, setUsername] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    setUsername(searchValue);
  }

  const [searchValue, searchToolbar] = TableToolbar({ handleSubmit, val: '' });
  const [showMore, setShowMore] = useState('');


  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    
    setErrMsg([]);

    getUrl(`admin/genealogy/sponsor`, {username}).then(response => {
      let {status, message, error, data} = response;
      if (isMountedRef.current) {
        if (status == 1) {
          setSponsorData(data.tree_data);
          setLoading(false);
        }
        else if (error) {
          setErrMsg(error);
          if (_.size(error) > 0) {
            _.map(error, (value, key) => {
              message += "\n " + value[0];
            })
          }
          setLoading(false);
          addAlert('', message, 'error', '');
        }
      }


    }).catch((error) => {
      setLoading(false);
      addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
    });

    return () => { isMountedRef.current = false };
    // eslint-disable-next-line
  }, [username]);



  const renderTree = (nodes) => {
    let rank_name = 'No Rank';
    let rank_name_display = '';
    if(nodes.mrank_name != null){
      rank_name = _.split(nodes.mrank_name, '|');
      rank_name_display =  i18n.language === 'cn' ? (rank_name[1] ? rank_name[1] : rank_name[0]) : rank_name[0] 
    }
    let cmrank_name = 'No Rank';
    let cmrank_name_display = '';
    if(nodes.cmrank_name != null){
      cmrank_name = _.split(nodes.cmrank_name, '|');
      cmrank_name_display =  i18n.language === 'cn' ? (cmrank_name[1] ? cmrank_name[1] : cmrank_name[0]) : cmrank_name[0] 
    }
    return (
    <StyledTreeItem key={nodes.id} nodeId={`${nodes.id}`} label={`${nodes.username} (Rank: ${nodes.rank_name != null ?nodes.rank_name:'No Rank'} |Crank: ${nodes.crank_name != null ?nodes.crank_name:'No Rank'} |Mrank: ${rank_name_display != '' ?rank_name_display :'No Rank'} |Cmrank: ${cmrank_name_display != '' ?cmrank_name_display:'No Rank'})`} endIcon={<PersonIcon color={"primary"} />}>

      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}

    </StyledTreeItem>
  )};


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1">{t('sponsor.sponsorTreeGenealogy')}</Typography>

      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }} >
          {searchToolbar}


          {
            _.size(sponsorData) > 0 && sponsorData != null ?

              <TreeView
                className={classes.root}
                defaultExpanded={['6']}
                defaultCollapseIcon={<IndeterminateCheckBoxIcon color="primary" />}
                defaultExpandIcon={<AddBoxIcon color="primary" />}
              // defaultEndIcon={<PersonIcon color="secondary"/>}
              >

                {renderTree(sponsorData)}


              </TreeView>

              :
              <Box className={classes.msgBox}>
                    <Typography variant="body1">{t('sponsor.noSponsor')}</Typography>

              </Box>

          }
        </Paper>

      </Grid>

    </Grid>

  );

}

const StyledTreeItem = withStyles((theme) => ({
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    fontSize: 17,
  },
  iconContainer: {
    fontSize: 20,
  }
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    // maxWidth: 400,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: 440,
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexDirection: 'row-reverse',
  },
  msgBox: {
    textAlign: 'center',
    // justifyContent:'center',
    // display:'flex',
    paddingBottom: 150,
    paddingTop: 150,
  },
}));