import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';

import { useTheme } from '@material-ui/core/styles';
import { Typography, Link, Grid, Button, RadioGroup, FormControl, FormLabel, FormControlLabel, Radio,Box ,TextField,MenuItem} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@material-ui/core';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable, {MTableToolbar} from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SettingsIcon from '@material-ui/icons/Settings';

function useSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
            <Grid item xs={2}>
                <TextField
                    select
                    label={label}
                    value={value}
                    size="small"
                    onChange={({ target }) => setValue(target.value)}
                    variant="outlined"
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                >
                    {data.map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        
    return [value, select, setErrorValue, setValue];
}

export default function Sellers() {
    // const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions, role, isAdmin } = useSelector(state => state.general);
    const [statusType, setStatusType] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const statusTypeToCode = { 'activate': 1, 'suspend': 2, 'terminate': 3 };
    const [updateDialog, setOpenUpdateDialog] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [updateBvCvDialog, setOpenUpdateBvCvDialog] = useState(false);
    const [state, setState] = useState({ deduct_type: "deduct_bv", bvCvStatus: "1" });
    const [refreshData, setRefreshData]=useState(false);
    const [resetShow, setResetShow]=useState(false);
    //filter data
    const accTypeData =[{value:"noType", label: t("seller.accTypeFilter.no")}, {value:"individual", label: t("seller.accTypeFilter.individual")}, {value:"corporate", label: t("seller.accTypeFilter.corporate")}];
    const [accTypeFilter,accTypeInput,accTypeValidate,setAccTypeFilter]=useSelect({ data: accTypeData, label: `${t('seller.type')}*`, val: "noType" });

    const statusData =[{value:"noStatus", label: t("seller.statusFilter.no")}, {value:"inactive", label: t("seller.statusFilter.inactive")}, {value:"active", label: t("seller.statusFilter.active")},{value:"suspended", label: t("seller.statusFilter.suspended")},{value:"terminated", label: t("seller.statusFilter.terminated")},{value:"rejected", label: t("seller.statusFilter.rejected")}];
    const [statusFilter,statusInput,statusValidate,setStatusFilter]=useSelect({ data: statusData, label: `${t('seller.status')}*`, val: "noStatus" });

    const premiumStoreData =[{value:"noStatus", label: t("seller.premiumStoreFilter.noStatus")}, {value:"no", label: t("seller.premiumStoreFilter.no")}, {value:"yes", label: t("seller.premiumStoreFilter.yes")}];
    const [premiumStoreFilter,premiumStoreInput,premiumStoreValidate,setPremiumStoreFilter]=useSelect({ data: premiumStoreData, label: `${t('seller.premiumStore.title')}*`, val: "noStatus" });

    const validate={premiumStore: premiumStoreValidate, status:statusValidate,accType:accTypeValidate};

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        getUrl(`admin/companies`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, refreshData]);

    const alertChangeStatus = (event, data, type) => {
        setStatusType(type);
        setSelectedRow(data);
        setOpenUpdateDialog(true);
    };

    const updateStatus = () => {
        setLoading(true);

        let apiData = { status: statusTypeToCode[statusType], ids: _.map(selectedRow, 'id') };
        putUrl(`admin/companies/status`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    _.map(selectedRow, (row, index) => {
                        let dataIndex = tableData.findIndex(item => item.id === row.id);
                        newTableData[dataIndex] = { ...row, status: statusTypeToCode[statusType] };
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateDialog(false);
    };
    
    const alertChangeBvCv = (event, data) => {
        setSelectedRow(data);
        setOpenUpdateBvCvDialog(true);
    };

    const updateBvCvStatus = () => {
        setLoading(true);

        let apiData = { status: state.bvCvStatus, ids: _.map(selectedRow, 'id') };
        putUrl(`admin/companies/deduct_status/${state.deduct_type}`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    _.map(selectedRow, (row, index) => {
                        let dataIndex = tableData.findIndex(item => item.id === row.id);
                        newTableData[dataIndex] = { ...row, [state.deduct_type]: state.bvCvStatus };
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateBvCvDialog(false);
    };

    const handleFilter =()=>{
        let filterData={
            status_filter:statusFilter,
            acc_type_filter:accTypeFilter,
            premium_store_filter:premiumStoreFilter
        }

        // console.log("filter..",filterData);

        setTableLoading(true);
        getUrl(`admin/companies`, filterData).then(response => {
            setTableLoading(false);
            if (response.status === 1 ) {
                setTableData(response.data);
                setResetShow(true);
            }
        }).catch((error) => {
            setTableLoading(false);
            setResetShow(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
    }

    const handleReset=()=>{
        setAccTypeFilter("noType");
        setPremiumStoreFilter("noStatus");
        setStatusFilter("noStatus");
        setResetShow(false);
        setRefreshData(!refreshData);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('seller.sellers')}</Typography>
                    { _.includes(permissions, "company_add") ?
                        <Link underline='none' color="inherit" to="/sellers/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: t('seller.id'), field: 'id' },
                        { title: t('seller.shopName'), field: 'shop_name', render: rowData => <Typography>{rowData.shop_name} <Link underline='none' to={`/sellers/${rowData.id}`} component={RouterLink}>{'(@' + rowData.slug + ')'}</Link></Typography> },
                        { title: t('seller.type'), field: 'type', render: rowData => i18n.language === 'cn' ? rowData.type_display_cn : rowData.type_display_en },
                        { title: t('seller.premiumStore.title'), field: 'type', render: rowData =>t('seller.premiumStore.' + rowData.premium_store) },
                        { title: t('seller.name'), field: 'company_name', render: rowData => <Typography>{rowData.company_name?rowData.company_name:'-'}</Typography> },
                        { title: t('profile.mobile'), field: 'mobile' },
                        { title: t('profile.email'), field: 'email' },
                        { title: t('table.status'), field: 'status', render: rowData => t('user.status.' + rowData.status) },
                        { title: t('seller.bv')+"/"+t('seller.cv'), field: 'deduct_bv', render: rowData => (rowData.deduct_bv ? t('seller.on') : t('seller.off'))+"/"+(rowData.deduct_cv ? t('seller.on') : t('seller.off'))}
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        selection: _.includes(permissions, "company_delete") || isAdmin ? true : false
                    }}
                    actions={[
                        {
                            tooltip: t('seller.activateSelectedShop'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'activate'),
                            disabled: !_.includes(permissions, "company_delete"),
                            hidden: !_.includes(permissions, "company_delete")
                        },
                        {
                            tooltip: t('seller.suspendSelectedShop'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'suspend'),
                            disabled: !_.includes(permissions, "company_delete"),
                            hidden: !_.includes(permissions, "company_delete")
                        },
                        {
                            tooltip: t('seller.terminateSelectedShop'),
                            icon: 'delete',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'terminate'),
                            disabled: !_.includes(permissions, "company_delete"),
                            hidden: !_.includes(permissions, "company_delete")
                        },
                        {
                            tooltip: t('seller.updateBvCvStatus'),
                            icon: () => <SettingsIcon/>,
                            onClick: (evt, data) => alertChangeBvCv(evt, data),
                            disabled: !isAdmin,
                            hidden: !isAdmin
                        }
                    ]}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <Box display="flex" flexDirection="row" alignItems="center" justify="flex-end" style={{marginTop:10}}>
                                    <Grid container spacing={1} justify="flex-end">
                                        {accTypeInput}  
                                        {premiumStoreInput}  
                                        {statusInput}  
                                        
                                        {
                                            resetShow &&  <Grid item xs={1}>
                                            <Button size="medium" variant="contained" color="primary" onClick={()=>handleReset()}>{t('button.reset')}</Button> 
                                            </Grid>
                                        }
                                        {
                                            statusFilter ==='noStatus' && accTypeFilter ==='noType' && premiumStoreFilter ==='noStatus'?
                                            null:
                                            <Grid item xs={1}>
                                            <Button size="medium" variant="contained" color="primary" onClick={()=>handleFilter()}>{t('button.filter')}</Button> 
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </div>
                        ),
                    }}
                />
                }
            </Grid>
            <Dialog
                open={updateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('user.confirmOnStatusUpdate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`user.askUpdateStatus.${statusTypeToCode[statusType]}`, { 'number': selectedRow ? selectedRow.length : 0 })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={updateBvCvDialog}
                onClose={() => setOpenUpdateBvCvDialog(false)}
                aria-labelledby="update-bvcvstatus-dialog-title"
                aria-describedby="update-bvcvstatus-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('seller.updateBvCvStatus')}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('seller.type')}</FormLabel>
                                <RadioGroup row aria-label="type" name="type" value={state.deduct_type} onChange={(event) => setState({ ...state, deduct_type: event.target.value })}>
                                    <FormControlLabel value="deduct_bv" control={<Radio />} label="BV" />
                                    <FormControlLabel value="deduct_cv" control={<Radio />} label="CV" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('table.status')}</FormLabel>
                                <RadioGroup row aria-label="status" name="status" value={state.bvCvStatus} onChange={(event) => setState({ ...state, bvCvStatus: event.target.value })}>
                                    <FormControlLabel value="1" control={<Radio />} label="On" />
                                    <FormControlLabel value="0" control={<Radio />} label="Off" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateBvCvDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateBvCvStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));