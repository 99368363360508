import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { makeStyles, List, ListItem, Box, Chip, Typography } from '@material-ui/core';
import { FaceOutlined } from '@material-ui/icons';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function ProductEditHistory({ productId }) {
    const [history, setHistory] = useState([]);

    const classes = useStyles();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();


    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`admin/products/${productId}/product_history`).then((response) => {
            if(response.status && isMountedRef.current) {
                setLoading(false);
                setHistory(response.data);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language]);


    return (
        <List className={classes.root}>
            { _.map(history, historyItem => {
                let lastIndex = 0;
                return (
                    <div key={historyItem.id}>
                        <ListItem alignItems="flex-start" className={classes.listItemRoot}>
                            <Box display="flex" flexDirection="row">
                                <Chip classes={{ root: classes.chipDateRoot, label: classes.chipLabel }} label={historyItem.action_tstamp_clk} />
                                <Chip classes={{ root: classes.chipInitiatorRoot, label: classes.chipLabel }} label={historyItem.initiator} icon={<FaceOutlined style={{ color: '#000' }} />} />
                            </Box>
                            <Box display="flex" flexDirection="row" marginY={2}>
                                <Chip classes={{ root: classes.chipActionRoot, label: classes.chipLabel }} label={historyItem.action_display} />
                                <Chip classes={{ root: classes.chipTableNameRoot, label: classes.chipLabel }} label={historyItem.table_name} />
                            </Box>
                            <Box display="flex" flexDirection="row" marginBottom={1}>
                                <Typography variant="body2">ID:</Typography>
                                <Typography variant="body2" className={classes.transactionIdStyle}>{ historyItem.id ?? '-' }</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" marginBottom={1}>
                                <Typography variant="body2">{ `${t('log.transactionId')} :` }</Typography>
                                <Typography variant="body2" className={classes.transactionIdStyle}>{ historyItem.transaction_id ?? '-' }</Typography>
                            </Box>
                            <Typography variant="body2">{ `${t('log.clientQuery')} :` }</Typography>
                            { _.size(historyItem.changed_field_display) > 0 ? 
                                <code className={classes.codeRoot}>
                                    <span style={{ paddingRight: 5 }}>{ historyItem.action_display }</span>
                                    { _.map(historyItem.changed_field_display, (value, field) => {
                                        lastIndex += 1;
                                        return (
                                            <span key={`${value}-${field}`}>
                                                <span style={{ fontWeight: 'bold' }}>{ `${field}` }</span>
                                                { ` ${t('log.as')} ` }
                                                <span style={{ fontWeight: 'bold' }}>{value}</span>
                                                { _.size(historyItem.changed_field_display) === lastIndex ? '.' : ', ' }
                                            </span>
                                        )
                                    })}
                                </code>
                            : null }
                        </ListItem>
                    </div>
                )
            }) }
        </List>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listItemRoot: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f5f5f5",
        marginBottom: 10,
        borderRadius: 20,
        padding: 20,
        overflowX: 'auto',
    },
    chipDateRoot: {
        borderRadius: 5,
        marginRight: 5,
        backgroundColor: "#e0e0e0",
    },
    chipActionRoot: {
        borderRadius: 5,
        marginRight: 5,
        backgroundColor: "#4db6ac",
    },
    chipTableNameRoot: {
        borderRadius: 5,
        marginRight: 5,
        backgroundColor: "#64b5f6",
    },
    chipInitiatorRoot: {
        borderRadius: 5,
        marginRight: 5,
        backgroundColor: "#7986cb",
    },
    chipLabel: {
        fontWeight: 'bold',
        fontSize: 12
    },
    codeRoot: {
        padding: 10,
        borderRadius: 5,
        backgroundColor: "#e0e0e0",
        color: "#757575",
        marginBottom: 10,
        fontSize: 10,
    },
    transactionIdStyle: { 
        fontWeight: 'bold', 
        color: '#757575', 
        marginLeft: 10,
    }
}));