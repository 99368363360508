export const ENV = 'liv'; // 'dev';
export const APP_NAME = 'Healthcare2U Backend';
export const PROJECT_TYPE = 'mall';
export const CLIENT_SECRET = '2UBQlYcsU71jhx8M60dx9TSaeyHDtRJSouf7WAae';

const DOMAIN_NAME_LIST = {
    liv: `healthcare2u.asia`,
    stage: `healthcare2u.asia`,
    dev: 'localhost',
};

export const DOMAIN_NAME = DOMAIN_NAME_LIST[ENV];

const BACKEND_URL_LIST = {
    liv: `https://api.${DOMAIN_NAME}`,
    stage: `https://dapi.${DOMAIN_NAME}`,
    dev: 'http://192.168.0.179:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://backend.${DOMAIN_NAME}`,
    stage: `https://dbackend.${DOMAIN_NAME}`,
    dev: `http://${DOMAIN_NAME}:3000`,
};
const MEMBER_URL_LIST = {
    liv: `https://www.healthcare2u.asia`,
    stage: `https://dmall.healthcare2u.asia`,
    dev: `http://localhost:3001`,
};

const PUSHER_KEY_LIST = {
    liv: '300a8ddcd263bda445fc',
    dev: 'a11d5ba0a6ee7f0d9ae5',
    stage: '4055f5d563df469ce07a',
}

export const BASE_URL = ENV === 'liv' ? '' : '';
export const LOCAL_URL = LOCAL_URL_LIST[ENV];
export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const MEMBER_URL = MEMBER_URL_LIST[ENV];

export const API_URL = `${BACKEND_URL}/api/`;

export const AUTH_URL = `http://auth.${DOMAIN_NAME}`;

export const PUSHER_KEY = PUSHER_KEY_LIST[ENV];
export const PUSHER_CLUSTER = 'ap1';
export const PUSHER_AUTH = `${BACKEND_URL}/broadcasting/auth`;
export const PUSHER_MESSAGE_CHANNEL = 'chat';
export const PUSHER_MESSAGE_EVENT = 'MessageSent';
