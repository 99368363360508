import React, { useEffect, useRef, useState } from 'react';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import { useSelector } from 'react-redux';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { PUSHER_KEY, PUSHER_CLUSTER, PUSHER_AUTH, PUSHER_MESSAGE_CHANNEL, PUSHER_MESSAGE_EVENT } from '../configs/Config';


import { Grid, Typography, Box, Paper, List, ListItem, Avatar, ListItemText, IconButton,  TextField, Divider, ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '../helper/useNotificationLoading';
import SendIcon from '@material-ui/icons/Send';


import _ from 'lodash';

export default function MerchantHelpdesk() {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const { id, name } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const theme = useTheme();

    const isMountedRef = useRef(null);
    const chatRef = useRef();

    const [state, setState] = useState({
        newMessage: '',  anchorEl: null,
    });
    const [chatAdmin, setChatAdmin] = useState({
        id: null
    });
    
    const [chatList, setChatList] = useState();
    const [chatData, setChatData] = useState(null);
    const [newMessage, setNewMessage] = useState();
    const [admin, setAdmin] = useState();

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`user`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                console.log("admin_id", result.data.id)
                setAdmin(result.data);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    // get all member chat
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('admin/merchantchats').then(response => {
            if (isMountedRef.current) {
                console.log("chatList", response.data);
                setLoading(false);
                setChatList(response.data)
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
    }, []);

    // get {id} member chat history
    useEffect(() => {
        isMountedRef.current = true;
        if(chatAdmin.id > 0) {
            getUrl(`admin/merchantchats/${chatAdmin.id}`).then(response => {
                if (isMountedRef.current) {
                    response.data.data = _.reverse(response.data.data);
                    setChatData(response.data);
                }
                scrollToBottom();
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
    }, [chatAdmin.id]);

    // when receive new message from pusher
    useEffect(() => {
        isMountedRef.current = true;
        console.log("newMessage", newMessage);
        if (isMountedRef.current) {
            if(_.size(newMessage) > 0) {
                // if received new message recipient is self
                    // find if new message chat_id in the current pull list
                    const chatExistIndex = _.findIndex(chatList, { 'id': newMessage.chat_id });
                    if(chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        newChatList[chatExistIndex].last_chat[0] = newMessage;
                        newChatList[chatExistIndex].updated_at = newMessage.updated_at;
                        newChatList[chatExistIndex].unread_count++;

                        newChatList = _.orderBy(newChatList, ['updated_at'], ['desc']);
                        setChatList(chatList => ([ ...newChatList ]));

                        // if chatData loaded and chat_info id is current opening chatbox id
                        if(_.size(chatData) > 0) {
                            if(chatData.chat_info.id === parseInt(newMessage.chat_id)) {
                                const prevData = chatData.data;
                                setChatData(chatData => ({ ...chatData, data: prevData.concat(newMessage) }));
                            }
                        }

                        console.log("chatList", chatList);
                    } 
            }
        }
        return () => { isMountedRef.current = false };
    }, [newMessage])

    useEffect(() => {
        console.log("chatList", chatList);
        if(_.size(chatData) && _.size(chatData.data) <= 30) {
            scrollToBottom();
        }
    },[chatData])

    useEffect(() => {
        isMountedRef.current = true;
        if(accessToken) {
            if (isMountedRef.current) {
                const echo = new Echo({
                    broadcaster: 'pusher',
                    key: PUSHER_KEY,
                    cluster: PUSHER_CLUSTER,
                    forceTLS: true,
                    authEndpoint: PUSHER_AUTH,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        },
                    },
                });
                console.log("echo initial done", echo);

                echo.private(PUSHER_MESSAGE_CHANNEL).listen(PUSHER_MESSAGE_EVENT, (pusherMsg) => {
                    console.log("pusherMsg", pusherMsg)
                    setNewMessage(newMessage => (pusherMsg.message));
                });
                console.log("echo start listening");
            }
        }

        return () => { isMountedRef.current = false };
    }, [accessToken])

    const sendMessage = () => {
        setLoading(true);
        if(state.newMessage) {
            const newMessage = state.newMessage;
            setState(state => ({ ...state, newMessage: '' }));
            postUrl(`admin/merchantchats/${chatAdmin.id}`, {
                message: state.newMessage
            }).then(response => {
                setLoading(false);
                if(response.status) {
                    const prevData = chatData.data;
                    setChatData({ ...chatData, data: prevData.concat(response.data) });

                    const chatExistIndex = _.findIndex(chatList, { 'id': parseInt(response.data.chat_id) });
                    console.log("chatList", chatList)
                    console.log("response.data", response.data)
                    console.log("chatExistIndex", chatExistIndex)
                    if(chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        newChatList[chatExistIndex].last_chat[0] = response.data;
                        newChatList[chatExistIndex].updated_at = response.data.updated_at;

                        // if chatData loaded and chat_info id is current opening chatbox id
                        if(_.size(chatData) > 0) {
                            if(chatData.chat_info.id === parseInt(response.data.chat_id)) {
                                newChatList[chatExistIndex].last_chat[0]['read'] = true;
                                newChatList[chatExistIndex].unread_count--;
                            }
                        }
                        newChatList = _.orderBy(newChatList, ['updated_at'], ['desc']);
                        setChatList(chatList => ([ ...newChatList ]));
                        console.log("chatList2", chatList);
                        scrollToBottom();
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    
    

    const scrollToBottom = () => {
        if(chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }

    const openMessage = chatItem => {
        setChatAdmin({ id: chatItem.id, user: chatItem.user });
        // set messages to read
        putUrl(`admin/merchantchats/${chatItem.id}`)
        .then(response => {
            console.log(response);

            const chatExistIndex = _.findIndex(chatList, { 'id': chatItem.id });
            if(chatExistIndex >= 0) {
                // exist, replace chat last message
                let newChatList = chatList;
                if(_.size(newChatList[chatExistIndex].last_chat) > 0) {
                    newChatList[chatExistIndex].last_chat[0]['merchant_id'] = admin.id;
                    newChatList[chatExistIndex].last_chat[0]['recipient'] = admin.id;
                    newChatList[chatExistIndex].unread_count = 0;
                }
                setChatList(chatList => ([ ...newChatList ]));
            }
            setState({ ...state });
           
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const renderChatList = (toRenderList) => {
        return (
            <Box>
            {
                _.isNull(toRenderList) ?
                <Box display="flex" flex={1} justifyContent="center" alignItems="center"><CircularProgress /></Box>
                :
                _.size(toRenderList) === 0 ?
                <Box display="flex" flex={1} justifyContent="center" alignItems="center"><Typography variant="caption">{ t('chat.noChat') }</Typography></Box>
                :
                _.map(toRenderList, chatItem => {
                    console.log('chatItem', chatItem);
                    return (
                        <Box key={chatItem.id}>
                            <ListItem button onClick={ () => openMessage(chatItem) } style={{ backgroundColor: _.size(chatAdmin) > 0 ? (chatAdmin.id === chatItem.id ? theme.palette.background.default : '#FFF') : '#FFF' }}>
                                {/* <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: '#F7971E' }}><Typography variant="button">{ chatItem.user.name[0] }</Typography></Avatar>
                                </ListItemAvatar> */}
                                <ListItemText 
                                    primary={ t('chat.merchantHelpdesk') } 
                                    secondary={ _.size(chatItem.last_chat) > 0 && _.size(admin) > 0 ? `${chatItem.last_chat[0].sender !== admin.id ? `${t('chat.merchantHelpdesk')} :` : `${chatItem.user.name} :`} ${chatItem.last_chat[0].message}` : '' } 
                                    secondaryTypographyProps={{ 
                                        style: { 
                                            color: _.size(chatItem.last_chat) > 0 ? ( chatItem.last_chat[0].recipient ? '#b5b5b5' : '#000000') : '#b5b5b5',
                                            fontWeight: _.size(chatItem.last_chat) > 0 ? ( chatItem.last_chat[0].recipient ? 'normal' : 'bold') : 'normal',
                                        },
                                        noWrap: true
                                    }}
                                />
                                {
                                    chatItem.unread_count > 0 ?
                                    <ListItemSecondaryAction>
                                        <Avatar style={{ backgroundColor: theme.palette.primary.main, width: theme.spacing(3), height: theme.spacing(3) }}>
                                            <Typography variant="caption">{ chatItem.unread_count }</Typography>
                                        </Avatar>
                                    </ListItemSecondaryAction>
                                    : null
                                }
                            </ListItem>
                            <Divider />
                        </Box>
                    )
                })
            }
            </Box>
        )
    }

    const checkScroll = event => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        console.log("scrollTop", {scrollTop, scrollHeight})
        if (scrollTop === 0) {
            if(_.size(chatData) > 0) {
                if(chatData.current_page !== chatData.last_page) {
                    const newCurrentPage = chatData.current_page + 1;
                    getUrl(`admin/merchantchats/${chatData.chat_info.id}?page=${newCurrentPage}`).then(response => {
                        const mergedChatDataConversation = _.concat(_.reverse(response.data.data), chatData.data);
                        response.data.data = mergedChatDataConversation;
                        setChatData(response.data);

                        // set the chat height back to the height before call api
                        const newHeight = chatRef.current.scrollHeight - scrollHeight;
                        chatRef.current.scrollTop = newHeight;
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                }
            }
        }
        
        if(scrollHeight - scrollTop === clientHeight) {
            console.log("bottom")
            // set messages to read
            putUrl(`admin/merchantchats/${chatAdmin.id}`)
            .then(response => {
                console.log(response);

                const chatExistIndex = _.findIndex(chatList, { 'id': chatAdmin.id });
                if(chatExistIndex >= 0) {
                    // exist, replace chat last message
                    let newChatList = chatList;
                    if(_.size(newChatList[chatExistIndex].last_chat) > 0) {
                        newChatList[chatExistIndex].last_chat[0]['read'] = true;
                        newChatList[chatExistIndex].unread_count = 0;
                    }
                    setChatList(chatList => ([ ...newChatList ]));
                }
                setState({ ...state });
        
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    let prevDate = null;

    return (
        <Grid container>
            <Grid item xs={5} md={3}>
                <Paper variant="outlined" style={{ padding: 12, backgroundColor: '#FFD200' }}>
                    { console.log("testestest", { admin, name}) }
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Avatar style={{ backgroundColor: '#F7971E' }}><Typography variant="button">{ _.size(admin) > 0 ? admin.name[0] : '' }</Typography></Avatar>
                            <Typography style={{ marginLeft: 10 }}>{ _.size(admin) > 0 ? admin.name : '' }</Typography>
                        </Box>
                    </Box>
                </Paper>
                <Paper variant="outlined" style={{ minHeight: 517, maxHeight: 517, overflow: 'auto' }}>
                    <List>
                        {
                            renderChatList(chatList)
                        }
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={7} md={9}>
                {
                    chatAdmin.id > 0 ?
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                        <Paper variant="outlined" style={{ padding: 12, backgroundColor: '#FFD200' }}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography style={{ marginLeft: 10 }}>{ t('chat.merchantHelpdesk') }</Typography>
                                </Box>
                            </Box>
                        </Paper>
                        <Box display="flex" flex={1} flexDirection="column" justifyContent="flex-end">
                            <Paper ref={chatRef} style={{ backgroundColor: 'transparent', minHeight: 464, maxHeight: 464, overflow: 'auto', display: 'flex', flexDirection: 'column' }} onScroll={checkScroll} >
                            {
                                _.isNull(chatData) ?
                                <Box display="flex" flex={1} justifyContent="center" alignItems="center"><CircularProgress /></Box>
                                :
                                _.size(chatData) > 0 ?
                                    _.size(chatData.data) > 0 ?
                                        _.map(chatData.data, (selectedChatItem, selectedChatItemIndex) => {
                                            console.log("selectedChatItem", selectedChatItem);
                                            let showDate = false;
                                            if(prevDate !== null) {
                                                const d1 = new Date(prevDate);
                                                const d2 = new Date(selectedChatItem.created_date_compare);
    
                                                if(d1 < d2) {
                                                    showDate = true;
                                                }
                                            } else {
                                                showDate = true;
                                            }
                                            console.log("showDate", showDate);

                                            prevDate = selectedChatItem.created_date_compare;

                                            return (
                                                <Box key={selectedChatItemIndex} display="flex" flexDirection="column">
                                                    {
                                                        showDate ?
                                                        <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
                                                            <Paper style={{ backgroundColor: '#b5b5b5', padding: 5 }} elevation={0}>
                                                                <Typography variant="caption" style={{ color: '#FFF' }}>{ selectedChatItem.created_date_display }</Typography>
                                                            </Paper>
                                                        </Box>
                                                        : null
                                                    }
                                                    <Paper style={{ margin: 5, padding: 8, alignSelf: selectedChatItem.sender === chatData.chat_info.user_id ? 'flex-end' : 'flex-start' }} variant="outlined">
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography variant="caption" style={{ whiteSpace: 'pre-line' }}>{ selectedChatItem.message }</Typography>
                                                            <Typography variant="caption" style={{ fontSize: 6, marginLeft: 30, alignSelf: 'flex-end' }}>{ selectedChatItem.created_time_display }</Typography>
                                                        </Box>
                                                    </Paper>
                                                </Box>
                                            )
                                        } )
                                    : 
                                    <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
                                        <Paper style={{ backgroundColor: theme.palette.background.default, padding: 5 }} elevation={0}>
                                            <Typography variant="caption" style={{ color: '#000', fontSize: 10 }}>{ t('chat.chatWithMerchantHelpdesk') }</Typography>
                                        </Paper>
                                    </Box>
                                : null
                            }
                            </Paper>
                        </Box>
                        <Paper variant="outlined" style={{ padding: 10, backgroundColor: '#FFD200' }}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
                                    <TextField 
                                        fullWidth 
                                        multiline 
                                        rowsMax={4}
                                        value={state.newMessage}
                                        onChange={({target}) => setState({ ...state, newMessage: target.value })}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter' && e.shiftKey) {
                                            } else if (e.key === 'Enter') {
                                                e.preventDefault();
                                                sendMessage();
                                            }
                                        }}
                                        helperText={ t('chat.helperText') }
                                        FormHelperTextProps={{ style: { fontSize: 8 } }}
                                    />
                                </Box>
                                <IconButton aria-label="settings" onClick={ sendMessage }>
                                    <SendIcon color="primary"/>
                                </IconButton>
                            </Box>
                        </Paper>
                    </Box>
                    : null
                }
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
}));