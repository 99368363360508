import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { TextField, MenuItem, Button } from '@material-ui/core';
import _ from 'lodash';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Snackbar, Typography, Divider, Breadcrumbs, Link, Grid, Paper, FormControlLabel, Switch } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { getUrl, putUrl ,postUrl} from '../helper/ApiAction';
import { useMessage } from '../helper/Tools';

export default function ParameterEdit() {
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        ptype: 1,
        code: '',
        value: '',
        rate: '',
        desc_cn: '',
        desc_en: '',
    });

    const { t } = useTranslation();

    const { value:errorGeneral, setValue:setErrorGeneral, reset:resetErrorGeneral } = useMessage('');
    const { value:successGeneral, setValue:setSuccessGeneral, reset:resetSuccessGeneral } = useMessage('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [inputErrors, setInputErrors] = useState([]);

    useEffect(() => {
        if(errorGeneral !== ""){
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        }
    }, [errorGeneral]);

    useEffect(() => {
        if(successGeneral !== ""){
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
        }
    }, [successGeneral]);

    const handleChange = () => {
        setState({ ...state, status: !state.status });
    }

    const updateParameter = () => {
        resetErrorGeneral();
        resetSuccessGeneral();
        setInputErrors("");
        postUrl(`admin/create_currency`, {
            rate: state.rate,
            value: state.value,
            code: state.code,
            desc_cn: state.desc_cn,
            desc_en: state.desc_en,
        }).then(result => {
            if(result.status === 1) {
                setSuccessGeneral(result.message);
            } if(result.error){
                setInputErrors(result.error);
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{ t('currency.add') }</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/currency`} component={RouterLink}>{t('title.currency')}</Link>
                        <Typography style={{ color: 'black' }}>{t('currency.add')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                        <MuiAlert elevation={6} variant="filled" onClose={closeSnackbar} severity={snackbarSeverity}>
                            {successGeneral || errorGeneral}
                        </MuiAlert>
                    </Snackbar>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="code" 
                                    label={ t('parameter.code') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.code}
                                    helperText={inputErrors.code ? inputErrors.code :''}
                                    error={inputErrors.code ? true : false}
                                    onChange={({ target }) => setState({ ...state, code: target.value }) } 
                                />
                            </Grid>
                        </Grid>  
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="value" 
                                    label={ t('parameter.value') }
                                    multiline
                                    rows={2}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.rate}
                                    helperText={inputErrors.rate ? inputErrors.rate :''}
                                    error={inputErrors.rate ? true : false}
                                    onChange={({ target }) => setState({ ...state, rate: target.value }) }    
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="value" 
                                    label={ t('parameter.description') }
                                    multiline
                                    rows={2}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.value}
                                    helperText={inputErrors.value ? inputErrors.value :''}
                                    error={inputErrors.value ? true : false}
                                    onChange={({ target }) => setState({ ...state, value: target.value }) }    
                                />
                            </Grid>
                        </Grid>    
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="description_en" 
                                    label={ t('currency.name_en') }
                                    multiline
                                    rows={4}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.desc_en}
                                    helperText={inputErrors.desc_en ? inputErrors.desc_en :''}
                                    error={inputErrors.desc_en ? true : false}
                                    onChange={({ target }) => setState({ ...state, desc_en: target.value }) }    
                                />
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="description_cn" 
                                    label={ t('currency.name_cn') }
                                    multiline
                                    rows={4}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.desc_cn}
                                    helperText={inputErrors.desc_cn ? inputErrors.desc_cn :''}
                                    error={inputErrors.desc_cn ? true : false}
                                    onChange={({ target }) => setState({ ...state, desc_cn: target.value }) }    
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <Button variant="contained" color="primary" onClick={updateParameter}>{ t('button.add') }</Button>
                            </Grid>
                        </Grid>    
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));