import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '../helper/useNotificationLoading';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import ProductEditMultiPricings from './ProductEditMultiPricings';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function MembershipMultiPricings({id}) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    
    const [productData, setProductData] = useState([]);
    const [multiPricingDialog, setMultiPricingDialog] = useState({
        open: false,
        productId: '',
    });
    const [optionMultiPricing, setOptionMultiPricing] = useState({
        open: false,
    });

    const handleMultiPricingOpen = () => {
        setMultiPricingDialog({
            open: true,
            productId: id,
        });
    }
    const handleMultiPricingClose = () => {
        setMultiPricingDialog({
            open: false,
            productId: '',
        });
    }
    const handleOptionMultiPricingOpen = () => {
        getUrl(`admin/products/${id}`).then(response => {
            if(response.status === 1) {
                setProductData(response.data);
                setOptionMultiPricing({
                    open: true,
                });
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })
    }
    const handleOptionMultiPricingClose = () => {
        setOptionMultiPricing({
            open: false,
        });
        setProductData([]);
    }

    const handleCheckboxChange = (event) => {
        let dataKey = '';
        _.map(productData.options, (data, key) => {
            if(parseInt(data.id) === parseInt(event.target.name)){
                dataKey = key;
            }
        })
        let tempData = productData.options[dataKey].multi_pricing_id;
        let tempData2 = '';
        if(event.target.checked){
            tempData2 = tempData.concat(event.target.value);
        }else{
            tempData2 = tempData.filter(item => item !== event.target.value);
        }
        setProductData({...productData, options: { ...productData.options, [dataKey]: {...productData.options[dataKey], multi_pricing_id: tempData2}}});
    };

    const saveOptionsMultiPricing = () => {
        let updateData = {
            product: productData
        };
        postUrl(`admin/memberships/${id}/multi_pricing`, updateData).then(response => {
            if(response.status === 1){
                handleOptionMultiPricingClose();
                addAlert('', t('snackbarMsg.updateSuccess'), 'success', '');
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    
    return (
        <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Button variant="outlined" color="primary" style={{margin: "5px"}} onClick={() => handleOptionMultiPricingOpen()}>
                    {t('membership.multiPricing')}
                </Button>
            </Grid>
            <Dialog onClose={handleOptionMultiPricingClose} open={optionMultiPricing.open} fullWidth maxWidth={'md'}>
                <DialogTitle onClose={handleOptionMultiPricingClose}>
                    {t('membership.multiPricing')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Button variant="outlined" color="primary" style={{margin: "5px"}} onClick={() => handleMultiPricingOpen()}>
                                {t('membership.editMultiPricing')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                _.size(productData.options) > 0
                                ?
                            <TableContainer style={theme.p20}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{ t('membership.productOptionId') }</TableCell>
                                            <TableCell>{ t('membership.productDescription') }</TableCell>
                                            <TableCell>{ t('membership.multiPricing') }</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            _.map((productData.options), (options, key) => {
                                                return(
                                                    <TableRow key={key}>
                                                        <TableCell>{ options.id }</TableCell>
                                                        <TableCell>
                                                            { 
                                                                options.id
                                                                ?
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        {t('product.color') + ': ' + (options.color?options.color:'-')}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {t('product.size') + ': ' + (options.size?options.size:'-')}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {(options.attribute_name?options.attribute_name:t('product.customAttribute')) + ': ' + (options.attribute_value?options.attribute_value:'-')}
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                '-'
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={3}>
                                                                {
                                                                    _.size(productData.multi_pricings) > 0
                                                                    ?
                                                                    _.map((productData.multi_pricings), (row, rowIndex) => {
                                                                        let name = _.split(row.wallet_name, '|');
                                                                        let label = row.cash_percent > 0 ? (row.cash_percent + "% " + t('product.cash')) : '';
                                                                        label += (row.cash_percent > 0 && row.wallet_amount > 0) ? ' + ' : '';
                                                                        label += row.wallet_amount > 0 ? (`${row.wallet_amount} ${i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}`) : '';
                                                                        let checked = false;
                                                                        _.map(options.multi_pricing_id, mp => {
                                                                            if(parseInt(mp) === parseInt(row.id)){
                                                                                checked = true;
                                                                            }
                                                                        })
                                                                        return(
                                                                            <Grid item xs={12} key={rowIndex} style={{padding: "0px 12px"}}>
                                                                                <FormControlLabel
                                                                                    key={rowIndex}
                                                                                    label={label}
                                                                                    control={<Checkbox checked={checked} value={row.id} name={(options.id).toString()} onChange={({ target }) => handleCheckboxChange({ target }) } />}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                    :
                                                                    null
                                                                }
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="primary" onClick={ () => saveOptionsMultiPricing() }>
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ multiPricingDialog.open } onClose={ handleMultiPricingClose } fullWidth >
                <DialogTitle onClose={ handleMultiPricingClose }>
                    { t('membership.editMultiPricing') }
                </DialogTitle>
                <DialogContent dividers>
                    <ProductEditMultiPricings data={productData} setLoading={setLoading} addAlert={addAlert} setData={setProductData} />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
}));