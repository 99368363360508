import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import _ from 'lodash';

// import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles'
import { Typography, Grid, LinearProgress, Breadcrumbs, Link } from '@material-ui/core';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';

export default function VoucherDetails() {
    // const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { id } = useParams();
    const { addAlert } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        getUrl(`admin/vouchers/${id}/list`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });       
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('voucher.voucherDetails')} ({_.size(tableData)})</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/vouchers`} component={RouterLink}>{t('voucher.vouchers')}</Link>
                        <Link to={`/vouchers/${id}`} component={RouterLink}>{t('voucher.voucherView')}</Link>
                        <Typography style={{ color: 'black' }}>{t('voucher.voucherDetails')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[                       
                        { title: t('voucher.voucherCode'), field: 'voucher_code' },
                        { title: t('voucher.buyerEmail'), field: 'email' },
                        { title: t('voucher.recipientEmail'), field: 'receiver_email' },
                        { title: t('table.created_at'), field: 'created_at' },
                        { title: t('table.status'), field: `status_${i18n.language}` },
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        filtering: true,
                        exportButton: true,
                        exportFileName: `VoucherReport_${new Date().toISOString().slice(0, 10)}`
                    }}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected'),
                            exportTitle: t('table.export'),
                            exportPDFName: t('table.exportPDF'),
                            exportCSVName: t('table.exportCSV')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
                }
            </Grid>
        </Grid>
    );
}

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));