import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, withRouter ,useHistory} from 'react-router-dom';
import ScrollToTop from '../helper/ScrollToTop';
import _ from 'lodash';

import NotificationLoadingProvider from '../providers/NotificationLoadingProvider';
import NotificationLoading from '../components/Layouts/NotificationLoading';

import HeaderSidebar from './Layouts/HeaderSidebar';

import Login from './Login';
import LoginCatalog from './LoginCatalog';
import PasswordForgot from './PasswordForgot';
import PasswordReset from './PasswordReset';
import Dashboard from './Dashboard';
import ImportProduct from './ImportProduct';
import Products from './Products';
import ProductAdd from './ProductAdd';
import ProductEdit from './ProductEdit';
import Categories from './Categories';
import CategoryEdit from './CategoryEdit';
import CategoryAdd from './CategoryAdd';
// import Companies from './Companies';
// import CompanyAdd from './CompanyAdd';
// import CompanyEdit from './CompanyEdit';
import Sellers from './Sellers';
import SellerAdd from './SellerAdd';
import SellerEdit from './SellerEdit';
import Admins from './Admins';
import AdminAdd from './AdminAdd';
import AdminEdit from './AdminEdit';
import Merchants from './Merchants';
import MerchantAdd from './MerchantAdd';
import MerchantEdit from './MerchantEdit';
import Members from './Members';
import MemberAdd from './MemberAdd';
import MemberEdit from './MemberEdit';
import Parameters from './Parameters';
import ParameterAdd from './ParameterAdd';
import ParameterEdit from './ParameterEdit';
import Faqs from './Faqs';
import FaqAdd from './FaqAdd';
import FaqEdit from './FaqEdit';
import Profile from './Profile';
import Chat from './Chat';
import Roles from "./Roles";
import RoleAdd from "./RoleAdd";
import RoleEdit from "./RoleEdit";
import Permissions from "./Permissions";
import PermissionAdd from "./PermissionAdd";
import PermissionEdit from "./PermissionEdit";
import Orders from './Orders';
import OrderEdit from './OrderEdit';
import OrderAdd from './OrderAdd';
import OrdersAll from './OrdersAll';
import Collections from './Collections';
import CollectionEdit from './CollectionEdit';
import ShopReviews from './ShopReviews';
import ProductReviews from './ProductReviews';
import ProductQuestions from './ProductQuestions';
import Withdrawals from './Withdrawals';
import Points from "./Points";
import PointAdd from "./PointAdd";
import PointEdit from "./PointEdit";
import Ranks from "./Ranks";
import RankAdd from "./RankAdd";
import RankEdit from "./RankEdit";
import Memberships from "./Memberships";
import Wallets from "./Wallets";
import WalletAdd from "./WalletAdd";
import WalletEdit from "./WalletEdit";
import WalletBalance from "./WalletBalance";
import WalletAddDeduct from "./WalletAddDeduct";
import ReportWalletTransaction from "./ReportWalletTransaction";
// import ReportWalletTransactionAll from "./ReportWalletTransactionAll";
import ReportWalletBalance from "./ReportWalletBalance";
import Reports from "./Reports";
import LandingBanner from "./LandingBanner";
import SellerRegister from "./SellerRegister";
import Notifications from "./Notifications";
import NotificationEdit from "./NotificationEdit";
import NotificationAdd from "./NotificationAdd";
import DailySales from "./DailySales";
import MonthlySales from "./MonthlySales";
import HalojeSales from "./ReportHaloje";
import AdminChat from "./AdminChat";
// import Refunds from "./Refunds";
import MerchantHelpdesk from "./MerchantHelpdesk";
import Vouchers from './Vouchers';
import VoucherAdd from './VoucherAdd';
import VoucherEdit from './VoucherEdit';
import VoucherDetails from './VoucherDetails';
import EventSummary from './EventSummary';
import LuckyDrawOrders from './LuckyDrawOrders';
import Bonuses from './Bonuses';
import BonusAdd from './BonusAdd';
import BonusEdit from './BonusEdit';
import ReportBonusDetails from "./ReportBonusDetails";
import ReportBonusSummary from "./ReportBonusSummary";
import SellerTnc from "./SellerTnc";
import EventProducts from "./EventProducts";
import EventProductAdd from "./EventProductAdd";
import EventProductEdit from "./EventProductEdit";
import Announcements from "./Announcements";
import Maintenance from './Maintenance';
import GenealogyPlacement from "./GenealogyPlacement";
import GenealogyPlacement2 from "./GenealogyPlacement2";
import SponsorGenealogy from "./SponsorGenealogy";
import Kyc from "./Kyc";
import WithdrawalsMember from "./WithdrawalsMember";
import Uplines from "./Uplines";
import ReportOrdersBV from "./ReportBVOrders";
import ReportSimplifiedSale from "./ReportSimplifiedSale";
import ReportAccountLink from "./ReportAccountLink";
import Currency from "./Currency";
import CurrencyEdit from "./CurrencyEdit";
import CurrencyAdd from "./CurrencyAdd";
import ProductBulkUpload from "./ProductBulkUpload";

import { APP_NAME, PROJECT_TYPE } from '../configs/Config';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Posts from "./Posts";
import PostEdit from "./PostEdit";
import PostAdd from "./PostAdd";

import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import GetAppIcon from '@material-ui/icons/GetApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CategoryIcon from '@material-ui/icons/Category';
import HowToRegIcon from '@material-ui/icons/HowToReg';
// import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CollectionsIcon from '@material-ui/icons/Collections';
import StorefrontIcon from '@material-ui/icons/Storefront';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SmsIcon from '@material-ui/icons/Sms';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import BarChartIcon from '@material-ui/icons/BarChart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ForumIcon from '@material-ui/icons/Forum';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EventIcon from '@material-ui/icons/Event';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import HelpIcon from '@material-ui/icons/Help';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const routes = [
    {
        path: "/dashboard",
        component: <Dashboard />,
        exact: true,
        permission: [],
        sidebar: true,
        icon: <DashboardIcon />,
        title: 'title.dashboard',
        badgeFor: 'dashboard',
    },
    {
        path: "/profile",
        component: <Profile />,
        exact: true,
        permission: [],
        sidebar: false,
        icon: null,
        title: '',
    },
    // category: admin
        //CUSTOMER SERVICE
        {
            path:"/servicecenter",
            component:<AdminChat />,
            exact:true,
            permission:['chat_response'],
            sidebar:true,
            icon:<ForumIcon />,
            title:'title.serviceCenter',
            category:'admin',
        },
        // MERCHANT CHAT WITH ADMIN
        {
            path:"/merchanthelpdesk",
            component:<MerchantHelpdesk />,
            exact:true,
            permission:[],
            sidebar:false,
            icon:null,
            title:'title.merchantHelpdesk',
            category:'admin',
        },
        // ADMIN
        {
            path: "/admins",
            component: <Admins />,
            exact: true,
            permission: ['admin'],
            sidebar: true,
            icon: <PersonIcon />,
            title: 'title.admins',
            category:'admin',
        },
        {
            path: "/admins/add",
            component: <AdminAdd />,
            exact: true,
            permission: ['admin_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/admins/:id",
            component: <AdminEdit />,
            exact: true,
            permission: ['admin_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // SELLER
        {
            path: "/sellers",
            component: <Sellers />,
            exact: true,
            permission: ['company'],
            sidebar: true,
            icon: <StorefrontIcon />,
            title: 'title.sellers',
            category:'admin',
        },
        {
            path: "/sellers/add",
            component: <SellerAdd />,
            exact: true,
            permission: ['company_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/sellers/:id",
            component: <SellerEdit />,
            exact: true,
            permission: ['company_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/wallets/add-deduct",
            component: <WalletAddDeduct />,
            exact: true,
            permission: ['wallet_adjust'],
            sidebar: true,
            icon: <AccountBalanceWalletIcon />,
            title: 'title.walletAdjust',
            category:'admin',
        },
        // WITHDRAWAL
        {
            path: "/withdrawals",
            component: <Withdrawals />,
            exact: true,
            permission: ['withdrawal_seller'],
            sidebar: true,
            icon: <LocalAtmIcon />,
            title: 'title.withdrawals',
            category:'admin',
        },    
        {
            path: "/withdrawals-member",
            component: <WithdrawalsMember />,
            exact: true,
            permission: ['withdrawal_member'],
            sidebar: true,
            icon: <LocalAtmIcon />,
            title: 'title.withdrawalsMember',
            category:'admin',
        },
        // MEMBERSHIP
        {
            path: "/memberships",
            component: <Memberships />,
            exact: true,
            permission: ['membership'],
            sidebar: true,
            icon: <CardMembershipIcon />,
            title: 'title.memberships',
            category: 'admin',
        },
        // Faq
        {
            path: "/faqs",
            component: <Faqs />,
            exact: true,
            permission: ['faq'],
            sidebar: true,
            icon: <HelpIcon />,
            title: 'title.faqs',
            category:'admin',
        },
        {
            path: "/faqs/add",
            component: <FaqAdd />,
            exact: true,
            permission: ['faq_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/faqs/:id",
            component: <FaqEdit />,
            exact: false,
            permission: ['faq_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // BANNER
        {
            path:"/landingbanner",
            component: <LandingBanner />,
            exact:true,
            permission: ['banner_landing_edit'],
            sidebar:true,
            icon:<PhotoLibraryIcon />,
            title:'banner.landingBanner',
            category: 'admin',
        },
        // NOTIFICATION
        {
            path: "/notifications",
            component: <Notifications />,
            exact: true,
            permission: ['notification'],
            sidebar: true,
            icon: <NotificationsIcon />,
            title: 'title.notifications',
            category:'admin',
        },
        {
            path: "/notifications/add",
            component: <NotificationAdd />,
            exact: true,
            permission: ['notification_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/notifications/:id",
            component: <NotificationEdit />,
            exact: true,
            permission: ['notification_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // ANNOUNCEMENT
        {
            path: "/announcement",
            component: <Announcements />,
            exact: true,
            permission: ['announcement'],
            sidebar: true,
            icon: <AnnouncementIcon />,
            title: 'title.announcements',
            category:'admin',
        },
        //KYC
        {
            path: "/kyc",
            component: <Kyc />,
            exact: true,
            permission: ['member'],
            sidebar: true,
            icon: <EmojiPeopleIcon />,
            title: 'title.kyc',
            category:'admin',
        },
        // POST
        {
            path: "/courses",
            component: <Posts />,
            exact: true,
            permission: ['post'],
            sidebar: true,
            icon: <CollectionsIcon />,
            title: 'title.post',
            category: 'admin',
        },
        {
            path: "/course/add",
            component: <PostAdd />,
            exact: true,
            permission: ['post_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/course/:id",
            component: <PostEdit />,
            exact: true,
            permission: ['post'],
            sidebar: false,
            icon: null,
            title: '',
        },
    // category: seller
        {
            path: "/chat",
            component: <Chat />,
            exact: true,
            permission: ['chat_merchant_member'],
            sidebar: true,
            icon: <SmsIcon />,
            title: 'title.chat',
            category:'seller',
        },
        // MERCHANT
        {
            path: "/merchants",
            component: <Merchants />,
            exact: true,
            permission: ['merchant'],
            sidebar: true,
            icon: <HowToRegIcon />,
            title: 'title.merchants',
            category:'seller',
        },
        {
            path: "/merchants/add",
            component: <MerchantAdd />,
            exact: true,
            permission: ['merchant_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/merchants/add/:comId",
            component: <MerchantAdd />,
            exact: true,
            permission: ['merchant_add', 'company_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/merchants/:id",
            component: <MerchantEdit />,
            exact: true,
            permission: ['merchant_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/import",
            component: <ImportProduct />,
            exact: true,
            permission: ['product_import'],
            sidebar: true,
            icon: <GetAppIcon />,
            title: 'title.importProducts',
            category: 'seller',
        },
        // PRODUCT
        {
            path: "/products",
            component: <Products />,
            exact: true,
            permission: ['product'],
            sidebar: true,
            icon: <ShoppingCartIcon />,
            title: 'title.products',
            category: 'seller',
        },
        {
            path: "/products/bulk-upload",
            component: <ProductBulkUpload />,
            exact: true,
            permission: ['product_add'],
            sidebar: true,
            icon: <ShoppingCartIcon />,
            title: 'product.bulkUploadProduct',
            category: 'seller',
        },
        {
            path: "/products/add",
            component: <ProductAdd />,
            exact: true,
            permission: ['product_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/products/:id",
            component: <ProductEdit />,
            exact: false,
            permission: ['product_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // VOUCHER
        {
            path: "/vouchers",
            component: <Vouchers />,
            exact: true,
            permission: ['voucher'],
            sidebar: true,
            icon: <ShoppingCartIcon />,
            title: 'title.vouchers',
            category:'seller',
        },
        {
            path: "/vouchers/add",
            component: <VoucherAdd />,
            exact: true,
            permission: ['voucher_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/vouchers/:id/details",
            component: <VoucherDetails />,
            exact: false,
            permission: ['voucher'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/vouchers/:id",
            component: <VoucherEdit />,
            exact: true,
            permission: ['voucher_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        //Event Product
        {
            path:"/event-products",
            component:<EventProducts/>,
            exact:true,
            permission: ['event'],
            sidebar: true,
            icon: <EventIcon />,
            title: 'title.eventProducts',
            category: 'seller',
        },
        {
            path: "/event-products/add",
            component: <EventProductAdd />,
            exact: true,
            permission: ['event'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/event-products/:id",
            component: <EventProductEdit />,
            exact: false,
            permission: ['event'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // ORDER
        {
            path: "/orders",
            component: <Orders />,
            exact: true,
            permission: ['order'],
            sidebar: true,
            icon: <LibraryBooksIcon />,
            title: 'title.orders',
            badge: true,
            category: 'seller',
        },
        {
            path: "/orders/add",
            component: <OrderAdd />,
            exact: true,
            permission: ['order_edit'],
            sidebar: true,
            icon: <LibraryBooksIcon />,
            title: 'order.addOrder',
            category: 'seller',
        },
        {
            path: "/orders/:id",
            component: <OrderEdit />,
            exact: true,
            permission: ['order_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/orders-all",
            component: <OrdersAll />,
            exact: true,
            permission: ['order_all'],
            sidebar: true,
            icon: <LibraryBooksIcon />,
            title: 'title.orders_all',
            badge: true,
            category: 'seller',
        },
        //LUCKYDRAW
        {
            path: "/luckydraw/event-summary",
            component: <EventSummary />,
            exact: true,
            permission: ['event_summary'],
            sidebar: true,
            icon: <ConfirmationNumberIcon />,
            title: 'title.eventSummary',
            category: 'seller',
        },
        {
            path: "/luckydraw/order-list/:id",
            component: <LuckyDrawOrders />,
            exact: true,
            permission: ['event_summary'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // COLLECTION
        {
            path: "/collections",
            component: <Collections />,
            exact: true,
            permission: ['collection'],
            sidebar: true,
            icon: <CollectionsIcon />,
            title: 'title.collections',
            category: 'seller',
        },
        {
            path: "/collections/:id",
            component: <CollectionEdit />,
            exact: true,
            permission: ['collection_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // REVIEW
        {
            path: "/shop-reviews",
            component: <ShopReviews />,
            exact: true,
            permission: ['review'],
            sidebar: true,
            icon: <RateReviewIcon />,
            title: 'title.shopReviews',
            category:'seller',
        },
        {
            path: "/product-reviews",
            component: <ProductReviews />,
            exact: true,
            permission: ['review'],
            sidebar: true,
            icon: <RateReviewIcon />,
            title: 'title.productReviews',
            category:'seller',
        },
        // Q&A
        {
            path: "/product-questions",
            component: <ProductQuestions />,
            exact: true,
            permission: ['qna'],
            sidebar: true,
            icon: <QuestionAnswerIcon />,
            title: 'title.productQuestions',
            category:'seller',
        },
        {
            path: "/wallets/balance",
            component: <WalletBalance />,
            exact: true,
            permission: ['wallet_balance'],
            sidebar: true,
            icon: <AccountBalanceWalletIcon />,
            title: 'title.walletBalance',
            category: 'seller',
        },
    // category: member
        // MEMBER
        {
            path: "/members",
            component: <Members />,
            exact: true,
            permission: ['member'],
            sidebar: true,
            icon: <PeopleIcon />,
            title: 'title.members',
            category: 'member',
        },
        {
            path: "/members/add",
            component: <MemberAdd />,
            exact: true,
            permission: ['member_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/members/:id",
            component: <MemberEdit />,
            exact: true,
            permission: ['member_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        //SPONSOR GENEALOGY
        {
            path: "/sponsor-genealogy",
            component:<SponsorGenealogy />,
            exact: true,
            permission: ['member_sponsor_genealogy'],
            sidebar: true,
            icon: <AccountTreeIcon />,
            title: 'title.sponsorGenealogy',
            category:'member',
        },
        //PLACEMENT GENEALOGY
        {
            path: "/placement-genealogy",
            component: <GenealogyPlacement />,
            exact: true,
            permission: ['member_placement_genealogy'],
            sidebar: true,
            icon: <AccountTreeIcon />,
            title: 'title.placementGenealogy',
            category:'member',
        },
        {
            path: "/placement-genealogy2",
            component: <GenealogyPlacement2 />,
            exact: true,
            permission: ['member_placement_genealogy'],
            sidebar: false,
            icon: <AccountTreeIcon />,
            title: 'title.placementGenealogy',
            category:'member',
        },
        //UPLINE
        {
            path: "/upline",
            component: <Uplines />,
            exact: true,
            permission: ['member_upline'],
            sidebar: true,
            icon: <AccountTreeIcon />,
            title: 'title.upline',
            category:'member',
        },
    // category: setting
        // BONUS
        {
            path: "/bonuses",
            component: <Bonuses />,
            exact: true,
            permission: ['bonus'],
            sidebar: true,
            icon: <MonetizationOnIcon />,
            title: 'title.bonuses',
            category:'setting',
        },
        {
            path: "/bonuses/add",
            component: <BonusAdd />,
            exact: true,
            permission: ['bonus_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/bonuses/:id",
            component: <BonusEdit />,
            exact: true,
            permission: ['bonus_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // CATEGORY
        {
            path: "/categories",
            component: <Categories />,
            exact: true,
            permission: ['category'],
            sidebar: true,
            icon: <CategoryIcon />,
            title: 'title.categories',
            category: 'setting',
        },
        {
            path: "/categories/add",
            component: <CategoryAdd />,
            exact: true,
            permission: ['category_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/categories/:id",
            component: <CategoryEdit />,
            exact: true,
            permission: ['category_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // PARAMETER
        {
            path: "/parameters",
            component: <Parameters />,
            exact: true,
            permission: ['parameter'],
            sidebar: true,
            icon: <SettingsIcon />,
            title: 'title.parameters',
            category:'setting',
        },
        {
            path: "/parameters/add",
            component: <ParameterAdd />,
            exact: true,
            permission: ['parameter_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/parameters/:id",
            component: <ParameterEdit />,
            exact: false,
            permission: ['parameter_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // PERMISSION
        {
            path: "/permissions",
            component: <Permissions />,
            exact: true,
            permission: ['permission'],
            sidebar: true,
            icon: <BeenhereIcon />,
            title: 'title.permissions',
            category: 'setting',
        },
        {
            path: "/permissions/add",
            component: <PermissionAdd />,
            exact: true,
            permission: ['permission_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/permissions/:id",
            component: <PermissionEdit />,
            exact: true,
            permission: ['permission_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // POINT
        {
            path: "/points",
            component: <Points />,
            exact: true,
            permission: ['point'],
            sidebar: true,
            icon: <MonetizationOnIcon />,
            title: 'title.points',
            category:'setting',
        },
        {
            path: "/points/add",
            component: <PointAdd />,
            exact: true,
            permission: ['point_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/points/:id",
            component: <PointEdit />,
            exact: true,
            permission: ['point_edit'],
            sidebar: false,
            icon: null,
            title: '',
        }, 
        // RANK
        {
            path: "/ranks",
            component: <Ranks />,
            exact: true,
            permission: ['rank'],
            sidebar: true,
            icon: <CardMembershipIcon />,
            title: 'title.ranks',
            category: 'setting',
        },
        {
            path: "/ranks/add",
            component: <RankAdd />,
            exact: true,
            permission: ['rank_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/ranks/:id",
            component: <RankEdit />,
            exact: true,
            permission: ['rank_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },  
        // ROLE
        {
            path: "/roles",
            component: <Roles />,
            exact: true,
            permission: ['role'],
            sidebar: true,
            icon: <AccountTreeIcon />,
            title: 'title.roles',
            category:'setting',
        },
        {
            path: "/roles/add",
            component: <RoleAdd />,
            exact: true,
            permission: ['role_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/roles/:id",
            component: <RoleEdit />,
            exact: true,
            permission: ['role_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        // WALLET
        {
            path: "/wallets",
            component: <Wallets />,
            exact: true,
            permission: ['wallet'],
            sidebar: true,
            icon: <AccountBalanceWalletIcon />,
            title: 'title.wallets',
            category:'setting',
        },
        {
            path: "/wallets/add",
            component: <WalletAdd />,
            exact: true,
            permission: ['wallet_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/wallets/:id",
            component: <WalletEdit />,
            exact: true,
            permission: ['wallet_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/currency/add",
            component: <CurrencyAdd/>,
            exact: true,
            permission: ['currency_add'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/currency/:id",
            component: <CurrencyEdit />,
            exact: true,
            permission: ['currency_edit'],
            sidebar: false,
            icon: null,
            title: '',
        },
        {
            path: "/currency",
            component: <Currency />,
            exact: true,
            permission: ['currency'],
            sidebar: true,
            icon: <AttachMoneyIcon />,
            title: 'title.currency',
            category:'setting',
        },
        
    // REPORT
    // {
    //     path: "/reports",
    //     component: <Reports />,
    //     exact: true,
    //     permission: ['report'],
    //     sidebar: true,
    //     icon: <BarChartIcon />,
    //     title: 'title.reports',
    // },
    {
        path: "/reports/wallet-transaction",
        component: <ReportWalletTransaction />,
        exact: true,
        permission: ['report_member_wallet_history', 'report_seller_wallet_history'],
        permissionCheckType: 'OR',
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportWalletTransaction',
        category:'report',
    },
    {
        path: "/reports/wallet-balance",
        component: <ReportWalletBalance />,
        exact: true,
        permission: ['report_seller_wallet_balance', 'report_member_wallet_balance'],
        permissionCheckType: 'OR',
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportWalletBalance',
        category:'report',
    },
    {
        path: "/reports/daily-sales",
        component: <DailySales />,
        exact: true,
        permission: ['report_sales'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportDailySales',
        category:'report',
    },
    {
        path: "/reports/monthly-sales",
        component: <MonthlySales />,
        exact: true,
        permission: ['report_sales'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportMonthlySales',
        category:'report',
    },
    {
        path: "/reports/bonus-details",
        component: <ReportBonusDetails />,
        exact: true,
        permission: ['report_bonus_details'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportBonusDetails',
        category:'report',
    },
    {
        path: "/reports/bonus-summary",
        component: <ReportBonusSummary />,
        exact: true,
        permission: ['report_bonus_details'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportBonusSummary',
        category:'report',
    },
    {
        path: "/reports/bv-order",
        component: <ReportOrdersBV />,
        exact: true,
        permission: ['report_bv_order'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportBVOrder',
        category:'report',
    },
    {
        path: "/reports/simplified-sale",
        component: <ReportSimplifiedSale />,
        exact: true,
        permission: ['report_bv_order'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportSimplifiedSale',
        category:'report',
    },
    {
        path: "/reports/account-link",
        component: <ReportAccountLink />,
        exact: true,
        permission: ['report_account_link'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reportAccountLink',
        category:'report',
    },
    {
        path: "/reports/haloje",
        component: <HalojeSales />,
        exact: true,
        permission: ['report_third_party_sales'],
        sidebar: true,
        icon: <AssignmentIcon />,
        title: 'title.reporthaloje',
        category:'report',
    },

    // // REFUND
    // {
    //     path: "/refunds",
    //     component: <Refunds />,
    //     exact: true,
    //     permission: [],
    //     sidebar: true,
    //     icon: <AttachMoneyIcon />,
    //     title: 'title.refunds',
    // },
    

    // // COMPANY
    // {
    //     path: "/companies",
    //     component: <Companies />,
    //     exact: true,
    //     permission: ['company'],
    //     sidebar: true,
    //     icon: <BusinessIcon />,
    //     title: 'title.companies',
    // },
    // {
    //     path: "/companies/add",
    //     component: <CompanyAdd />,
    //     exact: true,
    //     permission: ['company_add'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // {
    //     path: "/companies/:id",
    //     component: <CompanyEdit />,
    //     exact: true,
    //     permission: ['company_edit'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },

    
];

/* function GenericNotFound() {
    return (
        <div>Not Found</div>
    );
} */

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/home">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function PrivateRoute({ children, ...rest }) {
    const { accessToken ,companyStatus ,companyId} = useSelector(state => state.general);
    let history = useHistory();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                accessToken ? companyStatus == '4'?( 
                    history.push(`/seller/register?company_id=${companyId} `)
                ):(
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const App  = (props) => {
    const classes = useStyles();
    const { accessToken, permissions, role, companyType, isAdmin } = useSelector(state => state.general);

    const Wrapper = children => (
        <NotificationLoadingProvider>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                {children}
                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );
    
    return (
        <Fragment>
            <div className={classes.root}>
                {
                    !_.includes(['/login', '/forgot-password', '/authorize', '/seller/register', '/sellers/tnc'], props.location.pathname) && !props.location['pathname'].includes('/reset-password/') ? <HeaderSidebar routeProps={routes} /> : ''
                }
                <main className={classes.content}>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/login">{accessToken ? <Redirect to={{ pathname: "/dashboard" }} /> : <Login/> } </Route>
                        {PROJECT_TYPE === "mall" ? <Route exact path="/authorize"><LoginCatalog/></Route> : null}
                        <Route exact path="/forgot-password">{accessToken ? <Redirect to={{ pathname: "/dashboard" }} /> : <NotificationLoadingProvider><PasswordForgot /><NotificationLoading /></NotificationLoadingProvider> } </Route>
                        <Route exact path="/reset-password/:token">{accessToken ? <Redirect to={{ pathname: "/dashboard" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider> } </Route>
                        <Route exact path="/seller/register"> <NotificationLoadingProvider><SellerRegister /><NotificationLoading /></NotificationLoadingProvider></Route>
                        <Route exact path="/sellers/tnc">{<NotificationLoadingProvider><SellerTnc /><NotificationLoading /></NotificationLoadingProvider>} </Route>
                        <Route path="/maintenance" component={Maintenance} />
                        {
                            routes.map(route => {
                                let fulfilledAllPermission = true;
                                if(_.size(route.permission) > 0) {
                                    let permissionChecking = _.hasIn(route, 'permissionCheckType') ? route.permissionCheckType : 'AND';
                                    
                                    if(permissionChecking === 'AND') {
                                        _.map(route.permission, requiredPermission => {
                                            if(!_.includes(permissions, requiredPermission)) {
                                                fulfilledAllPermission = false;
                                            }                                            
                                            if(_.includes(['merchant', 'merchant_edit', 'merchant_add', 'merchant_delete'], requiredPermission) && !isAdmin && parseInt(companyType) !== 1){
                                                fulfilledAllPermission = false;
                                            }
                                        })
                                    } else if(permissionChecking === 'OR') {
                                        fulfilledAllPermission = false;
                                        _.map(route.permission, requiredPermission => {
                                            if(_.includes(['merchant', 'merchant_edit', 'merchant_add', 'merchant_delete'], requiredPermission) && !isAdmin && parseInt(companyType) !== 1){
                                                fulfilledAllPermission = false;
                                            }

                                            if(_.includes(permissions, requiredPermission)) {
                                                fulfilledAllPermission = true;
                                            }
                                        });
                                    }

                                    if(route.path == "/wallets/balance" && isAdmin){
                                        fulfilledAllPermission = false;
                                    }
                                }
                                if(fulfilledAllPermission) {
                                    return <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{Wrapper(route.component)}</PrivateRoute>
                                }
                            })
                        }
                        <Route><Redirect to={{ pathname: "/dashboard" }} /></Route>
                    </Switch>
                </main>
            </div>
        </Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

export default withRouter(App);