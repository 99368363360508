import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles, AppBar, Drawer, Toolbar, IconButton, Typography, Link, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Badge, Collapse } from '@material-ui/core';
import { MenuOutlined, ChevronLeftOutlined, AssignmentOutlined, MoreVertOutlined, TranslateOutlined, AccountCircleOutlined, ExitToAppOutlined, StorefrontOutlined, ExpandLessOutlined, ExpandMoreOutlined, GroupOutlined, AccessibilityNewOutlined, StoreOutlined, TuneOutlined } from '@material-ui/icons';

import { changeLanguage as reduxChangeLanguage, getOrderCount, getAnnouncementUnreadCount } from '../../actions';
import { API } from '../../configs/AxiosConfig';
import { removeLoginAccess, getUrl } from '../../helper/ApiAction';

const DRAWER_WIDTH = 240;
const DEFAULT_LEVEL_OPEN = {
    member:false,
    admin:false,
    setting:false,
    seller:false,
    report:false,
};
const DEFAULT_STATE = {
    languageAnchorEl: null,
    mobileAnchorEl: null,
};

export default function MiniDrawer({ routeProps }) {
    const [currentPath, setCurrentPath] = useState();
    const [showDrawer, setShowDrawer] = useState(false);
    const [state, setState] = useState(DEFAULT_STATE);
    const [levelOpen, setLevelOpen]=useState(DEFAULT_LEVEL_OPEN);

    const classes = useStyles();
    const { accessToken, permissions, role, companyType, companyId, orderTotal, unreadAnnounceCount, isAdmin } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    let page = location.pathname;

    useEffect(() => {
        let active = true;

        if (active) {
            const path = _.split(location.pathname, '/');
            if (path) {
                setCurrentPath(path[1].toLowerCase());
            }
        }
        return () => { active = false };
    }, [location.pathname]);

    useEffect(() => {
        setShowDrawer(false);
        setLevelOpen(DEFAULT_LEVEL_OPEN);
    }, [currentPath])

    useEffect(() => {
        if(accessToken) {
            API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } else {
            delete API.defaults.headers.common['Authorization'];
        }
        API.defaults.headers.common['Language'] = i18n.language === 'cn' ? 'zh-CN' : 'en';
    }, [accessToken, i18n.language]);

    useEffect(() => {
        if(_.includes(permissions, 'order')) {
            let params = {'status': 'Paid' };
            getUrl('admin/orders', params).then(result => {
                let orderTotal = result.data ? result.data.total : 0;
                dispatch(getOrderCount(orderTotal));
            })
        }
        getUrl(`admin/admin_announcements`).then(result => {
            let newCount = result.new_count ? result.new_count : 0;
            dispatch(getAnnouncementUnreadCount(newCount));
        })
    },[dispatch]);

    // selected page in category expand it
    useEffect(()=>{
        _.map(routeProps,route=>{
            if(page ===route.path && route.category){
                handleOpenSecondLevel(route.category);
            }
        })
    },[]);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setState(DEFAULT_STATE);
    };

    const handleOpenSecondLevel = type =>{
        setShowDrawer(true);
        setLevelOpen({ ...levelOpen, [type]: !levelOpen[type] });
    }

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    const logoutUser = () => {
        removeLoginAccess();
        history.replace('/login');  
    };

    // const navigateUrl = path => {
    //     history.push(path);
    //     setShowDrawer(false);
    //     setLevelOpen(DEFAULT_LEVEL_OPEN);
    // }

    const renderMultiLevelListUrl = () => (
        <>
            {
                renderSubListUrl('report').filter(list=>list !==undefined).length?
                    <>
                        <ListItem button onClick={() => handleOpenSecondLevel('report')}>
                            <ListItemIcon><AssignmentOutlined /></ListItemIcon>
                            <ListItemText>{t(`title.category.report`)}</ListItemText>
                            {levelOpen.report ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                        </ListItem>
                        <Collapse in={levelOpen.report} timeout="auto" unmountOnExit >
                            {renderSubListUrl('report')}
                        </Collapse>
                    </>
                    : null
            }
            {
                renderSubListUrl('admin').filter(list=>list !==undefined).length?
                    <>
                        <ListItem button onClick={() => handleOpenSecondLevel('admin')}>
                            <ListItemIcon><GroupOutlined /></ListItemIcon>
                            <ListItemText>{t(`title.category.admin`)}</ListItemText>
                            {levelOpen.admin ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                        </ListItem>
                        <Collapse in={levelOpen.admin} timeout="auto" unmountOnExit >
                            {renderSubListUrl('admin')}
                        </Collapse>
                    </>
                    : null

            }
            {
               renderSubListUrl('seller').filter(list=>list !==undefined).length?
                    <>
                        <ListItem button onClick={() => handleOpenSecondLevel('seller')}>
                            <ListItemIcon><StoreOutlined /></ListItemIcon>
                            <ListItemText>{t(`title.category.seller`)}</ListItemText>
                            {levelOpen.seller ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                        </ListItem>
                        <Collapse in={levelOpen.seller} timeout="auto" unmountOnExit >
                            {renderSubListUrl('seller')}
                        </Collapse>
                    </>
                    :null
            }
            {
                renderSubListUrl('member').filter(list=>list !==undefined).length?
                    <>
                        <ListItem button onClick={() => handleOpenSecondLevel('member')}>
                            <ListItemIcon><AccessibilityNewOutlined /></ListItemIcon>
                            <ListItemText>{t(`title.category.member`)}</ListItemText>
                            {levelOpen.member ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                        </ListItem>
                        <Collapse in={levelOpen.member} timeout="auto" >
                            {renderSubListUrl('member')}
                        </Collapse>
                    </>
                    : null
            }
            {
                renderSubListUrl('setting').filter(list=>list !==undefined).length?
                    <>
                        <ListItem button onClick={() => handleOpenSecondLevel('setting')}>
                            <ListItemIcon><TuneOutlined /></ListItemIcon>
                            <ListItemText>{t(`title.category.setting`)}</ListItemText>
                            {levelOpen.setting ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                        </ListItem>
                        <Collapse in={levelOpen.setting} timeout="auto" unmountOnExit >
                            {renderSubListUrl('setting')}
                        </Collapse>
                    </>
                    : null
            }
        </>
    );

    // renderlist with catagetory
    const renderSubListUrl=(data)=>(
        _.map(routeProps, route => {
            let fulfilledAllPermission = true;
            _.map(route.permission, requiredPermission => {
                if(!_.includes(permissions, requiredPermission)) {
                    fulfilledAllPermission = false;
                }
                if(_.includes(['merchant', 'merchant_edit', 'merchant_add', 'merchant_delete'], requiredPermission) && !isAdmin && parseInt(companyType) !== 1){
                    fulfilledAllPermission = false;
                }

                if(isAdmin) {
                    if(_.includes(['/wallets/balance'], route.path)){
                        fulfilledAllPermission = false;
                    }
                } else {
                    if(_.includes(['company'], requiredPermission)){
                        fulfilledAllPermission = false;
                    }
                }
            })
            if(route.sidebar && fulfilledAllPermission && route.category && route.category === data) {
                return (
                    <Link key={route.path} underline='none' color="inherit" /* onClick={() => navigateUrl(route.path)} */ to={route.path} component={RouterLink}>
                        <ListItem button selected={page === route.path ? true : false} style={{ paddingLeft:'25px' }}>         
                            <ListItemIcon>
                                { route.badge ? <Badge badgeContent={orderTotal} classes={{ badge: classes.badgeStyle }}></Badge> : '' }
                                { route.badgeFor === "dashboard" && unreadAnnounceCount ? <Badge badgeContent={" "} classes={{ badge: classes.badgeStyle }}></Badge> : '' }
                                { route.icon }
                            </ListItemIcon>
                            <ListItemText primary={t(route.title)} />
                        </ListItem>
                    </Link>
                )
            }
        })
    );  

     // renderlist without catagetory
    const renderListUrl = () => (
        _.map(routeProps, route => {
            let fulfilledAllPermission = true;
            _.map(route.permission, requiredPermission => {
                if(!_.includes(permissions, requiredPermission)) {
                    fulfilledAllPermission = false;
                }
                if(_.includes(['merchant', 'merchant_edit', 'merchant_add', 'merchant_delete'], requiredPermission) && !isAdmin && parseInt(companyType) !== 1){
                    fulfilledAllPermission = false;
                }

                if(isAdmin) {
                    if(_.includes(['/wallets/balance'], route.path)){
                        fulfilledAllPermission = false;
                    }
                } else {
                    if(_.includes(['company'], requiredPermission)){
                        fulfilledAllPermission = false;
                    }
                }
            })
            if(route.sidebar && fulfilledAllPermission && !route.category)  {
                return (
                    <Link key={route.path} underline='none' /*onClick={() => navigateUrl(route.path)}*/ color="inherit" to={route.path} component={RouterLink}>
                        <ListItem button selected={page === route.path ? true : false}>         
                            <ListItemIcon>
                                { route.badge ? <Badge badgeContent={orderTotal} classes={{ badge: classes.badgeStyle }}></Badge> : '' }
                                { route.badgeFor === "dashboard" && unreadAnnounceCount ? <Badge badgeContent={" "} classes={{ badge: classes.badgeStyle }}></Badge> : '' }
                                { route.icon }
                            </ListItemIcon>
                            <ListItemText primary={t(route.title)} />
                        </ListItem>
                    </Link>
                )
            }
        })
    );

    return (
        _.includes(['maintenance'], currentPath) === true ? "" :
        <>
            <AppBar position="absolute" className={clsx(classes.appBar, showDrawer && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        className={clsx(classes.menuButton, showDrawer && classes.menuButtonHidden)}
                    >
                        <MenuOutlined />
                    </IconButton>
                    {/* <img src="https://i.pinimg.com/564x/4b/f1/c4/4bf1c427287fdc4d030cd4a9c08a01cf.jpg" style={{ height: '50px' }} alt=""/> */}
                    <img src="/images/logo-white.png" style={{ height: '43px'}} alt=""/>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {/* {APP_NAME} */}
                    </Typography>
                    <IconButton
                        aria-label="show more"
                        aria-haspopup="true"
                        onClick={ (event) => setState({ ...state, mobileAnchorEl: event.currentTarget }) }
                        color="inherit"
                    >
                        <MoreVertOutlined />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={state.mobileAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.mobileAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget }) } >
                    <IconButton
                        aria-controls="primary-search-language-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <TranslateOutlined />
                    </IconButton>
                    <p>{ t('home.translate') }</p>
                </MenuItem>
                <Link underline='none' color="inherit" to="/profile" onClick={() => handleMenuClose()} component={RouterLink}>
                <MenuItem>
                    <IconButton
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircleOutlined />
                    </IconButton>
                    <Typography variant="body1">{ t('profile.account') }</Typography>
                </MenuItem></Link>
                <MenuItem onClick={logoutUser} >
                    <IconButton
                        aria-controls="primary-search-logout-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <ExitToAppOutlined />
                    </IconButton>
                    <p>{ t('button.logout') }</p>
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={state.languageAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.languageAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => changeLanguage('cn')}>中文</MenuItem>
            </Menu>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !showDrawer && classes.drawerPaperClose),
                }}
                open={showDrawer}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftOutlined />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    { renderListUrl() }
                    {
                        _.size(_.intersection(role, ['super-merchant'])) && (companyId !== null && companyId !== '') ?
                        <Link underline='none' color="inherit" to={`/sellers/${companyId}`} component={RouterLink}>
                            <ListItem button selected={page === `/sellers/${companyId}` ? true : false} >
                                <ListItemIcon>
                                    <StorefrontOutlined />
                                </ListItemIcon>
                                <ListItemText primary={t('title.editSeller')} />
                            </ListItem>
                        </Link>
                        :null
                    }
                    {renderMultiLevelListUrl()}
                </List>
                <Divider />
            </Drawer>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#008645',
        background: '#008645',  /* fallback for old browsers */
        // background: '-webkit-linear-gradient(to right, #FFD200, #F7971E)',  /* Chrome 10-25, Safari 5.1-6 */
        // background: 'linear-gradient(to right, #FFD200, #F7971E)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    },
    appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'auto',
        height: '100vh',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            // background: 'linear-gradient(180deg,#fffafa,#d8d8d8, #fffafa);',
            backgroundColor: '#008645',
            outline: '1px solid slategrey'
        }
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    badgeStyle: {
        color: "#000000",
        backgroundColor: '#008645',
    }
}));