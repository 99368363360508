import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';

export default function PointAdd() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const { permissions } = useSelector(state => state.general);

    const [inputErrors, setInputErrors] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [state, setState] = useState({
        source: '',
        code: '',
        name: '',
        prefix: '',
    });

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/source_list`).then(response => {
            if(isMountedRef.current) {
                if(response.status === 1){
                    setSourceList(response.data);
                }else{
                    if(response.data){
                        let msg = response.data;
                        addAlert('', msg, 'error', '');
                    }
                }
            }
        }).catch(error => {
            addAlert(t('snackbarMsg.getError', error+"\n"+t('error.contactSupport')));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert])

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            source : state.source,
            code : state.code,
            name: state.name,
            prefix: state.prefix,
            source_list: sourceList
        }
        console.log("updateData", updateData);
        postUrl(`admin/points/add`, updateData).then(response => {
            console.log("response", response);
            setLoading(false);
            if(response.status) {
                setState({
                    source: '',
                    code: '',
                    name: '',
                    prefix: '',
                });
                if(response.data.id){
                    addAlert( `${t('snackbarMsg.addSuccess')} ID: ${response.data.id}`, '', 'success');
                }else{
                    addAlert( `${t('snackbarMsg.addSuccess')}`, '', 'success');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.addError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.addError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('point.addPoint')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/points`} component={RouterLink}>{t('point.points')}</Link>
                        <Typography style={{ color: 'black' }}>{t('point.addPoint')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="source" 
                                label={t('point.source')}
                                variant="outlined" 
                                fullWidth 
                                value={state.source}
                                helperText={inputErrors.source ? inputErrors.source :''}
                                error={inputErrors.source ? true : false}
                                onChange={({ target }) => setState({ ...state, source: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="code" 
                                label={t('point.code')}
                                variant="outlined" 
                                fullWidth 
                                value={state.code}
                                helperText={inputErrors.code ? inputErrors.code :''}
                                error={inputErrors.code ? true : false}
                                onChange={({ target }) => setState({ ...state, code: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label={t('point.name')}
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name ? inputErrors.name :''}
                                error={inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="prefix" 
                                label={t('point.prefix')}
                                variant="outlined" 
                                fullWidth 
                                value={state.prefix}
                                helperText={inputErrors.prefix ? inputErrors.prefix :''}
                                error={inputErrors.prefix ? true : false}
                                onChange={({ target }) => setState({ ...state, prefix: target.value }) }
                            />
                        </Grid>
                        {
                            _.includes(permissions, "point_visible") && _.size(sourceList) > 0
                            ?
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3} direction="column">
                                    <Grid item>
                                        <Typography variant="body1">{t('point.pointVisible')}</Typography>
                                        <Typography variant="caption" style={{color: "#808080", fontStyle: "italic"}}>{t('point.emptyIsPublic')}</Typography>
                                    </Grid>
                                    {
                                        _.map((sourceList), (source, key) => {
                                        return(
                                            <Grid item key={key} style={{padding: "0px 12px"}}>
                                                <FormControlLabel
                                                    key={key}
                                                    control={<Checkbox checked={sourceList[key].checked} onChange={(event) => setSourceList({...sourceList, [key]:{ ...sourceList[key], checked: event.target.checked }})} />}
                                                    label={source.name}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit}>{t('button.add')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));