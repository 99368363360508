import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getUrl, putUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

import { Typography, Link, Grid, Button, TextField, Modal, List, ListItem, ListItemText, RootRef, Box, InputLabel, Select, MenuItem, LinearProgress, FormControl } from '@material-ui/core';

export default function MonthlySales() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions, role, isAdmin } = useSelector(state => state.general);
    const [merchantList, setMerchantList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [merchantFilter, setMerchantFilter] = useState('mall');
    const [statusFilter, setStatusFilter] = useState('25');
    const [dateFilter, setDateFilter] = useState(new Date());
    const [tableLoading, setTableLoading] = useState(false);

    function callApi(){
        getUrl(`admin/get_monthly_sales?merchantId=${merchantFilter}&create_at=${dateFilter}&status=${statusFilter}`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                console.log(response.data);
                setTableData(response.data);
                setStatusList(response.order_status);
                if(isAdmin){
                    setMerchantList(response.merchants);
                }
            }
        }).catch((error) => {
            setTableLoading(false);
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [merchantFilter, dateFilter, statusFilter]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.monthlysales.title')}</Typography>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: t('reports.dailysales.seller'), field: 'shop_name' },
                        { title: t('reports.dailysales.order_amount'), field: 'total_amount' },
                        { title: t('reports.monthlysales.best_product'), field: 'title' },
                        { title: t('reports.monthlysales.best_category'), field: 'cat_name' },
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        // selection: _.includes(permissions, "collection_delete") ? true : false,
                    }}
                    /* actions={_.includes(permissions, "collection_delete") ? [
                        {
                            tooltip: t('collection.activateSelectedCollection'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'active')
                        },
                        {
                            tooltip: t('collection.inactiveSelectedCollection'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'inactive')
                        },
                        {
                            tooltip: t('collection.deleteSelectedCollection'),
                            icon: 'delete',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'delete')
                        }
                    ] : null} */
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            // nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    {   
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker 
                                            views={["year", "month"]}
                                            label={t('reports.dailysales.create_date')}
                                            minDate={new Date("2020-01-01")}
                                            maxDate={new Date("2070-01-01")}
                                            value={dateFilter}
                                            onChange={ setDateFilter }
                                        />
                                        </MuiPickersUtilsProvider>
                                    }
                                    {
                                        <FormControl>
                                        <InputLabel>{t('reports.dailysales.status')}</InputLabel>
                                        <Select
                                            value={statusFilter}
                                            onChange={ ({target}) => setStatusFilter(target.value) }
                                            style={{ minWidth: 100, textAlign: 'center' }}
                                        >
                                            <MenuItem value='all'>{t('reports.dailysales.all')}</MenuItem>
                                            {
                                                Object.keys(statusList).map((keyname, key) => {
                                                    return <MenuItem key={keyname} value={keyname}>{` ${statusList[keyname]}`}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        </FormControl>
                                    }
                                </Box>
                            </div>
                        ),
                    }}
                />
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    modal: {
        // position: 'absolute',
        // width: 'fit-content',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        marginTop: 'auto',
        overflow: 'auto',
    },
}));