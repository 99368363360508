import {
    ADD_ERROR, CHANGE_LANGUAGE, STORE_UUID, REMOVE_UUID, CART_TOTAL, CODE_STATE, RESET_CODE_STATE, AUTH_SUCCESS, ORDER_COUNT, ANNOUNCE_UNREAD_COUNT
} from '../actions/types';
import jwt from 'jwt-decode';

const INITIAL_STATE = {
    error: false,
    errorMessage: '',
    token: '',
    language: 'en',
    uuid: '',
    cartTotal: 0,
    loginState: '',
    codeVerifier: '',
    accessToken: '',
    refreshToken: '',
    expired: 0,
    permissions: [],
    role: "",
    companyType: "",
    companyId: "",
    companyStatus: "",
    isAdmin: false,
    isMerchant: false,
}

export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case ADD_ERROR:
            return { ...state, error: actions.payload.error };
        case CHANGE_LANGUAGE:
            return { ...state, language: actions.payload };
        case STORE_UUID:
            return { ...state, uuid: actions.payload };
        case REMOVE_UUID:
            return { ...state, uuid: '' };
        case CART_TOTAL:
            return { ...state, cartTotal: actions.payload };
        case CODE_STATE:
            return { ...state, loginState: actions.payload.state, codeVerifier: actions.payload.code };
        case RESET_CODE_STATE:
            return { ...state, loginState: '', codeVerifier: '', accessToken: '', refreshToken: '', expired: '' };
        case AUTH_SUCCESS:
            const decoded_jwt = jwt(actions.payload.access_token);
            return { 
                ...state, 
                accessToken: actions.payload.access_token, 
                refreshToken: actions.payload.refresh_token,
                expired: actions.payload.expires_in,
                loginState: '',
                codeVerifier: '',
                role: decoded_jwt ? decoded_jwt.role : '',
                permissions: decoded_jwt ? decoded_jwt.permissions : '',
                companyType: decoded_jwt ? decoded_jwt.company_type : '',
                companyId: decoded_jwt ? decoded_jwt.company_id : '',
                isAdmin: decoded_jwt.isAdmin,
                isMerchant: decoded_jwt.isMerchant,
                companyStatus: decoded_jwt.company_status,
            };
        case ORDER_COUNT:
            return { ...state, orderTotal: actions.payload };
        case ANNOUNCE_UNREAD_COUNT:
            return { ...state, unreadAnnounceCount: actions.payload }; 
        default:
            return state;
    }
}