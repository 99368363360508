import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, putUrl,postUrl  } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, TextField, Button, Grid, Paper } from '@material-ui/core';

function useInput({ type, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} />
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

export default function Profile() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { role, isMerchant } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    let history =useHistory();

    // const [data, setData] = useState({});

    const [name, nameInput, setNameError, setNameValue] = useInput({ type: "text", label: `${t('profile.name')}`, val: "" });
    const [email, emailInput, setEmailError, setEmailValue] = useInput({ type: "text", label: `${t('profile.email')}`, val: "" });
    const [mobile, mobileInput, setMobileError, setMobileValue] = useInput({ type: "text", label: `${t('profile.mobile')}`, val: "" });
    const [username, usernameInput, setUsernameError, setUsernameValue] = useInput({ type: "text", label: `${t('profile.username')}`, val: "" });
    const [old_password, oldPasswordInput, setOldPasswordError] = useInput({ type: "password", label: `${t('profile.currentPassword')}`, val: "" });
    const [password, passwordInput, setPasswordError] = useInput({ type: "password", label: `${t('profile.newPassword')}`, val: "" });
    const [password_confirmation, passwordConfirmationInput, setPasswordConfirmationError] = useInput({ type: "password", label: `${t('profile.confirmPassword')}`, val: "" });

    const validate = { name: setNameError, email: setEmailError, mobile: setMobileError, username: setUsernameError, 
        old_password: setOldPasswordError, password: setPasswordError, password_confirmation: setPasswordConfirmationError };

    const resetAllFieldError = () => {
        setNameError("");
        setEmailError("");
        setMobileError("");
        setUsernameError("");
        setOldPasswordError("");
        setPasswordError("");
        setPasswordConfirmationError("");
    };    

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`user`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                // setData(result.data);
                setNameValue(result.data.name);
                setEmailValue(result.data.email || "");
                setMobileValue(result.data.mobile || "");
                setUsernameValue(result.data.username || "");
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [setNameValue, setEmailValue, setMobileValue, setUsernameValue, setLoading, addAlert]);

    const updateProfile = () => {
        setLoading(true);
        resetAllFieldError();

        let apiData = {
            name,
            email,
            mobile,
            username,
        }
        putUrl(`admin/profile`, apiData)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message, error} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                }else{
                    addAlert('', message, 'error', '');
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            if(validate[key])
                                validate[key](value[0]);
                        })
                    }
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    const updatePassword = () => {
        setLoading(true);
        resetAllFieldError();

        let apiData = {
            old_password,
            password,
            password_confirmation,
        }
        putUrl(`admin/profile/password`, apiData)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message, error} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                }else{
                    addAlert('', message, 'error', '');
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            if(validate[key])
                                validate[key](value[0]);
                        })
                    }
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    const chatWithAdmin =()=>{
        postUrl(`admin/merchantchats`).then(response=>{
            if(response.status){
                // console.log("response", response.data.id);
                history.push(`/merchanthelpdesk`);
            }
        }).catch(error=>{
            addAlert(JSON.stringify(error.message));
        })
    }

    return(
        <Grid container spacing={3}>            
            {/* <Grid item xs={12}>
                <Typography variant="h4" component="h1">{t('profile.account')}</Typography>
                <Divider />
            </Grid> */}
            <Grid item xs={9}>
                <Typography variant="h4" component="h1">{t('profile.account')}</Typography>
            </Grid>
            {isMerchant ?
                <Grid item xs={3}>
                    <Button type="button" variant="contained" color="primary" onClick={chatWithAdmin}>{t('button.merchantHelpdesk')}</Button>
                </Grid>
            : null }
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h1">{t('profile.profile')}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                {nameInput}
                                {usernameInput}
                                {emailInput}
                                {mobileInput}                                
                            </Grid>
                            <Grid item xs={12} justify="center">
                                <Button type="button" variant="contained" color="primary" onClick={updateProfile}>{t('button.update')}</Button>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10vh' }}>
                                <Typography variant="h5" component="h1">{t('profile.accountAndSecurity')}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                {oldPasswordInput}
                                {passwordInput}
                                {passwordConfirmationInput}
                            </Grid>
                            <Grid item xs={12} justify="center">
                                <Button type="button" variant="contained" color="primary" onClick={updatePassword}>{t('button.update')}</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
}));