import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch , useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl } from '../helper/ApiAction';
import { getAnnouncementUnreadCount } from '../actions';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Divider, Dialog, DialogActions, DialogContent, DialogTitle, Badge, Paper, Grid, Table, TableHead, TableRow, TableCell, TableBody, Avatar, AppBar, Tabs, Tab, Card, Box } from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

import { Chart, ArgumentAxis, ValueAxis, LineSeries, Title, Legend, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import { currencyFormat } from '../helper/Tools';

const format = () => tick => tick;
const legendStyles = () => ({
    root: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'row',
    },
});
const legendLabelStyles = theme => ({
    label: {
        paddingTop: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
});
const legendItemStyles = () => ({
    item: {
        flexDirection: 'column',
    },
});

const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
    <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);

const ValueLabel = (props) => {
    const { text } = props;
    return (
        <ValueAxis.Label
            {...props}
            text={`${text}`}
        />
    );
};

const titleStyles = {
    title: {
        whiteSpace: 'pre',
    },
};
const TitleText = withStyles(titleStyles)(({ classes, ...props }) => (
    <Title.Text {...props} className={classes.title} />
));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index &&
                children
            }
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

export default function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();
    const history = useHistory();
    // const { accessToken } = useSelector(state => state.general);
    const { permissions } = useSelector(state => state.general);
    
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const [userInfo, setUserInfo] = useState({name: "", email: "", status: 0 });
    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState("");
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    const [dashboardStats, setDashboardStats] = useState({
        total_orders: 0,
        completed_orders: 0,
        total_sales: 0,
        members: 0,
        distributed: [],
        latest_orders: [],
        best_selling: [],
        order_last_14_days: [],
        order_last_12_months: [],
        order_last_10_years: []
    });
    const [tabValue, setTabValue] = useState('14days');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getAdminAnnouncements = () => {
        getUrl(`admin/admin_announcements`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setAnnouncements(result.data);
                setUnreadAnnouncementCount(result.new_count);
                dispatch(getAnnouncementUnreadCount(result.new_count));
            }
        }).catch((error) => {
            // addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`user`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setUserInfo(result.data);
            }
        }).catch((error) => {
            // addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        getAdminAnnouncements();

        if(_.includes(permissions, "dashboard_stat")){
            getUrl(`admin/admin_dashboard`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setDashboardStats({...dashboardStats, ...result['data']});
                }
            }).catch((error) => {
                // addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });

        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const testCommission = () => {
        getUrl(`admin/bonus/test`).then(result => {
            //
        }).catch((error) => {
            //
        });
    }

    const descriptionElementRef = useRef(null);
        useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }

            if(_.size(announcements[viewAnnouncement]['users']) === 0){
                getUrl(`admin/admin_announcements/${announcements[viewAnnouncement]['id']}`).then(result => {
                    if(result.status === 1 && isMountedRef.current) {
                        getAdminAnnouncements();
                    }
                }).catch((error) => {
                    // addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
            }
        }
    }, [viewAnnouncement]);

    return(
        <Grid container spacing={3} style={{margin:0}}>
            <Typography variant="h4" component="h1">{t("title.dashboard")}</Typography>
            <Grid item xs={12}>
                {/* <Button onClick={() => testCommission()}>Commission Test</Button> */}
            </Grid>
             <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                    <Card onClick={ () => history.push(`/orders`) } style={{ ...theme.box1, ...theme.p20, height: "100%", backgroundColor: "white", cursor: "pointer" }}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <ShoppingCartOutlinedIcon style={{ color: "#ffa000"}} fontSize="large" />
                            <Box display="flex" flexDirection="column" paddingX={1}>
                                <Typography variant="h6" gutterBottom>{dashboardStats.total_orders}</Typography>
                                <Typography variant="caption" display="block">{t("dashboard.totalOrders")}</Typography>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                    <Card onClick={ () => history.push(`/orders`) } style={{ ...theme.box1, ...theme.p20, height: "100%", backgroundColor: "white", cursor: "pointer" }}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <AssignmentTurnedInOutlinedIcon style={{ color: "#388e3c"}} fontSize="large" />
                            <Box display="flex" flexDirection="column" paddingX={1}>
                                <Typography variant="h6" gutterBottom>{dashboardStats.completed_orders}</Typography>
                                <Typography variant="caption" display="block">{t("dashboard.completedOrders")}</Typography>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                    <Card onClick={ () => history.push(`/reports/daily-sales`) } style={{ ...theme.box1, ...theme.p20, height: "100%", backgroundColor: "white", cursor: "pointer" }}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <MonetizationOnOutlinedIcon style={{ color: "#00a9f1"}} fontSize="large" />
                            <Box display="flex" flexDirection="column" paddingX={1}>
                                <Typography variant="h6" gutterBottom>{dashboardStats.total_sales}</Typography>
                                <Typography variant="caption" display="block">{t("dashboard.totalSales")}</Typography>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                    <Card onClick={ () => history.push(`/members`) } style={{ ...theme.box1, ...theme.p20, height: "100%", backgroundColor: "white", cursor: "pointer" }}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <PeopleOutlineOutlinedIcon style={{ color: "#d32f2f"}} fontSize="large" />
                            <Box display="flex" flexDirection="column" paddingX={1}>
                                <Typography variant="h6" gutterBottom>{dashboardStats.members}</Typography>
                                <Typography variant="caption" display="block">{t("dashboard.members")}</Typography>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            { _.size(dashboardStats.distributed) > 0 ?
                <Grid item xs={12} container spacing={3}>
                    { _.map(dashboardStats.distributed, (walletItem, walletCode) => (
                        <Grid key={walletCode} item xs={6} md={3}>
                            <Card onClick={ () => history.push(`/reports/wallet-balance`) } style={{ ...theme.box1, ...theme.p20, height: "100%", backgroundColor: "white", cursor: "pointer" }}>
                                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                    <AccountBalanceWalletOutlinedIcon style={{ color: "#a88f00" }} fontSize="large" />
                                    <Box display="flex" flexDirection="column" paddingX={1}>
                                        <Typography variant="h6" gutterBottom>{ currencyFormat((walletItem.total * 100) / 100) }</Typography>
                                        <Typography variant="caption" display="block">{ walletItem[`display_${i18n.language}`] }</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            : null }
            { _.size(dashboardStats.order_last_14_days) ? 
                <Grid item xs={12}>
                    <AppBar position="static" color="transparent">
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="chart tabs">
                            <Tab value="14days" label={t("dashboard.14days")} {...a11yProps('14days')} />
                            <Tab value="12months" label={t("dashboard.12months")} {...a11yProps('12months')} />
                            <Tab value="10years" label={t("dashboard.10years")} {...a11yProps('10years')} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index="14days">
                        <Paper>
                            <Chart
                                data={dashboardStats.order_last_14_days}
                                className={classes.chart}
                            >
                                <ArgumentAxis tickFormat={format} />
                                <ValueAxis
                                    labelComponent={ValueLabel}
                                />
                                <LineSeries
                                    name={t("dashboard.ordersCount")}
                                    valueField="count"
                                    argumentField="date"
                                />
                                <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                                <Title
                                    text={t("dashboard.chart14Days")}
                                    textComponent={TitleText}
                                />
                                <EventTracker />
                                <Tooltip />
                                <Animation />
                            </Chart>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={tabValue} index="12months">
                        <Paper>
                            <Chart
                                data={dashboardStats.order_last_12_months}
                                className={classes.chart}
                            >
                                <ArgumentAxis tickFormat={format} />
                                <ValueAxis
                                    labelComponent={ValueLabel}
                                />
                                <LineSeries
                                    name={t("dashboard.ordersCount")}
                                    valueField="count"
                                    argumentField="date_month"
                                />
                                <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                                <Title
                                    text={t("dashboard.chart12Months")}
                                    textComponent={TitleText}
                                />
                                <EventTracker />
                                <Tooltip />
                                <Animation />
                            </Chart>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={tabValue} index="10years">
                        <Paper>
                            <Chart
                                data={dashboardStats.order_last_10_years}
                                className={classes.chart}
                            >
                                <ArgumentAxis tickFormat={format} />
                                <ValueAxis
                                    labelComponent={ValueLabel}
                                />
                                <LineSeries
                                    name={t("dashboard.ordersCount")}
                                    valueField="count"
                                    argumentField="year"
                                />
                                <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                                <Title
                                    text={t("dashboard.chart10Years")}
                                    textComponent={TitleText}
                                />
                                <EventTracker />
                                <Tooltip />
                                <Animation />
                            </Chart>
                        </Paper>
                    </TabPanel>
                </Grid>
                : null
            }
            <Grid item xs={12} md={7} lg={8}>
                { _.size(dashboardStats.latest_orders) ? 
                    <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20, minHeight: 240 }}>
                        <h5 style={{letterSpacing:"1px",fontSize:"15px",marginTop:'0'}}>{t("dashboard.latestOrders")}</h5>
                        <Table size="small" aria-label="latest order table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('order.orderNo')}</TableCell>
                                    <TableCell>{t('order.buyerName')}</TableCell>
                                    <TableCell>{t('order.status')}</TableCell>
                                    <TableCell>{t('order.createdAt')}</TableCell>
                                    <TableCell align="right">{t('order.amount')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(dashboardStats.latest_orders, (row, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {row.order_no}
                                        </TableCell>
                                        <TableCell>{row.buyer_name}</TableCell>
                                        <TableCell>{row.status_display}</TableCell>
                                        <TableCell>{row.created_at}</TableCell>
                                        <TableCell align="right">{row.total_amount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    :
                    <Paper className={fixedHeightPaper} style={theme.bg_noorder} >
                        <h5 style={{textAlign:"center",letterSpacing:"1px",marginBottom:"0",fontSize:"15px"}}>{t("dashboard.noOrders")}</h5>
                    </Paper>
                }
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                { _.size(dashboardStats.wallets) ? 
                    <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20, minHeight: 240 }}>
                        <h5 style={{ letterSpacing: "1px", fontSize: "15px", marginTop: '0' }}>{t("dashboard.wallets")}</h5>
                        <Table size="small" aria-label="wallet balance table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('reports.walletBalance.walletName')}</TableCell>
                                    <TableCell align="right">{t('reports.walletBalance.amount')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(dashboardStats.wallets, (row, index) => {
                                    let name = _.split(row.wallet_name, '|');
                                    return(
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">{ i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}</TableCell>
                                            <TableCell align="right"><NumberFormat value={row.balance} decimalScale={row.decimal} displayType={'text'} thousandSeparator={true} /></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                    :
                    <Paper className={fixedHeightPaper} style={theme.bg_nomember}>
                        {/* <div style={{display:"flex",justifyContent:"center"}}><img src="../images/nomember.png" style={{ width: '150px' }} alt=""/></div> */}
                        <h5 style={{letterSpacing:"1px",fontSize:"15px",marginTop:'0'}}>{t("dashboard.noNewMember")}</h5>
                    </Paper>
                }
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
                { _.size(dashboardStats.best_selling) ? 
                    <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20, minHeight: 240 }}>
                        <h5 style={{ letterSpacing: "1px", fontSize: "15px", marginTop: '0' }}>{t("dashboard.bestSellings")}</h5>
                        <Table size="small" aria-label="best selling table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t('product.id')}</TableCell>
                                    <TableCell>{t('product.productName')}</TableCell>
                                    <TableCell align="right">{t('dashboard.price')}</TableCell>
                                    <TableCell align="right">{t('dashboard.sold')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(dashboardStats.best_selling, (row, index) => {
                                    return(
                                        <TableRow key={index}>
                                            <TableCell>{_.size(row.images_array) ? <Avatar alt="product" src={row.images_array[0]}/> : <Avatar>?</Avatar>}</TableCell>
                                            <TableCell component="th" scope="row">{ row.id }</TableCell>
                                            <TableCell>{ row[`title_${i18n.language}`] ? row[`title_${i18n.language}`] : row[`title_en`] }</TableCell>
                                            <TableCell align="right"><NumberFormat value={row.sell_price} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell>
                                            <TableCell align="right">{row.total_sold}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                    :
                    <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                        <h5 style={{ letterSpacing: "1px", fontSize: "15px", margin: '0' }}>{t("dashboard.nothingShow")}</h5>
                    </Paper>
                }
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {_.size(announcements) ?
                        <>
                            <Badge color="primary" badgeContent={unreadCount}><h5 style={{ letterSpacing: "1px", fontSize: "15px", margin: '0' }}>{t("title.announcements")}</h5></Badge>
                            <Divider style={{ marginBottom: 15 }}/>
                            <Grid container spacing={3} style={{overflow:"auto", maxHeight: 280 }}>
                                {_.map(announcements, (row, index) => (
                                    <Grid item xs={12} key={index} onClick={() => setViewAnnouncement(index)} style={{ cursor: 'pointer', backgroundColor: _.size(row['users']) === 0 ? '#edf1f5' : "white"}}>
                                        {index > 0 ? <Divider style={{ marginBottom: 15 }}/> : null}
                                        <Typography variant="h6" component="h6" style={{ letterSpacing: "1px", fontSize: "14px" }}>
                                            {row[`title_${i18n.language}`]}
                                            <Typography variant="caption" display="block">{new Date(row.updated_at).toLocaleDateString()}</Typography>
                                        </Typography>
                                        <Typography classes={{ body2: classes.txtTruncate }} variant="body2" component="span"><div dangerouslySetInnerHTML={{ __html: row['content'][`${i18n.language}`] }} /></Typography>
                                    </Grid>
                                    
                                ))
                                }
                            </Grid>
                            
                        </>
                        :
                        <h5 style={{ letterSpacing: "1px", fontSize: "15px", margin: '0' }}>{t("dashboard.noAnnouncement")}</h5>
                    }
                </Paper>
            </Grid>
            <Dialog
                open={viewAnnouncement !== "" ? true : false}
                onClose={() => setViewAnnouncement("")}
                scroll="body"
                aria-labelledby="view Announcement"
                aria-describedby="view Announcement detail"
            >
                <DialogTitle id="scroll-dialog-title">{viewAnnouncement !== "" && announcements ? announcements[viewAnnouncement][`title_${i18n.language}`] : ""}</DialogTitle>
                <DialogContent dividers={false}>
                    <div
                        id="viewAnnouncement"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {viewAnnouncement !== "" && announcements &&  announcements[viewAnnouncement]['image'] ?
                            <img src={announcements[viewAnnouncement]['image']['file_name']} style={{ maxWidth: "80vw"}}></img>
                            : null
                        }
                        {viewAnnouncement !== "" && announcements ?
                            <div dangerouslySetInnerHTML={{ __html: announcements[viewAnnouncement]['content'][`${i18n.language}`] }} />
                            : null
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setViewAnnouncement("")} color="primary">{t("button.close")}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        // height: '2.86em'
    },
    chart: {
        paddingRight: '20px',
    },
    // title: {
    //     whiteSpace: 'pre',
    // },
}));