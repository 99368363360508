import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import MaterialTable, { MTableToolbar } from 'material-table';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getUrl, putUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, Link, Grid, Button, TextField, Modal, List, ListItem, ListItemText, RootRef, Box, InputLabel, Select, MenuItem, LinearProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SortIcon from '@material-ui/icons/Sort';

export default function Collections() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions, role, isAdmin } = useSelector(state => state.general);
    const [statusType, setStatusType] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const statusTypeToCode = { 'active': 1, 'inactive': 0, 'delete': 2 };
    const [updateDialog, setOpenUpdateDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [collectionField, setCollectionField] = useState({
        title: '',
        collectableId: 0
    });
    const [openModal, setOpenModal] = useState(false);
    const [sortData, setSortData] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    const [merchantFilter, setMerchantFilter] = useState('mall');
    const [tableLoading, setTableLoading] = useState(false);

    function callApi(){
        getUrl(`admin/collections?merchantId=${merchantFilter}`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
                setSortData(_.sortBy(response.data, ['priority']));
                console.log(_.sortBy(response.data, ['priority']));
                if(isAdmin){
                    setMerchantList(response.merchants);
                }
            }
        }).catch((error) => {
            setTableLoading(false);
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [merchantFilter]);

    const alertChangeStatus = (event, data, type) => {
        setStatusType(type);
        setSelectedRow(data);
        setOpenUpdateDialog(true);
    };

    const updateStatus = () => {
        setLoading(true);

        let apiData = { status: statusTypeToCode[statusType], ids: _.map(selectedRow, 'id') };
        putUrl(`admin/collections/status`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    
                    _.map(selectedRow, (row, index) => {
                        let dataIndex = newTableData.findIndex(item => item.id === row.id);
                        if(statusType === "delete"){
                            newTableData.splice(dataIndex, 1);
                        }else{
                            newTableData[dataIndex] = { ...row, status: statusTypeToCode[statusType] };
                        }
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateDialog(false);
    };

    const addCollection = () => {
        setLoading(true);

        postUrl(`admin/collections`, { title: collectionField.title, collectable_id: collectionField.collectableId })
            .then((response) => {
                setLoading(false);
                let { status, message } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    setOpenDialog(false);
                    callApi();
                } else {
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
    };

    const sortCollections = () => {
        setLoading(true);

        let apiData = { ids: _.map(sortData, 'id') };
        putUrl(`admin/collections/sorting`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    callApi();
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenModal(false);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            sortData,
            result.source.index,
            result.destination.index
        );
        setSortData(items);
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
    
        ...(isDragging && {
            background: "rgb(235,235,235)"
        })
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '',
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('collection.collections')}</Typography>
                    {_.includes(permissions, "collection_add") ?
                        <Button size="medium" variant="outlined" startIcon={<AddIcon />} onClick={() => setOpenDialog(true)}>{t('button.new')}</Button>
                        : null
                    }
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('collection.title'), field: 'title', render: rowData => <Link underline='none' to={`/collections/${rowData.id}`} component={RouterLink}>{rowData.title}</Link> },
                        { title: t('collection.totalProduct'), field: 'total' },
                        { title: t('table.status'), field: 'status', render: rowData => t('collection.status.' + rowData.status) },
                        { title: t('collection.sequence'), field: 'priority' },
                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        selection: _.includes(permissions, "collection_delete") ? true : false,
                    }}
                    actions={_.includes(permissions, "collection_delete") ? [
                        {
                            tooltip: t('collection.activateSelectedCollection'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'active')
                        },
                        {
                            tooltip: t('collection.inactiveSelectedCollection'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'inactive')
                        },
                        {
                            tooltip: t('collection.deleteSelectedCollection'),
                            icon: 'delete',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'delete')
                        }
                    ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    {
                                        _.size(tableData) > 1 ?
                                            <div style={{ padding: '0px 10px' }}>
                                                <Button size="small" variant="outlined" startIcon={<SortIcon />} onClick={() => setOpenModal(true)}>{t('collection.sortCollection')}</Button>
                                            </div>
                                        : null
                                    }
                                    {
                                        isAdmin ?
                                                <Select
                                                    value={merchantFilter}
                                                    onChange={ ({target}) => setMerchantFilter(target.value) }
                                                    style={{ minWidth: 100, textAlign: 'center' }}
                                                >
                                                    <MenuItem value={'mall'}>{t('collection.mall')}</MenuItem>
                                                    {
                                                        _.map(merchantList, merchantItem => {
                                                            return <MenuItem key={merchantItem.id} value={merchantItem.id}>{` ${merchantItem.shop_name} [ID: ${merchantItem.id}] `}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                        : null
                                    }
                                </Box>
                            </div>
                        ),
                    }}
                />
                }
            </Grid>
            <Dialog
                open={updateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('collection.confirmOnStatusUpdate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`collection.askUpdateStatus.${statusTypeToCode[statusType]}`, { 'number': selectedRow ? selectedRow.length : 0 })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth={true} maxWidth="sm" aria-labelledby="dialog-custom-attribute">
                <DialogTitle id="custom-attribute-dialog-title">{t('collection.addCollection')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('general.inputMethod')}</DialogContentText>
                    <DialogContentText>{t('collection.inputMethodExample')}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={t('collection.title')}
                        type="email"
                        value={collectionField.title}
                        fullWidth
                        onChange={e => setCollectionField({ ...collectionField, title: e.target.value })}
                    />
                    {
                        isAdmin ?
                            <div style={{ paddingTop: 20 }}>
                                <InputLabel>{ t('collection.type') }</InputLabel>
                                <Select
                                    value={collectionField.collectableId}
                                    onChange={ ({target}) => setCollectionField({ ...collectionField, collectableId: target.value }) }
                                    style={{ textAlign: 'center' }}
                                    fullWidth
                                >
                                    <MenuItem value={0}>{t('collection.mall')}</MenuItem>
                                    {
                                        _.map(merchantList, merchantItem => {
                                            return <MenuItem key={merchantItem.id} value={merchantItem.id}>{` ${merchantItem.shop_name} [ID: ${merchantItem.id}] `}</MenuItem>
                                        })
                                    }
                                </Select>
                            </div>
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">{t('button.cancel')}</Button>
                    <Button onClick={addCollection} color="primary">{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="collection list sorting"
                aria-describedby="collection list sorting modal"
            >
                <Grid container spacing={3} justify="center">
                    <Grid item xs={10} sm={8} md={6}>
                        <Grid container spacing={3} className={classes.modal} justify="center">
                            <Grid item xs={12}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <RootRef rootRef={provided.innerRef}>
                                                <List dense style={getListStyle(snapshot.isDraggingOver)}>
                                                    {_.map(sortData, (row, index) => {
                                                        return ([
                                                            <Draggable key={`listitem-${row.id}`} draggableId={`listitem-${row.id}`} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <ListItem
                                                                        divider
                                                                        ContainerComponent="li"                                                                        
                                                                        ContainerProps={{ ref: provided.innerRef }}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}>
                                                                        <ListItemText
                                                                            primary={row.title}
                                                                            secondary={`${ t('collection.totalProduct')}: ${row.total}`}
                                                                        />
                                                                    </ListItem>
                                                                )}
                                                            </Draggable>
                                                        ])
                                                    })}
                                                    {provided.placeholder}
                                                </List>
                                            </RootRef>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                
                            </Grid>
                            <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                <Grid item xs={6} sm={4} md={3}>
                                    <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => setOpenModal(false)}>{t('button.close')}</Button>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <Button type="button" fullWidth variant="contained" color="primary" onClick={sortCollections}>{t('button.save')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    modal: {
        // position: 'absolute',
        // width: 'fit-content',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        marginTop: 'auto',
        overflow: 'auto',
    },
}));