import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { TextField, MenuItem, Button } from '@material-ui/core';
import _ from 'lodash';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Snackbar, Typography, Divider, Breadcrumbs, Link, Grid, Paper, FormControlLabel, Switch } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { getUrl, putUrl } from '../helper/ApiAction';
import { useMessage } from '../helper/Tools';

export default function ParameterEdit() {
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams();
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        ptype: 1,
        code: '',
        value: '',
        desc: '',
        status: false,
    });

    // const { accessToken } = useSelector(state => state.general);
    const { t } = useTranslation();

    const [paramType, setParamType] = useState([]);
    const { value:errorGeneral, setValue:setErrorGeneral, reset:resetErrorGeneral } = useMessage('');
    const { value:successGeneral, setValue:setSuccessGeneral, reset:resetSuccessGeneral } = useMessage('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    useEffect(() => {
        // console.log("parameter edit here");
        setLoading(true);
        getUrl(`admin/parameters-type`).then(result => {
            console.log(result.data);
            setParamType(result.data);
            setLoading(false);
        }).catch(error => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg);
            setLoading(false);
        })
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        setLoading(true);
        getUrl(`admin/parameters/${id}`).then(result => {
            console.log(result.data);
            setState({
                ptype: result.data.ptype_id,
                code: result.data.code,
                value: result.data.value,
                desc: result.data.desc,
                status: result.data.status === 1 ? true : false,
            });
        }).catch(error => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg);
            setLoading(false);
        })
        // eslint-disable-next-line
    },[id, addAlert]);

    useEffect(() => {
        if(errorGeneral !== ""){
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        }
    }, [errorGeneral]);

    useEffect(() => {
        if(successGeneral !== ""){
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
        }
    }, [successGeneral]);

    const handleChange = () => {
        setState({ ...state, status: !state.status });
    }

    const updateParameter = () => {
        resetErrorGeneral();
        resetSuccessGeneral();

        putUrl(`admin/parameters/${id}`, {
            value: state.value,
            desc: state.desc,
            status: state.status, 
        }).then(result => {
            if(result.status === 1) {
                setSuccessGeneral(result.message);
            } else {
                setErrorGeneral(result.message);
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{ t('parameter.editParameter') }</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/parameters`} component={RouterLink}>{t('title.parameters')}</Link>
                        <Typography style={{ color: 'black' }}>{t('parameter.editParameter')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                        <MuiAlert elevation={6} variant="filled" onClose={closeSnackbar} severity={snackbarSeverity}>
                            {successGeneral || errorGeneral}
                        </MuiAlert>
                    </Snackbar>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField
                                    id="select-parameter"
                                    select
                                    label={ t('parameter.type') }
                                    value={state.ptype}
                                    onChange={({ target }) => setState({ ...state, ptype: target.value }) }
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                >
                                    {
                                        _.map(paramType, option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.ptype}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="code" 
                                    label={ t('parameter.code') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.code}
                                    onChange={({ target }) => setState({ ...state, code: target.value }) }    
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={importProduct}>Import Now</Button>
                            </Grid> */}
                        </Grid>  
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="value" 
                                    label={ t('parameter.value') }
                                    multiline
                                    rows={2}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.value}
                                    onChange={({ target }) => setState({ ...state, value: target.value }) }    
                                />
                            </Grid>
                        </Grid>    
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="description" 
                                    label={ t('parameter.description') }
                                    multiline
                                    rows={4}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.desc}
                                    onChange={({ target }) => setState({ ...state, desc: target.value }) }    
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <FormControlLabel
                                    control={<Switch checked={state.status} onChange={handleChange} />}
                                    label={ t('parameter.status') }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <Button variant="contained" color="primary" onClick={updateParameter}>{ t('button.update') }</Button>
                            </Grid>
                        </Grid>    
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));