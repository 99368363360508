import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { postUrl, getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';

export default function PermissionAdd() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        name: '',
        parent_id: 0,
        assignRole: [],
    });
    const [mainPermission, setMainPermission] = useState([]);
    const [role, setRole] = useState([]);
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('admin/permissions?level=1').then(permissionList => {
            if(isMountedRef.current) {
                setMainPermission(permissionList.data.data);
            }
        }).catch(error => {
            addAlert(t('snackbarMsg.getError', error+"\n"+t('error.contactSupport')));
        });

        getUrl('admin/roles?noPagination=1').then(roleList => {
            if(isMountedRef.current) {
                setRole(roleList.data);
            }
        }).catch(error => {
            addAlert(t('snackbarMsg.getError', error+"\n"+t('error.contactSupport')));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert])

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            name : state.name,
            parent_id : state.parent_id,
            role: state.assignRole,
        }
        console.log("updateData", updateData);
        postUrl(`admin/permissions`, updateData).then(response => {
            console.log("response", response);
            setLoading(false);
            if(response.status) {
                if(response.data.id){
                    addAlert( `${t('snackbarMsg.addSuccess')} ID: ${response.data.id}`, '', 'success');
                }else{
                    addAlert( `${t('snackbarMsg.addSuccess')}`, '', 'success');
                }
            }else{
                addAlert(t('snackbarMsg.addError'));
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.addError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    const authorizeRole = roleName => {
        let roleAuthorize = state.assignRole;

        // const index = roleAuthorize.indexOf(roleAuthorize);
        if(_.includes(roleAuthorize, roleName)) {
            // roleAuthorize.splice(index, 1);
            // _.remove(roleAuthorize, function(name) {
            //     return 
            // });

            roleAuthorize = _.difference(roleAuthorize, [roleName]);
        } else {
            roleAuthorize.push(roleName);
        }

        setState({ ...state, assignRole: roleAuthorize });
    }

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('permission.addPermission')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/permissions`} component={RouterLink}>{t('permission.permissions')}</Link>
                        <Typography style={{ color: 'black' }}>{t('permission.addPermission')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Grid container spacing={3} direction="column" style={theme.p20}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="category_parent" 
                                label={t('category.parent')}
                                variant="outlined" 
                                fullWidth
                                select
                                value={state.parent_id}
                                helperText={inputErrors.parent_id ? inputErrors.parent_id : ''}
                                error={inputErrors.parent_id ? true : false}
                                onChange={({ target }) => setState({ ...state, parent_id: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option key='0' value='0'> --- { t('permission.mainPermission') } --- </option>
                                {
                                    _.map(mainPermission, option => {
                                        // console.log(option)
                                        return (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="permission_name" 
                                label={t('permission.name')}
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name ? inputErrors.name :' '}
                                error={inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="caption">{t('permission.assignPermission')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {
                                            _.map(role, roleItem => {
                                                return (
                                                    <Grid item xs={4} key={roleItem.id}>
                                                        <FormControlLabel
                                                            control={<Checkbox color="primary" checked={_.includes(state.assignRole, roleItem.name)} onChange={ () => authorizeRole(roleItem.name) } />}
                                                            label={roleItem.name}
                                                        /> 
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit}>{t('button.submit')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));