import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

// import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Checkbox ,FormHelperText } from '@material-ui/core';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import AddIcon from '@material-ui/icons/Add';

import Autocomplete from '@material-ui/lab/Autocomplete';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DropzoneArea } from 'material-ui-dropzone';
import _ from 'lodash';

export default function SellerAdd() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [attachments, setAttachments] = useState([]);
    // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    // const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [companyType, setCompanyType] = useState([]);
    const [collaboratorList, setCollaboratorList] = useState([]);
    const [logisticList, setLogisticList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateGroup, setStateGroup] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityGroup, setCityGroup] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [districtGroup, setDistrictGroup] = useState([]);
    const [merchantButton, setMerchantButton] = useState({
        show: false,
        company_id: '',
    });
    const [state, setState] = useState({
        companyName: '',
        shopName: '',
        slug: '',
        email: '',
        mobile: '',
        register_no: '',
        type: 0,
        pic: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        logisticPartner: '',
        about: '',
        status: 1,
        collaborator: '',
        business_nature: '',
        product_sell: '',
        referral: '',
        commencementDate: ''
    });
    const [userRoles, setUserRoles] = useState([]);
    const [superMerchant, setSuperMerchant] = useState({
        name: '',
        username: '',
        email: '',
        mobile: '',
        role: 'super-merchant',
        pwd: '',
        cfm_pwd: '',
    });

    const handleLogisticChange = (event, values) => {
        setState({ ...state, logisticPartner: values })
    }

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/merchants/add`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setUserRoles(response.data);
                console.log(response.data);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/companies`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setCompanyType(response.company_type);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/collaborators`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setCollaboratorList(response.collaborator);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/address`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setCountryList(response.country_list);
                setStateList(response.state_list);
                setStateGroup(response.state_group);
                setCityList(response.city_list);
                setCityGroup(response.city_group);
                setDistrictList(response.district_list);
                setDistrictGroup(response.district_group);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        if(state.country){
            getUrl(`admin/logistic_list/${state.country}`).then(response => {
                if (response.status === 1) {
                    setLogisticList(response.easy_parcel_logistic);
                }
            }).catch((error) => {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            })
        }
        // eslint-disable-next-line
    }, [addAlert, state.country]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

   const form_submit =async () => {
        setLoading(true);
        setInputErrors("");

        let newAttachmentsBase64 = [];

        if(_.size(attachments) > 0){            
            for (const attachment of attachments) {
                newAttachmentsBase64.push(await toBase64(attachment));
            }
        }

        const updateData = {
            company_name: state.companyName,
            shop_name: state.shopName,
            slug: state.slug,
            email: state.email,
            mobile: state.mobile,
            register_no: state.register_no,
            type: state.type,
            pic: state.pic,
            address: state.address,
            address2: state.address2,
            city: state.city,
            state: state.state,
            zip: state.zip,
            country: state.country,
            log_partner: state.logisticPartner,
            about: state.about,
            status: state.status?1:0,
            manager_name: superMerchant.name,
            manager_username: superMerchant.username,
            manager_email: superMerchant.email,
            manager_mobile: superMerchant.mobile,
            manager_role: superMerchant.role,
            manager_password: superMerchant.pwd,
            manager_password_confirmation: superMerchant.cfm_pwd,
            collaborator: state.collaborator,
            create_outlet: state.create_outlet,
            business_nature:state.business_nature,
            product_sell:state.product_sell,
            referral:state.referral,
            attachments: newAttachmentsBase64,
            commencement_date: state.commencementDate
        }
        postUrl(`admin/companies/add`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = t('snackbarMsg.updateSuccess')+ ' ID: ' + response.data.id;
                    addAlert('', msg, 'success', '');
                    if(state.type === '1'){
                        setMerchantButton({
                            show: true,
                            company_id: response.data.id,
                        });
                    }
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                    setMerchantButton({
                        show: true,
                    });
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('seller.addSeller')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/sellers`} component={RouterLink}>{t('seller.sellers')}</Link>
                        <Typography style={{ color: 'black' }}>{t('seller.addSeller')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='primary' style={{textAlign: 'center'}}>{t('seller.sellerDetails')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="type" 
                            label={t('seller.type')}
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.type}
                            helperText={inputErrors.type?inputErrors.type:''}
                            error={inputErrors.type?true:false}
                            onChange={({ target }) => setState({ ...state, type: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        {companyType.map((value, key) => (
                            <option key={key} value={key}>
                                {i18n.language === 'cn' ?value['cn']:value['en'] }
                            </option>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="collaborator" 
                            label={t('seller.collaborator')}
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.collaborator}
                            helperText={inputErrors.collaborator?inputErrors.collaborator:''}
                            error={inputErrors.collaborator?true:false}
                            onChange={({ target }) => setState({ ...state, collaborator: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                            {Object.keys(collaboratorList).map((keyname, key) => (
                                <option key={key} value={keyname}>
                                    {collaboratorList[keyname]}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="company_name" 
                            label={t('seller.name')}
                            variant="outlined" 
                            fullWidth 
                            value={state.companyName}
                            helperText={inputErrors.company_name?inputErrors.company_name:''}
                            error={inputErrors.company_name?true:false}
                            onChange={({ target }) => setState({ ...state, companyName: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="register_no" 
                            label={t('seller.registerNo')}
                            variant="outlined" 
                            fullWidth 
                            value={state.register_no}
                            helperText={inputErrors.register_no?inputErrors.register_no:''}
                            error={inputErrors.register_no?true:false}
                            onChange={({ target }) => setState({ ...state, register_no: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="shop_name" 
                            label={t('seller.shopName')}
                            variant="outlined" 
                            fullWidth 
                            value={state.shopName}
                            helperText={inputErrors.shop_name?inputErrors.shop_name:''}
                            error={inputErrors.shop_name?true:false}
                            // onChange={({ target }) => setState({ ...state, shopName: target.value, slug: target.value.replace(/ /g, '-') }) }
                            onChange={({ target }) => {
                                const shop_name = target.value;
                                let slug = shop_name.split("|");
                                slug = slug[0].replace(/[^A-Za-z0-9| ]/g, '').replace(/ /g, '-').toLowerCase()
                                
                                setState({ ...state, shopName: shop_name, slug }) 
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="slug" 
                            label={t('seller.slug')}
                            variant="outlined" 
                            fullWidth 
                            value={state.slug}
                            helperText={inputErrors.slug?inputErrors.slug:t('seller.slug_helperText')}
                            error={inputErrors.slug?true:false}
                            // onChange={({ target }) => setState({ ...state, slug: target.value.replace(/ /g, '-') }) }
                            onChange={({ target }) => {
                                const shop_slug = target.value;
                                let slug = shop_slug.split("|");
                                slug = slug[0].replace(/[^A-Za-z0-9-]/g, '').replace(/ /g, '-').toLowerCase()
                                
                                setState({ ...state, slug }) 
                                // setState({ ...state, slug: target.value.replace(/ /g, '-') }).toLowerCase()
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="email" 
                            label={t('profile.email')}
                            variant="outlined" 
                            fullWidth 
                            value={state.email}
                            helperText={inputErrors.email?inputErrors.email:''}
                            error={inputErrors.email?true:false}
                            onChange={({ target }) => setState({ ...state, email: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="mobile" 
                            label={t('profile.mobile')}
                            variant="outlined" 
                            fullWidth 
                            value={state.mobile}
                            helperText={inputErrors.mobile?inputErrors.mobile:''}
                            error={inputErrors.mobile?true:false}
                            onChange={({ target }) => setState({ ...state, mobile: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="pic" 
                            label={t('seller.pic')}
                            variant="outlined" 
                            fullWidth 
                            value={state.pic}
                            helperText={inputErrors.pic?inputErrors.pic:''}
                            error={inputErrors.pic?true:false}
                            onChange={({ target }) => setState({ ...state, pic: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="country" 
                            label={t('address.country')} 
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.country}
                            helperText={inputErrors.country?inputErrors.country:''}
                            error={inputErrors.country?true:false}
                            onChange={({ target }) => setState({ ...state, country: target.value, state: '', city: '', address2: '', logisticPartner: [] }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('address.pleaseSelect')+t('address.country')}
                        </option>
                        {countryList.map((option) => (
                            <option key={option.code} value={option.code}>
                                {i18n.language === 'cn' ?option.name_cn:option.name }
                            </option>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            multiple
                            id="log_partner"
                            options={logisticList}
                            disableCloseOnSelect
                            filterSelectedOptions
                            onChange={handleLogisticChange}
                            value={state.logisticPartner}
                            getOptionLabel={(option) => option.desc}
                            // renderOption={(option, { selected }) => (
                            //     <React.Fragment>
                            //         <Checkbox
                            //             icon={icon}
                            //             checkedIcon={checkedIcon}
                            //             style={{ marginRight: 8 }}
                            //             checked={selected}
                            //         />
                            //         {option.desc}
                            //     </React.Fragment>
                            // )}
                            renderInput={(params) => (
                                <TextField {...params}
                                    variant="outlined"
                                    label={t('seller.logisticPartner')}
                                    fullWidth
                                    helperText={(state.country?'':t('seller.selectCountryForLogistic')) + t('seller.logisticLeaveBlank')}
                                />
                            )}
                            // getOptionSelected={(option, { desc }) => {
                            //     return (option.desc === desc);
                            // }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="about" 
                            label={t('seller.about')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.about}
                            helperText={inputErrors.about?inputErrors.about:''}
                            error={inputErrors.about?true:false}
                            onChange={({ target }) => setState({ ...state, about: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                            <TextField
                                id="business_nature"
                                label={t('seller.business_nature') + ' *'}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={5}
                                value={state.business_nature}
                                helperText={inputErrors.business_nature ? inputErrors.business_nature : ''}
                                error={inputErrors.business_nature ? true : false}
                                onChange={({ target }) => setState({ ...state, business_nature: target.value })}
                            />
                    </Grid>
                    <Grid item xs={12} md={6}>
                            <TextField
                                id="product_sell"
                                label={t('seller.product_sell') + ' *'}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={5}
                                value={state.product_sell}
                                helperText={inputErrors.product_sell ? inputErrors.product_sell : ''}
                                error={inputErrors.product_sell ? true : false}
                                onChange={({ target }) => setState({ ...state, product_sell: target.value })}
                            />
                     </Grid>
                    <Grid item xs={12} md={6}>
                            <TextField
                                id="referral"
                                label={t('seller.referral')}
                                variant="outlined"
                                fullWidth
                                value={state.referral}
                                helperText={inputErrors.referral ? inputErrors.referral : ''}
                                error={inputErrors.referral ? true : false}
                                onChange={({ target }) => setState({ ...state, referral: target.value })}
                            />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="commencementDate"
                            label={t('seller.commencementDate')}
                            variant="outlined"
                            fullWidth
                            type="date"
                            value={state.commencementDate}
                            helperText={inputErrors.commencement_date ? inputErrors.commencement_date : ''}
                            error={inputErrors.commencement_date ? true : false}
                            onChange={({ target }) => setState({ ...state, commencementDate: target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.create_outlet}
                                    onChange={( event ) => setState({ ...state, create_outlet: event.target.checked }) }
                                    name="create_outlet"
                                    color="primary"
                                />
                            }
                            label={t('seller.createOutlet')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='primary' style={{textAlign: 'center'}}>{t('seller.addressDetails')}</Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="zip" 
                            label={t('address.zip')} 
                            variant="outlined" 
                            fullWidth
                            value={state.zip}
                            helperText={inputErrors.zip?inputErrors.zip:''}
                            error={inputErrors.zip?true:false}
                            onChange={({ target }) => setState({ ...state, zip: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        {
                            stateGroup.includes(state.country)
                            ?
                            <TextField 
                                id="state" 
                                label={t('address.state')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.state}
                                helperText={inputErrors.state?inputErrors.state:''}
                                error={inputErrors.state?true:false}
                                onChange={({ target }) => setState({ ...state, state: target.value, city: '', address2: '' }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value='' disabled>
                                {t('address.pleaseSelect')+t('address.state')}
                            </option>
                            {stateList.map((option) => (
                                (option.country_code === state.country)?
                                <option key={option.id} value={option.id}>
                                    {i18n.language === 'cn' ?option.name_cn:option.name }
                                </option>
                                : null
                            ))}
                            </TextField>
                            :
                            <TextField 
                                id="state_text" 
                                label={t('address.state')} 
                                variant="outlined" 
                                fullWidth
                                value={state.state}
                                helperText={inputErrors.state?inputErrors.state:''}
                                error={inputErrors.state?true:false}
                                onChange={({ target }) => setState({ ...state, state: target.value }) }
                            />
                        }                    
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            cityGroup.includes(state.state)
                            ?
                            <TextField 
                                id="city" 
                                label={t('address.city')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.city}
                                helperText={inputErrors.city?inputErrors.city:''}
                                error={inputErrors.city?true:false}
                                onChange={({ target }) => setState({ ...state, city: target.value, address2: '' }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value='' disabled>
                                {t('address.pleaseSelect')+t('address.city')}
                            </option>
                            {cityList.map((option) => (
                                (option.state_id === state.state)?
                                <option key={option.id} value={option.id}>
                                    {i18n.language === 'cn' ?option.name_cn:option.name }
                                </option>
                                : null
                            ))}
                            </TextField>
                            :
                            <TextField 
                                id="city_text" 
                                label={t('address.city')}
                                variant="outlined" 
                                fullWidth 
                                value={state.city}
                                helperText={inputErrors.city?inputErrors.city:''}
                                error={inputErrors.city?true:false}
                                onChange={({ target }) => setState({ ...state, city: target.value }) }
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                    {
                        districtGroup.includes(state.city)
                        ?
                        <TextField 
                            id="address2" 
                            label={t('address.address2')} 
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.address2}
                            helperText={inputErrors.address2?inputErrors.address2:''}
                            error={inputErrors.address2?true:false}
                            onChange={({ target }) => setState({ ...state, address2: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('address.pleaseSelect')+t('address.address2')}
                        </option>
                        {districtList.map((option) => (
                            (option.city_id === state.city)?
                            <option key={option.id} value={option.id}>
                                {i18n.language === 'cn' ?option.name_cn:option.name }
                            </option>
                            : null
                        ))}
                        </TextField>
                        :
                        <TextField 
                            id="address2_text" 
                            label={t('address.address2')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.address2}
                            helperText={inputErrors.address2?inputErrors.address2:''}
                            error={inputErrors.address2?true:false}
                            onChange={({ target }) => setState({ ...state, address2: target.value }) }
                        />
                    }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="address" 
                            label={t('address.address')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.address}
                            helperText={inputErrors.address?inputErrors.address:''}
                            error={inputErrors.address?true:false}
                            onChange={({ target }) => setState({ ...state, address: target.value }) }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='primary' style={{textAlign: 'center'}}>{t('seller.merchantDetails')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="role" 
                            label={t('user.role')} 
                            variant="outlined" 
                            fullWidth 
                            select
                            disabled
                            value={superMerchant.role}
                            helperText={inputErrors.manager_role?inputErrors.manager_role:''}
                            error={inputErrors.manager_role?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, role: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('general.pleaseSelect')}
                        </option>
                        {userRoles.map((value, key) => (
                            <option key={key} value={value.name}>
                                { t('role.'+value.name) }
                            </option>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="name" 
                            label={t('profile.name')} 
                            variant="outlined" 
                            fullWidth 
                            value={superMerchant.name}
                            helperText={inputErrors.manager_name?inputErrors.manager_name:''}
                            error={inputErrors.manager_name?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, name: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField 
                            id="username" 
                            label={t('profile.username')} 
                            variant="outlined" 
                            fullWidth 
                            value={superMerchant.username}
                            helperText={inputErrors.manager_username?inputErrors.manager_username:''}
                            error={inputErrors.manager_username?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, username: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField 
                            id="email" 
                            label={t('profile.email')}
                            variant="outlined" 
                            fullWidth 
                            value={superMerchant.email}
                            helperText={inputErrors.manager_email?inputErrors.manager_email:''}
                            error={inputErrors.manager_email?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, email: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField 
                            id="mobile" 
                            label={t('profile.mobile')} 
                            variant="outlined" 
                            fullWidth 
                            value={superMerchant.mobile}
                            helperText={inputErrors.manager_mobile?inputErrors.manager_mobile:''}
                            error={inputErrors.manager_mobile?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, mobile: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="pwd" 
                            label={t('register.password')}
                            variant="outlined" 
                            fullWidth 
                            type="password"
                            value={superMerchant.pwd}
                            helperText={inputErrors.manager_password?inputErrors.manager_passwprd:''}
                            error={inputErrors.manager_password?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, pwd: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="cfm_pwd" 
                            label={t('register.passwordConfirmation')}
                            variant="outlined" 
                            fullWidth 
                            type="password"
                            value={superMerchant.cfm_pwd}
                            helperText={inputErrors.manager_password_confirmation?inputErrors.manager_password_confirmation:''}
                            error={inputErrors.manager_password_confirmation?true:false}
                            onChange={({ target }) => setSuperMerchant({ ...superMerchant, cfm_pwd: target.value }) }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary' style={{ textAlign: 'center' }}>{t('register.attachments')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <DropzoneArea
                                        acceptedFiles={['image/*', '.pdf']}
                                        dropzoneText={t('register.attachments')}
                                        onChange={(files) => isMountedRef.current ? setAttachments(files) : null}
                                        filesLimit={5}
                                        dropzoneClass={classes.dropzone}
                                        maxFileSize={2000000}
                                    />
                                    <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 5}) + t('general.fileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. pdf. '})}</Typography>
                                    { inputErrors.attachments ?
                                        <FormHelperText style={{color:"red"}}>{inputErrors.attachments}</FormHelperText>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color='primary' onClick={form_submit} >{t('button.save')}</Button>
                </Grid>
                {
                    merchantButton.show?
                    <Grid container spacing={3} direction="row" justify="center">
                        <Grid item>
                            <Button variant="contained" href={`../merchants/add/`+merchantButton.company_id} startIcon={<AddIcon />} style={{padding: "1rem", margin: "1rem"}}>{t('seller.addMerchant')}</Button>
                        </Grid>
                    </Grid>
                    : null
                }
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dropzone: {
        outline: "none",
    },
}));