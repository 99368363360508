import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, putUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, Paper, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, FormControlLabel, Switch } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';


function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

export default function EventProducts() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions, role, isMerchant } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [refreshData, setRefreshData] = useState(false);
    const [duplicateDialog, setDuplicateDialog] = useState({
        open: false,
        pid: ''
    });

    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/event-products${searchString !== '' ? '?'+searchString : ''}`);
    }


    function callApi(){
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order, 'event_only':true  };
        setTableLoading(true);
        getUrl('admin/products', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, querySearch, orderBy, order, refreshData]);

    const changeStatus = (event, pid) => {
        setLoading(true);

        let updateStatus = event.target.checked ? 1 : 0;
        let theIndex = listing.findIndex(item => item.id === pid);
        putUrl(`admin/products/${pid}/status`, {status: updateStatus })
        .then((response) => {
            setLoading(false);
            let {status, message, data} = response;
            if(status === 1){
                let newListing = [...listing];
                newListing[theIndex] = data;
                setListing([...newListing]);

                addAlert('', message, 'success', '');
            }else{
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };

    const handleDuplicateDialogOpen = (pid) => {
        setDuplicateDialog({
            open: true,
            pid: pid
        });
    }
    const handleDuplicateDialogClose = () => {
        setDuplicateDialog({
            open: false,
            pid: ''
        });
    }

    const deleteDuplicating = (pid) => {
        setLoading(true);
        handleDuplicateDialogClose();
        deleteUrl(`admin/products/${pid}/duplicate`).then((response) => {
            setLoading(false);
            if(response.status === 1){
                addAlert('', t('snackbarMsg.deleteSuccess'), 'success', '');
                setRefreshData(!refreshData);
            }else{
                if(response.data){
                    addAlert('', response.data, 'error', '');
                }else{
                    addAlert('', t('snackbarMsg.deleteError'), 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.eventProducts')}</Typography>
                    {_.includes(permissions, "product_add") ?
                        <Link underline='none' color="inherit" to="/event-products/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.id')} cellId={'id'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.productName')} cellId={'title'} />
                                    <TableCell>{t('product.category')}</TableCell>
                                    {isMerchant ? null : <TableCell>{t('product.shopName')}</TableCell> }
                                    {/* <TableCell>{t('product.source')}</TableCell> */}
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('table.ldate')} cellId={'updated_at'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.status')} cellId={'status'} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    let title_translate = _.split(row.title, '|');
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell component="th" scope="row"><Link  style={{color:"#929191"}} underline='none' to={`/event-products/${row.id}`} component={RouterLink}>{ i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }</Link></TableCell>
                                            <TableCell>{ row[`category_${i18n.language}`]}</TableCell>
                                            {isMerchant ? null : <TableCell>{row.shop_name}</TableCell> }
                                            {/* <TableCell>{row.source}</TableCell> */}
                                            <TableCell>{row.updated_at}</TableCell>
                                            <TableCell>
                                                {
                                                    row.status !== -1
                                                    ?
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={isMerchant ? (row.status ? true : false) : (row.status === 0 || row.status === 2 ? false : true)}
                                                                onChange={(event) => changeStatus(event, row.id)}
                                                                name="status"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                    :
                                                    <IconButton color="primary" onClick={() => handleDuplicateDialogOpen(row.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                                <Typography>{ row[`status_display_${i18n.language}`] }</Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={6} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
                <Dialog
                    open={duplicateDialog.open}
                    onClose={() => handleDuplicateDialogClose()}
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle>{t('dialog.reminderTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.deleteDuplicate') })}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDuplicateDialogClose()} color="secondary">{t('button.nope')}!</Button>
                        <Button onClick={() => deleteDuplicating(duplicateDialog.pid)} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));