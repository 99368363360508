import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import _ from 'lodash';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper,FormControlLabel, FormControl, FormHelperText,MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Editor } from '@tinymce/tinymce-react';

import { getUrl, postUrl } from '../helper/ApiAction';
import DescriptionIcon from '@material-ui/icons/Description';

export default function NotificationAdd() {
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { t,i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const [state, setState] = useState({
        type: "message",
        subject: '',
        message_cn: '',
        message_en: '',
    });
    const [memberList, setMemberList] = useState([{id: 'all', name: "All"}]);
    const [recipients, setRecipients] = useState();
    const [notificationAdd, setOpenNotificationAdd] = useState();
    {/*useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`admin/members`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setLoading(false);
                const { data } = response;
                setMemberList([{id: 'all', name: "All"}, ...data]);
            }
        }).catch(error => {
            if(isMountedRef.current) {
                let msg = error+"\n"+t('error.contactSupport');
                addAlert('', msg);
                setLoading(false);
            }
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    },[addAlert]);*/}

    const addNotification = () => {
        postUrl(`admin/notifications`, {
            type: state.type,
            subject: state.subject,
            message_cn: state.message_cn,
            message_en: state.message_en,
            recipients: recipients, 
        }).then(response => {
            let {status, message, error, data} = response;
            if(status === 1) {
                addAlert('', message, 'success', '');
                setTimeout(
                    () => history.replace(`/notifications/${data.id}`),
                    1500
                );
                
            } else {
                if(_.size(error) > 0){
                    _.map(error, (value, key) => {       
                        message += "\n "+value[0];
                    })
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    
    const messageType = [
        { code: 'promotions', title: {'en': 'Promotions', 'cn': '促销活动'}},
        { code: 'mstore_update', title: {'en': 'HealthCare2U Updates', 'cn': 'HealthCare2U 更新'}},
        { code: 'prizes_notification', title: {'en': 'Prizes notification', 'cn': '奖励通知'}},
        ];
    function useRefundSelect({ data, label, val,language }) {
        const [value, setValue] = useState(val);
        const [error, setErrorValue] = useState("");
        const select =
            <TextField
                select
                id ="type"
                label={label}
                value={ state[value]}
                onChange={({ target }) => setState({ ...state, type: target.value })}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {
                    _.map(data, dataList => {
                        return (
                            <MenuItem 
                                key={dataList['code'] ? dataList['code'] : dataList.id} 
                                value={dataList['code'] ? dataList['code'] : dataList.id}
                            >
                                { 
                                    language 
                                    ? dataList[i18n.language] ? dataList[i18n.language] : dataList.title[i18n.language] 
                                    : dataList.name
                                }
                            </MenuItem>
                        )
                    })
                }
            </TextField>
        return [value, select, setErrorValue, setValue];
    }
    const [type, messageTypeInput, setmessageTypeError] = useRefundSelect({ data: messageType, label: `${t('notification.type')}*`, val: "type" ,language:true});
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{ t('notification.addNotification') }</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/notifications`} component={RouterLink}>{t('title.notifications')}</Link>
                        <Typography style={{ color: 'black' }}>{t('notification.addNotification')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                {/*<TextField
                                    id="type"
                                    label={ t('notification.type') }
                                    value={state.type}
                                    onChange={({ target }) => setState({ ...state, type: target.value }) }
                                    variant="outlined"
                                    fullWidth
                                />*/}
                                {messageTypeInput}
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="subject" 
                                    label={ t('notification.subject') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.subject}
                                    placeholder={`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` }
                                    onChange={({ target }) => setState({ ...state, subject: target.value }) }    
                                />
                            </Grid>
                        </Grid>  
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                {/*<Autocomplete
                                    multiple
                                    id="recipients"
                                    options={memberList}
                                    getOptionLabel={option => `${option.name}${option.email ? `-${option.email}`: ''}${option.id !== "all" ? `[ID: ${option.id}]`: ''}`}
                                    defaultValue={[]}
                                    value={recipients}
                                    filterSelectedOptions
                                    onChange={(event, newValue) => { setRecipients(newValue); }}
                                    disableCloseOnSelect
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={t('notification.recipients')}
                                            placeholder={t('notification.recipients')}
                                        />
                                    )}
                                    />*/}
                                    <TextField 
                                    id="recipients" 
                                    label={ t('notification.recipients') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={recipients}
                                    onChange={({ target }) => setRecipients( target.value ) }    
                                />
                                <Typography variant="body2" component="label">{t('notification.subtitle')}</Typography>
                                <Grid>
                                    <Typography variant="body2" component="label">{t('notification.subtitle2')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>    
                        {/*<Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="message" 
                                    label={ t('notification.message') }
                                    multiline
                                    rows={4}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.message}
                                    onChange={({ target }) => setState({ ...state, message: target.value }) }    
                                />
                            </Grid>
                        </Grid>*/}
                        <Grid container >
                            <Grid item xs={12} style={{marginTop:15}}>
                                <Typography variant="h5"   component="h1" style={{paddingBottom: 10}}>{t('notification.message')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary"  onClick={() => setOpenNotificationAdd("message_en")} startIcon={<DescriptionIcon />}fullWidth>{t('product.english')}</Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary"  onClick={() => setOpenNotificationAdd("message_cn")} fullWidth startIcon={<DescriptionIcon />}>{t('product.mandarin')}</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <Button variant="contained" color="primary" onClick={addNotification}>{ t('button.add') }</Button>
                            </Grid>
                        </Grid>    
                    </form>
                    <Dialog
                        open={notificationAdd? true:false}
                        onClose={() => setOpenNotificationAdd()}
                        fullWidth= {true}
                        maxWidth={'md'}
                        aria-labelledby="edit-option-dialog-title"
                        aria-describedby="edit-option-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{t('notification.message')}</DialogTitle>
                        <DialogContent>
                            {
                                notificationAdd == "message_cn" ? 
                                <FormControl variant="outlined" fullWidth size="small" >
                                <Typography variant="subtitle2" component="label">{t('notification.message')}</Typography>
                                <Editor
                                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                    value={state.message_cn}
                                    init={{
                                        height: "70vh",
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount quickbars'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor forecolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                    }}
                                    onEditorChange={(content, editor) => setState({ ...state, message_cn: content})}
                                />
                            </FormControl>
                            :
                            <FormControl variant="outlined" fullWidth size="small" >
                                <Typography variant="subtitle2" component="label">{t('notification.message')}</Typography>
                                <Editor
                                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                    value={state.message_en}
                                    init={{
                                        height: "70vh",
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount quickbars'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor forecolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                    }}
                                    onEditorChange={(content, editor) => setState({ ...state, message_en: content})}
                                />
                            </FormControl>
                            }
                            <DialogContentText id="alert-dialog-description">{t('notification.askDeleteNotification')}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenNotificationAdd()} color="secondary">{t('button.confirm')}!</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
            
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));